<div class="form">
    <div class="mtc-header py-2 position-relative" fxLayout="row" fxLayoutAlign="center center">
        Paste to
    </div>
    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>

        <div class="middle-cont">
            <div class="div-sec" *ngIf="copyData.entity == 'neighbourhood'">
              
                <app-multi-select (changeEvent)="getValue($event)" [itemList]="neighList" 
                                [value_field]="'neigh_uid'" 
                                [field]="'neigh_name'" 
                                [placeholder]="'Select neighbourhood'"
                                [search]="true"></app-multi-select>
            </div>
            
            <ng-container *ngIf="copyData.entity == 'block'">
                <div class="div-sec mt-2">
                    <app-single-select (changeEvent)="getBlocks($event)" [itemList]="neighList" 
                        [value_field]="'neigh_uid'" 
                        [field]="'neigh_name'"
                        [placeholder]="'Select Neighburhood'"
                        [search]="true"></app-single-select>
                    <!-- <mat-select [(ngModel)]="selected_neighbourhood" placeholder="Select neighbourhood"  (selectionChange)="getBlocks($event.value)">
                        <input  [ngModelOptions]="{standalone: true}" class="searchIn" mat-input [(ngModel)]="searchText"  placeholder="Type here to search..." />
                        <ng-container *ngFor="let obj of neighList | filter : 'neigh_name': searchText" >
                            <mat-option value="{{obj.neigh_uid}}">
                              {{obj.neigh_name}}
                            </mat-option>
                        </ng-container>
                    </mat-select> -->
                    
                </div>
                <div class="div-sec">
                  
                    <app-multi-select (changeEvent)="getValueBlock($event)" [itemList]="blockList" 
                                [value_field]="'block_uid'" 
                                [field]="'block_name'" 
                                [placeholder]="'Select block'"
                                [search]="true"></app-multi-select>
                </div>
            </ng-container>
        </div>
        <div class="btn-action">
            <button type="submit" (click)="submit()">Save</button>
            <button type="button" (click)="close()">Cancel</button>
        </div>
</div>
