import { Component, OnInit, Output, ViewChild, AfterViewInit, QueryList, Inject, EventEmitter, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { ShareService } from '../services/share.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { UserAction } from "../user-action/user-activity";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-2';
import { ImageEditorComponent } from '../image-editor/image-editor.component';


/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>umsapi_language_get</li>
 * <li>umsapi_user_user_id_patch</li>
 * <li>umsapi_place_get</li>
 * <li>estapi_uploads_profile_images_post</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent implements OnInit {

  @Output() close = new EventEmitter();

  user_detail: any;
  language_list = [];
  city_list = [];
  loader: boolean = false;
  loader1: boolean = false;
  oldPass: String = '';
  newPass: string = '';
  newPass1: string = '';
  change: boolean = false;
  disableSubmit: boolean = false;
  profileForm: UntypedFormGroup;
  mismatch: boolean;
  passError: string;

  SearchCountryField = SearchCountryField;
  cuntry: string = 'us';
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Bahrain,
    CountryISO.India,
    CountryISO.Kuwait,
    CountryISO.SaudiArabia,
    CountryISO.Switzerland,
    CountryISO.UnitedKingdom,
    CountryISO.UnitedStates,
  ];
  @ViewChild('imageInput') imageInput;
  profile_image: string = '';
  constructor(private api: ApiserviceService, private fnc: CommonfunctionService,
    private dialog: MatDialog,
    private elementRef: ElementRef, private notify: NotificationService, private fb: UntypedFormBuilder, private share: ShareService) {
    this.getLanguageList();
    this.user_detail = JSON.parse(localStorage.getItem('user_info'));
    let phone_number = (this.user_detail?.contact) ? this.user_detail.contact.split('-')[1] : '';
    this.profile_image = localStorage.getItem('profile_image');
    this.profileForm = this.fb.group({
      'fname': [this.user_detail.fname, Validators.compose([Validators.required, Validators.maxLength(45), Validators.minLength(2), Validators.pattern('^[a-zA-Z ]*$')])],
      'lname': [this.user_detail.lname, Validators.compose([Validators.required, Validators.maxLength(45), Validators.minLength(2), Validators.pattern('^[a-zA-Z ]*$')])],
      'email': [{ value: this.user_detail.email, disabled: true }],
      'contact': [this.user_detail.contact, Validators.required],
      'city': [this.user_detail.country, Validators.required],
      'language': [this.user_detail.language, Validators.required],
      'image': [this.profile_image]
    });
    setTimeout(() => { this.profileForm.get('contact')?.setValue(phone_number) }, 150);
  }

  getLanguageList() {
    this.api.getUmsData('language')
      .subscribe(
        (res: any) => {
          if (res && res.data) {
            res.data.forEach(element => {
              if (element.status == 1) {
                this.language_list.push(element);
              }
              
            });
          }
        }
      );
  }

  changePass() {
    this.change = !this.change;
    if (this.change) {

    }
  }

  changePassword() {
    if (this.newPass === this.newPass1) {
      this.mismatch = false;
    }
    else {
      this.mismatch = true;
      this.passError = "Password Mismatch";
      setTimeout(() => {
        this.mismatch = false;
      }, 2000);
      return;
    }

    var body = {
      "user_id": this.api.user_id,
      "new_password": this.newPass,
      "old_password": this.oldPass
    }
    this.api.postUmsData('change-password', body).subscribe({
     next: (res: any) => {
      if (res && res.status == 201) {
        this.notify.notify(res.message, "success", 5000);
        this.close.emit();
      }
      else {
        // this.notify.notify(res.message, "Dismiss", 5000);
        this.mismatch = true;
        this.passError = res.message;
        setTimeout(() => {
          this.mismatch = false;
        }, 2000);
      }

    }, error : err => {
      this.notify.notify(err.error.message, 'error', 5000)
    }});
  }
  compare(id) {
    this.newPass1 = this.newPass1.trim();
    this.newPass = this.newPass.trim();
    if (id == 'new') {
      if (this.newPass1) {
        if (this.newPass == this.newPass1) {
          this.disableSubmit = true;
        } else {
          this.disableSubmit = false;
        }
      }
    } else {
      if (this.newPass) {
        if (this.newPass1 == this.newPass) {
          this.disableSubmit = true;
        } else {
          this.disableSubmit = false;
        }
      }
    }
  }

  ngOnInit() {
    this.getCityList();
  }
  getCityList() {
    this.api.getUmsData('place')
      .subscribe({next: (res: any) => {
        if(res.status == 200){
          res.data.forEach(element => {
            if (element.status == 1) {
              this.city_list.push(element);
            }
          });
        }
       
      },
    
    });
  }
  /**
  * This is a funtion that use to attach a file and open crop option to edit image
  
  * @param event event is parameter
  * @returns return void
  */

  uploadExplorImage(event) {
    const dialogRef = this.dialog.open(ImageEditorComponent, {
      width: '800px',
      panelClass: 'image-croper-dialog',
      data: { info: event, ratio: 1 / 1, size: 512, type: 'profile' },
      disableClose: true
    })
    dialogRef.afterClosed().subscribe((result: FormData) => {
      if (result) {
        this.uploadImage(result)
      }
      this.imageInput.nativeElement.value = '';
    })
  }
  /**
 * This is a funtion that use to update profile image

 * @param {FormData} formData event is parameter
 * @returns return void
 */
  uploadImage(formData: FormData) {
    this.loader = true;
    if (formData) {

      this.api.postFileUms(`profile-image?user_id=${this.api.user_id}`, formData).subscribe({
        next:(res: any) => {
          if (res && res.status == 201 && res.data) {
            this.loader = false;
            this.profileForm.patchValue({
              image: res.data
            })
            this.imageInput.nativeElement.value = '';
            this.notify.notify(res.message, 'success', 5000);
            localStorage.setItem('profile_image', res.data);
            this.api.profile_image.next(res.data);
            this.profile_image = res.data;
            // this.api.getUserDetail(this.api.user_id);

          }
        },
          error:err => {
            this.loader = false;
          
          }
    });
    }
  }

  openExplorer(type) {
    if (type == 'image') {
      let e = $(this.elementRef.nativeElement);
      e.find("#image_input").click();
    }
  }
  /**
   * This is a function that use to update profile
   * @param e e is use payload param
   */
  saveUserdetail(e): void {
    let user_name;
    this.loader1 = true;
    let val = e.value;
    let lang_code = e.value.language;
    let lang_detail = this.fnc.getArrayValue('language_code', lang_code, this.api.language_list);

    let attribute = {};
    attribute[1] = { "first_name": e.value.fname, "last_name": e.value.lname }
    let country_code = (e.value.contact) ? e.value.contact.dialCode : '';
    let phone_number = (e.value.contact?.e164Number) ? e.value.contact.e164Number.split(country_code)[1] : '';

    let body = {
      "attributes": attribute,
      "phone_number": country_code ? `${country_code}-${phone_number}` : null,
      "gender": e.value.gender,
      "nationality": e.value.city,
      "profile_image": this.profile_image?this.profile_image:null
    }

    this.api.patchUmsData(`user?updated_by=${this.api.user_id}&id=${this.api.user_id}`, body).subscribe({
      next:(res: any) => {
        this.loader1 = false;
        if (res && res.status == 201) {
          this.api.getUserDetail(this.api.user_id);
          this.notify.notify(res.message, "success", 5000);
        }
        else {
          this.notify.notify(res.message, 'warn', 5000);
        }
      },error: err => {
        this.loader1= false;
      }})
  }

}
