import { Injectable } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { ShareService } from './share.service';

@Injectable()
export class AppNotificationService {
  notification_list: any = [];
  top_notifications: any = [];
  notification_detail: any;
  constructor(private api: ApiserviceService, private share: ShareService) {
    this.getCount();
  }

  getCount(offset = 0){
    // let url = `deapp/notification/${this.api.user_id}?notification_status=sent&offset=${offset}&sort_by=added_date`;
    // this.api.getData(url).subscribe((res:any) => {
    //   if(this.share.adminComp) this.share.adminComp.notifycounter = res.length;
    // }, err=>{
    //   if(this.share.adminComp) this.share.adminComp.notifycounter = 0;
    // })
  }

}
