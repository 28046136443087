<ng-container *ngIf="selectedDataType.length>0">
  <ng-container *ngFor="let data of selectedDataType">
    <div class="rel main_div" *ngIf="data.render_type == 'paragraph'">
      <app-paragraph [editable]="editable" [editExisting]="editExisting" [data]="data" (templateUpdate)="getTemplate($event, data.id)"></app-paragraph>
      <button class="close-btn" mat-mini-fab (click)="removeItem(data.id)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="rel main_div" *ngIf="data.render_type == 'list'">
      <app-list [editable]="editable" [data]="data" (templateUpdate)="getTemplate($event, data.id)"></app-list>
      <button class="close-btn" mat-mini-fab (click)="removeItem(data.id)">
          <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="rel main_div" *ngIf="data.render_type == 'image'">
      <app-image [editable]="editable" [data]="data.data" (templateUpdate)="getTemplate($event, data.id)"></app-image>
      <button class="close-btn" mat-mini-fab (click)="removeItem(data.id)">
          <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="rel main_div" *ngIf="data.render_type == 'chart'">
      <app-chart [editable]="editable" [data]="data.data" (templateUpdate)="getTemplate($event, data.id)"></app-chart>
      <button class="close-btn" mat-mini-fab (click)="removeItem(data.id)">
          <mat-icon>close</mat-icon>
        </button>
    </div>
  </ng-container>
</ng-container>

<mat-toolbar class="w-100" fxLayout="row" fxLayoutAlign="start center">
  <button mat-button (click)="addDataType('paragraph')" [disabled]="!editable">
    <mat-icon [ngClass]="{'est-dgr': editable, 'est-gr': !editable}">short_text</mat-icon>
    Add text
  </button>
  <button mat-button (click)="addDataType('list')" [disabled]="!editable">
    <mat-icon [ngClass]="{'est-dgr': editable, 'est-gr': !editable}">more_horiz</mat-icon>
    Add list
  </button>
  <button mat-button (click)="addDataType('chart')" [disabled]="!editable">
    <mat-icon [ngClass]="{'est-dgr': editable, 'est-gr': !editable}">insert_chart_outlined</mat-icon>
    Add bar chart
  </button>
  <button mat-button (click)="addDataType('image')" [disabled]="!editable">
    <mat-icon [ngClass]="{'est-dgr': editable, 'est-gr': !editable}">add_a_photo</mat-icon>
    Add image
  </button>
</mat-toolbar>