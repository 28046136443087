<div class="">
    <div class="header-bar">
        <span *ngIf="action_type=='add'">Add Package Subscription</span>
        <span *ngIf="action_type=='edit'">Update Package Subscription</span>
    </div>
    <mat-progress-bar *ngIf="loader" class="progress" [color]="'primary'" mode="indeterminate">
    </mat-progress-bar>
    <form [formGroup]="PackageSubsForm" autocomplete="off" novalidate (ngSubmit)="addPackage(PackageSubsForm.value)" class="package-form">
        <mat-dialog-content style="max-height: 550px; margin: 0; padding: 0;">
            <div class="grid">                 
                                       
                <div class="card-section">
                    <span class="detail mb-3">Company Name <span class="text-danger">*</span></span>
                    <mat-select formControlName="company_name">
                        <mat-option [value]="com.company_id" *ngFor="let com of comp_list">{{com.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="PackageSubsForm.controls['company_name'].hasError('required') && PackageSubsForm.controls['company_name'].touched">Company name is required</mat-error>
                    <div class="p-2" *ngIf="comp_list.length == 0 && !loader">
                       <small> No company available, create one <a (click)="close()" routerLink="admin/broker-master/company">here</a> to assign subscription</small>
                    </div>
                </div>
            
                <div class="card-section">
                    <span class="detail mb-3">Package Name <span class="text-danger">*</span></span>
                    <mat-select formControlName="package_name">
                        <mat-option [value]="com.package_id" *ngFor="let com of package_list">{{com.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="PackageSubsForm.controls['package_name'].hasError('required') && PackageSubsForm.controls['package_name'].touched">Package name is required</mat-error>
                    <div class="p-2" *ngIf="package_list.length == 0 && !loader">
                        <small>No package available, create one <a (click)="close()" routerLink="admin/broker-master/package">here</a> to assign subscription </small>
                    </div>
                </div>
                  
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <div class="d-flex justify-content-end px-4">
                <button type="button" (click)="close()" class="cancel-btn mx-1">Cancel</button>
                <button *ngIf="action_type=='add'" type="submit" class="save-button mx-1" [disabled]="loader" tabindex="60"> Submit</button>
                <button *ngIf="action_type=='edit'" type="button" class="save-button mx-1" (click)="addPackage(PackageSubsForm.value)" [disabled]="loader"> Update</button>
            </div>
        </mat-dialog-actions>
    </form>
    
</div>