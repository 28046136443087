export const UserAction = {
    // "drop_marker": {"Action_ID":1,"Action_Type":"drop_marker"},
    // "view_property_detail": {"Action_ID":2,"Action_Type":"view_property_detail"},
    // "follow_property": {"Action_ID":3,"Action_Type":"follow_property"},
    // "save_property": {"Action_ID":4,"Action_Type":"save_property"},
    // "share_property": {"Action_ID":5,"Action_Type":"share_property"},
    // "nearby": {"Action_ID":6,"Action_Type":"nearby"},
    // "neighborhood": {"Action_ID":7,"Action_Type":"neighborhood"},
    // "block": {"Action_ID":8,"Action_Type":"block"},
    // "property_visit": {"Action_ID":9,"Action_Type":"property_visit"},
    // "tour": {"Action_ID":10,"Action_Type":"tour"},
    // "detail_menu": {"Action_ID":11,"Action_Type":"detail_menu"},
    // "compare_property": {"Action_ID":12,"Action_Type":"compare_property"},
    // "compare_pdf_download": {"Action_ID":13,"Action_Type":"compare_pdf_download"},
    // "add_statistics": {"Action_ID":14,"Action_Type":"add_statistics"},
    // "update_statistics": {"Action_ID":15,"Action_Type":"update_statistics"},
    // "delete_statistics": {"Action_ID":16,"Action_Type":"delete_statistics"},
    // "add_categories": {"Action_ID":17,"Action_Type":"add_categories"},
    // "update_categories": {"Action_ID":18,"Action_Type":"update_categories"},
    // "block_download": {"Action_ID":19,"Action_Type":"block_download"},
    // "download_price_data": {"Action_ID":20,"Action_Type":"download_price_data"},
    // "add_setting": {"Action_ID":21,"Action_Type":"add_setting"},
    // "update_setting": {"Action_ID":22,"Action_Type":"update_setting"},
    // "delete_setting": {"Action_ID":23,"Action_Type":"delete_setting"},
    // "add_attribute": {"Action_ID":24,"Action_Type":"add_attribute"},
    // "update_attribute": {"Action_ID":25,"Action_Type":"update_attribute"},
    // "delete_attribute": {"Action_ID":26,"Action_Type":"delete_attribute"},
    // "add_valuation_factor": {"Action_ID":27,"Action_Type":"add_valuation_factor"},
    // "update_valuation_factor": {"Action_ID":28,"Action_Type":"update_valuation_factor"},
    // "delete_valuation_factor": {"Action_ID":29,"Action_Type":"delete_valuation_factor"},
    // "add_transaction_attribute": {"Action_ID":30,"Action_Type":"add_transaction_attribute"},
    // "update_transaction_attribute": {"Action_ID":31,"Action_Type":"update_transaction_attribute"},
    // "delete_transaction_attribute": {"Action_ID":32,"Action_Type":"delete_transaction_attribute"},
    // "add_parameter": {"Action_ID":33,"Action_Type":"add_parameter"},
    // "update_parameter": {"Action_ID":34,"Action_Type":"update_parameter"},
    // "delete_parameter": {"Action_ID":35,"Action_Type":"delete_parameter"},
    // "set_marker": {"Action_ID":36,"Action_Type":"set_marker"},
    // "delete_marker": {"Action_ID":37,"Action_Type":"delete_marker"},
    // "login": {"Action_ID":38,"Action_Type":"login"},
    // "logout": {"Action_ID":39,"Action_Type":"logout"},
    // "update_profile": {"Action_ID":40,"Action_Type":"update_profile"},
    // "sync_data": {"Action_ID":41,"Action_Type":"sync_data"},
    // "csv_upload": {"Action_ID":42,"Action_Type":"csv_upload"},
    // "block_remove": {"Action_ID":43,"Action_Type":"block_remove"},
    // "send_app_notification": {"Action_ID":44,"Action_Type":"send_app_notification"},
    // "broker_company_add": {"Action_ID":45,"Action_Type":"broker_company_add"},
    // "broker_company_update": {"Action_ID":46,"Action_Type":"broker_company_update"},
    // "broker_company_delete": {"Action_ID":47,"Action_Type":"broker_company_delete"},
    // "package_master_add": {"Action_ID":48,"Action_Type":"package_master_add"},
    // "package_master_update": {"Action_ID":49,"Action_Type":"package_master_update"},
    // "package_master_delete": {"Action_ID":50,"Action_Type":"package_master_delete"},
    // "broker_package_subscriprtion_add": {"Action_ID":51,"Action_Type":"broker_package_subscriprtion_add"},
    // "broker_package_subscriprtion_update": {"Action_ID":52,"Action_Type":"broker_package_subscriprtion_update"},
    // "broker_package_subscriprtion_delete": {"Action_ID":53,"Action_Type":"broker_package_subscriprtion_delete"},
    // "api_master_add": {"Action_ID":54,"Action_Type":"api_master_add"},
    // "api_master_update": {"Action_ID":55,"Action_Type":"api_master_update"},
    // "api_master_delete": {"Action_ID":56,"Action_Type":"api_master_delete"},
    // "company_area_assign": {"Action_ID":57,"Action_Type":"company_area_assign"},
    // "menu_update": {"Action_ID":58,"Action_Type":"menu_update"},
    // "menu_delete": {"Action_ID":59,"Action_Type":"menu_delete"},

    // 
    "drop_marker": {"Action_ID":1,"Action_Type":"drop_marker"},
    "view_property_detail": {"Action_ID":2,"Action_Type":"view_property_detail"},
    "track_property": {"Action_ID":3,"Action_Type":"track_property"},
    "save_property": {"Action_ID":4,"Action_Type":"save_property"},
    "share_property": {"Action_ID":5,"Action_Type":"share_property"},
    "nearby": {"Action_ID":6,"Action_Type":"nearby"},
    "neighborhood": {"Action_ID":7,"Action_Type":"neighborhood"},
    "block": {"Action_ID":8,"Action_Type":"block"},
    "property_visit": {"Action_ID":9,"Action_Type":"property_visit"},
    "tour": {"Action_ID":10,"Action_Type":"tour"},
    "compare_property": {"Action_ID":11,"Action_Type":"compare_property"},
    "login": {"Action_ID":12,"Action_Type":"login"},
    "logout": {"Action_ID":13,"Action_Type":"logout"},
    "own_property": {"Action_ID":14,"Action_Type":"own_property"},
    "track_location": {"Action_ID":15,"Action_Type":"track_location"},
    "get_estimation": {"Action_ID":16,"Action_Type":"get_estimation"},
}
