import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CommonfunctionService } from '../../../../services/commonfunction.service';

@Component({
  selector: 'app-combo-toolbar',
  templateUrl: './combo-toolbar.component.html',
  styleUrls: ['./combo-toolbar.component.css']
})
export class ComboToolbarComponent implements OnInit {
  @Input() editable: boolean = false;
  @Input() data: any;
  @Input() editExisting: boolean = false;
  @Input() preview: boolean = false;
  @Input() selectedDataType: any = [];
  @Output() templateModified = new EventEmitter();
  id: number = 1;
  constructor(private fnc: CommonfunctionService, private cdRef:ChangeDetectorRef) {

  }

  ngOnInit() {

  }

  getExistingData() {
    if (this.data) {
      let arr = this.data;
      arr.forEach(element => {
        this.selectedDataType.push({
          render_type: element.render_type,
          data: element.data,
          id: this.id++,
          value: (element.data && element.data.value)? element.data.value : null,
          options: null
        })
      });
      // this.selectedDataType = this.data.data;
    }
  }

  addDataType(type, data?, options = {}) {
    this.selectedDataType.push(
      {
        render_type: type,
        data: data,
        id: this.id++,
        options: options,
        value: null
      }
    );
  }

  removeItem(id) {
    this.selectedDataType = this.fnc.getDeletedArray('id', id, this.selectedDataType);
  }

  getTemplate(template, id) {
    let arr: any = [];
    if (id) {
      this.selectedDataType.forEach(data => {
        if (data.id == id) {
          data.data = template;
          arr.push(data);
        } else {
          arr.push(data);
        }
      });
      this.templateModified.emit(arr);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getExistingData();   
      this.cdRef.detectChanges(); 
    }, 0);
  }
}
