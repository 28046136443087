import { Component, OnInit, Inject, inject } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormArray } from "@angular/forms";
import { ApiserviceService } from "../../../apiservice.service";
import { NotificationService } from "../../services/notification.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserAction } from "../../user-action/user-activity";
@Component({
  selector: 'app-add-valuation-factor-master',
  templateUrl: './add-valuation-factor-master.component.html',
  styleUrls: ['./add-valuation-factor-master.component.css']
})
export class AddValuationFactorMasterComponent implements OnInit {

  calculationType: any = [{
    label: "Absolute",
    value: "absolute"
  },
  {
    label: "Percent",
    value: "percent"
  }];
  firstValue: any = [{
    label: "Estater Assumption",
    value: "estater_assumption"
  },
  {
    label: "User Input",
    value: "user_input"
  }];

  public factorsForm: any = [];
  public editFactorsForm: any = [];
  public languages: Array<any> = [];
  public languageSet: Array<{}> = [];
  public langDescSet: Array<{}> = [];
  public Form: any;
  public factor_name: any;
  public parent_data;
  public mode;
  public factorForm: any;
  public loader: boolean;
  public language_list: any = [];
  constructor(
    public dialogRef: MatDialogRef<AddValuationFactorMasterComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any,
    private notify: NotificationService,
    private apiService: ApiserviceService,
    private fb: UntypedFormBuilder

  ) {
    this.parent_data = this.info;
    this.mode = this.parent_data.type;

    // this.getAttributes();
    this.language_list = this.apiService.language_list
    if (this.mode == 'add') {
      this.factorForm = this.fb.group({
        factorName: ['', Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9_ ]*$')])],
        language: this.fb.array([]),
        descLanguage: this.fb.array([]),
        // value: ['', Validators.required],
        calcType: ['', Validators.required],
        firstvalue: ['', Validators.required],
      });
      this.addRow();
      this.addDescRow();
    }
    if (this.mode == 'edit') {
      this.factorForm = this.fb.group({
        id: [null],
        factorName: [{ value: '', disabled: true }],
        language: this.fb.array([]),
        descLanguage: this.fb.array([]),
        // value: ['', Validators.required],
        calcType: [{ value: '', disabled: true }],
        firstvalue: [{ value: '', disabled: true }],
      });
      let editData = this.parent_data.edit, labels: any = [], index: number = 0;
      if (editData.label) {
        for (let prop in editData.label) {
          labels[index] = { language: +prop, value: editData.label[prop] };  // +prop to convert into number
          this.addRow();
          index++
        }
      }
      let desc_labels = [];
      index = 0;
      if (editData.details) {
        for (let prop in editData.details) {
          desc_labels[index] = { language: +prop, value: editData.details[prop] };  // +prop to convert into number
          index++
        }
      }


      this.addDescRow();
      setTimeout(() => {
        this.factorForm.patchValue({
          'id': this.parent_data.edit.id,
          'factorName': this.parent_data.edit.name,
          'firstvalue': this.parent_data.edit.first_value,
          'calcType': this.parent_data.edit.calculation_type,
          'language': labels,
          'descLanguage': desc_labels,
          // 'value': this.parent_data.edit.details,
        });
      });
    }

  }

  addFactor(val) {
    let data = val.value;
    let re = /\ /gi;
    this.factor_name = data.factorName.trim().replace(re, '_');
    let factor_label = {}, factor_desc = {};
    data.language.forEach((v, k) => {
      factor_label[v.language] = v.value;

    });
    data.descLanguage.forEach((v, k) => {
      factor_desc[v.language] = v.value;

    });

    let url = `valuation-factor-master`;
    let body = {
      "name": data.factorName,
      "label": factor_label,
      "calculation_type": data.calcType,
      "first_value": data.firstvalue,
      "description": factor_desc,
      "added_by": this.apiService.user_id,
    }
    this.loader = true;
    this.apiService.postValData(url, body).subscribe((res: any) => {
      if (res && res.status == 201) {
        // const activity={
        //   actionId: res.data,
        //   data: body,
        //   actionType:UserAction.add_valuation_factor.Action_ID
        // }
        // this.apiService.logUserActivity(activity);
        this.notify.notify(res.message, 'success');
        this.parent_data.pdata.getValuationMasters();
        this.parent_data.pdata.getFactorCount();
      }
      else {
        this.loader = false;
        this.notify.notify(res.message, 'warn')
      }
      this.loader = false;
      this.cancel();

    }, err => {
      this.loader = false;
      this.notify.notify('valuation factor Add Failure', 'error');
    })
  }

  updateValuation(val) {
    let updateData = val.value;
    let factor_label = {}, factor_desc = {};
    updateData.language.forEach((v, k) => {
      factor_label[v.language] = v.value;
    });
    updateData.descLanguage.forEach((v, k) => {
      factor_desc[v.language] = v.value;
    });
    let url = `valuation-factor-master`
    let body = {
      "id": updateData.id,
      "label": factor_label,
      "description": factor_desc,
      "added_by": this.apiService.user_id,
    }
    this.loader = true;
    this.apiService.patchValData(url, body).subscribe((res: any) => {
      if (res && res.status == 201) {
        // const activity={
        //   actionId: res.data,
        //   data: body,
        //   actionType:UserAction.update_valuation_factor.Action_ID
        // }
        // this.apiService.logUserActivity(activity);
        // this.getCategory();
        this.notify.notify(res.message, 'success');
        this.parent_data.pdata.getValuationMasters();
        this.parent_data.pdata.getFactorCount();
        this.factorForm.patchValue({ 'factor_name': null, 'language': [{ language: null, value: '' }] });
        // this.isFormDiv = false;
        // this.add_category = 'CATEGORYLIST';
      }
      else{
        this.loader = false;
        this.notify.notify(res.message, 'error')
      }
      this.loader = false;
      this.dialogRef.close();
    }, err => {
      this.loader = false;
      this.notify.notify('Valuation Factor Updation Error', 'error');
    })
  }

  initLanguageFields() {
    return this.fb.group({
      language: [this.language_list[0].language_id, Validators.required],
      value: ['',Validators.required]
    })
  }
  initDescLanguageFields() {
    return this.fb.group({
      language: [this.language_list[0].language_id,Validators.required],
      value: ['']
    })
  }
  addRow() {
    if (this.languageSet.length != this.language_list.length) {
      this.languageSet.push({ language: '', value: '' });
      /* #Form */
      let dd = <UntypedFormArray>this.factorForm.controls.language;
      dd.push(this.initLanguageFields());
      /* End - #Form */
    }
    else {
      this.notify.notify('No more languages to add', 'error', 5000);

    }
  }
  removeRow(key) {
    if (this.languageSet.length > 1) {
      this.languageSet.splice(key, 1);
      let dd = <UntypedFormArray>this.factorForm.controls.language;
      dd.removeAt(key);
    }
  }

  addDescRow() {
    if (this.langDescSet.length != this.language_list.length) {
      this.langDescSet.push({ language: '', value: '' });
      /* #Form */
      let dd = <UntypedFormArray>this.factorForm.controls.descLanguage;
      dd.push(this.initDescLanguageFields());
      /* End - #Form */
    }
    else {
      this.notify.notify('No more languages to add', 'error', 5000);
    }
  }

  removeDescRow(key) {
    if (this.langDescSet.length > 1) {
      this.langDescSet.splice(key, 1);
      let dd = <UntypedFormArray>this.factorForm.controls.descLanguage;
      dd.removeAt(key);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }


}
