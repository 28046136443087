import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminComponent } from '../admin.component';
import { AuthGuard } from '../services/auth.guard';
import { AdminValuatorComponent } from '../admin-valuator/admin-valuator.component';
// import { PropertyLandConfigComponent } from '../propertyLandConfig/property_land_config';
import { CsvUploadComponent } from '../csv-upload/csv-upload.component';
import { CategoriesComponent } from '../categories/categories.component';
import { SyncPropertyComponent } from '../sync-property/sync-property.component';
import { BlockValuationComponent } from '../block-valuation/block-valuation.component';
import { PricingDownloadComponent } from '../pricing-download/pricing-download.component';
import { RemoveBlockComponent } from '../remove-block/remove-block.component';
import { SettingsComponent } from '../settings/settings.component';
import { BrokerMasterComponent } from '../broker-master/broker-master.component';
import { ListableAttributeComponent } from "../listable-attribute/listable-attribute.component";
import { ValuationFactorMasterComponent } from "../valuation-factor-master/valuation-factor-master.component";
import { TransactionAttributeComponent } from "../transaction-attribute/transaction-attribute.component";
import { ComparisonParameterComponent } from "../comparison-parameter/comparison-parameter.component";
import { NeighbourhoodMarkersComponent } from '../neighbourhood-markers/neighbourhood-markers.component';
import { SendNotificationComponent } from '../send-notification/send-notification.component';
// import { CityWiseTableComponent } from '../city-wise-detail/city-wise-table/city-wise-table.component';
import { CityWiseDetailsComponent } from '../city-wise-details/city-wise-details.component';
import { RedisComponent } from '../redis/redis.component';
import { RedisViewComponent } from '../redis-view/redis-view.component';
import { MasterUiListboxComponent } from '../master-ui-listbox/master-ui-listbox.component';
import { ManageCsvUploadComponent } from './../manage-csv/csv-upload/manage-csv-upload.component';
import { CsvTemplateComponent } from './../manage-csv/csv-template/csv-template.component';
import { DeleteUploadCsvComponent } from './../manage-csv/delete-upload-csv/delete-upload-csv.component';
import { SyncTableComponent } from './../sync-table/sync-table.component';
import { BlogListComponent } from '../blog/blog-list.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'admin', component: AdminComponent, canActivate: [AuthGuard],
        children: [
          { path: 'menu', component: AdminValuatorComponent, canActivate: [AuthGuard] },
          { path: 'cityDetail', component: CityWiseDetailsComponent, canActivate: [AuthGuard] },
          { path: 'blog', component: BlogListComponent, canActivate: [AuthGuard] },
          { path: 'csv_upload', component: CsvUploadComponent, canActivate: [AuthGuard] },
          { path: 'categories', component: CategoriesComponent, canActivate: [AuthGuard] },
          { path: 'sync-data', component: SyncPropertyComponent, canActivate: [AuthGuard] },
          { path: 'block-valuation', component: BlockValuationComponent, canActivate: [AuthGuard] },
          { path: 'download-price-data', component: PricingDownloadComponent, canActivate: [AuthGuard] },
          { path: 'remove-block', component: RemoveBlockComponent, canActivate: [AuthGuard] },
          { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
          { path: 'broker-master', component: BrokerMasterComponent, canActivate: [AuthGuard] },
          { path: 'listable-attribute', component: ListableAttributeComponent, canActivate: [AuthGuard] },
          { path: 'valuation-factor-master', component: ValuationFactorMasterComponent, canActivate: [AuthGuard] },
          { path: 'transaction-attribute', component: TransactionAttributeComponent, canActivate: [AuthGuard] },
          { path: 'comparison-parameter', component: ComparisonParameterComponent, canActivate: [AuthGuard] },
          { path: 'neighbourhood-markers', component: NeighbourhoodMarkersComponent, canActivate: [AuthGuard] },
          { path: 'send-notification', component: SendNotificationComponent, canActivate: [AuthGuard] },
          { path: 'manage-redis', component: RedisComponent, canActivate: [AuthGuard]},
          { path: 'manage-redis/:id', component: RedisViewComponent, canActivate: [AuthGuard]},
          { path: 'broker-master/:page', component: BrokerMasterComponent, canActivate: [AuthGuard] },
          { path: 'masters-ui-listbox', component: MasterUiListboxComponent, canActivate: [AuthGuard] },
          { path: 'manage-csv-upload', component: ManageCsvUploadComponent, canActivate: [AuthGuard] },
          { path: 'manage-csv-template', component: CsvTemplateComponent, canActivate: [AuthGuard] },
          { path: 'delete-csv-upload', component: DeleteUploadCsvComponent, canActivate: [AuthGuard] },
          { path: 'sync-service', component: SyncTableComponent, canActivate: [AuthGuard] },
        ]
      },
    ])
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
