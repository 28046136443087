<div [ngClass]="{'sidebarnormal' : isNormal(), 'sidebarsmall' : isSmall()}">
  <mat-toolbar class="headtoolbar" color="primary">
    <div fxLayout="row" class="w-100 lnk" *ngIf="isSmall()" fxLayoutAlign="center center">
      <img routerLink="/admin" src="assets/images/logo-mini.svg" width="40" />
    </div>
    <div fxLayout="row" class="w-100 lnk" *ngIf="isNormal()" fxLayoutAlign="center center">
      <img class="w-100" routerLink="/admin" src="assets/images/logovert2.svg" width="100" />
    </div>
  </mat-toolbar>
  <mat-nav-list color="primary">
    <ng-container *ngFor="let menus of adminMenus">

      <mat-expansion-panel #expansion *ngIf="menus.show && menus.key == 'valuation'" style="background: #27325f;"
        [hideToggle]="size =='small'" [matMenuTriggerFor]="valuationMenu" [disabled]="size =='small'"
        class="expansion-panel">
        <mat-expansion-panel-header *ngIf="menus.show" class="expansion-header"
          [ngStyle]="{'height':isNormal() ? '65px' : '' }" [ngClass]="{'active':  getActive(menus)}">
          <mat-icon *ngIf="menus.icon" svgIcon="{{menus.icon}}" style="top: 0px;margin-right: 22px;"
            [ngStyle]="size =='small' ? {'height': '38px','width': '22px'} : {}" title="{{menus.title}}"></mat-icon>
          <span style="color:#d7d9e7;">{{menus.title}}</span>
          <mat-icon title="{{menus.title}}">keyboard_arrow_down</mat-icon>
        </mat-expansion-panel-header>
        <ng-container *ngIf="menus.key == 'valuation' && menus.show">

          <ng-container *ngFor="let menu of valuationMenus">
            <mat-list-item *ngIf="menu.show && size =='normal'" [routerLink]="menu.url" routerLinkActive="active"
              class="menu-list-expansion">
              <div class="menu-list-item-expansion">
                <mat-icon *ngIf="menu.icon" svgIcon="{{menu.icon}}" title="{{menu.title}}"
                  [ngStyle]="size =='normal' ? {'margin-right': '12px'} : {}"></mat-icon>
                <div class="menu-text" [ngStyle]="size =='small' ? {'display': 'none'} : {}">{{menu.title}}</div>
              </div>
            </mat-list-item>
          </ng-container>
        </ng-container>
      </mat-expansion-panel>

      <mat-list-item *ngIf="menus.show && menus.key != 'valuation'" class="itemlist" [routerLink]="menus.url"
        [ngClass]="{'active':getActive(menus)}">
        <mat-icon *ngIf="menus.icon" svgIcon="{{menus.icon}}" title="{{menus.title}}"></mat-icon>
        <div class="menu-text" *ngIf="isNormal()">{{menus.title}}</div>
      </mat-list-item>

      <!-- <ng-template *ngIf="size =='small'"> -->
      <mat-menu #valuationMenu="matMenu" class="menu-mat" [ngStyle]="size =='normal' ? {'display': 'contents'} : {}">
        <ng-container *ngFor="let menu of valuationMenus">
          <mat-list-item *ngIf="menu.show && size =='small'" [routerLink]="menu.url" routerLinkActive="active"
            class="menu-list">
            <div class="menu-list-item">
              <mat-icon *ngIf="menu.icon" svgIcon="{{menu.icon}}" title="{{menu.title}}"
                style="margin-right: 12px;"></mat-icon>
              <div class="menu-text">{{menu.title}}</div>
            </div>
          </mat-list-item>
        </ng-container>
      </mat-menu>
    </ng-container>
    <!-- </ng-template> -->

  </mat-nav-list>
</div>