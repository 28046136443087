<div class="form">
  <div class="mtc-header pt-2 pb-2 pr-2 pl-2 position-relative" fxLayout="row" fxLayoutAlign="center center">
    <div>
      <h5 *ngIf="mode == 'add'">Add Parameter</h5>
      <h5 *ngIf="mode == 'edit'">Edit Parameter</h5>
    </div>
    <mat-progress-bar class="mtc-progress" mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
  </div>
  <div>
    <form [formGroup]="parameterForm" autocomplete="off" (ngSubmit)="addParameters(parameterForm)">
      <div class="calc-f">
        <div class="add-lists pl-3 pr-3">
          <mat-form-field class="w-100">
            <mat-label>Select Neighborhood</mat-label>
            <mat-select [disabled]="loader" formControlName="neigh">
              <!-- <input class="searchIn" mat-input [(ngModel)]="searchText" placeholder="Type here to search..." /> -->
              <mat-option *ngFor="let neigh of neighborhoodsList | filter : 'neigh_name':searchText" [value]="neigh.neigh_uid">
                {{neigh.neigh_name}}
              </mat-option>
            </mat-select>
            <mat-error>Select a Neighbourhood!</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Enter Value[1-100]</mat-label>
            <input matInput min="1" max="100" type="number" placeholder="Enter Value" formControlName="value">
            <mat-error>Value is Required!</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Select Key name</mat-label>
            <mat-select formControlName="keyname">
              <mat-option *ngFor="let key of key_name" [value]="key.value">
                {{key.name}}
              </mat-option>
            </mat-select>
            <mat-error>Key Name is Required!</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="footer" fxLayout="row" fxLayoutAlign="center center">
        <div class="pr-2">
          <button mat-raised-button color="primary" [disabled]="parameterForm.invalid" *ngIf="mode == 'add'">
            Submit
          </button>
          <button type="button" mat-raised-button (click)="updateParameter(parameterForm)" color="primary"
            [disabled]="parameterForm.invalid" *ngIf="mode == 'edit'">
            Update
          </button>
        </div>
        <div class="pl-2">
          <button type="button" mat-raised-button (click)="cancel()">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>