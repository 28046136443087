import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { AdminMenusService } from '../../admin-sidebar/admin-menus.service';
import { ApiserviceService } from '../../../apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { NotificationService } from '../../services/notification.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../../../environments/environment";
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';


@Component({
  selector: 'app-csv-upload',
  templateUrl: './manage-csv-upload.component.html',
  styleUrls: ['./manage-csv-upload.component.css']
})
export class ManageCsvUploadComponent implements OnInit {
  dataSource: any;
  total: number;
  jsonData = [];
  displayedColumns = ['sno',
    // 'objective_name',
    'Upload_ID',
    'File_Name',
    'Added_Date',
    'Total',
    'Error',
    'Show_Log',
    'Status',
    'Action'
  ];
  StatusJson = {
    "0": "Uploaded",
    "1": "Valid",
    "-1": "Invalid",
    "2": "Accepted",
    "3": "Under Progress",
    "4": "Under Progress",
    "-3": "Accept Invalid",
    "-2": "Stopped"
  }
  status = '0,1';
  upload_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false
  };
  limit: number = 10;
  offset: number = 0;
  csvListLoader: boolean = false;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100, 200];
  csvResponseStatus: number;
  selectedLangCode: any;
  fileToUpload: File;
  fileName: string;
  reselectfile: boolean = true;
  removable: boolean = true;
  selectable: boolean = true;
  uploadLoading: boolean;
  acceptLoading: boolean;
  city_code;
  tableData: any;

  /**  
 * This is a function that uses switch case and returns value from provided 
 * array element on the basis of column provided as second parameter.  

 * @param element @type {object} - A single array element from array of upload csv API response
 * @param cols @type {string} - A single array element from {@link displayedColumns} array
 * @returns {String} Return a value based on the condition.  
 */ 
  returnVal(element, cols) {
    switch (cols) {
      case 'Upload_ID': return element.upload_id ? element.upload_id : '';
      case 'File_Name': return element.file_name ? element.file_name : '';
      case 'Total': return element.total_count ? element.total_count : '';
      case 'Error': return element.error_count
      case 'Added_Date': return element.added_date ? this.fncSrv.formatDateUTC(element.added_date,true) : '';

    }
  }
  @ViewChild('myFileInput') myFileInput;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private api: ApiserviceService,
    private menu: AdminMenusService,
    private notify: NotificationService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,private router: Router,
    private fncSrv: CommonfunctionService) {
    this.menu.setActive('manage_csv'); 
    this.city_code = localStorage?.getItem('city_code') ? localStorage.getItem('city_code') : '';
    iconRegistry.addSvgIcon('refresh', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/refresh.svg'));
    iconRegistry.addSvgIcon('down_arrow_fill', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/down_arrow_active.svg'));
    this.getAllowResources();
  }

  /**  
 * This is a function that is used to get list of all resources allocated 
 * to the logged in user and checks resource access for this component &
 * access APIs and to perform actions.  

 * @api GET user/resource
 * @returns {} Returns void.  
 */
  getAllowResources() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next :(res: any) => {
    
        this.api.allowResources = res.data;
        this.upload_access = this.fncSrv.checkResourceAccess('csv_upload', false);
        this.getUploadedData();
        this.csvMaster();
       
      }
    
      })
  }

  /**
   * This is a function that is used to CSV Master to be downloaded as template file to upload data
  
   * @api GET /csv-master
   * @returns {} Returns void.
   */
  csvMaster() {
    this.api.getData(`csv-master?sort_asc=true&sort_by=file_type&status=1`).subscribe((res: any) => {
      if (res && res.data) {
        this.jsonData = res.data;
      }
    })
  }

/**  
 * This is a functionto store uploaded file in a variable for report POST @api /upload-csv call.

 * @param event @type {Event} - event containing uploaded file
 * @returns {} Returns void.
 */
  handleFileInput(event) {
    let fileList: FileList = event.target.files;
   // let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /zip-*/;
      if (!file.type.match(pattern)) {
        this.notify.notify('Unsupported file type','error');
        return;
      }
      this.fileToUpload = file;
      this.fileName = this.fileToUpload.name;
    }
  };

   /**  
 * This is a functionto upload file to the database.

 * @returns {} Returns promise with uploaded file link.
 */
  uploadFile() {
    if (this.upload_access.POST == false) {
      this.notify.notify('You are not authorize you upload data','warn');
      return;
    }
    if (!this.fileToUpload || (this.fileToUpload && !this.fileToUpload.name)) {
      this.notify.notify('Please select file','warn');
      return;
    }
    let formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
    let url = `upload-csv?added_by=${this.api.user_id}`;
    
    this.uploadLoading = true;
    const activity = {
      userdata: this.fileToUpload,
      actionId: 9,
      entity:'csv_upload'
    };
   
    this.fileToUpload = null;
    this.myFileInput.nativeElement.value = '';
    this.api.postFileEst(url, formData).subscribe((res: any) => {
      this.uploadLoading = false;
      this.reset();
      if (res && res.status == 201) {        
        this.notify.notify(res.message,'success');
        this.getUploadedData();
        // this.api.logUserActivity(activity);
      } else {
        this.notify.notify(res.message,'error');
      }
    }, err => {
      this.notify.notify(err.error.message,'error');
      this.fileName = '';
      this.uploadLoading = false;
    })
    
  };

  /**
   * This is a function to reset uploaded file and variables that store uploaded file details
  
   * @returns {} Returns void.
   */
  reset() {
    this.fileName = '';
    this.fileToUpload = null;
    this.reselectfile = false;
    this.myFileInput.nativeElement.value = '';
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.reselectfile = true;
      }, 100);
    }
  }


  /**
   * This function is to get list of uploaded files on click of refresh button on UI
  
   * @returns {}  Returns void.
   */
  refresh() {
    if (!this.upload_access.GET) {
      this.notify.notify('You are not Authorized to GET Uploaded Data','warn');
      return;
    }
    this.getUploadedData();
  }

/**  
 * This is a function to GET list of upload-csv from API. 
 * Resources are checked.
 * List of uploaded files are stored in {@link dataSource} variable.
 * Limit and offset used for pagination.
 * sort order and sort by used for sorting conditionally. 

 * @api GET /upload-csv
 * @param searchTerm @type {string} - A string value to search upload-csv
 * @returns {} Returns void.
 */
  getUploadedData() {
    if (!this.upload_access.GET) {
      this.notify.notify('You are not Authorized to GET Uploaded Data','warn');
      return;
    }
    this.csvListLoader = true
    let url = `upload-csv?sort_asc=true&sort_by=file_name&limit=${this.limit}&offset=${this.offset}&is_count=true`;
    this.api.getData(url).subscribe((res: any) => {
      this.csvListLoader = false;
      if (res && res.status == 200) {        
        this.dataSource = res.data;
        this.total=res.totalRecord;
       
      } else {
        this.dataSource = [];
      }
    }, err => {
      this.dataSource = [];
      this.csvListLoader = false;
    })
  }

  /**
   * This is a function to pass VALID file for processing to the database.
  
   * @api POST /accept-csv
   * @param row @type {Object} - Object containing data of a uploaded file.
   * @returns {} Returns void.
   */
  accept(row) {
    if (!row) {
      this.notify.notify("Invalid File ID",'error');
      return;
    }
    this.acceptLoading = true;
    let url = `accept-csv?upload_id=${row.upload_id}&user_id=${this.api.user_id}`;
    this.api.getData(url).subscribe((res: any) => {
      this.acceptLoading = false;
      if (res && res.status == 200) {        
        this.getUploadedData();
        this.notify.notify(res.message,'success');
      }
      else {
        // this.alert.error(res.message);
      }
    }, err => {
      this.acceptLoading = false;
      // this.alert.error(err.error.message);
    })
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.getUploadedData()
      }, 2000);
    }
  }

  /**
   * This is a function to stop Processing of a file on click of button.
  
   * @param element @type {Object} - Object containing data of a uploaded file.
   * @api GET /stop-csv-upload
   * @returns {} Returns void.
   */
  stopProcess(element){
    this.csvListLoader = true; 
    let process_name='mi_upload_csv';
    if(element.status == 3){
      process_name='mi_upload_csv';
    }else if(element.status == 4){
      process_name='mi_accept_csv';
    }
    this.api.getData(`stop-csv-upload?process_name=${process_name}&upload_id=${element.upload_id}&user_id=${this.api.user_id}`).subscribe((res:any)=>{
      this.csvListLoader=false;
      if(res && res.status == 200){
        this.getUploadedData()
        this.notify.notify(res.message,'success');
      }else{
        this.notify.notify(res.message,'error');
      }
    },err=>{
      this.csvListLoader=false;
      this.notify.notify(err.error.message,'error');
    })
  }

  /**  
 * This is a function to return array of elements on the basis of application
 * language selection.

 * @returns @type {Array} Returns an array.
 */
  getStatus(status) {
    if (status) {
      return this.StatusJson[status];
    } else {
      return status;
    }
  }

  /**
   * This ia a to disable accept button based on file status
  
   * @param status @type {Number} - Status of an uploaded file
   * @returns {Boolean} Returns true or false
   */
  hideAccept(status) {
    if ([0, -1, -2, 2, 3, -3, 4].indexOf(status) > -1) {
      return true;
    }
  }

 /**
   * This ia a to disable Reject button based on file status
  
   * @param status @type {Number} - Status of an uploaded file
   * @returns {Boolean} Returns true or false
   */
  hideReject(status) {
    if ([2, -2, 3].indexOf(status) > -1) {
      return true;
    }
  }

  red(status) {
    if ([-1, -2].indexOf(status) > -1) {
      return true;
    }
  }

   /**
   * This ia a to disable Stop process button based on file status
  
   * @param status @type {Number} - Status of an uploaded file
   * @returns {Boolean} Returns true or false
   */
  hidestop(status){
    if ([3,4].indexOf(status) > -1) {
      return false;
    }
    return true;
  }

  downloadCSV(object: any) {
    if (this.upload_access.GET == false) {
      this.notify.notify('You are not authorize you download data','error');
      return;
    }
    if (object == 'All') {
      if (this.jsonData) {
        this.jsonData.map((element: any) => {
          let cols = element.column_name;
          let header = [];
          if (cols) {
            cols.map(ele => {
              header.push(ele.name);
            })
          }
          let csv = this.fncSrv.ConvertToCSV(header);
          this.fncSrv.downloadFile(element.file_type, csv);
        })
      }
    } else {
      let cols = object.column_name;
      let header = [];
      if (cols) {
        cols.map(ele => {
          header.push(ele.name);
        })
      };
      let csv = this.fncSrv.ConvertToCSV(header);
      this.fncSrv.downloadFile(object.file_type, csv);
    }
  };

    /**
   * This ia a fucntion to download Log of uploaded file on based of its status accept/validate
  
   * @param row @type {Number} - Object containing data of a uploaded file.
   * @param action @type {Number} - type of action fo which log is required
   * @returns {} Returns void
   */
  download_log(row,action){
    if(isPlatformBrowser(this.platformId)){
      let url;
      if (action && action == 'validate') {
        url=`csv-log?file_name=${row.validate_url}&city_code=${this.city_code}`
      }
      if (action && action == 'accept') {
        url=`csv-log?file_name=${row.accept_url}&city_code=${this.city_code}`;
      }
      window.open(`${environment.estUrl}${url}`,'_blank');
    }
  }

/**  
 * This is a function to change pagination page and get the list of objectives.
 * @param e @type {PageEvent} - A Page Event to get page details
 * @returns {} Returns void.
 */
  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getUploadedData();
  }


  ngOnInit(): void {
    this.tableData = setInterval(() => {
      if(this.uploadLoading){
        this.getUploadedData();
      }
    }, 5000);
  }

  ngOnDestroy() {
    if (this.tableData) {
      clearInterval(this.tableData);
    }
  }
}
