<div class="multi-select">
    <button #toggleButtonSingle (click)="opendropdown()">
        <div class="placeholder" *ngIf="!select_value">{{placeholder}}</div>
        <div class="placeholder" *ngIf="select_value">{{getLabel(select_value)}}</div>
        <div class="arrow">
            <svg [class.arrow-180]="checkActive()" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="18" height="18" x="0" y="0" viewBox="0 0 451.847 451.847" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g>
                <g xmlns="http://www.w3.org/2000/svg">
                    <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" fill="#959595" data-original="#000000" class=""></path>
                </g>                
                </g></svg>
            </div>
    </button>        
    <div class="dropdown-content" *ngIf="isdropdown" #dropdownidSingle>
        <div class="search-item" *ngIf="search">
            <input type="text" [(ngModel)]="search_value">
        </div>        
        <ul class="filter-list">                
            <li class="filter-item" *ngFor="let item of itemList | filter: field : search_value"
            [class.selected]="isChecked(item[value_field])" (click)="selectOneItem(item[value_field])">{{item[field]}}
            </li>
        </ul>
    </div>
</div>
