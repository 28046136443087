import { Component, Inject, OnInit } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../apiservice.service";
import { NotificationService } from "../../../../admin/services/notification.service";

@Component({
  selector: 'app-add-api-master',
  templateUrl: './add-api-master.component.html',
  styleUrls: ['./add-api-master.component.css']
})
export class AddApiMasterComponent implements OnInit {

 
  APiMasterForm: UntypedFormGroup;
  action_type: string;
  loader: boolean;
  parent_data:any;

  comp_list:any=[];
  package_list:any=[];

  constructor(
    private fb: UntypedFormBuilder,
    private api:ApiserviceService,
    private notify: NotificationService,
    public dialogRef: MatDialogRef<AddApiMasterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.action_type = data.type;
      this.parent_data = data.data;
      
      this.APiMasterForm = this.fb.group({
        short_name: ['', Validators.required],
        url:  ['', [Validators.required, Validators.pattern('(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})')]],
        description:  [''],
      });

      if(this.action_type == 'edit'){
        this.APiMasterForm.patchValue({
          short_name: this.parent_data.short_name,
          url:  this.parent_data.url,
          description:  this.parent_data.description,
        })
      }

    }

   close(){
    this.dialogRef.close();
  }
 
  ngOnInit(): void {}

   /**
   * Add / Update form package subscription
   */
  addAPiMaster(form_data) {
    if(this.APiMasterForm.invalid){
      this.notify.notify('Fill the mandatory fields', 'warn')
      return;
    }
  
  let body = {
    short_name: form_data.short_name,
    url:  form_data.url,
    description:  form_data.description?form_data.description:'',
    user_id: this.api.user_id   
  };

  this.loader=true;
    
    if(this.action_type == 'add'){
     
      this.api.postData('apiMaster', body).subscribe((res:any)=>{
        this.loader=false;
        if(res && res.status == 201){
          this.notify.notify(res.message, 'success');
          this.dialogRef.close(true);
        }else{
          this.notify.notify(res.message, 'warn');
        }
      }, err=>{
        this.loader=false;
      })
    }else if(this.action_type =='edit'){
      body['brk_api_master_id'] = this.parent_data.brk_api_master_id,
      this.api.patchData('apiMaster', body).subscribe((res:any)=>{
        this.loader=false;
        if(res && res.status == 201){
          this.notify.notify(res.message, 'success');
          this.dialogRef.close(true);
        }else{
          this.notify.notify(res.message, 'warn');
        }
      }, err=>{
        this.loader=false;
      })
    }
  }

}
