import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiserviceService } from '../../apiservice.service';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';
import { ShareService } from '../services/share.service';
import { AppNotificationService } from '../services/app-notification.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { UserAction } from "../user-action/user-activity";
import { environment } from '../../../environments/environment';

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>umsapi_user_notification_post</li>
 * <li>umsapi_user_login_post</li>
 * </ol>
 *
 */

@Component({
    selector: 'app-admin-login',
    templateUrl: './admin-login.component.html',
    styleUrls: ['./admin-login.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AdminLoginComponent implements OnInit, AfterViewInit {
    hide = true;
    loginForm: UntypedFormGroup;
    showForgot: boolean = false;
    error: string;
    forget_email: string;
    forget_message = '';
    f_error = false;
    enable_btn = true;
    login_action: boolean = false;
    @ViewChild('tabGroup') tabGroup: MatTabGroup;
    constructor(private _fb: UntypedFormBuilder, private api: ApiserviceService, private notify: NotificationService, private recap: ReCaptchaV3Service,
        private router: Router, public dialog: MatDialog, private share: ShareService, private alert: AppNotificationService) {
            this.dialog.closeAll();
            if(environment.isLocalServe || this.api.isMobile){
                if (localStorage.getItem('user_id') && !localStorage.getItem('city_id')) {
                    this.router.navigateByUrl('/country');
                }
                if (localStorage.getItem('user_id') && localStorage.getItem('city_id')) {
                    this.router.navigateByUrl('/admin');
                }
            }else{
                if (!localStorage.getItem('user_id') && !localStorage.getItem('user_token')) {
                    let newwindow = window.open(`${environment.adminHost}/login?continue=${(environment.baseURL)}`, '_self');
                    if (window.focus) {newwindow.focus()}    
                }else if (localStorage.getItem('user_id') && !localStorage.getItem('city_id')) {
                    this.router.navigateByUrl('/country');
                }else if (localStorage.getItem('user_id') && localStorage.getItem('city_id')) {
                    this.router.navigateByUrl('/admin');
                }
            }
           
        
    }
    ngAfterViewInit() {
        //this.tabGroup.selectedIndex = 1;
    }


    jumpRegister() {
        this.tabGroup.selectedIndex = 1;
    }
    /**
     * jumpLogin function to toggle Login form
     */
    jumpLogin() {
        this.tabGroup.selectedIndex = 0;
    }
    /**
     * jumpForgot function to toggle forget password form
     */
    jumpForgot() {
        this.showForgot = !this.showForgot;
    }
    /**
     * forgetPassword function to reset password
     * @returns Void
     */
    forgetPassword() {
        this.enable_btn = false;
        this.forget_message = '';
        let body = {
            "email": this.forget_email,
            "notification_type": "forgot_password"
        }
        // let body = "email=" + this.forget_email + "&notification_type=forgot_password";
        this.api.postUmsData('notification', body)
            .subscribe({
                next :(data: any) => {
                if (data && data.status == 201) {
                    this.enable_btn = true;
                    this.f_error = false;
                    this.forget_message = "Reset password link has been sent to your email";
                }
                else {
                    this.f_error = true;
                    this.enable_btn = true;
                    this.forget_message = data.message;
                }
            },
                error: err => {
                    this.f_error = true;
                    this.enable_btn = true;
                    this.forget_message = err.error.message;
                }
    });
    }
    // forgetPassword() {
    //     this.enable_btn = false;
    //     this.forget_message = '';
    //     let body = "email=" + this.forget_email + "&notification_type=forgot_password";
    //     this.api.postUmsData('user/notification', body)
    //         .subscribe((data: any) => {
    //             this.enable_btn = true;
    //             this.f_error = false;
    //             this.forget_message = "Reset password link has been sent to your email";
    //         },
    //             err => {
    //                 this.f_error = true;
    //                 this.enable_btn = true;
    //                 this.forget_message = "This email id is not register yet."
    //             }
    //         );
    // }
    doLogin(val) {
        this.recap.execute(null).subscribe((token) => {
            // console.log(token);
            
            this.login(val);
        }, error=>{
            console.log(error);
            
        })
    }

    /**
     * This is a function to use for Login
     * @param val val is payload
     * @returns Void
     */
    login(val: any) {
        let record: any;
        let name: string;
        this.error = null;
        this.login_action = true;
        let userInfo = {
            "user_name": val.email,
            "user_password": val.password,
            "keep_log_in": false
        }
        this.api.postUmsData('login', userInfo)
            .subscribe(
                (res: any) => {
                    if (res.data && res.status == 200) {
                        record = res.data;
                        const activity={
                            actionId: record.user_id,
                            data: res.data,
                            actionType:UserAction.login.Action_ID
                          }
                         
                        this.login_action = false;
                        this.share.appComp.logIn();
                        localStorage.setItem('user_token', record.token);
                        localStorage.setItem('user_id', record.user_id);
                        localStorage.setItem('session_id', record.session_id);
                        localStorage.setItem('profile_image', record.profile_image);
                        this.api.profile_image.next(record.profile_image)
                        if (this.share.adminComp) this.share.adminComp.profile_image = record.profile_image;
                        if (record.user_details[1].first_name) {
                            name = record.user_details[1].first_name;
                        }
                        if (record.user_details[1].last_name) {
                            name += " " +  record.user_details[1].last_name;
                        }
                        localStorage.setItem('user_name', name);
                        let user_info = {
                            "fname": record.user_details[1].first_name,
                            "lname": record.user_details[1].last_name,
                            "contact": record.phone_number,
                            "country": record.nationality,
                            "email": record.primary_email_id,
                            "language": 'en',
                            "imageURL": record.profile_image
                        }
                        localStorage.setItem('user_info', JSON.stringify(user_info));
                        this.api.user_id = record.user_id;
                        this.api.user_name.next(name);
                        this.api.session_id = record.session_id;
                        this.api.user_token = record.token;
                        this.api.getUserRole();
                        this.api.logUserActivity(activity)
                        this.router.navigateByUrl('country');
                    }
                    else {
                        this.error = res.message;
                    }
                },
                err => {
                    this.login_action = false;
                    this.error = err.error;
                }
            )

    }
    public resolved(captchaResponse: string) {
        // console.log(`Resolved captcha with response ${captchaResponse}:`);
    }

    ngOnInit() {
        this.loginForm = this._fb.group({
            email: [null, [Validators.email, Validators.required]],
            password: [null, [Validators.required]]
        });
    }

}
