import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiserviceService } from "../../apiservice.service";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { NotificationService } from "../services/notification.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";

import { ConfirmDialogBox } from "../confirm-dialog/confirm-dialogbox";
import { UntypedFormControl } from "@angular/forms";
import { AddMastersComponent } from './add-masters/add-masters.component';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-master-ui-listbox',
  templateUrl: './master-ui-listbox.component.html',
  styleUrls: ['./master-ui-listbox.component.scss']
})
export class MasterUiListboxComponent implements OnInit {

  displayedColumns = [
    'SN',
    'listbox',
    'label',
    'value',
    'page_name',
    'added_by',
    'added_date',
    'status',
    'action',
  ]
  searchTerm: any;
  returnValue(element, dispCol) {
    switch (dispCol) {
      case 'label': return this.showlabel(element, 'label');
      case 'added_date': return this.functionService.formatDateUTC(element.added_date, true);
      default : return element[dispCol];
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  limit: number = 10;
  offset: number = 0;
  totalCount: number;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  pageEvent: PageEvent;
  dataSource: any = [];
  status: string = "1";
  noRecords:boolean=false;
  listbox_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false,
  }
  loader: boolean;
  searchField: UntypedFormControl;

  constructor(private api: ApiserviceService, private menu: AdminMenusService,
    private functionService: CommonfunctionService,
    private notify: NotificationService,
    public dialog: MatDialog,
    public router: Router,) {
    this.menu.setActive('masters_ui_listbox');
    // this.getAllowResourceApi();
    this.getAllowResources();
  }

  ngOnInit() {
    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(term => {
        term = term.trim().toLowerCase();
        this.offset = 0;
        if (term) {
          this.searchTerm = term;
          this.getListBoxData();
        } else {
          this.searchTerm ='';
          this.getListBoxData();
        }
      })
  }

  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next:(res: any) => {

        this.api.allowResources = res.data;
        this.listbox_access = this.functionService.checkResourceAccess('listbox_master', false);
        this.getListBoxData();
      
      }
     
    })

  }

  selectionChange(){
    this.offset = 0;
    this.getListBoxData();
  }
  /**
   * This is a function to use get listbox master data
   * @api /listbox-master
   * @param searchTerm 
   * @returns 
   */
  
  getListBoxData() {
    if (!this.listbox_access.GET) {
      this.notify.notify("You are not Authorized to VIEW listbox master", 'warn');
      return;
    }
    
    let url = `listbox-master?status=${this.status}&sort_by=added_date&sort_asc=false&limit=${this.limit}&offset=${this.offset}`;
    if (this.searchTerm != null) {
      url += `&search_key=${this.searchTerm}`;
    }
    if(this.offset == 0){
      url += `&is_count=true`;
    }
    this.loader = true;
    this.api.getData(url).subscribe((res: any) => {
      this.loader = false;      
      if (res && res.data) {
        this.dataSource = res.data;
      }else{
        this.noRecords=true;
        this.dataSource = [];
      }
      if(this.offset == 0){
        this.totalCount = res.totalRecord;
      }
    }, err => {
      this.noRecords=false;
      this.dataSource = [];
      this.loader = false;
    })
  }

  openDialog(type: any, data: any = null) {
    if (type == 'add' && !this.listbox_access.POST) {
      this.notify.notify("You are not authorized to add listbox master", "warn");
      return;
    } if (type == 'edit' && !this.listbox_access.PATCH) {
      this.notify.notify("You are not authorized to edit listbox master", "warn");
      return;
    }
    if (type) {
      var _data = {
        type: type,
        edit: (type == 'edit') ? data : null
      }
      const dialogRef = this.dialog.open(AddMastersComponent, {
        width: '400px',
        panelClass: 'customPadding',
        data: _data
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.getListBoxData();
        }
      });
    }
  }

  deleteListBox(value) {
    if (!this.listbox_access.DELETE) {
      this.notify.notify("You are not authorized to delete listbox master", "warn");
      return;
    }
    
    let _data = {
      message: 'Do you want to delete listbox master?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'YES') {
        let body = {
          "id": value.id,
          "status": -1,
          "updated_by": this.api.user_id
        }
        this.loader = true;
        this.api.patchData('listbox-master', body).subscribe((res: any) => {
          this.loader = false;
          if (res && res.status == 201) {
            this.notify.notify("listbox master Deletion Successful", "success");
            this.getListBoxData();          
          }else{           
            this.notify.notify(res.message,'error');
          }
        }, err => {
          this.loader = false;
          this.notify.notify("Unsuccessful listbox master Deletion", "error")
        })
      }
    });
  }
 
  /**
   * To Patch API for /valuation-factor-master to update status
   * @param id 
   * @param event 
   * @returns 
   */
  updateStatus(id, event) {
    if (!this.listbox_access.PATCH) {
      this.notify.notify("You are not authorized to Update listbox master", "warn");
      return;
    }
    let body: any = {
      "id": id,
      "status": (event.checked == true) ? 1 : 0,
      "updated_by": this.api.user_id,
    };
    this.loader = true;
    
    this.api.patchData('listbox-master', body).subscribe((res: any) => {
      if (res && res.status == 201) {
        this.loader = false;
        this.notify.notify(`${res.message}`, "success")
        this.getListBoxData();
      }else{
        this.loader = false;
        this.notify.notify(res.message,'error');
      }
    }, err => {
      this.loader = false;
    })
  }
  


  /**
   * showlabel function (API) to show Language dependent lables
   * @param item 
   * @param key 
   * @return langauge
   */
  showlabel(item: any, key) {
    if (item && item[key] && item[key][this.api.language]) {
      return item[key][this.api.language];
    } else {
      return null;
    }
  }


  getLabel(key) {
    return key ? key.replace(/_/g, " ") : "";
  }


  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.functionService.formatDate(date);
    }
    else {
      return '';
    }
  }

  
  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getListBoxData();
  }


}
