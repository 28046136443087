<ng-container *ngIf="!editing">
  <mat-card appearance="outlined" class="mb-2">
    <mat-card-title>
      <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <button *ngIf="showAddButton" mat-button (click)="addThisTemplate('combo')">
            <mat-icon class="est-gr">add</mat-icon> Add
          </button>
        </div>
        <div></div>
      </div>
    </mat-card-title>
    <mat-divider class="w-100"></mat-divider>
    <mat-card-content>
      <app-combo-toolbar [editable]="false"></app-combo-toolbar>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" class="mb-2">
    <mat-card-title>
      <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <button *ngIf="showAddButton" mat-button (click)="addThisTemplate('paragraph')">
            <mat-icon class="est-gr">add</mat-icon> Add
          </button>
        </div>
        <div></div>
      </div>
    </mat-card-title>
    <mat-divider class="w-100"></mat-divider>
    <mat-card-content>
      <app-paragraph></app-paragraph>
    </mat-card-content>
  </mat-card>


  <mat-card appearance="outlined" class="mb-2">
    <mat-card-title>
      <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <button *ngIf="showAddButton" mat-button (click)="addThisTemplate('list')">
            <mat-icon class="est-gr">add</mat-icon> Add
          </button>
        </div>
        <div></div>
      </div>
    </mat-card-title>
    <mat-divider class="w-100"></mat-divider>
    <mat-card-content>
      <app-list></app-list>
    </mat-card-content>
  </mat-card>


  <mat-card appearance="outlined" class="mb-2">
    <mat-card-title>
      <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <button *ngIf="showAddButton" mat-button (click)="addThisTemplate('image')">
            <mat-icon class="est-gr">add</mat-icon> Add
          </button>
        </div>
        <div></div>
      </div>
    </mat-card-title>
    <mat-divider class="w-100"></mat-divider>
    <mat-card-content>
      <app-image></app-image>
    </mat-card-content>
  </mat-card>


  <mat-card appearance="outlined" class="mb-2">
    <mat-card-title>
      <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <button *ngIf="showAddButton" mat-button (click)="addThisTemplate('chart')">
            <mat-icon class="est-gr">add</mat-icon> Add
          </button>
        </div>
        <div></div>
      </div>
    </mat-card-title>
    <mat-divider class="w-100"></mat-divider>
    <mat-card-content>
      <app-chart></app-chart>
    </mat-card-content>
  </mat-card>
</ng-container>