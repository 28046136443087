import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from "@angular/forms";
import { CommonfunctionService } from "../../../services/commonfunction.service";
import { ApiserviceService } from "../../../apiservice.service";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "../../services/notification.service";
import { PageEvent } from "@angular/material/paginator";
import { ConfirmDialogBox } from "../../confirm-dialog/confirm-dialogbox";
import { AddBrokerSubscriptionComponent } from './add-broker-subscription/add-broker-subscription.component';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-package-subscription',
  templateUrl: './package-subscription.component.html',
  styleUrls: ['./package-subscription.component.css']
})
export class PackageSubscriptionComponent implements OnInit {

 
  status: string = "1";
  searchField: UntypedFormControl;
  package_subs_list=[];
  loader: boolean;
  broker_access: { GET: boolean; PATCH: boolean; POST: boolean; DELETE: boolean; };
  search_value: any;

  limit:number=10;
  offset: number = 0;
  total: number = 0;
  pageSizeOptions: number[] = [10, 15, 20, 30, 50];
  pageEvent: PageEvent;

  constructor(private api:ApiserviceService,
    public fnc: CommonfunctionService,
    private dailog: MatDialog,
    private notify: NotificationService) {

    this.getAllowResources();
   
    this.searchField = new UntypedFormControl();

  }

  
/**
 * getAllowResources function to get record if valid user token
 * @returns Void
 */
getAllowResources() {
  this.api.getUserRole();
  let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
  this.api.getUmsData(body).subscribe({
    next :(res: any) => {
     
      this.api.allowResources = res.data;
      this.broker_access = this.fnc.checkResourceAccess('broker_master', false);
      this.getPackageSubscription();
      // this.getCount();
    }
    
  })

}

handlePagination(e) {
  this.limit = e.pageSize;
  if (e.previousPageIndex !== e.pageIndex) {
    this.offset = e.pageSize * e.pageIndex;
  }
  this.getPackageSubscription();
}

  ngOnInit(): void {
    this.searchField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(term => {
        term = term.trim().toLowerCase();
        if (term) {
          setTimeout(() => {
            this.search_value = term;
            this.getPackageSubscription();
            // this.getCount()
          }, 100);
        } else {
          setTimeout(() => {
            this.search_value = '';
            this.getPackageSubscription();
            // this.getCount()
          }, 100);
        }
      })
  }

  getLabel(key) {
    return key ? key.replace(/_/g, " ") : "";
  }
  getValue(val) {
    if (typeof val === "object") {
      return val ? val[this.api.language] : "";
    } else {
      return val ? val : "";
    }
  }
  checkType(type, data) {
    let labels = ["added_date", "updated_date", "start_date", "end_date"];
    if (type == "date") {
      if (labels.includes(data)) {
        return true;
      } else {
        return false;
      }
    }
  }
   /**
   * This function used on change status filter
   * @returns null
   */
  changeStatus(event) {
    this.status = event;
    this.offset == 0;
    this.searchField.setValue('');
    // this.getPackageSubscription();
    // this.getCount()
  }
  /**
   * This function used to get count for pagination
   * @returns null
   */
  // getCount(){
  //   if(!this.broker_access.GET){
  //     this.notify.notify("You are not authorized to VIEW package subscription", "warn");
  //     return;
  //   }
  //   let url = `package-subscription?status=${this.status}`;
  //   if(this.search_value) url += `&company_name=${this.search_value}`
  //   this.api.getData(url).subscribe((res:any)=>{
  //     if(res && res.data){
  //         this.total = res.data.length;
  //     }
  //   })
  // }

   /**
   * This function used to get package subscription list
   * @returns null
   */
  getPackageSubscription(){
    if(!this.broker_access.GET){
      this.notify.notify("You are not authorized to VIEW package subscription", "warn");
      return;
    }
    this.loader = true;
    let url = `package-subscription?sort_asc=false&sort_by=added_date&status=${this.status}&limit=${this.limit}&offset=${this.offset}`;
    if(this.offset == 0) {
      url += `&is_count=true`;
    }
    if(this.search_value) url += `&company_name=${this.search_value}`
    this.api.getData(url).subscribe({
      next :(res:any)=>{
      this.loader = false;
      if(this.offset == 0) {
        this.total = res?.totalRecord;
      }
      if(res && res.status == 200){
        let temp =[];
        
        res.data.map(ele=>{
          let pack = {
            subscription_id: ele.subscription_id,
            package_id: ele.package_id,
            company_id: ele.company_id,
            package_name: this.getValue(ele.package_name),
            company_name: this.getValue(ele.company_name),
            start_date: this.fnc.formatDateUTC(ele.start_date),
            end_date: this.fnc.formatDateUTC(ele.end_date),
            api_count: ele.api_count,
            status: ele.status,
          };
          
          temp.push(pack)
        })
       this.package_subs_list = temp;
      }else{
        this.package_subs_list =[];
      }
    },error : err=>{
      this.loader = false;
    }})
  }

   /**
   * This function used to open add package subscription dialog box
   * @returns null
   */
   addPackageSub(type:string, packagedata=null){
      if(type == 'add'){
        if(!this.broker_access.POST){
          this.notify.notify("You are not authorized to POST package subscription", "warn");
          return;
        }
       const def= this.dailog.open(AddBrokerSubscriptionComponent, {
          data: {'type':type, data: null},
          width: '400px',
          minHeight: '390px',
          disableClose: true,
          panelClass:'customPadding'
        })
        def.afterClosed().subscribe(flg=>{
          if(flg) this.getPackageSubscription();
        })
      }else if(type == 'edit'){
        if(!this.broker_access.POST){
          this.notify.notify("You are not authorized to Update package subscription", "warn");
          return;
        }
       const def = this.dailog.open(AddBrokerSubscriptionComponent, {
          data: {'type':type, data: packagedata},
          width: '400px',
          panelClass:'customPadding',
          minHeight: '335px',
          disableClose: true
        })
        def.afterClosed().subscribe(flg=>{
          if(flg) this.getPackageSubscription();
        })
      }    
  }

   /**
   * This function used to delete package subscription
   * @returns null
   */
  deleteMaster(ele){
    if(!this.broker_access.DELETE){
      this.notify.notify("You are not authorized to DELETE package subscription", "warn");
      return;
    }
    const def = this.dailog.open(ConfirmDialogBox,{
        data: {parent_data: null, "message":"Do you want to delete package subscription"},
        width: '400px',
        disableClose: true
    })
    def.afterClosed().subscribe(flag=>{
      if(flag == 'YES'){
        let body = {
          'subscription_id': ele.subscription_id,
          'status': -1,
          "user_id": this.api.user_id,
        }
        this.loader = true;
          this.api.patchData('package-subscription', body).subscribe({
            next :(res:any)=>{
            this.loader = false;
              if(res && res.status == 201){
                this.notify.notify(res.message, 'success');
                this.getPackageSubscription();
              }
          }, error :err=>{
            this.loader = false;
          }})
      }
    })
  }


  /**
   * This function used to Update status
   * @returns null
   */
  updateStatus(id, event, status){
    if(!this.broker_access.PATCH){
      this.notify.notify("You are not authorized to Update package subscription", "warn");
      event.source.checked = status == 1?true:false;
      return;
    }

    let body = {
      'subscription_id': id,
      'status': (event.checked == true) ? 1 : 0,
      "user_id": this.api.user_id,
    }
    this.loader = true;
      this.api.patchData('package-subscription', body).subscribe({
        next :(res:any)=>{
        this.loader = false;
          if(res && res.status == 201){
            this.notify.notify(res.message, 'success');
            this.getPackageSubscription();
          }
      },error : err=>{
        this.loader = false;
      }})
  }

}
