import { Component, OnInit, Inject } from '@angular/core';
import { ApiserviceService } from "../../../apiservice.service";
import { CommonfunctionService } from "../../../services/commonfunction.service";
import { NotificationService } from "../../services/notification.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UserAction } from "../../user-action/user-activity";
@Component({
  selector: 'app-add-parameter',
  templateUrl: './add-parameter.component.html',
  styleUrls: ['../comparison-parameter.component.css']
})
export class AddParameterComponent implements OnInit {
  neighborhoodsList: any = [];
  loader: boolean = false;
  key_name = [{
    value: "setback_range",
    name: "Setback Range"
  }, {
    value: "area_range",
    name: "Area Range"
  }]
  searchText: string = '';
  parent_data: any;
  parameterForm: any;
  mode: any;
  constructor(public dialogRef: MatDialogRef<AddParameterComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any,
    private api: ApiserviceService,
    private notify: NotificationService,
    private fnc: CommonfunctionService,
    private fb: UntypedFormBuilder,
  ) {
    this.parent_data = this.info;
    this.mode = this.parent_data.type;
    this.getNeighborhoods();

    if (this.mode == 'add') {
      this.parameterForm = this.fb.group({
        neigh: ['', Validators.required],
        value: ['', [Validators.required, Validators.min(1), Validators.max(100)]],
        keyname: ['', Validators.required]
      })
    }
    if (this.mode == 'edit') {

      this.parameterForm = this.fb.group({
        neigh: ['', Validators.required],
        value: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
        keyname: ['', Validators.required]
      });
      // this.listData.push({
      //   "name": this.parent_data.edit.attribute_name,
      //   "label": this.parent_data.edit.attribute_label,
      // })
      setTimeout(() => {
        this.parameterForm.patchValue({
          'neigh': this.parent_data.edit.neigh_uid,
          'value': this.parent_data.edit.value,
          'keyname': this.parent_data.edit.key_name,
        });
      });
    }
  }


  getNeighborhoods() {
    this.loader = true;
    this.api.getData(`neighbourhoods/details?limit=1000&offset=0`).subscribe({
      next:(res: any) => {
      this.loader = false;
      if (res.data?.length) {
        let sortedList = this.fnc.neighborhoodSortOrder(res.data);
        sortedList.map((item: any) => {
          this.neighborhoodsList.push({
            neigh_id: item.neigh_id,
            neigh_uid: item.neigh_uid,
            neigh_name: (item.neigh_name[this.api.language]) ? item.neigh_name[this.api.language] : item.neigh_uid,
            no_of_blocks: item.no_of_blocks
          })
        });
      }
      else {
        this.notify.notify(res.message, "success")
      }
    }, error:err => {
      this.notify.notify(err.error.message, "error");
      this.loader = false;
    }});
  }

  addParameters(data) {
    let val = data.value;
    let url = `comparison-parameter`;
    var body = {
      "neigh_uid": val.neigh,
      "parameter_name": val.keyname,
      "key_name": val.keyname,
      "value": val.value,
      "added_by": this.api.user_id,

    }
    this.api.postData(url, body).subscribe({
      next:(res: any) => {
      if (res && res.status == 201) {
        // const activity = {
        //   actionId: res.data,
        //   data: body,
        //   actionType: UserAction.add_parameter.Action_ID
        // }
        // this.api.logUserActivity(activity);
        this.notify.notify(res.message, "success");
        this.dialogRef.close();
        this.parent_data.pdata.getParameters();
      }
      else {
        this.loader = false;
        this.notify.notify(res.message, "error");
      }

    }, error:err => {
      this.notify.notify(err.error.message, "error")
    }});
  }
  updateParameter(data) {
    let update = data.value;
    let url = `comparison-parameter`;
    var body = {
      "id": this.parent_data.edit.id,
      "neigh_uid": update.neigh,
      "parameter_name": update.keyname,
      "key_name": update.keyname,
      "value": update.value,
      "updated_by": this.api.user_id,
      "status": 1
    }
    this.api.patchData(url, body).subscribe({
      next:(res: any) => {
        if (res && res.status == 201) {
        //   const activity = {
        //     actionId: res.data,
        //     data: body,
        //     actionType: UserAction.add_parameter.Action_ID
        //   }
        // this.api.logUserActivity(activity);
        this.notify.notify(res.message, "success");
        this.dialogRef.close();
        this.parent_data.pdata.getParameters();
      }
      else {
        this.loader = false;
        this.notify.notify(res.message, "error");
      }


    }, error:err => {
      this.notify.notify(err.error.message, "error")
    }});

  }
  cancel() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
