<div class="row w-100">
    <div class="col-md-12 pr-0">
        <mat-card appearance="outlined" class="m-2 w-100">
            <mat-card-header class="mtc-header pt-2 pb-2 pr-2 position-relative d-flex justify-content-between">
                <mat-card-title>
                    Valuation Factor List
                </mat-card-title>
                <div class="d-flex justify-content-between ">
                    <div class="search">
                        <input class="search-input" [formControl]="searchField" type="text" name="search" placeholder="Search Factor Name...">
                    </div>
                    <div class="show-deleted-container">
                        <div class="show-deleted">
                            <!-- Show Deleted-->
                            <mat-slide-toggle class="deleted-row" (change)="getDeleted($event)">
                                Show Deleted
                            </mat-slide-toggle>
                        </div>
                        <button  mat-raised-button (click)="openDialog('add')">
                            Add Factor
                        </button>
                    </div>
                </div>
            </mat-card-header>
            <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
            <mat-card-content class="list pr-2 pb-2 pl-2 pt-2">
                <div class="w-100 calc-h">
                    <table mat-table matSort [dataSource]="dataSource" class=w-100>

                        <ng-container *ngFor='let disCol of displayedColumns'>
                            <!-- for action column -->
                            <ng-container *ngIf="disCol == 'action'">
                                <ng-container matColumnDef="{{disCol}}">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <span class="header action cp">{{getLabel(disCol)}}</span>
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="pl-2 pr-2">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <button color='white' mat-mini-fab (click)="openDialog('edit',element)">
                        <mat-icon class="editIcon blue d-flex  align-items-center">edit</mat-icon>
                      </button>
                                            <button mat-mini-fab color="warn" (click)="openConfirmation(element)">
                        <mat-icon class="deleteIcon d-flex   align-items-center">delete</mat-icon>
                      </button>
                                        </div>
                                    </td>
                                </ng-container>
                            </ng-container>
                            <!--  -->

                            <!-- for status -->
                            <ng-container *ngIf="disCol == 'status'">
                                <ng-container matColumnDef="{{disCol}}">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <span class="header cp">{{getLabel(disCol)}}</span>
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="pl-2 pr-2">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <mat-slide-toggle [checked]="element.status == 1 ? true: false" (change)="updateStatus(element.id,$event)" (click)="$event.stopPropagation()">
                                            </mat-slide-toggle>
                                        </div>
                                    </td>
                                </ng-container>
                            </ng-container>
                            <!--  -->

                            <!-- for every other column -->
                            <ng-container *ngIf="disCol !='action' && disCol != 'status'">
                                <ng-container matColumnDef="{{disCol}}">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                        <span class="header cp"> {{getLabel(disCol)}} </span>
                                    </th>
                                    <td mat-cell *matCellDef="let element; let i=index;">
                                        <span *ngIf="disCol != 'description'"> {{returnVal(element, disCol)}}</span>
                                        <span *ngIf="disCol == 'SN'"> {{offset+i+1}}</span>
                                        <div class="d-flex justify-content-start align-items-center" *ngIf="disCol == 'description'">
                                            <p matTooltip={{returnVal(element,disCol)}} class="more"> {{returnVal(element,disCol)}} </p>
                                        </div>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-paginator [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = $event; handlePagination($event)">
        </mat-paginator>
    </div>
</div>