<div class="row w-100 master_ui">
    <!-- table -->
    <div class="col-md-12 pr-0">
      <mat-card appearance="outlined" class="m-2 w-100">
        <mat-card-header class="mtc-header pt-2 pb-2 pr-2 position-relative d-flex justify-content-between">
          <mat-card-title class="text-nowrap">
            Listbox Masters
          </mat-card-title>
          <div class="d-flex justify-content-between">
            <div class="search">
              <input class="search-input" [formControl]="searchField" name="search"
                placeholder="Search..." type="text">
            </div>
            <div class=" btn-add-status">
            <mat-select class="form-control mr-2" (selectionChange)="selectionChange()" [(ngModel)]="status">
              <mat-option value="1">Enable</mat-option>
              <mat-option value="0">Disabled</mat-option>
              <mat-option value="-1">Deleted</mat-option>
            </mat-select>
           
              <button class="add-button" (click)="openDialog('add')">
                Add Listbox Masters
              </button>
            </div>
          </div>
        </mat-card-header>
        <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
        <mat-card-content class="translist pr-2 pl-2 pb-2 pt-2">
          <div class="w-100 calc-h">
            <table mat-table [dataSource]="dataSource" class="w-100">
              <ng-container *ngFor='let cols of displayedColumns'>
            
                <!-- for action -->
                <ng-container *ngIf="cols == 'action'">
                  <ng-container matColumnDef="{{cols}}">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="header action cp">{{getLabel(cols)}}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="pl-2 pr-2 ">
                      <div class="d-flex justify-content-center align-items-center">
                        <button color='white' mat-mini-fab (click)="openDialog('edit',element)" *ngIf="element.status != '0' && element.status != '-1'">
                          <mat-icon class="editIcon blue d-flex align-items-center justify-content-center">edit</mat-icon>
                        </button>
                        <button mat-mini-fab color="warn" (click)="deleteListBox(element)" *ngIf="element.status != '-1'">
                          <mat-icon class="deleteIcon d-flex align-items-center justify-content-center">delete</mat-icon>
                        </button>
                      </div>
                    </td>                   
                  </ng-container>
                </ng-container>
  
                <!-- for status -->
                <ng-container *ngIf="cols == 'status'">
                  <ng-container matColumnDef="{{cols}}">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="header cp">{{getLabel(cols)}}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="pl-2 pr-2">
                      <div class="d-flex justify-content-start align-items-center">
                        <mat-slide-toggle [checked]="element.status == 1 ? true: false"
                          (change)="updateStatus(element.id,$event)">
                        </mat-slide-toggle>
                      </div>
                    </td>
                  </ng-container>
                </ng-container>
                <!--  -->
                <!-- for every other column -->
                <ng-container *ngIf="cols !='action' && cols!='status'">
                  <ng-container matColumnDef="{{cols}}">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                      <span class="header cp">
                        {{getLabel(cols)}}
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element; let i=index;">
                      <span> {{returnValue(element,cols)}} </span>
                      <span *ngIf="cols == 'SN'"> {{(i+1)+offset}} </span>
                     
                    </td>
                  </ng-container>
                </ng-container>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>              
            </table>
            <div class="noRecords" *ngIf="dataSource.length === 0 && noRecords">No records found</div>
          </div>
          <div>
            <mat-paginator [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
                (page)="pageEvent = $event; handlePagination($event)">
            </mat-paginator>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>