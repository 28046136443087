<div class="form">
    <div class="mtc-header pt-2 pb-2 pr-2 pl-2 position-relative" fxLayout="row" fxLayoutAlign="center center">
        <div class="pt-1">
            <h5 *ngIf="mode =='add'">Add listbox master</h5>
            <h5 *ngIf="mode =='edit'">Update listbox master</h5>
        </div>
    </div>
    <div>
        <form [formGroup]="listBoxForm" autocomplete="off" novalidate (ngSubmit)="addListbox(listBoxForm)">
            <div class="calc-f">
                <div class="pt-2 pl-4 pr-4">
                    <mat-form-field class="w-100">
                        <input matInput placeholder="Listbox" formControlName="listbox" type="text">
                        <mat-error *ngIf="listBoxForm.controls['listbox'].hasError('required')">Listbox is required</mat-error>
                        <mat-error *ngIf="listBoxForm.controls['listbox'].hasError('pattern')">Listbox is invalid</mat-error>
                    </mat-form-field>
                    <div formArrayName="language">
                        <div *ngFor="let languagevalues of languageSet;let kk = index">
                            <div [formGroupName]="kk" fxLayout="row" fxLayoutAlign="space-between start">
                                <mat-form-field floatLabel="never" fxFlex="30%">
                                    <mat-select placeholder="Language" formControlName="language">
                                        <mat-option *ngFor="let language of language_list"
                                            [value]="language.language_id">
                                            {{ language.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field floatLabel="never" fxFlex="40%">
                                    <input matInput type="text" placeholder="Label" formControlName="value">
                                    <!--<mat-hint>Hint message goes here</mat-hint>-->
                                    <mat-error>Label is required</mat-error>
                                </mat-form-field>

                                <div class="add-btn">
                                    <button type="button" (click)="removeRow(kk)">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                    <button type="button" (click)="addRow()">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-form-field class="w-100">
                        <input matInput placeholder="Value" formControlName="box_value" type="text">
                        <mat-error *ngIf="listBoxForm.controls['box_value'].hasError('required')">value is required</mat-error>
                        <mat-error *ngIf="listBoxForm.controls['box_value'].hasError('pattern')">Special characters not allowed</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <input matInput placeholder="Page name" formControlName="page_name" type="text">
                        <mat-error *ngIf="listBoxForm.controls['page_name'].hasError('required')">Page name is required</mat-error>
                        <mat-error *ngIf="listBoxForm.controls['page_name'].hasError('pattern')">Special characters not allowed</mat-error>
                    
                    </mat-form-field>
                </div>
            </div>
            <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                <div class="pr-2">
                    <button *ngIf="mode =='add'" type="submit" class="submit">
                        Submit
                    </button>
                    <button type="button" *ngIf="mode == 'edit'" class="submit"
                         (click)="updateListbox(listBoxForm)">
                        Update
                    </button>
                </div>
                <div class="pl-2">
                    <button type="button" (click)="cancel()" class="cancel">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>