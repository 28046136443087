import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-type5',
  templateUrl: './type5.component.html',
  styleUrls: ['./type5.component.css']
})
export class Type5Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
