<section class="pro-type-amenities" id="item3">
  <div class="card card-danger">
    <div class="card-body">
      Property value will change based on the selections of amenities
    </div>
  </div>
  <ul class="list-group list-group-horizontal icon-group icon-gr-2">
    <li class="list-group-item swimming"><span class="btn btn-circle active"></span> Swimming<br>Pool</li>
    <li class="list-group-item garden"><span class="btn btn-circle active"></span> Garden</li>
  </ul>
  <p class="font-weight-normal pl-3">Select from the available amenities</p>
  <ul class="list-group list-group-horizontal icon-group icon-gr-2">
    <li class="list-group-item"><span class="btn btn-circle"><img src="./assets/images/template/icons/parking.svg"
          alt=""></span>Parking</li>
    <li class="list-group-item"><span class="btn btn-circle"><img src="./assets/images/template/icons/Gym.svg"
          alt=""></span> Gym</li>
    <li class="list-group-item"><span class="btn btn-circle"><img
          src="./assets/images/template/icons/Multiple%20Purpose%20Hall.svg" alt=""></span> Multi Purpose hall</li>
  </ul>
</section>