<div class="row w-100 mr-0 ml-0">
    <nav class="navbar nav navbar-expand-lg navbar-light bg-light" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <div class="mt-2 mb-2" fxLayout="row" fxLayoutAlign="space-between center">
                <span class="mat-h1 text-center types m-0">Category</span>
            </div>
        </div>
    </nav>
</div>
<div class="row w-100 mr-0 ml-0" id="broker-section">
    <div class="col-md-2 type p-0 br-1">
        <div class="h-100 p-2">
            <mat-list role="list" class="typesList">
                <ng-container *ngFor="let type of master_types">
                    <mat-list-item class="mb-2 l-item card-{{ getIndex(type.id) }} {{
                        isSelected(type.name)
                        }} mat-elevation-z1" role="listitem" routerLink="/admin/broker-master/{{type.name}}"
                        matTooltip="{{ type.label }}">
                        {{ type.label }}
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </div>
    </div>

    <div class="col-md-10 cal-h">
    
        <ng-container *ngIf="selectedFormName == 'company'" >
            <app-company></app-company>
        </ng-container> 
        <ng-container *ngIf="selectedFormName == 'company-area'" >
             <app-area></app-area>
        </ng-container>
          <ng-container *ngIf="selectedFormName == 'package'" >
            <app-package></app-package>
        </ng-container>
        <ng-container *ngIf="selectedFormName == 'broker-package-subscription'" >
            <app-package-subscription></app-package-subscription>
        </ng-container>
        <ng-container *ngIf="selectedFormName == 'api-master'" >
            <app-api-master></app-api-master>
        </ng-container>
    </div>
</div>