import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {

  select_value=[];
  @Input() itemList=[];
  @Input() field='';
  @Input() value_field='';
  @Input() placeholder='';
  @Input() search=false;  
  
  @Output() changeEvent = new EventEmitter();

  search_value:string='';

  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('dropdownid') dropdownid: ElementRef;

  showDropdown=false;
  constructor(private renderer: Renderer2) { 
   
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.renderer.listen('window', 'click',(e:Event)=>{
        if(!this.toggleButton.nativeElement.contains(e.target) && !this.dropdownid?.nativeElement.contains(e.target)) {
          this.showDropdown =false;
          this.search_value='';
        }
      });
    }, 100);
  }

    /**
   * This is function to check dropdown list is opened
   * @returns return boolean value 
   */
  checkActive(){
    if(this.showDropdown){
      return true;
    }
    return false;
  }

  /**
   * This is function to open selection dropdown
   * @returns return boolean value 
   */
  opendropdown(){
    if(this.showDropdown){
      this.showDropdown =false;
    }else{
      this.showDropdown =true;
    }
  }

    /**
   * This is a funtion to use select all value
   * @param event 
   */
  selectAll(event:MatCheckboxChange){
    if(event.checked){
      let temp =[];
      this.select_value = this.itemList.map(item =>{
        temp.push(item[this.value_field]);     
      })
      this.select_value=temp;
    }else{
      this.select_value =[];
    }
    this.changeEvent.emit(this.select_value);
  }

    /**
   * This is a funtion to use select one by one dropdown value
   * @param event event is parameter of checkbox event 
   * @param value event is parameter of checkbox value
   */
  selectOneItem(event, value){
    if(event.checked){
      if(this.select_value.indexOf(value) == -1){
        this.select_value.push(value)
      }
    }else{
      let index = this.select_value.indexOf(value);
      if(index > -1){
        this.select_value.splice(index,1);
      }
    }  
    this.changeEvent.emit(this.select_value);  
  }

   /**
   * This is a funtion to check is_check value
   * @param val val is value of checkbox
   * @returns return boolean value
   */
  isChecked(val){
    if(val && this.select_value.indexOf(val) > -1){
      return true;
    }
    return false;
  }

   /**
   * This is a funtion to check checkAll value
   * @returns return boolean value
   */
  isCheckedAll(){
    if(this.select_value.length == this.itemList.length){
      return true;
    }
    return false;
  }

/**
 * This is a funtion to get label of selected value
 * @param array 
 * @returns 
 */
  getLabel(array:Array<any>):String{
    if(array){
      let temp=[];
      array.map(e=>{
        let item_value = this.itemList.filter(item=> item[this.value_field] == e);
        if(item_value && item_value[0]){
          temp.push(item_value[0][this.field]);
        }
      })      
      return temp.toString();
    }
    return null
  }
}
