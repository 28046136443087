<div class="row w-100 pr-0 ">
  <!-- table -->
  <div class="col-md-12 pr-0 ">
    <mat-card appearance="outlined" class="m-2 w-100">
      <mat-card-header class="mtc-header pt-2 pb-2 pr-2 position-relative d-flex justify-content-between">
        <mat-card-title class="title-wrapper">
          Attribute List
        </mat-card-title>
        <div class="d-flex justify-content-between">
          <div class="search title-wrapper">
            <input class="search-input" [formControl]="searchField" type="text" name="search"
              placeholder="Search Attribute Name...">
          </div>
     <div class="show-delete-add">
      <div class="show-deleted ">
        <!-- Show Deleted-->
        <mat-slide-toggle class="deleted-row title-wrapper" (change)="getDeleted($event)">
          Show Deleted
        </mat-slide-toggle>
      </div>
      <div>
        <button class="title-wrapper" mat-raised-button (click)="openDialog('add')">
          Add Attribute
        </button>
     </div>
          </div>
        </div>
      </mat-card-header>
      <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
      <mat-card-content class="list p-0">
        <div class="w-100 calc-h">
          <table mat-table matSort [dataSource]="dataSource" class="w-100">
            <!-- Serial Number -->
            <ng-container matColumnDef="sn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> SN. </th>
              <td mat-cell *matCellDef="let element; let i=index">
                <span> {{offset + i + 1}} </span>
              </td>
            </ng-container>

            <!-- Column Name Column -->
            <ng-container matColumnDef="column_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Column Name </th>
              <td mat-cell *matCellDef="let element">{{element.column_name}} </td>
            </ng-container>

            <!--Attribute Name Column -->
            <ng-container matColumnDef="attribute_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Attribute Name </th>
              <td mat-cell *matCellDef="let element">{{element.attribute_name}} </td>
            </ng-container>

            <!-- is columnn check Column
             <ng-container matColumnDef="is_column_check">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Is Column Check </th>
              <td mat-cell *matCellDef="let element">{{element.is_column_check}} </td>
            </ng-container> -->

            <!-- data type Column -->
            <ng-container matColumnDef="data_type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Type </th>
              <td mat-cell *matCellDef="let element">{{element.data_type}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let element">
                <mat-slide-toggle class="example-margin" [checked]="element.status == 1 ? true: false"
                  (change)="updateStatus(element.id,$event)">
                </mat-slide-toggle>
              </td>
            </ng-container>

            <!-- Added Date Column -->
            <ng-container matColumnDef="added_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Added Date </th>
              <td mat-cell *matCellDef="let element;">
                <span> {{fncSrv.formatDateUTC(element.added_date)}} </span>
              </td>
            </ng-container>


            <!-- Action Column -->
            <ng-container ngFor matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">
                <button *ngIf="status!='-1'" mat-mini-fab color="warn" (click)="openConfirmation(element)"
                  title="delete">
                  <mat-icon class="delete_icon">delete</mat-icon>
                </button> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

        </div>

      </mat-card-content>

    </mat-card>
    <mat-paginator [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent = $event; handlePagination($event)">
    </mat-paginator>
  </div>

</div>