import { Component, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-city-wise-info',
  templateUrl: './city-wise-info.component.html',
  styleUrls: ['./city-wise-info.component.css']
})
export class CityWiseInfoComponent implements OnInit {
@Output() back= new EventEmitter;
  constructor() { }

  ngOnInit(): void {
  }
  arrowbutton(){
    this.back.emit()
  }

}
