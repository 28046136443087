<!-- *ngIf="!isFormDiv" -->
<mat-card appearance="outlined" class="m-2" >
    <mat-card-header class="mtc-header pt-2 pb-2 pr-2 position-relative d-flex justify-content-between">
        <mat-card-title>
            Nearby Categories
        </mat-card-title>
        <div class="d-flex justify-content-between">
          
          <div class="d-flex align-items-center">
            <mat-menu #menu="matMenu">
                <div class="info_">
                    <div class="info_div_child">Menu With Icons</div>
                    <div class="menu_list">
                        <ng-container *ngFor="let menus of nearby">
                            <div>
                                <div class="info_div">{{menus}}
                                    <mat-icon svgIcon="{{menus}}"></mat-icon>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
              </mat-menu>
            <span class="info"  [matMenuTriggerFor]="menu">
                <mat-icon matTooltip="Nearby Names With Icons" style="cursor: pointer;">info</mat-icon>
            </span>
         </div>
          <div class="show-deleted_custom">
            <!-- Show Deleted-->
                <mat-slide-toggle [(ngModel)]="statusCode" class="deleted-row" (change)="getDeleted($event)">
                    Show Disabled 
                </mat-slide-toggle>
            </div>
            <div>
                <button mat-raised-button (click)="open_category(template)">
                    Add Category
                </button>
            </div>
        </div>
    </mat-card-header>
    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
    <mat-card-content class="pl-2 pr-2 pt-2 pb-3">
        <div class="w-100 calc-h">
            <div class="">
                <mat-table [sortablejs]="dataSource" [sortablejsOptions]="reportoptions" [dataSource]="dataSource"
                    class="w-100">
                    <!-- select Column -->
                    <ng-container matColumnDef="drag">
                        <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <div fxLayout="row" fxLayoutAlign="space-between center" class="draghandle1">
                                <mat-icon class="handle" svgIcon="dragx"></mat-icon>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <!--SN Column -->
                    <ng-container matColumnDef="sn">
                        <mat-header-cell *matHeaderCellDef> S.No</mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index;">
                            <span>{{ i + 1 }}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <span>{{element.name}}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Title Column -->
                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <span>{{element.label}}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Categories Column -->
                    <ng-container matColumnDef="categories">
                        <mat-header-cell *matHeaderCellDef> Categories </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <span>{{element.categories}}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <mat-slide-toggle class="example-margin" [checked]="element.status"
                                (change)="updateStatus(element.id,$event)">
                            </mat-slide-toggle>
                        </mat-cell>
                    </ng-container>

                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <button mat-mini-fab color="primary" (click)="edit(element,$event, template)">
                                <mat-icon class="size-sm">edit</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" class="dragx1"></mat-row>
                </mat-table>
            </div>
            <!-- <table width="100%" class="table">
                <thead>
                    <tr>
                        <th>SN</th>
                        <th>Name</th>
                        <th>Label</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of dataSource">
                        <td>{{data.sn}}</td>
                        <td>{{data.name}}</td>
                        <td>{{data.label}}</td>
                        <td>{{data.categories}}</td>
                        <td>
                            <mat-slide-toggle class="example-margin" [checked]="data.status" (change)="updateStatus(data.id,$event)">
                            </mat-slide-toggle>
                        </td>
                        <td>
                            <button mat-mini-fab color="primary" (click)="edit(data,$event)">
                                <mat-icon class="size-sm">edit</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table> -->
        </div>
    </mat-card-content>
</mat-card>

<!-- add/edit categories *ngIf="isFormDiv"-->
<ng-template #template>
<div class="addCategory" >
    <div class="mtc-header pt-2 pb-2 pr-2 pl-2 position-relative" fxLayout="row" fxLayoutAlign="center center">
        <div>
            <h5 *ngIf="add_category == 'ADD'">Add category near by</h5>
            <h5 *ngIf="add_category == 'UPDATE'">Update category near by</h5>
        </div>
        <mat-progress-bar *ngIf="loader" class="mtc-progress" [color]="color" [mode]="mode" [value]="value">
        </mat-progress-bar>
    </div>
    <div>
        <form [formGroup]="categoryForm" autocomplete="off" novalidate>
            <div class="calc-h">
                <div class="pl-2 pr-2 popup_body">
                    <mat-form-field floatLabel="never" class="w-100">
                        <input matInput type="text" placeholder="Category Name" formControlName="category_name">
                    </mat-form-field>
                    <div formArrayName="language">
                        <div *ngFor="let languagevalues of language_valueset;let kk = index">
                            <div [formGroupName]="kk" fxLayout="row" fxLayoutAlign="space-between start">
                                <mat-form-field floatLabel="never" fxFlex="30%">
                                    <mat-select placeholder="Language" formControlName="language">
                                        <mat-option *ngFor="let language of language_list"
                                            [value]="language.language_id">
                                            {{ language.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field floatLabel="never" fxFlex="40%">
                                    <input matInput type="text" placeholder="Label" formControlName="value">
                                    <!--<mat-hint>Hint message goes here</mat-hint>-->
                                    <mat-error>Label is required</mat-error>
                                </mat-form-field>

                                <div fxFlex="10%">
                                    <button type="button" mat-icon-button (click)="removeRow(kk)">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                                <div>
                                    <button type="button" mat-icon-button (click)="addRow()">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-form-field floatLabel="never" class="w-100">
                        <mat-select placeholder="entity" formControlName="entity"
                            (selectionChange)="changeEntity($event)">
                            <mat-option *ngFor="let data of entities" [value]="data.name">
                                {{data.label}}
                            </mat-option>
                        </mat-select>
                        <mat-error>entity is required</mat-error>
                    </mat-form-field>
                    <mat-form-field floatLabel="never" class="w-100" *ngIf="selected_entity_name == 'property_unit'">
                        <mat-select placeholder="property types" formControlName="proprty_type"
                            (selectionChange)="changePropertyType($event)">
                            <mat-option *ngFor="let data of property_types" [value]="data.property_type_id">
                                {{data.property_type_label[apiService.language]}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field floatLabel="never" class="w-100">
                        <mat-select placeholder="master" formControlName="master"
                            (selectionChange)="changeMaster($event)">
                            <mat-option *ngFor="let data of mastersList" [value]="data.master_id">
                                {{data.master_label[apiService.language]}}
                            </mat-option>
                        </mat-select>
                        <mat-error>master is required</mat-error>
                    </mat-form-field>
                    <mat-form-field floatLabel="never" class="w-100">
                        <mat-select placeholder="Sub master" formControlName="sub_master"
                            (selectionChange)="changeSubMaster($event)">
                            <mat-option *ngFor="let masters of sub_masters" [value]="masters.master_id">
                                {{masters.master_label[apiService.language]}}
                            </mat-option>
                        </mat-select>
                        <mat-error>sub master is required</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="never" class="w-100">
                        <mat-select placeholder="Select category" multiple formControlName="category"
                            (selectionChange)="savetempCat($event)">
                            <mat-option *ngFor="let data of masters_values" [value]="data.master_value_name">
                                {{data.master_value_label[apiService.language]}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-slide-toggle *ngIf="add_category == 'ADD'" class="example-margin" [checked]="status"
                        [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
                        Status
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                <div class="pr-2">
                    <button mat-raised-button color="primary" [disabled]="categoryForm.invalid"
                        *ngIf="add_category == 'ADD'" (click)="addCategory(categoryForm,$event)">
                        Submit
                    </button>
                    <button mat-raised-button color="primary" [disabled]="categoryForm.invalid"
                        *ngIf="add_category == 'UPDATE'" (click)="updateCategoryNearBy(categoryForm,$event)">
                        Update
                    </button>
                </div>
                <div class="pl-2">
                    <!-- (click)="cancel()" -->
                    <button mat-raised-button mat-dialog-close >Cancel</button>
                </div>
            </div>
        </form>

    </div>
</div>
</ng-template>