import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef  } from '@angular/core';
import {Chart} from 'chart.js';
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {
  @Input () editable: boolean = false;
  @Input() data:any;
  @Input() update:boolean = false;
  @Input () editExisting: boolean = false;
  @Output() templateUpdate = new EventEmitter();
  @Input() preview:boolean = false;

  selectedValue: any;
  values: any = [
    {name: 'Land Price', value: 'land_price'},
    {name: 'Building Price', value: 'building_price'},
  ]

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
  }

  selectedBinding(e){
    if(e.value){
      this.selectedValue= e.value
      this.templateUpdate.emit({
        content: '',
        type: 'chart',
        value: this.selectedValue
      })
    }
  }
  changeToggle(e){
    // if(e.checked){
    //   this.staticList = true;
    // }else{
    //   this.staticList = false;
    // }
  }
  ngAfterViewInit(){
    if(this.data){ 
      // console.log(this.data);
    
      if(this.data.value){
        this.selectedValue = this.data.value;
        if(!this.preview){
          this.templateUpdate.emit({
            content: '',
            type: 'chart',
            value: this.selectedValue
          })
        }
      }
    }
    this.cdRef.detectChanges(); 
  }
}
