import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss']
})
export class SingleSelectComponent implements OnInit {
  select_value=null;
  @Input() itemList=[];
  @Input() field='';
  @Input() value_field='';
  @Input() placeholder='';
  @Input() search=false;
  @Output() changeEvent = new EventEmitter();
  search_value:string='';
  isdropdown:boolean=false;
  @ViewChild('toggleButtonSingle') toggleButtonSingle: ElementRef;
  @ViewChild('dropdownidSingle') dropdownidSingle: ElementRef;
  constructor(private renderer: Renderer2) { 
    
  }

  ngOnInit(): void {
    setTimeout(() => {
      // this.renderer.listen('window', 'click',(e:Event)=>{
      //   if(!this.toggleButtonSingle?.nativeElement.contains(e.target) && !this.dropdownidSingle?.nativeElement.contains(e.target)) {
      //     this.isdropdown=false;
      //     this.search_value='';
      //   }
      //  });
    }, 100);
  }

  /**
   * This is function to check dropdown list is opened
   * @returns return boolean value 
   */
  checkActive(){
    if(this.isdropdown){
      return true;
    }
    return false;
  }

  /**
   * This is function to open selection dropdown
   * @returns return boolean value 
   */
  opendropdown(){
    if(this.isdropdown){
      this.isdropdown=false;
    }else{
      this.isdropdown=true;
    }
  }

  /**
   * This is a funtion to use select one by one dropdown value
   * @param event event is parameter of checkbox event 
   * @param value event is parameter of checkbox value
   */
  selectOneItem(value){
    this.select_value = value;
    this.isdropdown=false;
    this.changeEvent.emit(this.select_value);  
  }

  /**
   * This is a funtion to check is_check value
   * @param val val is value of checkbox
   * @returns return boolean value
   */
  isChecked(val){
    if(this.select_value == val){
      return true;
    }
    return false;
  }

/**
 * This is a funtion to get label of selected value
 * @param array 
 * @returns 
 */
  getLabel(value):String{
    if(value){
        let item_value = this.itemList.filter(item=> item[this.value_field] == value);
        if(item_value && item_value[0]){
          return item_value[0][this.field];
        }
        return null   
    }
    return null
  }
}