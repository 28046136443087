<ng-container *ngIf="editable && !editExisting && !preview">
    <div class="p-2" #container (click)="update()">
        <div class="section-header">
            <span>Overview</span>
        </div>
        <p>B - 16, Corel Main road, Block - 9, Kuwait.</p>
        <p>Tower - 3, Floor - 5, Unit - 3</p>
        <p> Wonderful opportunity to own this charming move-in ready appartment Easy access to Hwys, yet situated in a
            quiet, upscale family
            neighborhood in kuwait.vaulted ceilings in the living and dining rooms, kitchen with lots of windows and
            light, spacious master
            bedroom and bathroom suite, inside laundry room etc.</p>
    </div>
</ng-container>
<ng-container *ngIf="!editable && !editExisting && !preview">
    <div class="p-2">
        <div class="section-header">
            <span>Overview</span>
        </div>
        <p>B - 16, Corel Main road, Block - 9, Kuwait.</p>
        <p>Tower - 3, Floor - 5, Unit - 3</p>
        <p> Wonderful opportunity to own this charming move-in ready appartment Easy access to Hwys, yet situated in a
            quiet, upscale family
            neighborhood in kuwait.vaulted ceilings in the living and dining rooms, kitchen with lots of windows and
            light, spacious master
            bedroom and bathroom suite, inside laundry room etc.</p>
    </div>
</ng-container>
<ng-container *ngIf="editable && editExisting && !preview">
    <div class="p-2" #container (keyup)="update()" (click)="update()">
    </div>
</ng-container>
<ng-container *ngIf="preview">
    <div class="p-2" #container>
    </div>
</ng-container>