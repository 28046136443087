import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  @Input () editable: boolean = false;
  @Input() data:any;
  @Input() update:boolean = false;
  @Input () editExisting: boolean = false;
  @Output() templateUpdate = new EventEmitter();
  @Input() preview:boolean = false;
  staticList: boolean = false;
  selectedValue: any;
  selectedListType: any;
  // values: any = ['Property type','Amenities type','Area type']
  listValues: any = [
    {name: 'Property detail', value: 'property_detail'},
    {name: 'POI Distance', value: 'poi_distance'},
    {name: 'Accessibility index', value: 'accessibility_index'},
    {name: 'Amenities', value: 'amenities'},
    {name: 'Table View', value: 'table_view'},
  ]
  values: any = [
    {name: 'Property details', value: 'property_detail'},
    {name: 'Amenities', value: 'amenities'},
    {name: 'Parcel Detail', value: 'parcel_detail'}
  ]
  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
  
  }

  selectList(e){
    if(e.value){
      this.selectedListType = e.value;
      this.templateUpdate.emit({
        content: '',
        type: 'list',
        subtype: this.selectedListType,
        value: this.selectedValue
      })
    }
  }
  selectedBinding(e){
    if(e.value){
      this.selectedValue= e.value
      this.templateUpdate.emit({
        content: '',
        type: 'list',
        subtype: this.selectedListType,
        value: this.selectedValue
      })
    }
  }
  changeToggle(e){
    if(e.checked){
      this.staticList = true;
    }else{
      this.staticList = false;
    }
  }
  ngAfterViewInit(){
    if(this.data){
      if(this.data.value){ 
        this.selectedValue = this.data.value;
        this.selectedListType = this.data.data.subtype;
        if(!this.preview){
          this.templateUpdate.emit({
            content: '',
            type: 'list',
            subtype: this.selectedListType,
            value: this.selectedValue
          })
        }
      }
    }
    this.cdRef.detectChanges(); 
  }
}
