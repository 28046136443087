import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { PaginationInstance } from 'ngx-pagination/dist/ngx-pagination.module';

@Component({
  selector: 'app-city-wise-table',
  templateUrl: './city-wise-table.component.html',
  styleUrls: ['./city-wise-table.component.css']
})
export class CityWiseTableComponent implements OnInit {
  toggle: boolean= false;
  dialog: any;
  constructor() { }

  ngOnInit(): void {
  }
  action(){
    this.toggle = !this.toggle
  }
 


}
