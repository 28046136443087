<div class="form">
    <div class="mtc-header pt-2 pb-2 pr-2 pl-2 position-relative" fxLayout="row" fxLayoutAlign="center center">
        <div>
            <h5 *ngIf="mode == 'add'">Add Attribute/Column</h5>
        </div>
        <mat-progress-bar class="mtc-progress" mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
    </div>
    <div>
        <form [formGroup]="AttributeForm" autocomplete="off" (ngSubmit)="addAttribute(AttributeForm)">
            <div>
                <div class="add-lists pl-4 pr-4">
                    <mat-form-field class="w-100">
                        <mat-select required placeholder="Select Type" (selectionChange)="selectTypeList($event)" formControlName="type">
                            <mat-option *ngFor="let type of listTypes" [value]="type.value">
                                {{ type.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <mat-select required placeholder="Field Name" formControlName="name">
                            <div class="search_bar">
                                <input mat-input [(ngModel)]="searchText"  placeholder="Type here to search..." (keydown)="$event.stopPropagation()" [ngModelOptions]="{standalone: true}"/>
                            </div>
                            <mat-option *ngFor="let data of listData | filter :'name': searchText" [value]="data.name">
                                {{ data.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <mat-form-field floatLabel="never" class="w-100">
        <input matInput type="text" placeholder="Description" formControlName="description">
    </mat-form-field> -->
                </div>
            </div>
            <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                <div class="pr-2">
                    <button type="submit" mat-raised-button color="primary" [disabled]="AttributeForm.invalid" *ngIf="mode == 'add'">
            Submit
          </button>
                </div>
                <div class="pl-2">
                    <button type="button" mat-raised-button (click)="cancel()">
            Cancel
          </button>
                </div>
            </div>
        </form>
    </div>
</div>