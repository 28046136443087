<div class="p-3">
  <div class="title-bar">
    <h4>Company Area</h4>
  </div>
  <div class="d-flex flex-direction-column h-100 w-100">
    <mat-drawer-container class="broker-area-container" autosize>
      <mat-drawer #sidenav class="broker-area-sidenav" mode="over">
        <div class="inner">
          <mat-toolbar class="mat-elevation-z3">
            <h4>Saved Areas</h4>
          </mat-toolbar>
          <div class="outer-cont">
            <div class="chip-cont" *ngIf="polygons.length>0; else noarea">
                <mat-chip-option (click)="currentArea(poly)" *ngFor="let poly of polygons" [removable]="removable"
                  [selectable]="selectable" (removed)="confirm(poly)">
                  {{poly.label[currentLanguage]}}
                  <mat-icon class="rem" matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip-option>
            </div>
            <ng-template #noarea>
              <div class="chip-cont p-3">
                Area not selected
              </div>
            </ng-template>
          </div>
        </div>
      </mat-drawer>

      <div class="broker-area-sidenav-content position-relative">
        <div class="load-cont" *ngIf="load">
          <span>Loading...</span>
          <mat-progress-bar class="progress" [color]="'primary'" mode="indeterminate">
          </mat-progress-bar>
        </div>
        <mat-toolbar class="w-100">
          <div class="w-100 wrap-on overflow-auto" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="start center">
              <button mat-icon-button (click)="sidenav.toggle()">
                <mat-icon aria-label="Toggle menu">menu</mat-icon>
                <b *ngIf="polygons.length>0" class="badge bg-back up">{{polygons.length}}</b>
              </button>
              <button class="drawButton" mat-button [class.active]="mode == 'Circle'" (click)="drawCircle()">
                <span>Circle <mat-icon class="iconfx" [class.active]="mode == 'Circle'" svgIcon="circle"></mat-icon>
                </span>
              </button>
              <button class="drawButton" mat-button [class.active]="mode == 'Polygon'" (click)="drawPolygon()">
                <span>Polygon <mat-icon class="iconfx" [class.active]="mode == 'Polygon'" svgIcon="polygon"></mat-icon>
                </span>
              </button>
              <button class="drawButton" (click)="reset()" mat-button [disabled]="mode == ''">Reset</button>

              <mat-select (selectionChange)="selectNeighbourhood()" placeholder="Select Neighbourhood"
                [(ngModel)]="selectedNeighborhood" class="form-select-l ml-1">
                <div class="search_bar">
                  <input mat-input [(ngModel)]="neighbourhood_searchText" placeholder="Search" (keydown)="$event.stopPropagation()" />
              </div>
                <mat-option *ngFor="let neigh of neighbourhoods | filter :'name': neighbourhood_searchText" [value]="neigh.neigh_uid">
                  {{neigh.name}}</mat-option>
              </mat-select>

              <ng-container *ngFor="let item of dropDownValues">
                <mat-select (selectionChange)="selectDropdownItem($event, item.name)" placeholder="Select {{item.name}}"
                  class="form-select-l ml-1">
                  <div class="search_bar">
                    <input mat-input [(ngModel)]="company_searchText" placeholder="Search" (keydown)="$event.stopPropagation()" />
                </div>
                  <mat-option *ngFor="let val of item.value | filter :'company_name[1]': company_searchText" value="{{getLabel(val, item, true)}}">
                    {{getLabel(val, item)}}</mat-option>
                </mat-select>
              </ng-container>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center">
              <button class="save-button" (click)="saveData()">Save</button>
            </div>
          </div>
        </mat-toolbar>
        <div class="w-100 h-100">
          <div #gmap class="w-100" style="height: 470px;"></div>
        </div>
      </div>

    </mat-drawer-container>
  </div>
</div>