import { Component, OnInit, Inject } from '@angular/core';
import { ApiserviceService } from '../../../apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { NotificationService } from '../../services/notification.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { UserAction } from "../../user-action/user-activity";
@Component({
  selector: 'app-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.css']
})
export class CategoryDialogComponent implements OnInit {

  master_values: any = [];
  selected_values = [];
  mastervaluesLeft: any[] = [];
  mastervaluesright: any[] = [];

  _loader: boolean;
  search1:any;
  search2:any;
  dummy = 0;
  constructor(
    public dialogRef: MatDialogRef<CategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public parentData: any,
    private notify: NotificationService,
    private fnc: CommonfunctionService,
    private apiService: ApiserviceService,
  ) {
    if (this.master_values.length <= 0) {
      this.getmastervalue();
    }
  }

  /**
   * getmastervalue function to get Master list from master
   */
  getmastervalue() {

    this.apiService.getData(`property/masters/values?master_names=prop_sub_cat`).subscribe({
      next: (res: any) => {
      if (res && res.status == 200) {
        let all = {
          master_value_name:'all'
        }
        this.master_values = res.data;
        this.master_values.push(all);
        let mastervalues = this.parentData.ele.value.split(',');
        mastervalues.forEach(element => {
          for (var i = 0; i < this.master_values.length; i++) {
            this.master_values[i]['id'] = i;
            if (this.master_values[i].master_value_name == element) {
              this.mastervaluesright.push(this.master_values[i]);
              this.master_values.splice(i, 1);
            }
          }
        });
        this.mastervaluesLeft = this.master_values;

        this.mastervaluesLeft.sort(this.fnc.GetSortOrder("master_value_name"));
        this.mastervaluesright.sort(this.fnc.GetSortOrder("master_value_name"));
      }
    }, error: () => {

    }
  })
  }
  /**
   * Drop Event to drag/drop both direction 
   * @param event Drag Event 
   * @param direction container
   */
  drop(event: CdkDragDrop<any[]>, direction:any=null) {
    if (event.previousContainer !== event.container) {
      let itemId = event.previousContainer.data[event.previousIndex].id;
      if (event.previousContainer.id !== 'selectedList') {
        this.moveItem(this.mastervaluesLeft, this.mastervaluesright, itemId, event.previousIndex, event.currentIndex);
      } else {
        this.moveItem(this.mastervaluesright, this.mastervaluesLeft, itemId, event.previousIndex, event.currentIndex);
      }
      this.dummy++;
    }
  }
  /**
   * moveItem to move on specific index 
   * @param fromList Data list to/from
   * @param toList Data list from/to
   * @param itemId previoud index item id
   * @param prevIndex previous index
   * @param currentIndex  current index
   */
  moveItem(fromList, toList, itemId, prevIndex, currentIndex) {
    let item = fromList.find((x) => x.id == itemId);
    let itemIndex = fromList.findIndex((x) => x == item);
    fromList.splice(itemIndex, 1);
    toList.splice(currentIndex, 0, item);
  }
  /**
   * Update function to update/add item in DB
   * @returns 
   */
  Update() {
    if (!this.parentData.info.setting_access.PATCH) {
      this.notify.notify("You are not authorized to add setting", "warn");
      return;
    }
    let selected_vals = [];
    this.mastervaluesright.forEach(element => {
      selected_vals.push(element.master_value_name);
    });
    var body = {
      "setting_id": this.parentData.ele.setting_id,
      "value": selected_vals.toString(),
      "status": this.parentData.ele.status,
      "user_id": this.apiService.user_id
    }
    this._loader = true;
    
    this.apiService.patchData('settings', body).subscribe((res: any) => {
      if (res && res.status == 201) {
        // const activity={
        //   actionId: res.data,
        //   data: body,
        //   actionType:UserAction.update_setting.Action_ID
        // }
        // this.apiService.logUserActivity(activity);
        this.notify.notify(res.message, "success")
        this.parentData.info.getSettings();
        this.cancel();
      }
      this._loader = false;
    }, err => {
      this.notify.notify("settings not update", "error")
      this._loader = false;
    })

  }
  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close();
  }
}
