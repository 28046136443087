import { Component, OnInit, Inject, Input, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { AdminBreadcrumbService } from '../admin-breadcrumb/admin-breadcrumb.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
// import { renderComponent } from '@angular/core/src/render3';
import { SafeUrlPipe } from '../../safe-url.pipe';
import { Options } from 'sortablejs';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuItem } from '../../interfaces';
import { NotificationService } from '../services/notification.service';
import { ShareService } from '../services/share.service';
import { Router } from '@angular/router';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { UserAction } from "../../../app/admin/user-action/user-activity";
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';
/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>umsapi_user_resource_post</li>
 * <li>emapi_menu_get</li>
 * <li>emapi_menu_patch</li>
 * </ol>
 *
 */
@Component({
  selector: 'app-menu-table',
  templateUrl: './menu-table.component.html',
  styleUrls: ['./admin-valuator.component.css']
})
export class MenuTableComponent implements OnInit {
  public menu_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  selectedCellId: any;
  displayedColumns = ['drag', 'sn', 'name', 'title', 'require_login', 'status', 'template', 'action'];
  menuItems: Array<MenuItem> = [];
  dataSource: Array<MenuItem> = [];
  loader: boolean = false;
  @Output() valueChange = new EventEmitter();
  menuData: any;
  selected_status='1';

  searchField: UntypedFormControl;

  constructor(
    private api: ApiserviceService,
    private menus: AdminMenusService,
    private _crumbs: AdminBreadcrumbService,
    private fnc: CommonfunctionService,
    public dialog: MatDialog,
    private notify: NotificationService,
    private share: ShareService,
    private route: Router) {
    this.share.tableObj = this;
  }
  reportoptions: Options = {
    draggable: '.dragx1',
    handle: '.draghandle1',
    scroll: true,
    onUpdate: (event: any) => this.sortMenu()
  };

  ngOnInit() {
    //api calls on init
    this.getAllowResources();

    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges.pipe(
      debounceTime(500))
      .subscribe(term => {
        term = term.trim().toLowerCase();
        if (term) {
          this.getMenuList(term);
        } else {
          this.getMenuList(null)
        }
      })
  }
  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next :(res: any) => {
        
        this.api.allowResources = res.data;
        this.menu_access = this.fnc.checkResourceAccess('menu', false);
        this.getMenuList();
      }
      
    }
    )

  }
  
  
  // getAllowResourcesApi() {
  //   this.api.getUserRole(); // getting role assign to this user
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     .subscribe(
  //       (data:any) => {
  //          this.api.allowResources = data;
  //          this.menu_access = this.fnc.checkResourceAccess('menu', false);
  //          this.getMenuList();
  //       },
  //       err => {
  //         if (err['status'] == 403) { // log out when unauthorised
  //           localStorage.clear();
  //           this.api.user_id = null;
  //           this.route.navigateByUrl('admin/login');
  //         }
  //       }
  //     )
  // }
  // functions--------------------------------------------------------------------------------------------------------
  sortMenu() {
    this.sortMenuItems();
  }
  edit(element) {
    if (!this.menu_access.PATCH) {
      this.notify.notify('You are not authorized to update menu', 'warn');
      return;
    }
    this.valueChange.emit(element);
    this.selectedCellId = element.id;
  }
  cancelEdit(id) {
    if (id == this.selectedCellId) {
      this.selectedCellId = '';
    }
  }
  showlabel(item: any) {
    if (item && item.title && item.title[this.api.language]) {
      return item.title[this.api.language];
    } else {
      return item.name;
    }
  }
  // functions--------------------------------------------------------------------------------------------------------

  // Calling all autobots---------------------------------------------------------------------------------------------
  getMenuList(search='') {
    if (!this.menu_access.GET) {
      this.notify.notify('You are not authorized to get menu', 'warn');
      return;
    }
    this.loader = true;
    let url = `menu?order_by=sort_order&sort_asc=true&status=${this.selected_status}`
    if(search){
      url += `&name=${search}`
    }
    this.api.getData(url).subscribe((res: any) => {
      this.loader = false;
      if (res) {
        this.menuItems = res;
        // res.forEach(item => {
        //     let data: MenuItem = {
        //         created_date: item.created_date,
        //         icon: item.icon,
        //         id: item.id,
        //         name: item.name,
        //         property_Type: item.property_Type,
        //         sort_order: item.sort_order,
        //         status: item.status,
        //         template: item.template,
        //         title: item.title,
        //         type_id: item.type_id,
        //         login_require: item.login_require
        //     }
        //     this.menuItems.push(data);
        // });
        this.dataSource = this.menuItems;
        
      }
    }, err => {
      this.loader = false;

    })
  }
  //sorting menu items
  sortMenuItems() {
    if (!this.menu_access.PATCH) {
      this.notify.notify('You are not authorized to update menu', 'warn');
      return;
    }
    let body: any = [];
    this.menuItems.forEach((v, k) => {
      body.push({
        "user_id": this.api.user_id,
        "sort_order": k + 1,
        "id": v.id
      });
    });
    this.loader = true;
    this.api.patchData('menu', body).subscribe((res: any) => {
      // if(res[0]?.code == 1){
      //   const activity={
      //     actionId: res[0]?.id,
      //     data: body,
      //     actionType:UserAction.menu_update.Action_ID
      //   }
      //   this.api.logUserActivity(activity);
      // }
      this.loader = false;
      if (res) {
        this.getMenuList();
        this.notify.notify('Menu order updated', 'success');
      }
    }, err => {
      this.loader = false;
      this.getMenuList();
      // this.notify.notify('Order not updated, please try again', 'DISMISS');
    })
  }

  //change values for toggle type section
  changeToggle(e, data, type) {
    if (!this.menu_access.PATCH) {
      this.notify.notify('You are not authorized to update menu', 'warn');
      return;
    }
    let body: any = []
    if (type == 'status') {
      body.push({
        "user_id": this.api.user_id,
        "status": (e.checked) ? 1 : 0,
        "id": data.id
      })
    }
    if (type == 'login') {
      body.push({
        "user_id": this.api.user_id,
        "login_require": (e.checked) ? 1 : 0,
        "id": data.id
      })
    }
    this.api.patchData('menu', body).subscribe((res: any) => {
      // if(res[0]?.code == 1){
      //   const activity={
      //     actionId: res[0]?.id,
      //     data: body,
      //     actionType:UserAction.menu_update.Action_ID
      //   }
      //   this.api.logUserActivity(activity);
      // }
      if (type == 'status') {
        this.notify.notify('Status updated', 'success');
      } else {
        this.notify.notify('Require login updated', 'warn');
      }
      this.getMenuList()
    }, err => {
      this.notify.notify('Data not updated', 'error');
    })
  }
  deteleMenu(v) {
    if (!this.menu_access.DELETE) {
      this.notify.notify('You are not authorized to delete menu', 'warn');
      return;
    }
    this.menuData = v;
    let _data = {
      parent_data: this,
      message: 'Do you want to delete menu'
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
    });

  }
  public confirmDialogYes(check) {
    if (check == 'YES') {
      let body = [];
      body.push({
        "user_id": this.api.user_id,
        "status": - 1,
        "id": this.menuData.id
      })

      this.api.patchData('menu', body).subscribe(res => {
        // if(res[0]?.code == 1){
        //   const activity={
        //     actionId: res[0]?.id,
        //     data: body,
        //     actionType:UserAction.menu_delete.Action_ID
        //   }
        //   this.api.logUserActivity(activity);
        // }
        this.notify.notify('Menu deleted successful', 'success');
        this.getMenuList();
      }, err => {
        this.notify.notify(this.fnc.getErrorMessage(err), "error")
      })
    }
  }
  openDialog(template) {
    let dialogRef = this.dialog.open(HtmlDialog, {
      width: '550px',
      panelClass: 'create-master-panel',
      data: { parent: this, template: template }//optional
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  // Calling all autobots---------------------------------------------------------------------------------------------
}


//create dialog component
@Component({
  selector: 'html-dialog',
  templateUrl: './html-dialog.html',
  styleUrls: ['./admin-valuator.component.css']
})

export class HtmlDialog {
  selectedDataType: any = [];
  id: number = 1;
  arr: any = [];

  constructor(public dialogRef: MatDialogRef<HtmlDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, private api: ApiserviceService) {
    if (this.data.template) this.arr = this.data.template[this.api.language];
  }

  ngOnInit() {
    this.getExistingData();
  }

  capitalize_each_word(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  removeUnderscores(value) {
    if (value) {
      value = value.toString().trim();
      value = value.toLowerCase();
      let name = value.replace(/_/g, ' ');
      return this.capitalize_each_word(name);
    } else {
      return '';
    }
  }
  getExistingData() {
    if (this.data) {
      if (Array.isArray(this.arr)) {
        this.arr.forEach(element => {
          this.selectedDataType.push({
            render_type: element.render_type,
            data: element.data,
            id: this.id++,
            value: (element.data && element.data.value) ? this.removeUnderscores(element.data.value) : null,
            options: null
          })
        });
      }
    }
  }


}
