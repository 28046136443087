<div class="row w-100">
  <div class="col-md-3 pr-0">
    <mat-card appearance="outlined" class="m-2 w-100">
      <mat-card-header>
        <mat-card-title>Find Block For Removing</mat-card-title>
      </mat-card-header>
      <mat-progress-bar [color]="'primary'" mode="indeterminate" *ngIf="loder_1">
      </mat-progress-bar>
      <mat-card-content class="d-flex flex-column justify-content-center align-items-center">
        <mat-form-field class="w-90">
          <mat-label>Select valuation type</mat-label>
          <mat-select (selectionChange)="selectValueType($event)" matNativeControl required>
            <mat-option *ngFor="let type of valuationTypes" [value]="type.value">{{type.label}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-90">
          <mat-label>Select neighbourhood</mat-label>
          <mat-select (selectionChange)="selectneighUID($event)" matNativeControl required>
            <mat-option *ngFor="let neigh of neighbourhoods" [value]="neigh.uid">{{neigh.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-content>
      <!-- <mat-card-actions>
       
      </mat-card-actions> -->
    </mat-card>
  </div>
  <div class="col-md-9 pr-0">
    <mat-card appearance="outlined" class="m-2 w-100">
      <mat-card-header>
        <mat-card-title>
          Block List
        </mat-card-title>
      </mat-card-header>
      <mat-progress-bar [color]="'primary'" mode="indeterminate" *ngIf="loder">
      </mat-progress-bar>
      <mat-card-content class="overFlow">
        <div class="w-100 calc-h">
          <mat-table [dataSource]="blockDataList" class="w-100">             
            <!--SN Column -->
            <ng-container matColumnDef="sn">
                <mat-header-cell *matHeaderCellDef> S.No </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index;">
                    <span>{{ i + 1 }}</span>
                </mat-cell>
            </ng-container>
           
            <!-- name Column -->
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element;">
                    <span>{{element.name}}</span>
                </mat-cell>
            </ng-container>

           <!-- id Column -->
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
                <mat-cell *matCellDef="let element;">
                    <span>{{element.id}}</span>
                </mat-cell>
            </ng-container>
            
           
            <!-- uid Column -->
            <ng-container matColumnDef="uid">
              <mat-header-cell *matHeaderCellDef> Uid </mat-header-cell>
              <mat-cell *matCellDef="let element;">
                  <span>{{element.uid}}</span>
              </mat-cell>
          </ng-container>

          <!-- action Column -->
            <ng-container matColumnDef="action" class="action-column">
                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                <mat-cell *matCellDef="let element;">
                  <button mat-mini-fab color="warn" (click)="deleteBlock(element,$event)">
                    <mat-icon class="delete_icon">delete</mat-icon>
                </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="dragx1"></mat-row>
        </mat-table>
      </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>