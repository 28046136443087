<mat-card appearance="outlined" class="m-2">
    <mat-card-header class="mtc-header pt-2 pb-2 pr-2 position-relative d-flex justify-content-between">
        <mat-card-title>Settings</mat-card-title>
        <div class="d-flex justify-content-between">
            <mat-icon class="index-icon" *ngIf="indexList.length > 0" matTooltip="Show Available Servers"
                [matMenuTriggerFor]="tableIndexList">
                info_outline</mat-icon>
            <mat-menu #tableIndexList>
                <mat-table [dataSource]="indexList" class="w-100 tableMaterial">
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef class="headerCellMaterial">Name</mat-header-cell>
                        <mat-cell class="p-3" *matCellDef="let element" class="cellMaterial"> {{element.name}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="options">
                        <mat-header-cell *matHeaderCellDef class="headerCellMaterial"> Options </mat-header-cell>
                        <mat-cell class="p-3" *matCellDef="let element" class="cellMaterial"> {{element.options}}
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="indexListCols" class="headerRowMaterial"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: indexListCols;" class="rowMaterial"></mat-row>
                </mat-table> 
            </mat-menu>
            <input class="search-input" [formControl]="searchField" type="text" name="search" placeholder="Search...">

       <div class="show-delete-add">  
           <div class="show-deleted">
        <!-- Show Deleted-->
        <!-- <mat-slide-toggle class="deleted-row" (change)="getDeleted($event)">
            Show Deleted
        </mat-slide-toggle> -->
        <mat-select placeholder="Choose status" [(ngModel)]="statusCode" (selectionChange)="selectionChange($event.value)">
            <mat-option value="1">Active</mat-option>
            <mat-option value="0">Inactive</mat-option>
            <mat-option value="-1">Deleted</mat-option>
        </mat-select>
    </div>
    <button class="text-nowrap" mat-raised-button (click)="openDailog('add')">
        Add Setting
    </button>
</div>
</div>
    </mat-card-header>
    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
    <mat-card-content class="pl-2 pr-2 pt-2 pb-3">
        <div class="w-100 calc-h">
            <div class="">
                <mat-table [dataSource]="dataSource" class="w-100">
                    <!--SN Column -->
                    <ng-container matColumnDef="sn">
                        <mat-header-cell *matHeaderCellDef> SN </mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index;">
                            <span>{{ i + 1 }}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Key Column -->
                    <ng-container matColumnDef="key">
                        <mat-header-cell *matHeaderCellDef> Key </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <span>{{element.key}}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Value Column -->
                    <ng-container matColumnDef="value">
                        <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <span class="_bg view_value" (click)="editValue(element, 'value')"
                                *ngIf="selectedCellId != element.setting_id+'_value'"
                                title="{{element.value}}">{{element.value}}</span>
                            <input matInput type="text" class="input-box" [(ngModel)]="changed_value"
                                *ngIf="selectedCellId == element.setting_id+'_value'">
                            <div class="mlr-2" fxLayout="row" fxLayoutAlign="center center"
                                *ngIf="selectedCellId == element.setting_id+'_value'">
                                <mat-button-toggle class="marg-2" value="left" (click)="edit_setting(element)"
                                    title="Save">
                                    <mat-icon class="size-sm accent">save</mat-icon>
                                </mat-button-toggle>
                                <mat-button-toggle class="marg-2" value="center" (click)="cancelEdit(element.value)"
                                    title="Cancel">
                                    <mat-icon class="size-sm"
                                        [ngClass]="{'default': selectedCellId == element.value, 'inactive': selectedCellId != element.value}">
                                        close</mat-icon>
                                </mat-button-toggle>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <!-- Type Column -->
                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <span>{{element.type}}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef> status </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <mat-slide-toggle class="example-margin" [checked]="element.status == 1 ? true: false"
                                (change)="updateStatus(element.setting_id,$event)">
                            </mat-slide-toggle>
                        </mat-cell>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="added_by">
                        <mat-header-cell *matHeaderCellDef> Added By </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <span>{{element.added_by}}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Added Date Column -->
                    <ng-container matColumnDef="added_date">
                        <mat-header-cell *matHeaderCellDef> Added Date </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <span>{{element.added_date | date:'dd MMM yyyy'}}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <button mat-mini-fab color="warn" (click)="openConfirmation(element)" *ngIf="element.status != -1">
                                <mat-icon class="deleteIcon">delete</mat-icon>
                              </button>
                              <span *ngIf="element.status == -1">-</span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" class="dragx1"></mat-row>
                </mat-table>
            </div>
        
 
        </div>
    </mat-card-content>
    <mat-paginator *ngIf="totalCount > 0" [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event; handlePagination($event)">
</mat-paginator>
</mat-card>
