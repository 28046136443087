
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], field: string, value: string, list: any[]): any[] {
        if (!items) return [];
        if (!value) return items;
        if (list) items = list;
        if (field) {
            if (field.indexOf("[") != -1 && field.indexOf("]") != -1) { ////For if the value is present in a object array
                let index = field.indexOf("[");
                let val = field.slice(index);
                val = val.replace(/[\])}[{(]/g, '');
                let val1 = field.slice(0, index);
                return items.filter(item => item[val1][val]?.toLowerCase().includes(value.toLowerCase()));
            } else {
                return items.filter(item => item[field]?.toLowerCase().includes(value.toLowerCase()));
            }
        } else {
            return items.filter(item => item.toLowerCase().includes(value.toLowerCase()));
        }

    }
}