import { Component, OnInit, HostListener, ElementRef, Inject, AfterViewInit, Input, Output } from '@angular/core';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import * as $ from 'jquery';
import { NgForm, Validators, FormGroup, FormControl, FormBuilder, FormArray, NgModel } from '@angular/forms';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { Options } from 'sortablejs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiserviceService } from '../../apiservice.service';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { AdminBreadcrumbService } from '../admin-breadcrumb/admin-breadcrumb.service';
import { ReportGroups } from '../../interfaces';
import { SafeUrlPipe } from '../../safe-url.pipe';

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_reportGroups_get</li>
 * <li>estapi_report_get</li>
 * <li>estapi_reportType_get</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-admin-master',
  templateUrl: './admin-master.component.html',
  styleUrls: ['./admin-master.component.css']
})
export class AdminMasterComponent {
  color = 'accent';
  orderBy: string = '';
  height: number;
  inHeight: number;
  selected_item: any;
  selected: any;

  loader: boolean = false;
  noaccess:boolean = false;
  sort_asc: boolean = true;
  showsubmas: boolean = false;
  loaderiframe: boolean = true;
  group_loader: boolean = false;

  //data variables
  group_list: Array<ReportGroups> = [];
  report_list: Array<ReportGroups> = [];
  selected_report_type: string;
  report_type: any = [];

  selected_report: any;

  constructor(public dialog: MatDialog,
    private menus: AdminMenusService,
    private _crumbs: AdminBreadcrumbService,
    private _el: ElementRef,
    iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public api: ApiserviceService,
    private fnc: CommonfunctionService,
    private notify: NotificationService,
    private route: Router,
    private alert: NotificationService) {
    //registering SVG icons----------------------------
    iconRegistry.addSvgIcon('edit-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/edit.svg'))
      .addSvgIcon('down-arrow-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/down_arrow.svg'))
      .addSvgIcon('delete-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/delete.svg'))
      .addSvgIcon('property-listing-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/property_listing.svg'))
      .addSvgIcon('move-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/move.svg'))
      .addSvgIcon('sub_master_icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/sub_master.svg'));

    //setting active menus----------------------------
    this.menus.setActive('reports');
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: 'Reports' };
    this._crumbs.mobiletitle = 'Reports';
    this._crumbs.count = 1;
    //setting active menus----------------------------

    //initialization----------------------------------
    this.onResize();
    this.getReportTypes();
    //initialization----------------------------------
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.height = (window.innerHeight - 140);
    this.inHeight = (window.innerHeight - 200);
  }

  changeOrder(e) {
    if (e.checked) {
      this.sort_asc = e.checked;
    } else {
      this.sort_asc = e.checked;
    }
  }

  select(item, id?) {
    this.report_list = [];
    this.selected = (this.selected === item ? null : item);
    this.selected_item = id;
    if (this.selected_item) {
      this.getReports(id);
    }
  }
  isActive(item) {
    return this.selected === item;
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  showSubmaster() {
    this.showsubmas = !this.showsubmas;
  }
  showLabel(item) {
    if (item) {
      let label = (item.label && item.label[this.api.language]) ? item.label[this.api.language] : item.name;
      return label;
    }
  }

  changeType(type: any) {
    this.getReportGroups(type.value);
    this.selected_report_type = type.value;
  }

  select_child(item) {
    if (item) {
      this.selected_report = item;
    }
  }
  refresh(){
    this.getReportGroups(this.selected_report_type, this.orderBy,this.sort_asc);
  }

  /**
   * Getting Report groups
   * @param type 
   * @param orderBy 
   * @param asc 
   */
  getReportGroups(type: string, orderBy:string = 'added_date', asc:boolean = false) {
    let url = 'reportGroups?report_type_id=' + type;
    if(asc) url += '&asc='+asc;
    if(orderBy) url += '&order_by='+orderBy;
    this.loader = true;
    this.api.getData(url).subscribe((res: any) => {
      this.group_list = res;
      this.loader = false;
    }, err => {
      this.loader = false;
    })
  }

  /**
   * Getting Reports by group id
   * @param id 
   */
  getReports(id) {
    let url = 'report?group_id=' + id;
    this.group_loader = true;
    this.api.getData(url).subscribe((res: any) => {
      this.group_loader = false;
      this.report_list = res;
      this.noaccess = false;
    }, err => {
      if(err['status'] == 401) {
        this.noaccess = true;
        this.alert.notify('User unauthorized', 'error')
      }else{
        this.noaccess = false;
      }
      this.group_loader = false;
    })
  }

  /**
   * Getting Report types
   */
  getReportTypes() {
    let url = 'reportType';
    this.api.getData(url).subscribe((res: any) => {
      this.report_type = res;
      if (res) {
        let defaultVal = this.report_type[0];
        this.getReportGroups(defaultVal.id, this.orderBy,this.sort_asc);
        this.selected_report_type = defaultVal.id;
      }
    }, err => {
      this.report_type = [];
      if(err['status'] == 401) {
        this.noaccess = true;
        this.alert.notify('User unauthorized', 'error')
      }else{
        this.noaccess = false;
      }
    })
  }
  //API calls -------------------------------------------------------------------------------------


  success() {
    this.loaderiframe = false;
  }
  ngAfterViewInit() {
    $(document).ready(function () {
      $('#frame').on('load', function () {

      });
    });
  }
}
