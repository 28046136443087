<mat-card appearance="outlined" class="m-2">
  <mat-card-header class="mtc-header position-relative">
    <mat-card-title>CSV upload</mat-card-title>
    <button class="margl5" mat-raised-button color="primary" (click)="downloadtemplate()"> {{download}} </button>
  </mat-card-header>
  <mat-card-content class="pl-2 pr-2 pt-2 pb-3">
    <mat-progress-bar *ngIf="loader" class="mtc-progress" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-bar>
    <form [formGroup]="fileUploadForm" (ngSubmit)="uploadFile(fileUploadForm)">
      <div class="p-1">
        <span class="fntcustom" *ngIf="fileName">{{fileName}}</span>
        <!-- <span class="fntcustom" *ngIf="!fileName">No file selected, choose file</span> -->
      </div>
      <div class="mtc-buttons">
        <div class="upload-btn-wrapper">
          <button [disabled]="loader"><span class="sele-file">Select file</span> or Drag & drop file here</button>
          <input type="file" #myFileInput [disabled]="loader" (change)="handleFileInput($event)" formControlName="file"
            accept=".zip" />
        </div>
        <button class="upload" [ngStyle]="!fileName || loader ? {'opacity': '0.5'} : {}" [disabled]="!fileName || loader" type="submit">
          Upload</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="m-2">
  <mat-card-header class="mtc-header pt-2 pb-2 pr-2 position-relative d-flex justify-content-between">
    <mat-card-title>Upload history</mat-card-title>
    <div>
      <button mat-mini-fab class="refreshB" [disabled]="history_loader" (click)="getUploadHistory()">
        <mat-icon>cached</mat-icon>
      </button>
    </div>
    <mat-progress-bar class="mtc-progress" *ngIf="history_loader" [color]="color" [mode]="mode" [value]="value" style="top: 44px;">
    </mat-progress-bar>
  </mat-card-header> 
  <mat-card-content class="pl-2 pr-2 pt-2 pb-3" style="top: 3px;position: relative;">
    <div class="w-100 calc-h">
      <table width="100%" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns; let i=index">
          <th mat-header-cell *matHeaderCellDef class="cp" [width]="getColumnWidth(column)"
            title="{{getColumnName(column)}}">
            {{getColumnName(column)}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="climit">{{element[column]}} </span>
          </td>
            
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator [length]="total_count" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent = $event; handlePagination($event)">
    </mat-paginator>
  </mat-card-content>
</mat-card>