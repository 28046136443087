<ng-container *ngIf="editable && !preview">
  <div>
    <!-- <div class="w-100">
      <strong class="mr-2" *ngIf="staticList">Static list</strong>
      <strong class="mr-2" *ngIf="!staticList">Dynamic list</strong>
        <mat-slide-toggle [checked]="staticList"
        (change)="changeToggle($event)">
        </mat-slide-toggle>
    </div> -->
    <div class="form-group">
      <label for="exampleSelect1">Select list type</label>
      <div>
        <mat-select class="form-control w-80" [(ngModel)]="selectedListType" (selectionChange)="selectList($event)">
          <mat-option *ngFor="let option of listValues" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </div>
      <label for="exampleSelect1">Bind list with</label>
      <div>
        <mat-select class="form-control w-80" [(ngModel)]="selectedValue" (selectionChange)="selectedBinding($event)">
          <mat-option *ngFor="let option of values" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </div>
      <!-- <button class="ml-2" mat-icon-button>
        <mat-icon class="tiny">save</mat-icon>
      </button> -->
    </div>
  </div>
  <div class="p-2">
    <app-type1 *ngIf="selectedListType == 'property_detail'"></app-type1>
    <app-type2 *ngIf="selectedListType == 'poi_distance'"></app-type2>
    <app-type3 *ngIf="selectedListType == 'accessibility_index'"></app-type3>
    <app-type4 *ngIf="selectedListType == 'amenities'"></app-type4>
    <app-type5 *ngIf="selectedListType == 'table_view'"></app-type5>
  </div>
</ng-container>
<ng-container *ngIf="preview">
  <div>
    <!-- <div class="w-100">
      <strong class="mr-2" *ngIf="staticList">Static list</strong>
      <strong class="mr-2" *ngIf="!staticList">Dynamic list</strong>
        <mat-slide-toggle [checked]="staticList"
        (change)="changeToggle($event)">
        </mat-slide-toggle>
    </div> -->
    <div class="w-100">
      <strong>{{selectedValue}}</strong>
    </div>
  </div>
  <div class="p-2">
      <app-type1 *ngIf="selectedListType == 'property_detail'"></app-type1>
      <app-type2 *ngIf="selectedListType == 'poi_distance'"></app-type2>
      <app-type3 *ngIf="selectedListType == 'accessibility_index'"></app-type3>
      <app-type4 *ngIf="selectedListType == 'amenities'"></app-type4>
      <app-type5 *ngIf="selectedListType == 'table_view'"></app-type5>
  </div>
</ng-container>
<ng-container *ngIf="!editable && !preview">
  <div class="p-2">
      <app-type1 *ngIf="selectedListType == 'property_detail'"></app-type1>
      <app-type2 *ngIf="selectedListType == 'poi_distance'"></app-type2>
      <app-type3 *ngIf="selectedListType == 'accessibility_index'"></app-type3>
      <app-type4 *ngIf="selectedListType == 'amenities'"></app-type4>
      <app-type5 *ngIf="selectedListType == 'table_view'"></app-type5>
  </div>
</ng-container>