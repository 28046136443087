import { Component, OnInit } from "@angular/core"
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { UserAction } from "../../admin/user-action/user-activity";
@Component({
  selector: 'app-sync-property',
  templateUrl: './sync-property.component.html',
  styleUrls: ['./sync-property.component.css']
})
export class SyncPropertyComponent implements OnInit {

  cardsList: Array<{}> = [];
  load: boolean = false;
  selectedCardId: any = null;
  sync_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false,
  };
  selected_city: any;
  constructor(private api: ApiserviceService, private menu: AdminMenusService,
    private fnc: CommonfunctionService,
    private notify: NotificationService) {
    this.menu.setActive('sync-data');
    this.getAllowResources();
    this.getCityList();
  }


  getCityList() {
    this.api.getUmsData('place')
        .subscribe({next: (data: any) => {
            if(data.status == 200){
              let cities:any[] = data.data;
              this.selected_city = cities.find(e=> e.place_id == localStorage.getItem('city_id'))
            }
        
         }
    });
}

  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next :(res: any) => {
        this.api.allowResources = res.data;
        this.sync_access = this.fnc.checkResourceAccess('sync_data', false);
        this.getSnapshotCards();
      }
     
    })

  }

  /**
   * This function used to sync data
   * @param data
   * @returns
   */
  refreshData(data) {
    if (!this.sync_access.POST) {
      this.notify.notify("You are not authorized to Refresh Sync Data", "warn");
      return;
    }
    this.selectedCardId = data.index;
    this.load = true;
    let body = {
      "action": data['action'],
      "type": data['type']
    };
    if(data['action'] == 'address_search' && this.selected_city?.slug){
      body['slug'] = this.selected_city.slug;
    }
    this.api.postData("sync/manual-sync", body).subscribe({
      next: (res: any) => {
        this.load = false;
        if (res?.status != 500) {
          this.notify.notify("Refresh complete", "success");
          this.getSnapshotCards();
        }else{
          this.notify.notify(res?.message, "warn");
        }
      },
      error: () => {
        this.load = false;
        this.notify.notify(
          "Could not refresh snapshot, please try again in a while",
          "error"
        );
      },
    });
  }

  /**
   * This is function to use getting sync records
   * @returns
   */
  getSnapshotCards() {
    if (!this.sync_access.GET) {
      this.notify.notify("You are not authorized to Access Refresh Sync Data", "warn");
      return;
    }
    this.api.getData('em-data-sync?action=get').subscribe((res: any) => {
      if (!res) return;
      let arr = [];
      res.map((item: any, index) => {
        arr.push({
          title: item['title'],
          id: item['id'],
          index: index + 1,
          description: item['description'],
          action: item['action'],
          type: item['type'],
          last_sync: item['last_sync']
        })
      });
      this.cardsList = arr;
    }, err => {

    })
  }
  ngOnInit() {
    this.getAllowResources();
  }

  getIndex(index) {
    return ((index % 10) == 0) ? (index % 10) + 1 : index % 10;
  }

}
