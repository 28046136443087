import { Component, Inject, OnInit, Output, Input, EventEmitter, ViewChild, NgZone } from "@angular/core";
import { UntypedFormControl } from '@angular/forms';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { AdminBreadcrumbService } from '../admin-breadcrumb/admin-breadcrumb.service';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { NotificationService } from '../services/notification.service';
import { debounceTime } from "rxjs";

@Component({
    selector: 'app-property_land_config',
    templateUrl: 'property_land_config.html',
    styleUrls: ['property_land_config.css']
  })
  export class PropertyLandConfigComponent {

    public type = ['land','property'];
    public grade = ['A','B','C','D','E','F'];

    selected_type:string = null;
    selected_grade:string = null;

    changed_grade:string;
    changed_price_min:string;
    changed_price_max:string;
    changed_price_avg:string;

    displayedColumns = ['neigh_uid', 'neigh_Name', 'type', 'grade', 'price_min', 'price_max','price_avg', 'action'];
    dataSource: any = [
        { "neigh_uid": "NKWTAA0355", "neigh_Name": "Abu Ftaira", "type": "Land", "grade": "B", "price_min": 232, "price_max": 428, "price_avg": 340 },
        { "neigh_uid": "NKWTAA0356", "neigh_Name": "Abu Ftaira", "type": "Land", "grade": "B", "price_min": 232, "price_max": 428, "price_avg": 340 },
        { "neigh_uid": "NKWTAA0357", "neigh_Name": "Abu Ftaira", "type": "Land", "grade": "C", "price_min": 232, "price_max": 428, "price_avg": 340 },
        { "neigh_uid": "NKWTAA0358", "neigh_Name": "Abu Ftaira", "type": "Land", "grade": "B", "price_min": 232, "price_max": 428, "price_avg": 340 },
        { "neigh_uid": "NKWTAA0359", "neigh_Name": "Abu Ftaira", "type": "Land", "grade": "B", "price_min": 232, "price_max": 428, "price_avg": 340 },
        { "neigh_uid": "NKWTAA0350", "neigh_Name": "Abu Ftaira", "type": "Land", "grade": "A", "price_min": 232, "price_max": 428, "price_avg": 340 },
        { "neigh_uid": "NKWTAA0351", "neigh_Name": "Abu Ftaira", "type": "Land", "grade": "B", "price_min": 232, "price_max": 428, "price_avg": 340 },
        { "neigh_uid": "NKWTAA0352", "neigh_Name": "Abu Ftaira", "type": "Land", "grade": "D", "price_min": 232, "price_max": 428, "price_avg": 340 },
        { "neigh_uid": "NKWTAA0353", "neigh_Name": "Abu Ftaira", "type": "Land", "grade": "B", "price_min": 232, "price_max": 428, "price_avg": 340 },
        { "neigh_uid": "NKWTAA0354", "neigh_Name": "Abu Ftaira", "type": "Land", "grade": "E", "price_min": 232, "price_max": 428, "price_avg": 340 },
        { "neigh_uid": "NKWTAA0360", "neigh_Name": "Abu Ftaira", "type": "Land", "grade": "F", "price_min": 232, "price_max": 428, "price_avg": 340 },
    ];
    selectedCellId: any;
    
    searchField: UntypedFormControl;
    search_input: any;
    loader1:boolean;
    updateLoader:boolean;

    total: any;
    sizeOptions: string = '5, 10, 15, 25';
    pageSizeOptions: any;
    defaultPageSize: number = 10;
    pageEvent: PageEvent;
    pageIndex = 0;
    limit: number = 10;
    offset: number = 0;
    pageNo: number = 1;
    dataLength: any = 0;

    height:any;

    constructor(
        private menus: AdminMenusService,
        private _crumbs: AdminBreadcrumbService,
        private notify: NotificationService,
    ){
        this.menus.setActive('property_land_config');
        this._crumbs.clear();
        this._crumbs.addcrumb = { title: 'Property Land Grade' };
        this._crumbs.mobiletitle = 'Property Land Grade';
    }
    
    changeType(event_type){

    }
    changeGrade(event_grade){
    }
    edit(ele,type){
      this.selectedCellId = ele.neigh_uid+'_'+type;
      if(type == 'grade'){
        this.changed_grade = ele.grade;
      }else if(type == 'price_min'){
        this.changed_price_min = ele.price_min;
      }else if(type == 'price_max'){
        this.changed_price_max = ele.price_max;
      }else if(type == 'price_avg'){
        this.changed_price_avg = ele.price_avg;
      }
     
    }
    cancelEdit(uid){
      this.selectedCellId = '';
    }
    public updatePropertLandData(e){
      this.selectedCellId = '';
    }
    pageAction(event:any){
      
    }
    public changePropertyType(event){

    }
    public getpropertyLandGrade(type,grade,serarch:string = null, flag:boolean=false){
      
    

    }

    ngOnInit() {
        this.searchField = new UntypedFormControl();
        this.searchField.valueChanges
          .pipe(debounceTime(800))
          .subscribe(term => {
            if(this.selected_type == null && this.selected_grade == null){
              this.notify.notify('please select type or grade', 'warn');
            }else{
              term = term.trim().toLowerCase();
              this.search_input = term;
              if (term) {
                this.getpropertyLandGrade(this.selected_type, this.selected_grade, term)
              } else {
                this.getpropertyLandGrade(this.selected_type, this.selected_grade ,null, true)
              }
            }
          })
      }
  }