import { Component, OnInit } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { UserAction } from "../user-action/user-activity";
@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.css']
})
export class SendNotificationComponent implements OnInit {
  sendNotif_access = { 'GET': false, 'PATCH': false, 'POST': false, 'DELETE': false, };
  public title: string = '';
  public message: string = ''
  loader: boolean;
  constructor(private api: ApiserviceService,
    private menu: AdminMenusService,
    private fnc: CommonfunctionService,
    private notify: NotificationService) {
    this.menu.setActive('send_notification');
    this.getAllowResources()
  }

  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next:(res: any) => {
       
        this.api.allowResources = res.data;
        this.sendNotif_access = this.fnc.checkResourceAccess('send_notification', false);
        // this.getApplicationCount();
      }
      
    })
  }

  sendNotification() {
    let url = `send-notifications`;
    var body = {
      'user_id': this.api.user_id,
      'title': this.title,
      'notif_text': this.message,
      'mode': null,
      'regid': 0,
    }
    this.loader = true;
    this.api.postData(url, body).subscribe({
      next :(res: any) => {
      this.loader = false;
      if (res && res.status == 201) {
          // const activity = {
          //   actionId: res.data,
          //   data: body,
          //   actionType: UserAction.add_parameter.Action_ID
          // }
          // this.api.logUserActivity(activity);
        this.notify.notify(res.message, 'success');
      }
      else {
        this.notify.notify(res.message, 'error');
      }

    }, error :err => {
      this.notify.notify(err.error.message, 'error');
    }})

  }

  ngOnInit() {
  }

}
