<ul class="list-group list-group-flush pl-5 pr-5">
    <li class="list-group-item d-flex justify-content-between align-items-center">
        Airport
        <span class="badge">10 km</span>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-center">
        Bus Stop
        <span class="badge">1.5 km</span>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-center active">
        Main Heyway
        <span class="badge">17 km</span>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-center">
        Main Market
        <span class="badge">5 km</span>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-center">
        Fuel Station
        <span class="badge">3 km</span>
    </li>

</ul>