// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  google_key: 'AIzaSyAmnH9DzaMJXdNw2TzgZ18RydRBDIhWHUo',
  firebase: {
    apiKey: "AIzaSyC58On0h_c59jMtJ4vqfgFnptcfIFKngnU",
    authDomain: "notify-43ece.firebaseapp.com",
    databaseURL: "https://notify-43ece.firebaseio.com",
    projectId: "notify-43ece",
    storageBucket: "notify-43ece.appspot.com",
    messagingSenderId: "610400916616"
  },
      estUrl : "https://apiem.dev10.in/",
      valUrl : "https://valapi.dev10.in/",
     
      umsUrl : "https://umsapinode.dev10.in/",
      // gisUrl : "https://gisapi.dev10.in/gis/api/",
      gisUrl : "https://gms.dev10.in/",
      wms_url : "https://est.dev10.in/geoserver/gisdb/wms?",
      api_key :'a8f124bb6c4044be9a971284327563c3',
      
      baseURL : 'https://emadmin.dev10.in',
      adminHost: "https://umsnew.dev10.in",
      
      // baseURL : 'http://localhost:2002',
      // adminHost: "http://localhost:4200",
      user_token: "TmpNPU0yTTJaVEJpT0dFNVl6RTFNakkwWVRneU1qaGlPV0U1T0dOaE1UVXpNV1E9TlRjNE1HVXdPRE00Wm1KaFpHSTRNMlF3WXpjeFpUQm1aV1ZqTmpSbU5EZz1NMk0yWlRCaU9HRTVZekUxTWpJMFlUZ3lNamhpT1dFNU9HTmhNVFV6TVdRPTE1Njk0NzgyNjE=",
      isLocalServe: false,
};
