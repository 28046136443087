<!-- <div id="chart" class="mt-5">
  <div class="chart-area">
      <ul id="numbers">
          <li><span>7,110,340</span></li>
          <li><span>6,325,890</span></li>
          <li><span>5,225,820</span></li>
          <li><span>4,223,824</span></li>
          <li><span>3,975,721</span></li>
          <li><span>AED</span></li>
      </ul>
      <ul id="bars">
          <li><div data-percentage="12" class="bar" style="height: 20%;"></div></li>
          <li><div data-percentage="21" class="bar" style="height: 30%;"></div></li>
          <li><div data-percentage="50" class="bar" style="height: 40%;"></div></li>
          <li><div data-percentage="30" class="bar" style="height: 55%;"></div></li>
          <li><div data-percentage="95" class="bar" style="height: 60%;"></div></li>
          <li><div data-percentage="32" class="bar" style="height: 70%;"></div></li>
          <li><div data-percentage="38" class="bar" style="height: 80%;"></div></li>
      </ul>
  </div>
  <ul id="bar-title">
      <li><span>2015</span></li>
      <li><span></span></li>
      <li><span>2016</span></li>
      <li><span></span></li>
      <li><span>2017</span></li>
      <li><span></span></li>
      <li><span>2018</span></li>
  </ul>
</div> -->

<ng-container *ngIf="editable && !preview">
    <div>
      <!-- <div class="w-100">
        <strong class="mr-2" *ngIf="staticList">Static list</strong>
        <strong class="mr-2" *ngIf="!staticList">Dynamic list</strong>
          <mat-slide-toggle [checked]="staticList"
          (change)="changeToggle($event)">
          </mat-slide-toggle>
      </div> -->
      <div class="form-group">
        <label for="exampleSelect1">Bind chart with</label>
        <div>
          <mat-select class="form-control w-80" [(ngModel)]="selectedValue" (selectionChange)="selectedBinding($event)">
            <mat-option *ngFor="let option of values" [value]="option.value">{{option.name}}</mat-option>
          </mat-select>
        </div>
        <!-- <button class="ml-2" mat-icon-button>
          <mat-icon class="tiny">save</mat-icon>
        </button> -->
      </div>
    </div>
    <div id="chart" class="mt-5">
        <div class="chart-area">
            <ul id="numbers">
                <li><span>7,110,340</span></li>
                <li><span>6,325,890</span></li>
                <li><span>5,225,820</span></li>
                <li><span>4,223,824</span></li>
                <li><span>3,975,721</span></li>
                <li><span>AED</span></li>
            </ul>
            <ul id="bars">
                <li><div data-percentage="12" class="bar" style="height: 20%;"></div></li>
                <li><div data-percentage="21" class="bar" style="height: 30%;"></div></li>
                <li><div data-percentage="50" class="bar" style="height: 40%;"></div></li>
                <li><div data-percentage="30" class="bar" style="height: 55%;"></div></li>
                <li><div data-percentage="95" class="bar" style="height: 60%;"></div></li>
                <li><div data-percentage="32" class="bar" style="height: 70%;"></div></li>
                <li><div data-percentage="38" class="bar" style="height: 80%;"></div></li>
            </ul>
        </div>
        <ul id="bar-title">
            <li><span>2015</span></li>
            <li><span></span></li>
            <li><span>2016</span></li>
            <li><span></span></li>
            <li><span>2017</span></li>
            <li><span></span></li>
            <li><span>2018</span></li>
        </ul>
      </div>
  </ng-container>
  <ng-container *ngIf="preview">
    <div>
      <!-- <div class="w-100">
        <strong class="mr-2" *ngIf="staticList">Static list</strong>
        <strong class="mr-2" *ngIf="!staticList">Dynamic list</strong>
          <mat-slide-toggle [checked]="staticList"
          (change)="changeToggle($event)">
          </mat-slide-toggle>
      </div> -->
      <div class="w-100">
        <strong>{{selectedValue}}</strong>
      </div>
    </div>
    <div id="chart" class="mt-5">
        <div class="chart-area">
            <ul id="numbers">
                <li><span>7,110,340</span></li>
                <li><span>6,325,890</span></li>
                <li><span>5,225,820</span></li>
                <li><span>4,223,824</span></li>
                <li><span>3,975,721</span></li>
                <li><span>AED</span></li>
            </ul>
            <ul id="bars">
                <li><div data-percentage="12" class="bar" style="height: 20%;"></div></li>
                <li><div data-percentage="21" class="bar" style="height: 30%;"></div></li>
                <li><div data-percentage="50" class="bar" style="height: 40%;"></div></li>
                <li><div data-percentage="30" class="bar" style="height: 55%;"></div></li>
                <li><div data-percentage="95" class="bar" style="height: 60%;"></div></li>
                <li><div data-percentage="32" class="bar" style="height: 70%;"></div></li>
                <li><div data-percentage="38" class="bar" style="height: 80%;"></div></li>
            </ul>
        </div>
        <ul id="bar-title">
            <li><span>2015</span></li>
            <li><span></span></li>
            <li><span>2016</span></li>
            <li><span></span></li>
            <li><span>2017</span></li>
            <li><span></span></li>
            <li><span>2018</span></li>
        </ul>
      </div>
  </ng-container>
  <ng-container *ngIf="!editable && !preview">
    <div id="chart" class="mt-5">
        <div class="chart-area">
            <ul id="numbers">
                <li><span>7,110,340</span></li>
                <li><span>6,325,890</span></li>
                <li><span>5,225,820</span></li>
                <li><span>4,223,824</span></li>
                <li><span>3,975,721</span></li>
                <li><span>AED</span></li>
            </ul>
            <ul id="bars">
                <li><div data-percentage="12" class="bar" style="height: 20%;"></div></li>
                <li><div data-percentage="21" class="bar" style="height: 30%;"></div></li>
                <li><div data-percentage="50" class="bar" style="height: 40%;"></div></li>
                <li><div data-percentage="30" class="bar" style="height: 55%;"></div></li>
                <li><div data-percentage="95" class="bar" style="height: 60%;"></div></li>
                <li><div data-percentage="32" class="bar" style="height: 70%;"></div></li>
                <li><div data-percentage="38" class="bar" style="height: 80%;"></div></li>
            </ul>
        </div>
        <ul id="bar-title">
            <li><span>2015</span></li>
            <li><span></span></li>
            <li><span>2016</span></li>
            <li><span></span></li>
            <li><span>2017</span></li>
            <li><span></span></li>
            <li><span>2018</span></li>
        </ul>
      </div>
  </ng-container>