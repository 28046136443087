import { Component, OnInit, Inject } from '@angular/core';
import { ApiserviceService } from "../../../apiservice.service";
import { CommonfunctionService } from "../../../services/commonfunction.service";
import { NotificationService } from "../../services/notification.service";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgForm, Validators, FormGroup, FormControl, UntypedFormBuilder, FormArray, NgModel } from "@angular/forms";
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { UserAction } from "../../user-action/user-activity";
@Component({
  selector: 'app-add-attribute',
  templateUrl: './add-attribute.component.html',
  styleUrls: ['../listable-attribute.component.scss']
})
export class AddAttributeComponent implements OnInit {

  listTypes: any = [{
    label: "Attribute Name",
    value: "attribute_name"
  },
  {
    label: "Column Name",
    value: "column_name"
  },
  {
    label: "Parcel Attribute",
    value: "parcel"
  }];

  public parent_data;
  public mode;
  public AttributeForm: any;
  public loader: boolean;
  public attributeList: any = [];
  public listData: any = [];
  public selectedListType: string;
  public attributeListData: any = [];
  searchText:string='';
  
  constructor(
    public dialogRef: MatDialogRef<AddAttributeComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any,
    private notify: NotificationService,
    private apiService: ApiserviceService,
    private fb_: UntypedFormBuilder
  ) {
    this.parent_data = this.info;
    this.mode = this.parent_data.type;
    // this.getAttributes();
    if (this.mode == 'add') {
      this.AttributeForm = this.fb_.group({
        type: ['', Validators.required],
        name: ['', Validators.required],
      });
    }
  }

  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close();
  }
  addAttribute(data) {
    var val = data.value;
    if (this.selectedListType == "attribute_name") {
    }
    var body = {
      "entity_id": 1,
      "status": 1,
      "attribute_name": null,
      "column_name": null,
      "user_id": this.apiService.user_id,
    }
    if (this.selectedListType == "attribute_name") {
      body.attribute_name = val.name;
      body.column_name = 'data';
    }
    if (this.selectedListType == "column_name") {
      body.column_name = val.name;
    }
    if (this.selectedListType == "parcel") {
      body.attribute_name = val.name;
      body.column_name = 'parcel_data';
    }
    this.loader = true;
    this.apiService.postData('listable-attribute', body).subscribe({
      next :(res: any) => {
      if (res && res.status == 201) {
        // const activity={
        //   actionId: res.data,
        //   data: body,
        //   actionType:UserAction.add_attribute.Action_ID
        // }
        // this.apiService.logUserActivity(activity);
        this.notify.notify(res.message, "success");
        this.parent_data.pdata.getAttribute();
        this.parent_data.pdata.getAttibuteCount();
        this.loader = false;
        this.dialogRef.close();
      }
      else {
        this.loader = false;
        this.notify.notify(res.message, "error")
      }
    },error: err => {
      this.loader = false;
    }})
  }

  selectTypeList(event) {
    this.selectedListType = event.value;
    if (this.selectedListType == "column_name") {
      this.getColumn();
    }
    if (this.selectedListType == "parcel") {
      this.getColumn('parcel');
    }
    if (this.selectedListType == "attribute_name") {
      this.getAttributName();
    }
  }

  getAttributName() {
    this.listData = [];
    this.loader = true;
    let url = 'em-attributes?module_type=deadmin&entity=property';
    this.apiService.getData(url).subscribe({
      next :(res: any) => {
      if (res && res.data?.length) {
        if (res.data.length) {
          res.data.forEach(element => {
            this.listData.push({
              "id": element.attribute_id,
              "name": element.attribute_name,
            })
            
          });
          if(this.listData.length){
            this.listData.sort((a, b) => {
              const nameA = a.name.toLowerCase(); // ignore upper and lowercase
              const nameB = b.name.toLowerCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });     
          }
        } 
      }
      this.loader = false;
    },error: err => {
      this.listData = [];
      this.loader = false;
    }})
  }

  getColumn(type = 'properties') {
    this.listData = [];
    this.loader = true;
    let action = 'get';
    if (type == 'parcel') {
      action = type;
    }
    let url = `listable-column?type=${type}&action=${action}`;
    this.apiService.getData(url).subscribe({
      next :(res: any) => {

      if (res && res.data) {
        if (res.data.length) {
          res.data.forEach(element => {
            this.listData.push({
              "id": 1,
              "name": element.column_name,
            })
          });
          if(this.listData.length){
            this.listData.sort((a, b) => {
              const nameA = a.name.toLowerCase(); // ignore upper and lowercase
              const nameB = b.name.toLowerCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });      
          }
        }
      }
      this.loader = false;
    },error: err => {
      this.listData = [];
      this.loader = false;
    }})
  }

  
}
