<div class="row w-100 mr-0 ml-0 mange_radis">
    <nav class="navbar nav navbar-expand-lg navbar-light bg-light" fxLayout="row" fxLayoutAlign="space-between">
        <div class="mt-2 mb-2 w-100" fxLayout="row" fxLayoutAlign="space-between">
            <span class="mat-h1 text-center types m-0 text-nowrap">Manage Redis</span>            
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="position-relative mx-2">
                    <mat-icon class="search-icon">search
                    </mat-icon>
                    <input (keyup)="applyFilter($event.target.value)" [(ngModel)]="filter" class="form-control search-bar" placeholder="Search Keys" type="text">
                </div>
            </div> 

        </div>
    </nav>
</div>
<mat-progress-bar *ngIf="redisListLoader" class="w-100 abs" mode="indeterminate"></mat-progress-bar>
<ng-container *ngIf="master_list.length > 0">
    <button *ngIf="selection && selection.selected.length > 0" class="redisdelete-btn btn_delete" (click)="deleteReport()">Delete Redis</button>
    <div class="tableRedis w-100 mt-2 mb-3">       
      <table mat-table [dataSource]="dataSource" class="w-100 table-bordered">
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef> SNo. </th>
                <td mat-cell *matCellDef="let element"> {{element.position}} </td>
            </ng-container>
            <ng-container matColumnDef="key">
                <th mat-header-cell *matHeaderCellDef> Keys </th>
                <td mat-cell *matCellDef="let element"> {{element.key}} </td>
            </ng-container>
            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef> View  </th>
                <td mat-cell *matCellDef="let element"> 
                    <a [routerLink]="['/admin/manage-redis', element.view]">View Keys</a>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th  mat-header-cell *matHeaderCellDef>
                  <ng-container *ngIf="dataSource?.data?.length >0">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox><span style="white-space: nowrap; margin-left: 10px;">Delete All</span>
                  </ng-container>
                  
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)">
                  </mat-checkbox>
                </td>
              </ng-container>
            <!-- <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Delete </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="d-flex justify-content-center align-items-center">
                        <button matTooltip="Delete" (click)="deleteReport(element.key)">
                            <img src="../../../assets/icon/delete.svg" alt="">
                          </button>
                    </div>    
                </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="dataSource?.filteredData?.length === 0" class="nodata">
                <span> No Record Found</span>
              </div>
    </div>