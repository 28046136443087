import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { ApiserviceService } from "../../apiservice.service";
import { CommonfunctionService } from '../../services/commonfunction.service';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { MatDialog } from '@angular/material/dialog';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-redis',
  templateUrl: './redis.component.html',
  styleUrls: ['./redis.component.css']
})
export class RedisComponent implements OnInit {

  master_list: any = [1,2];
  displayedColumns = ['position', 'key', 'view', 'action'];
  dataSource: any = [];
  redisData:any = [];
  filter;
  reportName=[];
  redisListLoader: boolean = false;
  public redis_access = {
    'GET': true,
    'POST': false,
    'PATCH': false,
    'DELETE': true
  };
  selection = new SelectionModel(true, []);
  constructor(private notify: NotificationService, private apiService: ApiserviceService, 
    private fncSrv: CommonfunctionService, public dialog: MatDialog, private menu: AdminMenusService) { 
    this.getAllowResources();
    this.menu.setActive('manage_redis');
  }

  ngOnInit(): void {
    
  }
  getAllowResources() {
    let body = `user/resource?place_id=${this.apiService.city_id}&user_id=${this.apiService.user_id}`
    this.apiService.getUmsData(body).subscribe({
      next: (res: any) => {
       
        this.apiService.allowResources = res.data;
        this.getAllowResource();
        this.getRedisData();
      }
      
    })

  }
  getAllowResource() {
    this.redis_access = this.fncSrv.checkResourceAccess('redis', false);
  }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.filteredData.length;
      return numSelected === numRows;
    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {      
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSource.filteredData.forEach((row) =>
            this.selection.select(row)
          );
    }

  /**  
   * This is a functionto to get redis data
   * Resources are checked for redis.
   * @returns {} Returns void.
   */
  getRedisData() {
    if (!this.redis_access.GET) {
      this.notify.notify("You are not Authorized to GET Redis Manage", "warn");
      return;
    }
    this.redisListLoader = true;
    let url = `/redis-keys`;
      this.apiService.getData(url).subscribe((res: any) => {
      this.redisListLoader = false;
      let data = res.data.map(
        (val, index) => {
          return {
            position: index + 1,
            key: val,
            view:val,
            action: val,
          };
        }
      );
      this.dataSource = new MatTableDataSource(data);
    },(err) => {
      this.dataSource = new MatTableDataSource([]);
      this.redisListLoader = false;
    });
  }
/**  
   * This is a functionto open confirmation dialog box while deleting a Parameter
   * Resources are checked for DELETE.
  
   * @requires MatDialog
   * @requires ConfirmDialogBox
   * @returns {} Returns void.
   */
 deleteReport() {
   
  if (!this.redis_access.DELETE) {
    this.notify.notify("You are not authorized to DELETE Redis", "warn");
    return;
  }
  let _data = {
    parent_data: this,
    message: `Do you want to delete?`
  }
  const dialogRef = this.dialog.open(ConfirmDialogBox, {
    width: '270px',
    panelClass: 'confirm_dialog',
    data: _data
  });
  dialogRef.afterClosed().subscribe(result => {
  });
}
public confirmDialogYes(check) {
  if (check == 'YES') {  
    this.reportName=[];  
        this.selection.selected.forEach((val, index) => {       
          this.reportName.push(val.key)
        }); 
        let body={
          "group_name":this.reportName.join(',')
        };   
      let url = `/redis-delete-key`;
      this.apiService.postData(url,body).subscribe((res: any) => {
        this.selection.clear();
        this.notify.notify('Deleted Successfully', 'success');
        this.dataSource.filter = '';
        this.filter = '';
        this.getRedisData();
      }, (err) => {
        this.notify.notify('Something went wrong, Try again', 'warn');
        this.selection.clear();
      });
    } else if (check == 'NO') {
      this.dialog.closeAll();
    }
}
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
}
