import { Component, OnInit } from '@angular/core';
import { ShareService } from '../services/share.service'
import { ApiserviceService } from '../../apiservice.service';
import { NotificationService } from '../services/notification.service';
import { HostListener } from "@angular/core";
import { AppNotificationService } from '../services/app-notification.service';
import { AdminBreadcrumbService } from '../admin-breadcrumb/admin-breadcrumb.service';

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_notification_user_id_get</li>
 * <li>estapi_module_type_notification_user_id_patch</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  notify_list: any = [];
  loader: boolean = false;
  constructor(private share: ShareService, private api: ApiserviceService, private alert: AppNotificationService) {
    this.getTopNotifications();
  }

  getTopNotifications(offset = 0, limit = 20) {
    this.loader = true;
    let url = `deapp/notification/${this.api.user_id}?notification_status=sent,read&offset=${offset}&limit=${limit}&sort_by=added_date`;
    this.api.getData(url).subscribe((res) => {
      this.notify_list = res;
      this.loader = false;
    }, err => {
      this.loader = false;
    })
  }
  markAsRead(n) {
    if (n.email_status != 'read') {
      let user_id = this.api.user_id;
      let url = `deapp/notification/${user_id}`;
      let data = {
        "email_status": "read",
        "notification_id": n.notification_id,
        "updated_by": this.api.user_id
      }
      this.api.patchData(url, data).subscribe((data:any) => {
        this.getTopNotifications();
        this.alert.getCount();
        if(this.share.notify_obj){
          this.share.notify_obj.getNotifications();
        }
      });
    }
  }

  overlay_off() {
    this.share.adminComp.overlay_off();
  }

  getDate(v) {
    let date = new Date(v);
    let month = date.getMonth() + 1;
    let format = date.getDate() + '-' + month + '-' + date.getFullYear();
    return format;
  }
  getTime(v) {
    let date = new Date(v);
    let format = date.getHours() + ':' + date.getMinutes();
    return format;
  }
  ngOnInit() {
  }

}

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_notification_user_id_get</li>
 * <li>estapi_module_type_notification_user_id_patch</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-notify-details',
  templateUrl: './notify-detail.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotifyDetailComponent implements OnInit {
  height: number;
  height2: number;
  offset: number = 0;
  limit: number = 50;
  loader: boolean = false;
  notification_list: any = [];
  selected_notification: any;
  constructor(private share: ShareService,
    private api: ApiserviceService,
    private notify: NotificationService,
    private _crumbs: AdminBreadcrumbService,
    private alert: AppNotificationService) {
    this.share.notify_obj = this;
    this._crumbs.addcrumb = { title: 'Notifications' };
    this._crumbs.mobiletitle = 'Notifications';
    this._crumbs.count = 1;
    this.onResize();
    this.getNotifications();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.height = (window.innerHeight - 125);
    this.height2 = this.height - 20;
  }
  getDate(v) {
    let date = new Date(v);
    let month = date.getMonth() + 1;
    let format = date.getDate() + '-' + month + '-' + date.getFullYear();
    return format;
  }
  getTime(v) {
    let date = new Date(v);
    let format = date.getHours() + ':' + date.getMinutes();
    return format;
  }
  getNotifications(offset = 0, limit = 50, scroll = false) {
    this.loader = true;
    let url = `deapp/notification/${this.api.user_id}?notification_status=sent,read&offset=${offset}&limit=${limit}&sort_by=added_date`;
    this.api.getData(url).subscribe((res:any) => {
      if (!scroll) {
        this.notification_list = res;
      } else {
        res.forEach(n => {
          this.notification_list.push(n);
        });
      }
      this.loader = false;
    }, err => {
      this.loader = false;
    })
  }
  markAsRead(n) {
    this.selected_notification = n;
    if (n.email_status != 'read') {
      let user_id = this.api.user_id;
      let url = `deapp/notification/${user_id}`;
      let data = {
        "email_status": "read",
        "notification_id": n.notification_id,
        "updated_by": this.api.user_id
      }
      this.api.patchData(url, data).subscribe((data:any) => {
        this.getNotifications();
        this.alert.getCount();
      });
    }
  }
  isSelected(i) {
    if (i && this.selected_notification) {
      if (this.selected_notification.notification_id == i.notification_id) {
        return true;
      } else {
        return false;
      }
    }

  }
  onScroll(e) {
    if (e.isReachingBottom) {
      this.offset += this.limit;
      this.getNotifications(this.offset, this.limit, true);
    }
  }
  ngOnInit() {
  }

}
