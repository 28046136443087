
<div class="">
    <div class="header-bar">
        <span *ngIf="action_type=='add'">Add Company</span>
        <span *ngIf="action_type=='edit'">Update Company</span>
    </div>
    <mat-progress-bar *ngIf="loader" class="progress" [color]="'primary'" mode="indeterminate">
    </mat-progress-bar>
    <form [formGroup]="companyForm" autocomplete="off" novalidate (ngSubmit)="addCompany(companyForm.value)" class="company-form">
        <mat-dialog-content style="max-height: 550px; margin: 0; padding: 0;">
            <div class="grid">
                <div class="col">
                    <div class="row card-section">
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Company Type <span class="text-danger">*</span></span>
                            <mat-select formControlName="company_type" placeholder="Select company type" 
                            [class.invalid-border]="companyForm.controls['company_type'].hasError('required') && isSubmited">
                                <mat-option [value]="com.value" *ngFor="let com of comp_type">{{com.label}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="companyForm.controls['company_type'].hasError('required') && (isSubmited || companyForm.controls['company_type'].touched)">Company Type is required</mat-error>
                           
                        </div>
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Company name <span class="text-danger">*</span></span>
                            <input type="text" placeholder="Enter company name" formControlName="company_name" tabindex="1"
                            [class.invalid-border]="companyForm.controls['company_name'].hasError('required') && (isSubmited || companyForm.controls['company_name'].touched)">
                            <mat-error *ngIf="companyForm.controls['company_name'].hasError('required') && (isSubmited || companyForm.controls['company_name'].touched)">Company name is required</mat-error>
                            <mat-error *ngIf="companyForm.controls['company_name'].hasError('minlength')">Company name should be minmum 2 character</mat-error>
                            <mat-error *ngIf="companyForm.controls['company_name'].hasError('maxlength')">Company name should be maximum 100 character</mat-error>
                            <!-- <mat-error *ngIf="companyForm.controls['company_name'].hasError('pattern')">Special characters not allowed</mat-error> -->
                        </div>
                        
                    </div>
                    <div  class="row card-section">
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Register name</span>
                            <input type="text" placeholder="Enter register name" formControlName="register_name" tabindex="2">
                            
                        </div>
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Registration number</span>
                            <input type="text" placeholder="Enter registration number" formControlName="registration_number" tabindex="3">
                            
                        </div>
                        
                    </div>
                    <div class="row card-section">
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Registration authority</span>
                            <mat-select formControlName="registration_authority">
                                <mat-option value="YES">Yes</mat-option>
                                <mat-option value="NO">No</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Email <span class="text-danger" >*</span></span>
                            <input type="email" placeholder="Enter email" formControlName="email" 
                            [class.invalid-border]="companyForm.controls['email'].hasError('required') && (isSubmited || companyForm.controls['email'].touched)">
                            <mat-error *ngIf="companyForm.controls['email'].hasError('required') && (isSubmited || companyForm.controls['email'].touched)">Email is required</mat-error>
                            <mat-error *ngIf="companyForm.controls['email'].hasError('pattern') && companyForm.controls['email'].touched">Email is invalid</mat-error>
                        </div>

                    </div>

                    <div class="row card-section">
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Contact Person</span>
                            <input type="text" placeholder="Enter contact person" formControlName="contact_person"/>
                        </div>
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Contact Number <span class="text-danger">*</span></span>
                            <!-- <input type="text" placeholder="Enter contact number" formControlName="contact_number" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                            [class.invalid-border]="companyForm.controls['contact_number'].hasError('required') && (isSubmited || companyForm.controls['contact_number'].touched)"
                            /> -->
                            <ngx-intl-tel-input 
                                [cssClass]="'custom'" 
                                [preferredCountries]="preferredCountries"
                                [enableAutoCountrySelect]="true" 
                                [enablePlaceholder]="true" 
                                [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.All]" 
                                [selectFirstCountry]="true"
                                [selectedCountryISO]="cuntry" 
                                [maxLength]="15"
                                [phoneValidation]="true" 
                                [separateDialCode]="true"
                                [numberFormat]="PhoneNumberFormat.National"
                                [(ngModel)]="phoneNumber" [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="checkNumber()">
                            </ngx-intl-tel-input>
                            <mat-error *ngIf="companyForm.controls['contact_number'].hasError('minlength')">Contact Number should be minmum 2 digit</mat-error>
                            <mat-error *ngIf="companyForm.controls['contact_number'].hasError('maxlength')">Contact Number should be maximum 15 digit</mat-error>
                            <mat-error *ngIf="companyForm.controls['contact_number'].hasError('required') && (isSubmited || companyForm.controls['contact_number'].touched)">Contact number is required</mat-error>
                        </div>
                    </div>

                    <div class="row card-section">
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Working since</span>
                           
                            <span class="d-flex align-items-center">
                                 <input [max]="today" 
                                 [matDatepicker]="picker1" formControlName="working_since" disabled>      
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1 disabled="false"></mat-datepicker>                                                     
                            </span>   
                        </div>
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Whatsapp number</span>
                            <!-- <input type="text" placeholder="Enter whatsapp number" formControlName="whatsapp_number"  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"/> -->
                            <ngx-intl-tel-input 
                                [cssClass]="'custom'" 
                                [preferredCountries]="preferredCountries"
                                [enableAutoCountrySelect]="true" 
                                [enablePlaceholder]="true" 
                                [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.All]" 
                                [selectFirstCountry]="true"
                                [selectedCountryISO]="cuntry" 
                                [maxLength]="15"
                                [phoneValidation]="true" 
                                [separateDialCode]="true"
                                [numberFormat]="PhoneNumberFormat.National"
                                [(ngModel)]="whatsAppNumber" [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="checkWhatsNumber()">
                            </ngx-intl-tel-input>
                            <mat-error *ngIf="companyForm.controls['whatsapp_number'].hasError('minlength')">Whatsapp Number should be minmum 2 digit</mat-error>
                            <mat-error *ngIf="companyForm.controls['whatsapp_number'].hasError('maxlength')">Whatsapp Number should be maximum 15 digit</mat-error> 
                            
                        </div>

                      
                    </div>

                    <!-- <div class="row card-section">
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Email template</span>
                            <input type="text" placeholder="Email template" formControlName="email_template"/>
                        </div>
                    </div> -->

                    <div class="row card-section">
                        <div class="col-12 input-box">
                            <span class="detail mb-3">Address</span>
                            <textarea placeholder="Enter address" formControlName="address"></textarea>
                        </div>
                    </div>

                </div>
                <div class="col">
                    <div class="row card-section">
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Facebook page</span>
                            <input type="text" placeholder="(https://abc.com)" formControlName="fb_page">
                            <mat-error *ngIf="companyForm.controls['fb_page'].hasError('pattern') && companyForm.controls['fb_page'].touched">Please input valid URL with https://</mat-error>
                        
                        </div>
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Twitter handler</span>
                            <input type="text" placeholder="(https://abc.com)" formControlName="twitter_handler"/>
                            <mat-error *ngIf="companyForm.controls['twitter_handler'].hasError('pattern') && companyForm.controls['twitter_handler'].touched">Please input valid URL with https://</mat-error>
                        </div>
                    </div>
                    <div class="row card-section">
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Languages</span>
                            <mat-select multiple formControlName="languages">
                                <mat-option value="English">English</mat-option>
                                <mat-option value="Arabic">Arabic</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-6 input-box">
                            <span class="detail mb-3">Nationality</span>
                            <input type="text" placeholder="Nationality" formControlName="nationality"/>
                        </div>
                    </div>
                    <div class="row card-section">
                        <div class="col">
                            <span class="detail">Upload logo <small>(Logo size 500px X 500px)</small></span>
                            <div class="mt-2 d-flex justify-content-start align-items-end">
                               
                                <div class="mr-2 position-relative" *ngIf="this.parent_data?.logo_url || logo_url">
                                    <div (click)="zoomImage()" (mouseout)="imgOverlayOut()" class="zoom-img d-none" id="zoomID"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="18" height="18" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M289.8 200.2h-49.3v-49.3c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v49.3h-49.3c-11.3 0-20.4 9.1-20.4 20.4 0 11.3 9.1 20.4 20.4 20.4h49.3v49.3c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V241h49.3c11.3 0 20.4-9.1 20.4-20.4 0-11.3-9.2-20.4-20.4-20.4z" fill="#ffffff" data-original="#000000" class=""></path><path d="M220.2 388.7C127.3 388.7 52 313.5 52 220.6S127.3 52.5 220.2 52.5s168.1 75.3 168.1 168.1S313 388.7 220.2 388.7zm274.8 78L381.7 353.4c29.7-36.1 47.6-82.4 47.6-132.8 0-115.5-93.6-209.2-209.2-209.2S11 105.1 11 220.6s93.6 209.2 209.2 209.2c50.4 0 96.6-17.8 132.7-47.5l113.3 113.3c5.2 5.3 21.1 7.9 28.9 0 7.9-8 7.9-20.9-.1-28.9z" fill="#ffffff" data-original="#000000" class=""></path></g></svg></div>
                                    <img (mouseover)="imgOverlay()"
                                    [src]="[logo_url ? logo_url : this.parent_data?.logo_url]" alt="" style="width: 100%; height: 47px; object-fit: contain;">
                                </div>
                                <div class="upload-btn-wrapper">
                                    <div class="wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="18" height="18" x="0" y="0" viewBox="0 0 496 496" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M488 240H256V8a8 8 0 0 0-16 0v232H8a8 8 0 0 0 0 16h232v232a8 8 0 0 0 16 0V256h232a8 8 0 0 0 0-16z" fill="#444" data-original="#000000" class=""></path></g></svg>
                                        <p> Drag or  browse Image</p>
                                    </div>
                                    <input type="file" #myFileInput accept="image/*" (change)="uploadImage($event)">
                                </div>
                            </div>
                            <div class="mt-1" *ngIf="img_name">
                                <p>{{img_name}}</p>
                            </div>
                        </div>
                        <div class="full-view d-none" id="fullImg" (click)="deZoomImage()">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="30" height="30" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g data-name="02 User"><path d="M25 512a25 25 0 0 1-17.68-42.68l462-462a25 25 0 0 1 35.36 35.36l-462 462A24.93 24.93 0 0 1 25 512z" fill="#ffffff" data-original="#000000" class=""></path><path d="M487 512a24.93 24.93 0 0 1-17.68-7.32l-462-462A25 25 0 0 1 42.68 7.32l462 462A25 25 0 0 1 487 512z" fill="#ffffff" data-original="#000000" class=""></path></g></g></svg>
                            <img [src]="[logo_url ? logo_url : this.parent_data?.logo_url]" alt=""></div>
                    </div>
                    <div class="row card-section">
                        <div class="col">
                            <div class="detail mb-3">Description</div>
                            <angular-editor formControlName="description" [config]="editorConfig" tabindex="3"></angular-editor>
                        </div>
                    </div> 
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <div class="d-flex justify-content-end px-4">
                <button type="button" (click)="close()" class="cancel-btn mx-1">Cancel</button>
                <button *ngIf="action_type=='add'" type="submit" class="save-button mx-1" [disabled]="loader" tabindex="60"> Submit</button>
                <button *ngIf="action_type=='edit'" type="button" class="save-button mx-1" (click)="addCompany(companyForm.value)" [disabled]="loader"> Update</button>
            </div>
        </mat-dialog-actions>
    </form>
    
</div>