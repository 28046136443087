import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiserviceService } from "../../apiservice.service";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { NotificationService } from "../services/notification.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { AddTransactionAttributeComponent } from "./add-transaction-attribute/add-transaction-attribute.component";
import { ConfirmDialogBox } from "../confirm-dialog/confirm-dialogbox";
import { UntypedFormControl } from "@angular/forms";
import { UserAction } from "../user-action/user-activity";
import { debounceTime } from 'rxjs';
@Component({
  selector: 'app-transaction-attribute',
  templateUrl: './transaction-attribute.component.html',
  styleUrls: ['./transaction-attribute.component.css']
})
export class TransactionAttributeComponent implements OnInit {
  displayedColumns = [
    'SN',
    'attribute_name',
    'column_name',
    'description',
    'added_by',
    'added_date',
    'is_required',
    'show_in_detail',
    'status',
    'action',
  ]
  returnValue(element, dispCol) {
    switch (dispCol) {
      case 'attribute_label': return this.showlabel(element, 'attribute_label');
      case 'attribute_name': return element.attribute_name;
      case 'column_name': return element.column_name;
      case 'description': return element.description;
      case 'added_by': return element.added_by;
      case 'added_date': return this.functionService.formatDateUTC(element.added_date);
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  limit: number = 10;
  offset: number = 0;
  totalCount: number;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;
  dataSource: any = [];
  statusCode: string= '1';
  status: string = "1";
  transaction_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false,
  }
  loader: boolean;
  searchField: UntypedFormControl;
  selected_transact: any;

  constructor(private api: ApiserviceService, private menu: AdminMenusService,
    private functionService: CommonfunctionService,
    private notify: NotificationService,
    public dialog: MatDialog,
    public router: Router,) {
    this.menu.setActive('transaction_attribute');
    // this.getAllowResourceApi();
    this.getAllowResources();
  }

  ngOnInit() {
    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(term => {
        term = term.trim().toLowerCase();
        if (term) {
          this.getTransactionAttributes(term);
        } else {
          this.getTransactionAttributes(null);
        }
      })
  }
 /**
   * API call to get transaction_attribute with status
   */
 selectionChange(val) {
  this.statusCode = val;
  this.getTransactionAttributes();
}
  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next :(res: any) => {

        this.api.allowResources = res.data;
        this.transaction_access = this.functionService.checkResourceAccess('transaction_attribute', false);
        this.getTransactionAttributes();
        this.getTransactCount();
      }
      
    })

  }
  /**
   * This is a function to use get attribute
   * @api /transaction-attribute
   * @param searchTerm 
   * @returns 
   */
  
  getTransactionAttributes(searchTerm = null) {
    if (!this.transaction_access.GET) {
      this.notify.notify("You are not Authorized to VIEW Transaction Attribute", 'warn');
      return;
    }
    this.loader = true;
    let url = `transaction-attribute?status=${this.statusCode}&sort_by=attribute_name&sort_asc=true`;
    if (searchTerm != null) {
      url = `transaction-attribute?attribute_name=${searchTerm}&status=${this.statusCode}`;
    }
    this.api.getData(url).subscribe((res: any) => {
      this.loader = false;
      if (res && res.data) {
        this.dataSource = res.data;

      }
    }, err => {
      this.dataSource = [];
      this.loader = false;
    })
  }
  openDialog(type: any, data: any = null) {
    if (type == 'add' && !this.transaction_access.POST) {
      this.notify.notify("You are not authorized to Add Transaction Attribute", "warn");
      return;
    } if (type == 'edit' && !this.transaction_access.PATCH) {
      this.notify.notify("You are not authorized to EDIT Transaction Attribute", "warn");
      return;
    }
    if (type) {
      var _data = {
        type: type,
        edit: data,
        pdata: this
      }
      const dialogRef = this.dialog.open(AddTransactionAttributeComponent, {
        width: '450px',
        panelClass: 'customPadding',
        data: _data
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  openConfirmation(value) {
    if (!this.transaction_access.DELETE) {
      this.notify.notify("You are not authorized to delete Transaction Attribute", "warn");
      return;
    }
    this.selected_transact = value;
    let _data = {
      parent_data: this,
      message: 'Do you want to delete Transaction Attribute?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  //patch[Delete] API for /transaction-attribute
  confirmDialogYes(confirm) {
    if (confirm == 'YES') {
      let body = {
        "transaction_attribute_id": this.selected_transact.transaction_attribute_id,
        "status": -1,
        "user_id": this.api.user_id
      }
     
      this.api.patchData('transaction-attribute', body).subscribe((res: any) => {
        if (res && res.status == 201) {
          // const activity={
          //   actionId: res.data,
          //   data: body,
          //   actionType:UserAction.delete_transaction_attribute.Action_ID
          // }
          // this.api.logUserActivity(activity);
          this.notify.notify("Transaction Attribute Deletion Successful", "success");
          this.getTransactionAttributes();
          this.getTransactCount();
        }else{
          this.loader = false;
          this.notify.notify(res.message,'error');
        }
       

      }, err => {
        this.notify.notify("Unsuccessful Transaction Attribute Deletion", "error")
      })
    }
  }
  /**
   * To Patch API for /valuation-factor-master to update status
   * @param id 
   * @param event 
   * @returns 
   */
  updateStatus(id, event) {
    if (!this.transaction_access.PATCH) {
      this.notify.notify("You are not authorized to Update Transaction Attribute", "warn");
      return;
    }
    let body: any = {
      "transaction_attribute_id": id,
      "status": (event.checked == true) ? 1 : 0,
      "added_by": this.api.user_id,
      "user_id": this.api.user_id,
    };
    this.loader = true;
    
    this.api.patchData('transaction-attribute', body).subscribe((res: any) => {
      if (res && res.status == 201) {
      //   const activity={
      //     actionId: res.data,
      //     data: body,
      //     actionType:UserAction.delete_transaction_attribute.Action_ID
      //   }
      // this.api.logUserActivity(activity);
      this.getTransactionAttributes();
      this.loader = false;
      this.notify.notify(`${res.message}`, "success")
      }else{
        this.loader = false;
        this.notify.notify(res.message,'error');
      }
    }, err => {
      this.loader = false;
    })
  }
  /**
   * To update Transaction Attribute detail
   * @param id 
   * @param event 
   * @returns 
   */
  updateRequired(id, event) {
    if (!this.transaction_access.PATCH) {
      this.notify.notify("You are not authorized to Update Transaction Attribute", "warn");
      return;
    }
    let body: any = {
      "transaction_attribute_id": id,
      "is_required": (event.checked == true) ? true : false,
      "added_by": this.api.user_id,
      "user_id": this.api.user_id,
    };
    this.loader = true;
    
    this.api.patchData('transaction-attribute', body).subscribe((res: any) => {
      if (res && res.status == 201) {
      //   const activity={
      //     actionId: res.data,
      //     data: body,
      //     actionType:UserAction.delete_transaction_attribute.Action_ID
      //   }
      // this.api.logUserActivity(activity);
      this.loader = false;
      this.notify.notify(`${res.message}`, "success")
      }else{
        this.loader = false;
        this.notify.notify(res.message,'error');
      }
    }, err => {
      this.loader = false;
    })
  }

  /**
   * To update Transaction Attribute detail
   * @param id 
   * @param event 
   * @returns 
   */
  updateInDetail(id, event) {
    if (!this.transaction_access.PATCH) {
      this.notify.notify("You are not authorized to Update Transaction Attribute", "warn");
      return;
    }
    let body: any = {
      "transaction_attribute_id": id,
      "is_show_in_details": (event.checked == true) ? true : false,
      "added_by": this.api.user_id,
      "user_id": this.api.user_id,
    };
    
    this.loader = true;
    this.api.patchData('transaction-attribute', body).subscribe((res: any) => {
      if (res && res.status == 201) {
      //   const activity={
      //     actionId: res.data,
      //     data: body,
      //     actionType:UserAction.delete_transaction_attribute.Action_ID
      //   }
      // this.api.logUserActivity(activity);
      this.loader = false;
      this.notify.notify(`${res.message}`, "success")
      }else{
        this.loader = false;
        this.notify.notify(res.message,'error');
      }
    }, err => {
      this.loader = false;
    })
  }


  /**
   * showlabel function (API) to show Language dependent lables
   * @param item 
   * @param key 
   * @return langauge
   */
  showlabel(item: any, key) {
    if (item && item[key] && item[key][this.api.language]) {
      return item[key][this.api.language];
    } else {
      return null;
    }
  }


  getLabel(key) {
    return key ? key.replace(/_/g, " ") : "";
  }


  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.functionService.formatDate(date);
    }
    else {
      return '';
    }
  }
  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getTransactionAttributes();
  }
   /**
   * @api transaction-attribute API for data length for pagination 
   * @returns 
   */
  getTransactCount() {
    let url = `transaction-attribute?status=${this.status}`;
    this.api.getData(url).subscribe((res: any) => {
      if (res && res.data) {
        this.totalCount = res.data.length;
      }
    }, err => {
    })
  }

}
