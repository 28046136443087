<section class="photosGallery" id="item6">
    <div class="container-fluid">
        <div class="d-flex flex-row flex-wrap justify-content-center">
            <div class="row">
                <div class="col-md-5 col-sm-5 col-12 p-0">
                    <div class="d-flex flex-column">
                        <img src="./assets/images/template/icons/img1.jpg" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-7 col-sm-7 col-12 p-0">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-12 p-0">
                            <div class="d-flex flex-column">
                                <img src="./assets/images/template/icons/img2.jpg" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-12 p-0">
                            <div class="d-flex flex-column">
                                <img src="./assets/images/template/icons/img3.jpg" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 p-0">
                            <div class="d-flex flex-column">
                                <img src="./assets/images/template/icons/img4.jpg" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>