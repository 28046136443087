<div>
    <div fxHide="true" fxShow.gt-sm="true" fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">

        <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="start center">
            <label class="main-heading">Add Statistics Information</label>
        </div>


    </div>
    <div fxLayout="column">
        <mat-toolbar class="w-100 br-b  back-border">
            <button mat-button (click)="arrowbutton()">
                <!-- <mat-icon class="est-gr">arrow_backward</mat-icon> -->
                Back
            </button>
        </mat-toolbar>
    </div>

    <div class="container left-50">
        <div class="content">
            <form action="#">
                <div class="user-details">
                    <div class="grid">
                        <div>
                            <div class="input-box mb-5">
                                <span class="detail mb-3">Title</span>
                                <input type="text" placeholder="Area Overview">
                            </div>
                            <div class="input-box mt-5 mb-5">
                                <span class="detail type">Type of Information</span>
                                <mat-select placeholder="Area overview" class="mt-2">
                                    <mat-option>Shape</mat-option>
                                    <mat-option>Others</mat-option>
                                </mat-select>
                            </div>
                            <div>
                                <div class="detail mt-5">Data</div>
                                <div class="grid  data-div mt-3">

                                    <div class=" detail">Key
                                        <input type="text" class="mr-4" placeholder="Total area">
                                        <input type="text" placeholder="Land area">
                                        <input type="text" placeholder="Frontage">
                                        <input type="text" placeholder="Commercial">
                                        <input type="text" placeholder="Total Value">
    
                                    </div>
                                    <div class=" detail"><span>Value<span class=" d-flex justify-content-end"><img src="/assets/icon/Group 1347.svg"></span></span>
                                        <input type="text" placeholder="200,000 sqm">
                                        <input type="text" placeholder="387 sqm">
                                        <input type="text" placeholder="20 m">
                                        <input type="text" placeholder="3 %">
                                        <input type="text" placeholder="400,000 KWD">
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        <div>
                            <div class="input-box">
                                <div class="detail">Description</div>
                                <!-- <input type="text-area" class="detail-description" placeholder="Type here">
                                 -->
                                 <textarea class="detail-description" placeholder="Type here"></textarea>
                            </div>

                            <div class="input-box mt-4">
                                <span class="detail ">Entity</span>
                                <mat-select placeholder="Choose entity" class="mt-2">
                                    <mat-option>Shape</mat-option>
                                    <mat-option>Others</mat-option>
                                </mat-select>

                                <div class="input-box mt-3 grid">
                                    <div class="detail mt-3">
                                        <mat-select placeholder="Neighborhood">
                                            <mat-option>Shape</mat-option>
                                            <mat-option>Others</mat-option>
                                        </mat-select>

                                    </div>
                                    <div class="detail mt-3">
                                        <mat-select placeholder="Block">
                                            <mat-option>Shape</mat-option>
                                            <mat-option>Others</mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>






                </div>

                <div class="button d-flex justify-content-end">
                    <input type="submit" value="Save" class="save-button mr-4">
                    <input type="submit" value="Cancel">
                </div>
            </form>

        </div>
    </div>
</div>