<div class="row w-100 mr-0 ml-0 mange_radis">
    <nav class="navbar nav navbar-expand-lg navbar-light bg-light" fxLayout="row" fxLayoutAlign="space-between">
        <div class="mt-2 mb-2 w-100" fxLayout="row" fxLayoutAlign="space-between">
            <span class="mat-h1 text-center types m-0">Manage Redis</span>
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="position-relative mx-2">
                    <mat-icon class="search-icon">search
                    </mat-icon>
                    <input (keyup)="applyFilter($event.target.value)" [(ngModel)]="filter" class="form-control search-bar" placeholder="Search Keys" type="text">
                </div>
            </div> 

        </div>
    </nav>
</div>
<mat-progress-bar *ngIf="redisListLoader" class="w-100 abs" mode="indeterminate"></mat-progress-bar>
<div class="breadcumredis">
  <span><a  class="breadcumMain" routerLink= "/admin/manage-redis">Manage Redis</a></span>
  <span><mat-icon>keyboard_arrow_right</mat-icon>
  </span>
  <span class="breadcumKey">{{id}} <button *ngIf="selection && selection.selected.length > 0" class="redisdelete-btn btn_delete" (click)="deleteKey()">Delete Keys</button></span>
</div>
<div class="tableRedis w-100 mt-2 mb-0">
<table mat-table [dataSource]="dataSource" class="w-100 table-bordered">
  <ng-container matColumnDef="select">
      <th  mat-header-cell *matHeaderCellDef>
        <ng-container *ngIf="dataSource?.data?.length >0">
          <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </ng-container>
        
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
      <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef> SNo. </th>
        <ng-container *ngIf="dataSource?.data?.length >0">
          <td mat-cell *matCellDef="let element"> {{element.sno}} </td>
        </ng-container>
      </ng-container>
      <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef> Keys </th>
        <ng-container *ngIf="dataSource?.data?.length >0">
          <td mat-cell *matCellDef="let element"> {{element.key}} </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator #paginator *ngIf="dataSource?.data?.length > 25" (page)="pageEvent = $event" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
      
      <div *ngIf="dataSource?.filteredData?.length === 0" class="nodata">
        <span> No Record Found</span>
      </div>
  </div>