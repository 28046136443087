<div class="">
    <div class="header-bar">
        <span *ngIf="action_type=='add'">Add Package</span>
        <span *ngIf="action_type=='edit'">Update Package</span>
    </div>
    <mat-progress-bar *ngIf="loader" class="progress" [color]="'primary'" mode="indeterminate">
    </mat-progress-bar>
    <form [formGroup]="PackageForm" autocomplete="off" novalidate (ngSubmit)="addPackage(PackageForm.value)" class="package-form">
        <mat-dialog-content style="margin: 0; padding: 0;">
            <div class="grid">                 
                <div class="card-section">
                    <span class="detail mb-3">Package name <span class="text-danger">*</span></span>
                    <input type="text" placeholder="Enter package name" formControlName="package_name" tabindex="2">
                    <mat-error *ngIf="PackageForm.controls['package_name'].hasError('required') && PackageForm.controls['package_name'].touched">Package name is required</mat-error>
                </div>
                <div class="card-section">
                    <span class="detail mb-3">Price <span class="text-danger">*</span></span>
                    <input type="number" placeholder="Enter price" formControlName="price"
                    onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57"
                    >
                    <mat-error *ngIf="PackageForm.controls['price'].hasError('required') && PackageForm.controls['price'].touched">Price is required</mat-error>
                </div>                   
                <div class="card-section">
                    <span class="detail mb-3">Validity days <span class="text-danger">*</span></span>
                    <input type="text" placeholder="Enter validity days" formControlName="validity_days"  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"/>
                    <mat-error *ngIf="PackageForm.controls['validity_days'].hasError('required') && PackageForm.controls['validity_days'].touched">Validity days is required</mat-error>
                    <mat-error *ngIf="PackageForm.controls['validity_days'].hasError('minlength')">Validity days should be minmum 1 digit</mat-error>
                    <mat-error *ngIf="PackageForm.controls['validity_days'].hasError('maxlength')">Validity days should be maximum 5 digit</mat-error> 
                    
                </div>                        
                <div class="card-section">
                    <span class="detail mb-3">Api count <span class="text-danger">*</span></span>
                    <input type="text" placeholder="Enter api count" formControlName="api_count" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"/>
                    <mat-error *ngIf="PackageForm.controls['api_count'].hasError('required') && PackageForm.controls['api_count'].touched">Api count is required</mat-error>
                </div>
            
                <!-- <div class="card-section">
                    <span class="detail mb-3">Template</span>
                    <input type="text" placeholder="Template" formControlName="template"/>
                </div> -->
                  
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <div class="d-flex justify-content-end px-4">
                <button type="button" (click)="close()" class="cancel-btn mx-1">Cancel</button>
                <button *ngIf="action_type=='add'" type="submit" class="save-button mx-1" [disabled]="loader" tabindex="60"> Submit</button>
                <button *ngIf="action_type=='edit'" type="button" class="save-button mx-1" (click)="addPackage(PackageForm.value)" [disabled]="loader"> Update</button>
            </div>
        </mat-dialog-actions>
    </form>
    
</div>