import { Component, OnInit } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { UserAction } from "../user-action/user-activity";
@Component({
  selector: 'app-neighbourhood-markers',
  templateUrl: './neighbourhood-markers.component.html',
  styleUrls: ['./neighbourhood-markers.component.css']
})
export class NeighbourhoodMarkersComponent implements OnInit {
  displayedColumns = [
    'sno',
    'Neighbourhood UID',
    'Neighbourhood Name',
    'Total Properties',
    'No of Blocks',
    'Action',
  ]
  searchText: string = '';
  limit: number = 10;
  offset: number = 0;
  totalCount: number;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;
  selectedHood: any;
  selectedNeighUid: any;
  neighWithMarker: any = [];
  returnValue(element, cols) {
    switch (cols) {
      case 'Neighbourhood UID': return element.neigh_uid;
      case 'Neighbourhood Name': return this.showlabel(element, 'neigh_name');
      case 'Total Properties': return element.total_properties;
      case 'No of Blocks': return element.no_of_blocks;
    }
  }
  public marker_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false,
  }
  neighborhoodsList: any = [];
  loader: boolean;
  selected_marker: any;
  dataSource: any = [];

  constructor(private api: ApiserviceService,
    private menu: AdminMenusService,
    private fnc: CommonfunctionService,
    private notify: NotificationService,
    public dialog: MatDialog,) {
    this.menu.setActive('neighbourhood_markers');
    this.getAllowResources()
  }

  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next :(res: any) => {
      
        this.api.allowResources = res.data;
        this.marker_access = this.fnc.checkResourceAccess('neigh_markers', false);
        this.getNeighborhoods('list');
        this.getNeighborhoods('table');
        // this.getApplicationCount();
      }
      
    })
  }

  /**
   * This is a function that use to get neighorhoods
   * @param type type is param
   * @returns 
   */
  getNeighborhoods(type) {
    if (!this.marker_access.GET) {
      this.notify.notify("You are not Authorized to access Neighbourhood Markers", "warn");
      return;
    }
    let url;
    this.loader = true;
    if (type == 'list') {
      url = `neighbourhoods/details?limit=1000&offset=0`;
    }
    if (type == 'table') {
      url = `neighbourhoods/details?limit=1000&offset=${this.offset}`
    }
    this.api.getData(url).subscribe({
      next: (res: any) => {
      this.loader = false;
      if (!res.data?.length) return;
      if (type == 'table') {
        this.neighWithMarker = [];
        this.dataSource = [];
        res.data.forEach(element => {
          if (element.is_show_marker == true) {
            this.neighWithMarker.push(element);
          }
        });
        this.dataSource = this.neighWithMarker;
        this.totalCount = this.dataSource.length;
      }
      // this.neighWithMarkers=res.data;
      if (type == 'list') {
        let sortedList = this.fnc.neighborhoodSortOrder(res.data);
        sortedList.map((item: any) => {
          this.neighborhoodsList.push({
            neigh_id: item.neigh_id,
            neigh_uid: item.neigh_uid,
            neigh_name: (item.neigh_name[this.api.language]) ? item.neigh_name[this.api.language] : item.neigh_uid,
            no_of_blocks: item.no_of_blocks
          })
        });
      }
    }, error : (err) => {
      this.loader = false;
    }})
  }

  setMarker() {
    if (this.selectedNeighUid == undefined || this.selectedNeighUid == null) {
      this.notify.notify("Select a neighbourhood", 'warn')
      return;
    }
    let url = `neighbourhoods/details`;
    var body = {
      "neigh_uid": this.selectedNeighUid,
      "is_show_marker": true,
      "user_id": this.api.user_id,
      "status": 1,
    }
    
    this.api.patchData(url, body).subscribe({
      next: (res: any) => {
      if (res && res.status == 201) {
        // const activity={
        //   neigh:this.selectedNeighUid,
        //   data: body,
        //   actionType:UserAction.set_marker.Action_ID
        // }
        // this.api.logUserActivity(activity);
        this.notify.notify(res.message, 'success');
        this.getNeighborhoods('table');
      }
      else {
        this.loader = false;
        this.notify.notify(res.message, 'error');
      }
    }, error : (err) => {
      this.notify.notify(err.error.message, 'error')
    }})
  }

  selectedNeigh(event) {
    this.selectedNeighUid = event.value;

  }
  openConfirmation(value) {
    if (!this.marker_access.DELETE) {
      this.notify.notify("You are not authorized to delete Neighbourhood with Markers", "warn");
      return;
    }
    this.selected_marker = value;
    let _data = {
      parent_data: this,
      message: 'Do you want to delete Neighburhood with Marker?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  //patch[Delete] API for /transaction-attribute
  confirmDialogYes(confirm) {
    if (confirm == 'YES') {
      let body = {
        "neigh_uid": this.selected_marker.neigh_uid,
        "is_show_marker": false,
        "user_id": this.api.user_id
      }
     
      this.api.patchData('neighbourhoods/details', body).subscribe({
        next : (res: any) => {
        if (res && res.status == 201) {
          // const activity={
          //   neigh:this.selected_marker.neigh_uid,
          //   data: body,
          //   actionType:UserAction.delete_marker.Action_ID
          // }
          // this.api.logUserActivity(activity);
          this.notify.notify("Neighbourhood Marker Deletion Successful", "success");
          this.getNeighborhoods('table');
        }
        else {
          this.loader = false;
          this.notify.notify(res.message, "error");
        }


      }, error : (err) => {
        this.notify.notify("Unsuccessful Neighbourhood Marker Deletion", "error")
      }})
    }
  }

  // API to show Language dependent lables
  showlabel(item: any, key) {
    if (item && item[key] && item[key][this.api.language]) {
      return item[key][this.api.language];
    } else {
      return null;
    }
  }

  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.fnc.formatDate(date);
    }
    else {
      return '';
    }
  }
  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getNeighborhoods('table');
  }

  ngOnInit() {
  }

}
