import { Component, OnInit,Input } from '@angular/core';
import { AdminBreadcrumbService } from './admin-breadcrumb.service';
import { ShareService } from '../services/share.service';

/**
 * This is a breadcrumb class to breadcrumb design of EM
 * @author Vishnu
 */
@Component({
  selector: 'admin-breadcrumb',
  templateUrl: './admin-breadcrumb.component.html',
  styleUrls: ['./admin-breadcrumb.component.css']
})
export class AdminBreadcrumbComponent implements OnInit {
  
  constructor(private _crumbs : AdminBreadcrumbService, private share: ShareService) { }

  ngOnInit() { 
  }

  getCrumbs() {
    return this._crumbs.crumbs;
  }

  getMobileLastCrumb() {
    let totalcrumb = this.getCrumbs().length;
    return this._crumbs.crumbs[totalcrumb-1];
  }

  getCount() {
    return this._crumbs.count;
  }

  callFunction(name){
    if(name == 'floor'){
      // this.share.qaqc_obj.switchEntity('property_floor');
    }
    if(name == 'property'){
      // this.share.qaqc_obj.switchEntity('property');
    }
    else{
      return;
    }
  }

}

@Component({
  selector : 'search-bar',
  templateUrl : './admin-search.component.html',
  styleUrls: ['./admin-breadcrumb.component.css'] 
})
export class AdminSearchbarComponent implements OnInit {
  showsearchbar : boolean =  false;
  @Input('search') search;
  constructor() { 
  }

  ngOnInit() {
  }

  showSearch() {
    this.showsearchbar = true;
  }
}


@Component({
  selector : 'action-buttons',
  templateUrl : './admin-actions.component.html',
  styleUrls: ['./admin-breadcrumb.component.css'] 
})
export class AdminActionbuttonsComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
