import { Component, OnInit , Inject} from '@angular/core';
import { Validators, UntypedFormGroup, FormControl, UntypedFormBuilder, UntypedFormArray } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification.service';
import { ApiserviceService } from '../../../apiservice.service';

@Component({
  selector: 'app-add-masters',
  templateUrl: './add-masters.component.html',
  styleUrls: ['./add-masters.component.scss']
})
export class AddMastersComponent implements OnInit {
 
  public languages: Array<any> = [];
  public languageSet: Array<{}> = [];
  
  public parent_data;
  public mode;
  public listBoxForm: UntypedFormGroup;
  public loader: boolean;
  public language_list: any = [];
  
  constructor(
    public dialogRef: MatDialogRef<AddMastersComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any,
    private notify: NotificationService,
    private apiService: ApiserviceService,
    private fb: UntypedFormBuilder

  ) {
    this.parent_data = this.info.edit;
    this.mode = this.info.type;

    // this.getAttributes();
    this.language_list = this.apiService.language_list;

    this.listBoxForm = this.fb.group({
      listbox: ['', Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9_ ]*$')])],
      language: this.fb.array([]),
      box_value: ['', Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9_.+]*$')])],
      page_name: ['', Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9_ ]*$')])],
    });

    if (this.mode == 'add') {
      this.addRow();
    }
    if (this.mode == 'edit') {
    
      let editData = this.parent_data, labels: any = [], index: number = 0;
      if (editData.label) {
        for (let prop in editData.label) {
          labels[index] = { language: +prop, value: editData.label[prop] };  // +prop to convert into number
          this.addRow();
          index++
        }
      }
      setTimeout(() => {
        this.listBoxForm.patchValue({
          'listbox': this.parent_data.listbox,
          'box_value': this.parent_data.value,
          'page_name': this.parent_data.page_name,
          'language': labels,
        });
      });
    }

  }

  addListbox(val) {
    if(this.listBoxForm.invalid){
      return;
    }
    let data = val.value;
    let re = /\ /gi;
    let libox=  data.listbox.trim().replace(re, '_');
    let box_label = {};
    data.language.forEach((v, k) => {
      box_label[v.language] = v.value;

    });
    
    let url = `listbox-master`;
    let body = {
      "listbox": libox,
      "label": box_label,
      "value": data.box_value,
      "page_name": data.page_name,
      "added_by": this.apiService.user_id,
    }
    this.loader = true;
    this.apiService.postData(url, body).subscribe((res: any) => {
      this.loader = false;
      if (res && res.status == 201) {
        this.notify.notify(res.message, 'success');
        this.dialogRef.close(true);
      }
      else {
        this.notify.notify(res.message, 'warn');
      }
      
    }, err => {
      this.loader = false;
      this.notify.notify('listbox master addtion Error', 'error');
    })
  }

  updateListbox(val) {
    
    if(this.listBoxForm.invalid){
      return;
    }
    let updateData = val.value;
    let box_label = {};
    let re = /\ /gi;
    let libox=  updateData.listbox.trim().replace(re, '_');

    updateData.language.forEach((v, k) => {
      box_label[v.language] = v.value;
    });
    
    let url = `listbox-master`
    let body = {
      "id": this.parent_data.id,
      "listbox": libox,
      "label": box_label,
      "value": updateData.box_value,
      "page_name": updateData.page_name,
      "updated_by": this.apiService.user_id,
    }
    this.loader = true;
    this.apiService.patchData(url, body).subscribe((res: any) => {
      this.loader = false;
      if (res && res.status == 201) {
        this.notify.notify(res.message, 'success');
        this.dialogRef.close(true)
      }
      else{
        this.notify.notify(res.message, 'error')
      }
    }, err => {
      this.loader = false;
      this.notify.notify('listbox master Updation Error', 'error');
    })
  }

  initLanguageFields() {
    return this.fb.group({
      language: [this.language_list[0].language_id, Validators.required],
      value: ['',Validators.required]
    })
  }
  
  addRow() {
    if (this.languageSet.length != this.language_list.length) {
      this.languageSet.push({ language: '', value: '' });
      /* #Form */
      let dd = <UntypedFormArray>this.listBoxForm.controls.language;
      dd.push(this.initLanguageFields());
      /* End - #Form */
    }
    else {
      this.notify.notify('No more languages available', 'error', 5000);

    }
  }
  removeRow(key) {
    if (this.languageSet.length > 1) {
      this.languageSet.splice(key, 1);
      let dd = <UntypedFormArray>this.listBoxForm.controls.language;
      dd.removeAt(key);
    }
  }

  

  cancel() {
    this.dialogRef.close(null);
  }

  ngOnInit() {
  }

}
