import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogBox } from '../../confirm-dialog/confirm-dialogbox';
import { NotificationService } from '../../services/notification.service';
// import { SubjectService } from 'src/app/services/subject.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { ApiserviceService } from '../../../apiservice.service';


@Component({
  selector: 'app-delete-upload-csv',
  templateUrl: './delete-upload-csv.component.html',
  styleUrls: ['./delete-upload-csv.component.scss']
})
export class DeleteUploadCsvComponent implements OnInit {
  dataSource: any = [];
  total: number = 0;
  displayedColumns: any = [];
  limit: number = 10;
  offset: number = 0;
  loader: boolean;
  tables = [];
  table_filters = [];
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  Selected_row = [];
  upload_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false
  };
  SerchForm: FormGroup;

  filter_value: any;
  selected_filter: string;
  selected_table_name: string;
  error_message: string = '';

  constructor(
    public api: ApiserviceService,
    public fnc: CommonfunctionService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private notify: NotificationService,
    // private subject: SubjectService
  ) {
    this.upload_access = this.fnc.checkResourceAccess('csv_upload', false);
    this.getTables();
  }

  /**  
* This is a function to GET list of Tables from API. 
* Resources are checked.
* List of tables are stored in {@link tables} variable.
function
* @api GET /csv-master
* @returns {} Returns void.
*/
  getTables() {
    this.api.getData(`csv-master?sort_asc=true&sort_by=file_type`).subscribe((res: any) => {
      if (res.status == 200 && res.data) {
        this.tables = res.data;
      }
    }, err => {
      this.tables = [];
    })
  }

  /**
   * This fucntion is to create an Array of Selected rows to be deleted an remove from array if already selected.
   function
   * @param change @type {MatCheckboxChange} - Event of mat check box selection. 
   * @param data @type {Object} - Selected row data.
   * @returns {void} - Returns void.
   */
  toggleSelection(change: MatCheckboxChange, data) {
    if (change.checked) {
      this.Selected_row.push(data.id)
    } else {
      const index = this.Selected_row.indexOf(data.id);
      if (index > -1) {
        this.Selected_row.splice(index, 1);
      };
    }
  }

  /**
   * This function is to check if Row is checked or not
   function
   * @param data @type {Object} - Selected row data.
   * @returns @type {Boolean} - Returns true if selected row is checked else false.
   */
  isChecked(data): boolean {
    return (this.Selected_row.indexOf(data.id) > -1)
  }

  /**
   * This function is to check if all Rows are checked or not
   function
   * @returns @type {Boolean} - Returns true or/else false.
   */
  isCheckedAll() {
    return (this.Selected_row.length == this.dataSource.length)
  }

  /**
   * This function is to check available rows in table.
   function
   * @param change @type {MatCheckboxChange} - Event of mat check box selection.
   * @returns @type {Boolean} - Returns true or/else false.
   */
  checkedAll(change:MatCheckboxChange) {
    if (change.checked) {
      this.dataSource.map((ele: any) => {
        if ((this.Selected_row.indexOf(ele.id) == -1)) {
          this.Selected_row.push(ele.id)
        }
      })
    } else {
      this.Selected_row = [];
    }
  }
  ngOnInit(): void {
    this.SerchForm = this.fb.group({
      filter_value: [''],
      table_name: ['', Validators.required],
      filter_name: ['']
    })
  }
  searchCsvUpload(data: any) {
    this.selected_filter = data.filter_name;
    this.filter_value = data.filter_value;
    this.selected_table_name = data.table_name.table_name;
    this.getCsvFiles();
    this.getTotal();
  }

  /**
   * This function is to get Total Length of Reposnse from API based on filter selection
   function
   * @api GET /csv-data
   * @returns {void} - Returns void.
   */
  getTotal() {
    let url = `csv-data?user_id=${this.api.user_id}`;
    if (this.selected_table_name) url += `&tablename=${this.selected_table_name}`;
    if (this.selected_filter && this.filter_value) {
      let filter = {};
      filter[this.selected_filter] = this.filter_value
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }
    this.api.getData(url).subscribe((res: any) => {
      if (res.data) {
        let temp = [];
        res.data.map(ele => {
          temp.push(ele._result)
        })
        this.total = temp.length;
      } else {
        this.total = 0;
      }
    })
  }

    /**
   * This function is to GET list of all data from API based on filter selection
   * Data is stored in {@link dataSource} variable.
   function
   * @api GET /csv-data
   * @returns {void} - Returns void.
   */
  getCsvFiles() {
    this.error_message = '';
    let url = `csv-data?limit=${this.limit}&offset=${this.offset}&user_id=${this.api.user_id}`;
    if (this.selected_table_name) url += `&tablename=${this.selected_table_name}`;

    if (this.selected_filter && this.filter_value) {
      let filter = {};
      filter[this.selected_filter] = this.filter_value
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }
    this.loader = true;
    this.api.getData(url).subscribe((res: any) => {
      this.loader = false;
      if (res.data) {
        // let temp = [];
        // res.data.map(ele => {
        //   temp.push(ele._result)
        // })
        // console.log(temp)
        this.dataSource = res.data;
        this.getTotal();
      } else {
        this.loader = false;
        this.error_message = "Record not available";
        this.dataSource = [];
        this.getTotal();
      }
    })

  }

  // dropdown selection
  optionChanges(value: any, type: string) {
    if (type == 'table') {
      let column_name = value.column_name;
      this.dataSource = [];
      this.SerchForm.patchValue({
        filter_value: ''
      })
      let temp = [];
      this.displayedColumns = ['#'];
      column_name.forEach((element: any) => {
        if (element.col_name == 'data') {
        } else {
          temp.push(element)
          this.displayedColumns.push(element.col_name);
        }
      });
      this.displayedColumns.push('action');
      this.table_filters = temp;
    } else if (type == 'filter') {
      this.SerchForm.patchValue({
        filter_value: ''
      })
    }
  }

  /**
   * This is a function to open Dialdg to confirm delete action.
   function
   * @param deleted_data @type {Array} - Array holding elements of row data to be deleted.
   * @param action @type {String} - Action to be performed.
   * @returns {void} - Returns void.
   */
  delete(deleted_data, action = null) {
    if (!this.upload_access.DELETE) {
      this.notify.notify("You do not have access to delete data",'warn');
      return;
    }
    let _data = {
      parent_data: null,
      message: "Do you want to Delete record?",
    };
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      panelClass: 'show-dialog',
      data: _data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'YES') {
        if (action == 'all') {
          this.deleteCsvData(deleted_data);
        } else {
          this.deleteCsvData([deleted_data]);
        }

      } else if (result == 'NO') {

      }
    });
  }

 /**
   * This is a function to delete csv data.
   function
   * @api DELETE /csv-record
   * @param deleted_data @type {Array} - Array holding elements of row data to be deleted.
   * @returns {void} - Returns void.
   */
  deleteCsvData(deletedData) {
    this.loader = true;
    let url = `csv-record?tablename=${this.selected_table_name}&user_id=${this.api.user_id}`;
    if (deletedData) {
      let filter = {};
      filter['id'] = deletedData;
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`
    }
    this.api.deleteData(url).subscribe((res: any) => {
      this.loader = false;
      if (res.status == 201) {
        this.notify.notify(res.message, 'success');
        this.Selected_row = [];
        this.getCsvFiles();
        this.getTotal()
      } else {
        this.notify.notify(res.message, 'error');
      }
    }, err => {
      this.loader = false;
    })

  }

  /**  
 * This is a function to change pagination page and get the list of objectives.
 function
 * @param e @type {PageEvent} - A Page Event to get page details
 * @return {} Returns void.
 */
  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getCsvFiles();
  }
}
