<!-- #1 For Web view-->
<!-- <div>
  <div fxHide="true" fxShow.gt-sm="true" fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
    <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="space-between center">
      <admin-breadcrumb></admin-breadcrumb>
      <div fxLayout="row" fxLayoutAlign="end center">
        <div fxLayout="row" fxShow="false" fxShow.gt-xs="true" fxLayoutAlign="start center" fxLayoutGap="10px"
          class="search-bar">
          <input class="form-input-l search-inputbox" placeholder="Type to search..."
            type="text">
          <button mat-icon-button>
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="button-b">
        <button mat-button class="orange-button">Create
          Reports Group</button>
      </div>
    </div>
  </div>
</div> -->
<!-- end of #1 For Web view-->


<!-- #3 For web view -->
<div class="container" fxHide="true" fxShow.gt-sm="true" fxLayout="row">
  <div class="l-cont">
    <div class="w-100 mb-2 rel">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h4 class="l-mg">Report Groups</h4>
        <button mat-icon-button class="refresh" (click)="refresh()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <mat-progress-bar *ngIf="loader" class="w-100 abs" mode="indeterminate"></mat-progress-bar>
    </div>
    <!-- #4 Sortable Master Groups set -->
    <div class="newscroll">
      <div class="list-container">
        <span *ngIf="group_list.length == 0 && !loader" class="l-mg-sm">No results found...</span>
        <div class="cst rowse" fxLayout="column" fxLayoutAlign="start left"
          *ngFor="let item of group_list;let k = index;">
          <button class="group-b dragx rel" mat-button [ngClass]="{active: isActive(k)}" (click)="select(k, item.id)">
            <!-- <mat-icon class="draghandle" matTooltip="Drag me" svgIcon="move-icon">drag_handle</mat-icon> -->
            <span class="w-100 title">{{showLabel(item)}}</span>
            <mat-icon class="add-b" svgIcon="down-arrow-icon"></mat-icon>
            <mat-progress-bar *ngIf="group_loader && selected_item == item.id " class="w-100 abs-group"
              mode="indeterminate"></mat-progress-bar>
          </button>
          <!-- #5 Submenu Master group -->
          <div class="cs" fxLayout="row" fxLayoutAlign="start start">
            <mat-nav-list class="masterslist w-100" [ngClass]="{active: isActive(k)}"
              *ngIf="report_list && selected_item == item.id && report_list.length > 0">
              <ng-container *ngFor="let childitem of report_list;let ks = index">
                <mat-list-item class="item subdraghandle" (click)="select_child(childitem)">
                  <!-- <mat-icon class="draghandle" matTooltip="Drag me" svgIcon="move-icon"></mat-icon> -->
                  <div class="txt cp">{{showLabel(childitem)}}</div>
                </mat-list-item>
              </ng-container>
            </mat-nav-list>
          </div>
          <!-- #5 End of submaster group -->
        </div>
      </div>
    </div>
    <!-- End of #4 -->
  </div>

  <div fxFlex="calc3cols" class="col3">
    <div class="r-cont">
      <div class="r-mv h50p" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
         <span *ngIf="selected_report">Showing report for: <b class="cp">{{showLabel(selected_report)}}</b></span>
         <span *ngIf="!selected_report">No reports selected</span>
        </div>
        <div>
          <mat-select [(ngModel)]="selected_report_type" (selectionChange)="changeType($event)" class="form-select-l">
            <mat-option class="cp" *ngFor="let data of report_type" [value]="data.id">{{showLabel(data)}}</mat-option>
          </mat-select>
          <mat-slide-toggle class="togg" [color]="color" [checked]="sort_asc" (change)="changeOrder($event)">
            <span *ngIf="!sort_asc">Decending</span>
            <span *ngIf="sort_asc">Ascending</span>
          </mat-slide-toggle>
        </div>
      </div>
      <div class="w-100 cal-h">
        <ngx-loading [show]="loaderiframe && selected_report" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
        <iframe *ngIf="selected_report" class="strech" id="frame" (load)="success()" [src]="selected_report.url | safe" frameborder="0"></iframe>
        <div *ngIf="noaccess" fxFlex="fill" fxLayoutAlign="center center">
          <h1 class="mat-h1">You're not authorized to view this page.</h1>
        </div>
      </div>
    </div>
  
  </div>
</div>