<div class="w-100">
    <div class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
        <span></span>
        <div class="table-filter max_w">
            <input type="text" class="form-control" placeholder="Search by name..."  [formControl]="searchField" >
            <mat-select (selectionChange)="getMenuList()" [(ngModel)]="selected_status">
                <mat-option [value]="'1'">Enable</mat-option>
                <mat-option [value]="'0'" >Disabled</mat-option>
            </mat-select>
        </div>
    </div>
    <div class="table-container mat-elevation-z8">
        <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
        <mat-table [sortablejs]="dataSource" [sortablejsOptions]="reportoptions" [dataSource]="dataSource"
            class="w-100">
            <!-- select Column -->
            <ng-container matColumnDef="drag">
                <mat-header-cell *matHeaderCellDef width="10%"> </mat-header-cell>
                <mat-cell *matCellDef="let element;">
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="draghandle1">
                        <mat-icon class="handle" svgIcon="dragx"></mat-icon>
                    </div>
                </mat-cell>
            </ng-container>

            <!-- Id Column -->
            <ng-container matColumnDef="sn">
                <mat-header-cell *matHeaderCellDef> SN </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index;">
                    <span>{{ i + 1 }}</span>
                </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element;">
                    <span>{{element.name}}</span>
                </mat-cell>
            </ng-container>

            <!-- Title Column -->
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
                <mat-cell *matCellDef="let element;">
                    <span>{{showlabel(element)}}</span>
                </mat-cell>
            </ng-container>

            <!-- Require Login Column -->
            <ng-container matColumnDef="require_login">
                <mat-header-cell *matHeaderCellDef> Require Login </mat-header-cell>
                <mat-cell *matCellDef="let element;">
                    <mat-slide-toggle class="mlr-2" [checked]="element.login_require == '1'"
                        (change)="changeToggle($event, element, 'login')">
                    </mat-slide-toggle>
                </mat-cell>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                <mat-cell *matCellDef="let element;">
                    <mat-slide-toggle class="mlr-2" [checked]="element.status == '1'"
                    (change)="changeToggle($event, element, 'status')">
                    </mat-slide-toggle>
                </mat-cell>
            </ng-container>

            <!-- template Column -->
            <ng-container matColumnDef="template">
                <mat-header-cell *matHeaderCellDef> Template </mat-header-cell>
                <mat-cell *matCellDef="let element;">
                    <button mat-button (click)="openDialog(element.template)">
                        view
                    </button>
                </mat-cell>
            </ng-container>

            <!-- action Column -->
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                <mat-cell *matCellDef="let element;">
                    <mat-button-toggle-group class="toggle-btn-group" #group="matButtonToggleGroup">
                        <mat-button-toggle value="left" (click)="edit(element)"
                            title="Edit">
                            <mat-icon class="size-sm default">edit</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="right" title="delete" (click)="deteleMenu(element)">
                            <mat-icon class="size-sm warn">delete</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="dragx1"></mat-row>
        </mat-table>
    </div>
</div>