import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { ApiserviceService } from '../../../apiservice.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-view-data',
  templateUrl: './view-data.component.html',
  styleUrls: ['./view-data.component.scss']
})
export class ViewDataComponent implements OnInit {

  datalist=[];
  keyAttrctionList=[]
  imagesList=[];
  selectedType;
  dragDisabled: boolean;
  language_list=[];
  parent_Data;
  lang=1;
  loader: boolean;

  isDraged=false;
  heading={
    "title":"",
    "entity":"",
    "neigh_name":"",
    "block_name":""
  }
  constructor(
    public dialogRef: MatDialogRef<ViewDataComponent>,
    @Inject(MAT_DIALOG_DATA) public parentData: any,
    public api: ApiserviceService,
    private notify:NotificationService,
    private fnc: CommonfunctionService
  ) {
    
    this.language_list =  localStorage.getItem('languageList') ? JSON.parse(localStorage.getItem('languageList')): []
    this.selectedType = parentData.type;
    this.parent_Data = parentData.info;
  //  console.log(this.parent_Data);
   
    this.heading['title'] = this.parent_Data && this.parent_Data?.title ? this.parent_Data?.title[api.language]:'';
    if(this.selectedType == 'trends'){
      this.heading['entity'] = this.parent_Data.entity;
      if(this.parent_Data.entity == 'neighbourhood' || this.parent_Data.entity == 'block'){
        this.heading['neigh_name'] = this.parent_Data?.neigh_name[api.language];
      }
      if(this.parent_Data.entity == 'block'){
        this.heading['block_name'] = this.parent_Data?.block_name[api.language];
      }
    }
    
    this.selectedLang(this.lang);
   }

   /**
    * This is a set langauge dependent data function
    * @param language 
    */
   selectedLang(language){
    let data_list = this.parent_Data.data && this.parent_Data.data[language]
            ? this.parent_Data.data[language]
            : this.parent_Data.data && this.parent_Data.data[1]
            ? this.parent_Data.data[1]
            : this.parent_Data.data && this.parent_Data.data[2]
            ? this.parent_Data.data[2]
            : []
    this.imagesList = (this.parent_Data.images)?this.parent_Data.images:[];
    this.datalist=[];
    if(data_list){
        this.datalist = data_list;
    }
    this.keyAttrctionList=[]
    if(this.parent_Data.info_type == 'trends'){
      let key_attrction_list = this.parent_Data.key_attraction && this.parent_Data.key_attraction[language]
      ? this.parent_Data.key_attraction[language]
      : this.parent_Data.key_attraction && this.parent_Data.key_attraction[1]
      ? this.parent_Data.key_attraction[1]
      : this.parent_Data.key_attraction && this.parent_Data.key_attraction[2]
      ? this.parent_Data.key_attraction[2]
      : []

      if(key_attrction_list.length > 0){
        this.keyAttrctionList = key_attrction_list;
      }
    }
   }

  /**
   * This is on drop data sort fuction
   * @param event 
   */
  dropData(event){
   this.dragDisabled = true;  
    if (event.previousIndex != event.currentIndex) {
      this.isDraged = true;
    }
    moveItemInArray(this.datalist, event.previousIndex, event.currentIndex);    
  }

   /**
   * This is on drop key attrction sort fuction
   * @param event 
   */
  dropKey(event){
    this.dragDisabled = true; 
    if(event.previousIndex != event.currentIndex){
      this.isDraged =true; 
    }
     moveItemInArray(this.keyAttrctionList, event.previousIndex, event.currentIndex);
   }

  /**
   * This is on drop key attrction sort fuction
   * @param event 
   */
   dropImages(event){
    this.dragDisabled = true;
    if(event.previousIndex != event.currentIndex){
      this.isDraged =true; 
    }
    moveItemInArray(this.imagesList, event.previousIndex, event.currentIndex);
   }

  savedata(){

    let dataset={}, keyattractset ={};
    let tempdata = {}, tempattrat = {};
    
    
    if(this.datalist.length>0){
      for(let i =0; i<this.datalist.length; i++){
        this.datalist[i]['order'] = i;
      }
      dataset[this.lang]=this.datalist;
    }
    if(this.keyAttrctionList.length>0){
      for(let i =0; i<this.keyAttrctionList.length; i++){
        this.keyAttrctionList[i]['order'] = i;
      }
      keyattractset[this.lang]=this.keyAttrctionList;
    }
  
    let body = {
        "id": this.parent_Data.id,
        "entity": this.parent_Data.entity,
        "info_type": this.parent_Data.info_type,
        "updated_by": this.api.user_id,
        "images": this.imagesList?this.imagesList:[],
        "data": dataset,
        "key_attraction": keyattractset
      }
      this.loader=true;
      this.api.patchData(`city-details`, body).subscribe({
        next: (res:any)=>{
        this.loader=false;
        if(res && res.status==201){
          this.notify.notify(res.message, "success");
          this.dialogRef.close(true);
        }else{
          this.notify.notify(res.message, "warn");
        }
      },error :err=>{
        this.loader=false;
      }})
    
  }
  canceled(){
    this.dialogRef.close(false);
  }
  ngOnInit(): void {
  }

}
