<div class="p-3">
    <div class="title-bar">
        <div class="d-flex align-items-center">
            <h4>Company List</h4>
            <input class="form-control search-bar" [formControl]="searchField" type="text" name="search" placeholder="Search by company name">
        </div>
        <div class="d-flex align-items-center">
           <mat-select [(ngModel)]="status" (selectionChange)="changeStatus($event.value)">
                <mat-option [value]="'1'">Enabled</mat-option>
                <mat-option [value]="'0'">Disabled</mat-option>
                <mat-option [value]="'-1'">Deleted</mat-option>
            </mat-select>
            <button (click)="addCompany('add')">Add Company</button>
        </div>
    </div>
    <mat-progress-bar *ngIf="loader" class="progress" [color]="'primary'" mode="indeterminate">
    </mat-progress-bar>
    <div>
        <div class="tableDiv border">
            <table class="table">
                <thead>
                    <tr> 
                        <th>Company Name</th>
                        <th>Register Name</th>
                        <th>Registration Number</th>
                        <th>Registration Authority</th>
                        <th>Contact Person</th>
                        <th>Contact Number</th>
                        <th>Email</th>
                        <th>Whatsapp Number</th>
                        <th>Company Code</th>
                        <th>Company Type Id</th>
                        <th>Company Type Name</th>
                        <th>Working Since</th>
                        <th>Nationality</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let obj of companies">
                        <td>{{obj.company_name}}</td>
                        <td>{{obj.register_name}}</td>
                        <td>{{obj.registration_number}}</td>
                        <td>{{obj.registration_authority}}</td>
                        <td>{{obj.contact_person}}</td>
                        <td>{{obj.contact_view}}</td>
                        <td>{{obj.email}}</td>
                        <td>{{obj.whatsapp_view}}</td>
                        <td>{{obj.company_code}}</td>
                        <td>{{obj.company_type_id}}</td>
                        <td>{{obj.company_type_name}}</td>
                        <td>{{obj.working_since}}</td>
                        <td>{{obj.nationality}}</td>
                        <td class="d-flex align-items-center">
                                <mat-slide-toggle  matTooltip="status"
                                    class="example-margin mx-2" [checked]="obj.status == 1 ? true: false"
                                    (change)="updateStatus(obj.company_id,$event, obj.status)">
                                </mat-slide-toggle>
                              <mat-icon *ngIf="status == 1" matTooltip="Edit" (click)="addCompany('edit', obj)" class="icon edit">edit</mat-icon>
                              <mat-icon *ngIf="status == 1 || status == 0" matTooltip="Delete" (click)="deleteMaster(obj)" class="icon delete">delete</mat-icon>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <mat-paginator [length]="total" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
                (page)="pageEvent = $event; handlePagination($event)">
            </mat-paginator>
        </div>
    </div>
</div>