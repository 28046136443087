import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiserviceService } from "../../apiservice.service";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { NotificationService } from "../services/notification.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogBox } from "../confirm-dialog/confirm-dialogbox";
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AddParameterComponent } from './add-parameter/add-parameter.component';
import { UntypedFormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { UserAction } from "../user-action/user-activity";
import { debounceTime } from 'rxjs';
@Component({
  selector: 'app-comparison-parameter',
  templateUrl: './comparison-parameter.component.html',
  styleUrls: ['./comparison-parameter.component.css']
})
export class ComparisonParameterComponent implements OnInit {
  displayedColumns = ['sn', 'neigh_name', 'neigh_uid', 'key_name', 'value_type', 'value', 'status', 'actions'];
  dataSource: any = [];
  loader: boolean;
  status: string = "1";
  searchField: UntypedFormControl;
  seach: any = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  limit: number = 10;
  offset: number = 0;
  statusCode: string= '1';
  totalCount: number;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;
  selected_parameter;
  returnVal(element, cols) {
    switch (cols) {
      case 'neigh_name': return element.neigh_name;
      case 'neigh_uid': return element.neigh_uid;
      // case 'parameter_name': return element.parameter_name;
      case 'key_name': return element.key_name;
      case 'value_type': return element.value_type;
      case 'value': return element.value;

    }
  }
  parameter_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };

  constructor(private api: ApiserviceService,
    private menu: AdminMenusService,
    private fncSrv: CommonfunctionService,
    private notify: NotificationService,
    private apiServ: ApiserviceService,
    public dialog: MatDialog,) {
    this.menu.setActive('compare_parameter');
    this.menu.setActive('valuation');
    this.getParameters();
    this.getParameterCount();
    // this.getAllowResourcesApi();
  }



  getParameters(searchTerm = null) {
    // if (!this.parameter_access.GET) {
    //   this.notify.notify("You are not authorized to Add Comparison Parameter", "Dismiss");
    //   return;
    // }
    this.loader = true;
    let url = `comparison-parameter?status=${this.statusCode}&limit=${this.limit}&offset=${this.offset}`;
    if (searchTerm != null) {
      url = `comparison-parameter?neigh_uid=${searchTerm}&status=${this.statusCode}`;
    }
    this.apiServ.getData(url).subscribe({
      next:(res: any) => {
      this.loader = false;
      if (res && res.data) {
        this.dataSource = res.data;
      } else {
        this.notify.notify(res.message, "error", 5000);
        this.dataSource = [];
      }
    }, error:err => {
      this.notify.notify(err.error.message, "error", 5000)
      this.dataSource = [];
      this.loader = false;
    }});
  }

  getParameterCount() {
    let url = `comparison-parameter?status=${this.statusCode}`;
    this.apiServ.getData(url).subscribe((res: any) => {
      if (res && res.data) {
        this.totalCount = res.data.length;
      } else {
        this.totalCount = 0;
      }
    }, err => {
      this.totalCount = 0;
    })
  }

  //patch API for /comparison-parameter to update status
  updateStatus(id, event) {
    // if (!this.parameter_access.PATCH) {
    //   this.notify.notify("You are not authorized to update Comparison Parameter", "Dismiss");
    //   return;
    // }
    let body: any = {
      "id": id,
      "status": (event.checked == true) ? 1 : 0,
      "updated_by": this.apiServ.user_id
    };
    this.loader = true;
    this.apiServ.patchData('comparison-parameter', body).subscribe((res: any) => {
      if(res && res.status == 201){
      //   const activity={
      //     actionId: res.data,
      //     data: body,
      //     actionType:UserAction.update_parameter.Action_ID
      //   }
      // this.api.logUserActivity(activity);
      this.getParameterCount();
      this.getParameters();
      this.loader = false;
      this.notify.notify(`${res.message}`, "success")
      }else{
        this.getParameterCount();
        this.getParameters();
        this.loader = false;
        this.notify.notify(`${res.message}`, "error")
      }
    }, err => {
      this.getParameterCount();
      this.getParameters();
      this.loader = false;
    })
  }

  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.fncSrv.formatDate(date);
    }
    else {
      return '';
    }
  }


  getLabel(key) {
    return key ? key.replace(/_/g, " ") : "";
  }

  openDialog(type: any, data: any = null) {
    // if (!this.parameter_access.POST) {
    //   this.notify.notify("You are not authorized to Add Comparison Parameter", "Dismiss");
    //   return;
    // }
    if (type) {
      var _data = {
        type: type,
        edit: data,
        pdata: this
      }
      const dialogRef = this.dialog.open(AddParameterComponent, {
        width: '350px',
        panelClass: 'customPadding',
        data: _data
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  openConfirmation(value) {
    // if (!this.parameter_access.DELETE) {
    //   this.notify.notify("You are not authorized to delete Comparison Parameter", "Dismiss");
    //   return;
    // }

    this.selected_parameter = value;
    let _data = {
      parent_data: this,
      message: 'Do you want to delete Parameter?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  //patch[Delete] API for /comparison-parameter
  confirmDialogYes(confirm) {
    if (confirm == 'YES') {
      let body = {
        "id": this.selected_parameter.id,
        "status": -1,
        "updated_by": this.apiServ.user_id
      }
      
      this.apiServ.patchData('comparison-parameter', body).subscribe((res: any) => {
        if(res && res.data){
        // const activity={
        //   actionId: res.data,
        //   data: body,
        //   actionType:UserAction.delete_parameter.Action_ID
        // }
        // this.api.logUserActivity(activity);
        this.notify.notify("Parameter Delete Successful", "success");
        this.getParameters();
        this.getParameterCount();
      }else{
        this.loader = false;
        this.notify.notify(res.message, "error");
      }
      }, err => {
        this.notify.notify("Unsuccessful Parameter Deletion", "error")
      })
    }
  }

  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getParameters();
  }

  //API call to get Parameters with status=-1 and Data length for pagination
  getDeleted(event) {
    // if (!this.parameter_access.GET) {
    //   this.notify.notify("You are not authorized to Access Comparison Parameter", "Dismiss");
    //   return;
    // }
    if (event.checked == false) {
      this.status = "0,1";
    }
    else {
      this.status = "-1";
    }
    this.getParameterCount();
    this.getParameters();
  }
  selectionChange(val){
    this.statusCode = val;
    this.getParameterCount();
    this.getParameters();
  }
  ngOnInit() {
    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(term => {
        term = term.trim().toLowerCase();
        if (term) {
          this.getParameters(term)
        } else {
          this.getParameters(null)
        }
      })
  }

}
