<div class="row w-100">
  <!-- table -->
  <div class="col-md-12 pr-0">
    <mat-card appearance="outlined" class="m-2 w-100">
      <mat-card-header class="mtc-header pt-3 pb-2 pr-2 position-relative d-flex justify-content-between">
        <mat-card-title>
          Transaction Attributes
        </mat-card-title>
        <div class="d-flex justify-content-between">
          <div class="search">
            <input class="search-input" [formControl]="searchField" name="search"
              placeholder="Search Transaction Name...." type="text">
          </div>
          <div class="show-delete-addbtn">
          <div class="show-deleted_dd">
            <!-- Show Deleted-->
                <!-- <mat-slide-toggle [(ngModel)]="statusCode" class="deleted-row" (change)="getDeleted($event)">
                  Show Deleted
                </mat-slide-toggle> -->
                <mat-select placeholder="Choose status" [(ngModel)]="statusCode" (selectionChange)="selectionChange($event.value)">
                  <mat-option value="1">Active</mat-option>
                  <mat-option value="0">Inactive</mat-option>
                  <mat-option value="-1">Deleted</mat-option>
              </mat-select>
            </div>
         
            <button mat-raised-button (click)="openDialog('add')">
              Add Transaction
            </button>
          </div>
        </div>
      </mat-card-header>
      <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
      <mat-card-content class="translist pr-2 pl-2 pb-2 pt-2">
        <div class="w-100 calc-h">
          <table mat-table [dataSource]="dataSource" class="w-100">
            <ng-container *ngFor='let cols of displayedColumns'>
              <!-- for required -->
              <ng-container *ngIf="cols == 'is_required'">
                <ng-container matColumnDef="{{cols}}">
                  <th mat-header-cell *matHeaderCellDef>
                    <span class="header cp">{{getLabel(cols)}}</span>
                  </th>
                  <td mat-cell *matCellDef="let element" class="pl-2 pr-2">
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="d-flex justify-content-start align-items-center">
                        <mat-slide-toggle [checked]="element.is_required == truw ? true: false"
                          (change)="updateRequired(element.transaction_attribute_id,$event)">
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </td>
                </ng-container>
              </ng-container>
              <!-- for show in details -->
              <ng-container *ngIf="cols == 'show_in_detail'">
                <ng-container matColumnDef="{{cols}}">
                  <th mat-header-cell *matHeaderCellDef>
                    <span class="header cp">{{getLabel(cols)}}</span>
                  </th>
                  <td mat-cell *matCellDef="let element" class="pl-2 pr-2">
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="d-flex justify-content-start align-items-center">
                        <mat-slide-toggle [checked]="element.is_show_in_details == true ? true: false"
                          (change)="updateInDetail(element.transaction_attribute_id,$event)">
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </td>
                </ng-container>
              </ng-container>
              <!-- for action -->
              <ng-container *ngIf="cols == 'action'">
                <ng-container matColumnDef="{{cols}}">
                  <th mat-header-cell *matHeaderCellDef>
                    <span class="header action cp">{{getLabel(cols)}}</span>
                  </th>
                  <td mat-cell *matCellDef="let element" class="pl-2 pr-2">
                    <div class="d-flex justify-content-center align-items-center">
                      <button color='white' mat-mini-fab (click)="openDialog('edit',element)">
                        <mat-icon class="editIcon blue d-flex justify-content-center align-items-center">edit</mat-icon>
                      </button>
                      <button mat-mini-fab color="warn" (click)="openConfirmation(element)" *ngIf="element.status != -1">
                        <mat-icon class="deleteIcon d-flex justify-content-center align-items-center">delete</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>
              </ng-container>

              <!-- for status -->
              <ng-container *ngIf="cols == 'status'">
                <ng-container matColumnDef="{{cols}}">
                  <th mat-header-cell *matHeaderCellDef>
                    <span class="header cp">{{getLabel(cols)}}</span>
                  </th>
                  <td mat-cell *matCellDef="let element" class="pl-2 pr-2">
                    <div class="d-flex justify-content-start align-items-center">
                      <mat-slide-toggle [checked]="element.status == 1 ? true: false"
                        (change)="updateStatus(element.transaction_attribute_id,$event)">
                      </mat-slide-toggle>
                    </div>
                  </td>
                </ng-container>
              </ng-container>
              <!--  -->
              <!-- for every other column -->
              <ng-container *ngIf="cols !='action' && cols!='status' && cols!='is_required' && cols!='show_in_detail'">
                <ng-container matColumnDef="{{cols}}">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    <span class="header cp">
                      {{getLabel(cols)}}
                    </span>
                  </th>
                  <td mat-cell *matCellDef="let element; let i=index;">
                    <span *ngIf="cols != 'description'"> {{returnValue(element,cols)}} </span>
                    <span *ngIf="cols == 'SN'"> {{i+1}} </span>
                    <div *ngIf="cols=='description'">
                      <p matTooltip={{returnValue(element,cols)}} class="more"> {{returnValue(element,cols)}} </p>
                    </div>
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>