import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { ApiserviceService } from '../../apiservice.service';
import { NotificationService } from '../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { UserAction } from "../user-action/user-activity";
import { CopyPasteComponent } from './copy-paste/copy-paste.component';
import { ViewDataComponent } from './view-data/view-data.component';
import { debounceTime } from 'rxjs';
@Component({
  selector: 'app-city-wise-details',
  templateUrl: './city-wise-details.component.html',
  styleUrls: ['./city-wise-details.component.scss']
})
export class CityWiseDetailsComponent implements OnInit {
  displayedColumns = ['SN',
    'title',
    'slug',
    'Entity',
    'Data', 
    'Neighbourhood',
    'Block',
    "status",
    'action']
  searchField: UntypedFormControl;
  toggle: boolean=false;
  showData:boolean=false;
  neighList:any
  blockList:any
  searchText:string='';
  selected_type:string='details';
  selected_status:string='1';
  selected_entity:string='';
  selected_neighbourhood:string='';
  selected_block:string='';
  public entity = [
    {
      name: 'All',
      value: ''
    },
    {
      name: 'City',
      value: 'city'
    },
     {
      name: 'Neighbourhood',
      value: 'neighbourhood'
    },
    {
      name: 'Block',
      value: 'block'
    }]
  totalRecords: any=[];
  
  returnVal(element, dispCol) {
    if (element) {
      switch (dispCol) {
        case "slug":
          if (element.slug) {
            return element.slug;
          }else{
            return "";
          }
        case "title":
          if (element.title) {
            if (typeof element.title === "object") {
              return element.title
                ? element.title[this.api.language]
                  ? element.title[this.api.language]
                  : element.title[1]
                  ? element.title[1]
                  : element.title[2]
                : "";
            } else {
              return element.title ? element.title : "";
            }
          } else {
            return "";
          }
        case "Data":
          if (element.data) {
            if (typeof element.data === "object") {
              return element.data
                ? element.data[this.api.language]
                  ? element.data[this.api.language]
                  : element.data[1]
                  ? element.data[1]
                  : element.data[2]
                : "";
            } else {
              return element.data ? element.data : "";
            }
          } else {
            return "";
          }
        case "Key_Attraction":
          if (element.key_attraction) {
            if (typeof element.key_attraction === "object") {
              return (element.key_attraction  && element.key_attraction[this.api.language])
                  ? element.key_attraction[this.api.language]
                  : '';
            } else {
              return element.key_attraction ? element.key_attraction : "";
            }
          } else {
            return "";
          }
        case "Neighbourhood":
          if (element.neigh_name) {
            if (typeof element.neigh_name === "object") {
              return element.neigh_name
                ? element.neigh_name[this.api.language]
                  ? element.neigh_name[this.api.language]
                  : element.neigh_name[1]
                  ? element.neigh_name[1]
                  : element.neigh_name[2]
                : "";
            } else {
              return element.neigh_name ? element.neigh_name : "";
            }
          } else {
            return "";
          }
        case "Block":
          if (element.block_name) {
            if (typeof element.block_name === "object") {
              return element.block_name
                ? element.block_name[this.api.language]
                  ? element.block_name[this.api.language]
                  : element.block_name[1]
                  ? element.block_name[1]
                  : element.block_name[2]
                : "";
            } else {
              return element.block_name ? element.block_name : "";
            }
          } else {
            return "";
          }
        case "Entity":
          return element.entity;
      }
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  limit: number = 10;
  offset: number = 0;
  totalCount: number;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;
  dataSource: any = [];
  loader: boolean;
  form_action:string;
  update_data:any
  status: string = "1";
  detail_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  constructor(private api:ApiserviceService,
    private notify:NotificationService,
    private fnc:CommonfunctionService,
    private dialog:MatDialog,
    private menu:AdminMenusService) { 
      this.menu.setActive('cityDetail');
      this.getAllowResources();
      this.neighDetails()
    }

/**
 * This function used to copy
 * @param ele_data 
 */
    copyDialog(ele_data?){
     let dialogbox = this.dialog.open( CopyPasteComponent,{
       width: '350px',
       panelClass:'customPadding',
       data: {data: ele_data, neigh_list : this.neighList, dataSource:  this.totalRecords}
      })
      dialogbox.afterClosed().subscribe(res=>{
        if(res){
          this.getCityDetails();
        }
      })
    }
  /**
   * action function to add, edit city ditails
   * @returns Void
   */
    action(action?,ele_data?){
      if (action && action=='add') {
        this.form_action='add';
      }
      else if (action && action=='edit') {
        this.form_action='edit'
        this.update_data=ele_data;
      }
      this.toggle = !this.toggle
      if (this.toggle==false && action == 'update-list') {
        this.getCityDetails();
        this.getDetailsCount();
      }
    }
   

  ngOnInit(): void {
    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(term => {
        term = term.trim().toLowerCase();
        if (term) {
          this.getCityDetails(term)
        } else {
          this.getCityDetails(null)
        }
      })
  }


  showDataPopup(element, type){
    // let div=document.getElementById(`${name}${div_id}`);
    // if (div.style.display=="none") {
    //   div.style.display="block";
    // }
    // else if(div.style.display=="block"){
    //   div.style.display="none";
    // }

   let dialogbox = this.dialog.open(ViewDataComponent, {
      width: '800px',
      panelClass:'customPadding',
      data: { info: element, type: type}
    })
    dialogbox.afterClosed().subscribe(res=>{
      if(res){
        this.getCityDetails();
      }
    })
  }

    /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
    getAllowResources() {
      let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
      this.api.getUmsData(body).subscribe({
        next :(res: any) => {
          
          this.api.allowResources = res.data;
          this.detail_access = this.fnc.checkResourceAccess('city_detail', false);
          this.getCityDetails();
          this.getDetailsCount();
          // this.getApplicationCount();
        }
       
      })
    }

/**
 * this is a filter function that use to get filter wise data 
 * @param type 
 * @param val 
 */
  selectionChange(type:string, val){
    if(type == 'status' && val){
      this.status = val;
    }
    if(type == 'type'){
      this.selected_entity ='';
      this.selected_neighbourhood ='';
      this.selected_block ='';
      // if( val=='trends'){
      //   this.displayedColumns.splice(4,0,'Key_Attraction');
      // }else if( val=='details'){
      //   let index =this.displayedColumns.indexOf('Key_Attraction');
      //   if (index > -1) {
      //     this.displayedColumns.splice(index, 1); 
      //   }
      // }
    }
    if(type == 'entity'){
      this.selected_neighbourhood ='';
      this.selected_block ='';
      this.blockList=[];
    }
    this.getCityDetails();
    this.getDetailsCount();
  }

  /**
   * 
   * @param searchTerm 
   * @returns 
   */
  getCityDetails(searchTerm?){
    if (!this.detail_access.GET) {
      this.notify.notify('You are not Authorized to VIEW City Wise Details','warn',4000);
      return;
    }
    this.loader=true;
    let url=`city-details?limit=${this.limit}&offset=${this.offset}&status=${this.status}`;
    if (searchTerm) {
      url+=`&title=${searchTerm}`;
    }
    if(this.selected_neighbourhood){
      url+=`&neigh_uid=${this.selected_neighbourhood}`;
    }
    if(this.selected_block){
      url+=`&block_uid=${this.selected_block}`;
    }
    if(this.selected_type){
      url+=`&type=${this.selected_type}`;
    }
    if(this.selected_entity){
      url += `&entity=${this.selected_entity}`
    }
    this.api.getData(url).subscribe({
      next: (res:any)=>{
      this.loader=false;
      if (res.data && res.status==200) {
        this.dataSource=res.data;
      } else{
        this.dataSource = [];
        this.notify.notify(res.message,'warn',4000);
      }
    },error : err=>{
      this.dataSource = [];
      this.loader = false;
      this.notify.notify(err.error.message,'error',5000);
    }})
  }

  /**
   * get total count
   */
  getDetailsCount(){
    let url = `city-details?status=${this.status}`;
    if(this.selected_type){
      url+=`&type=${this.selected_type}`;
    }
    if(this.selected_entity){
      url += `&entity=${this.selected_entity}`
    }
    this.api.getData(url).subscribe({
      next:(res: any) => {
      if (res && res.data?.length) {
        this.totalCount = res.data.length;        
        this.totalRecords = res.data;        
      }
    }, error:err => {
    }})
  }
  
    public selected_stat;
    openConfirmation(value) {
      if (!this.detail_access.DELETE) {
        this.notify.notify("You are not authorized to delete City Wise Detail", "warn");
        return;
      }
      this.selected_stat = value;
      let _data = {
        parent_data: this,
        message: 'Do you want to delete City Wise Detail?',
      }
      const dialogRef = this.dialog.open(ConfirmDialogBox, {
        width: '350px',
        data: _data
      });
      dialogRef.afterClosed().subscribe(result => {
        this.confirmDialogYes(result);
      });
    }


    //patch[Delete] API for /valuation-factor-master
    confirmDialogYes(confirm) {
      if (confirm == 'YES') {
        let body = {
          "id": this.selected_stat.id,
          "status": -1,
          "updated_by": this.api.user_id
        }
       
        this.api.patchData('city-details', body).subscribe({
          next :(data: any) => {
          if (data && data.status == 201) {
            // const activity={
            //   actionId: data.data,
            //   data: body,
            //   actionType:UserAction.delete_statistics.Action_ID
            // }
            // this.api.logUserActivity(activity);
            this.notify.notify("City Wise Detail Deleted Successfully", "success");
            this.getCityDetails();
            this.getDetailsCount();
          }
          
  
        }, error :err => {
          this.notify.notify("Unsuccessful City Wise Detail Deletion", "error")
        }})
      }
    }

  /**
   * This function is use to update status
   * @param id 
   * @param event 
   * @returns 
   */
  updateStatus(id, event) {
    if (!this.detail_access.PATCH) {
      this.notify.notify("You are not authorized to Update City Wise Detail", "warn");
      return;
    }
    let body: any = {
      "id": id,
      "status": (event.checked == true) ? 1 : 0,
      "updated_by": this.api.user_id,
    };
    this.loader = true;
    this.api.patchData('city-details', body).subscribe({
      next :(res: any) => {
      this.loader = false;
      if(res && res.status==201){
        // const activity={
        //   actionId: res.data,
        //   data: body,
        //   actionType:UserAction.update_statistics.Action_ID
        // }
        this.getCityDetails();
        // this.api.logUserActivity(activity);
        this.notify.notify(`${res.message}`, "success")
      }else{
        this.notify.notify(`${res.message}`, "warn")
      }
    }, error :err => {
      this.loader = false;
    }})
  }

    getLabel(key) {
      return key ? key.replace(/_/g, " ") : "";
    }
  

  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getCityDetails();
  }
  neighDetails(){
    this.api.getData(`neighbourhoods/details?get_geom=false&get_media=false&place_id=${this.api.city_id}&user_id=${this.api.user_id}`).subscribe((res: any) => {
      if(res.data?.length){
        let data = res.data;
        let list=[];
        data.map((ele:any)=>{
          let sd=ele;
          sd['neigh_name'] = ele.neigh_name['1']
          list.push(sd);
        })
        this.neighList=list;
      }
      
    })
  }
  getBlocks(value){
    this.api.getData(`blocks/details?neigh_uid=${value}&get_geom=false&get_media=false`).subscribe((res: any) => {
      if(res.data){
        res.data.map(e=>{
          e['order']= (e.block_name && e.block_name[this.api.language]) ? e.block_name[this.api.language] : '0'
        })
        this.blockList = res.data;
        this.blockList.sort(function(a, b) {
          return a.order - b.order;
        })
      }   
     this.getCityDetails()
    })
  }


  
}

