<div class="w-100 p-2 d-flex justify-content-center align-items-center">
  <mat-card appearance="outlined" class="m-2 mat-elevation-z6">
    <mat-card-header class="position-relative">
      <mat-card-title>
        Set Neighbourhood Marker
      </mat-card-title>
      <mat-progress-bar class="mtc-progress" *ngIf="loader" [color]="'primary'" mode="indeterminate">
      </mat-progress-bar>
    </mat-card-header>

    <mat-card-content class="overFlow">
      <section class="w-100">
        <h4 class="mat-h2">Select a Neighbourhood to Set Marker</h4>
        <mat-form-field class="mr-3">
          <mat-label>Select Neighborhood</mat-label>
          <mat-select [disabled]="loader" (selectionChange)="selectedNeigh($event)">
            <input class="searchIn" mat-input placeholder="Type here to search..." />
            <mat-option *ngFor="let neigh of neighborhoodsList | filter : 'neigh_name': searchText" [value]="neigh.neigh_uid">
              {{neigh.neigh_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button class="mr-3" mat-raised-button color="primary" [disabled]="loader" (click)="setMarker()">Set
          Marker</button>
      </section>
    </mat-card-content>
  </mat-card>
</div>
<div class="w-100 p-2 d-flex justify-content-center align-items-center">
  <mat-card appearance="outlined" class="m-2 w-100">
    <mat-card-header class="mtc-header pt-2 pb-2 pr-2 position-relative d-flex justify-content-between">
      <mat-card-title>
        Neighborhoods with Markers
      </mat-card-title>
    </mat-card-header>
    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
    <mat-card-content class="translist pr-2 pl-2 pb-2 pt-2">
      <div class="w-100 calc-h">
        <table mat-table [dataSource]="dataSource" class="w-100">
          <ng-container matColumnDef="sno">

            <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No </th>
            <td mat-cell *matCellDef="let element; let i=index">
              <span> {{offset + i + 1}} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Neighbourhood UID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Neighbourhood UID </th>
            <td mat-cell *matCellDef="let element">{{element.neigh_uid}} </td>
          </ng-container>

          <ng-container matColumnDef="Neighbourhood Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Neighbourhood Name </th>
            <td mat-cell *matCellDef="let element">{{this.showlabel(element,'neigh_name')}} </td>
          </ng-container>

          <ng-container matColumnDef="Total Properties">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Properties </th>
            <td mat-cell *matCellDef="let element">{{element.total_properties}} </td>
          </ng-container>

          <ng-container matColumnDef="No of Blocks">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> No of Blocks </th>
            <td mat-cell *matCellDef="let element">{{element.no_of_blocks}} </td>
          </ng-container>

          <ng-container ngFor matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <button mat-mini-fab color="warn" (click)="openConfirmation(element)" title="delete">
                <mat-icon class="delete_icon">delete</mat-icon>
              </button> </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <mat-paginator [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event; handlePagination($event)">
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>