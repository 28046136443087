<div fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
    <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="space-between center">
        <h4 class="open-sans mb-0 text-uppercase" style="color: #616474;">{{mode}} CSV Template
        </h4>
        <div fxLayout="row" fxLayoutAlign="end center">
        </div>
    </div>
    <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="button-b">
            <button *ngIf="mode=='add'" class="btn orange lean-radius" [disabled]="templateForm.invalid" (click)="saveTemplate(templateForm)">
                SUBMIT</button>
            <button *ngIf="mode=='edit'" class="btn orange lean-radius" [disabled]="templateForm.invalid" (click)="updateTemplate(templateForm)">
                UPDATE</button>
            <button class="btn lean-radius" style="background: white;border: 1px solid #9c9c9c !important;" (click)="parent.closeDialog()">
                CANCEL</button>
        </div>
    </div>
</div>
<div class="mat-loader">
    <mat-progress-bar *ngIf="loader" mode="indeterminate">
    </mat-progress-bar>
</div>
<div class="d-flex flex-column m-3">
    <form [formGroup]="templateForm">
        <mat-dialog-content>
            <div class="form-container">
                <div class="d-flex align-items-center">
                    <!-- file type -->
                    <div class="form-input-container d-flex flex-row mr-4">
                        <label class="font-weight-bold open-sans mb-0 d-flex pt-2 mr-4" style="font-size: 16px!important;">File Type</label>
                        <div class="d-flex flex-column">
                            <div class="form-group mb-1" fxFlex="70%">
                                <input [class.cursor-none]="mode=='edit'" [attr.disabled]="mode == 'edit' ? '' : null" type="text" [class.is-invalid]="fileType.invalid && fileType.touched || fileType.hasError('pattern')" class="form-control input-background h45p open-sans f-14" placeholder="Enter file Type"
                                    formControlName="fileType">
                            </div>
                            <div style="height: 20px;">
                                <small [class.d-none]="!fileType.hasError('required') || fileType.untouched" class="text-danger open-sans">File type is required</small>
                                <small [class.d-none]="!fileType.hasError('pattern')" class="text-danger open-sans">Special characters are not allowed!</small>
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <!-- table Name input -->
                    <div class="form-input-container d-flex flex-row mr-4">
                        <label class="font-weight-bold open-sans mb-0 d-flex pt-2 mr-4" style="font-size: 16px!important;">Table Name</label>
                        <div class="d-flex flex-column">
                            <div class="form-group mb-1" fxFlex="70%">
                                <input type="text" [class.is-invalid]="tableName.invalid && tableName.touched" class="form-control input-background h45p open-sans f-14" placeholder="Enter table Name" formControlName="tableName" name="tableName">
                            </div>
                            <div style="height: 20px;">
                                <small [class.d-none]="!tableName.hasError('required') || tableName.untouched" class="text-danger open-sans">Table name is Required</small>
                                <small [class.d-none]="!tableName.hasError('pattern')" class="text-danger open-sans">Special characters are not allowed!</small>
                            </div>
                        </div>
                    </div>
                    <!--  -->
                </div>
                <!-- column input -->
                <div class="form-input-container align-items-start d-flex flex-column">
                    <label class="font-weight-bold p-0 open-sans" style="font-size: 18px!important;padding-top: 2%;" fxFlex="15%">Column Name</label>
                    <div class="w-100 table-div" fxLayout="column" formArrayName="columnName">
                        <table #templateTable mat-table [dataSource]="selectedElement ? selectedElement.column_name:addElements.column_name" class="column-table w-100">
                            <ng-container *ngFor="let cols of displayedColumns">
                                <ng-container matColumnDef="{{cols}}">
                                    <th mat-header-cel *matHeaderCellDef>
                                        <span *ngIf="cols=='Name'">Name *</span>
                                        <span *ngIf="cols=='Data-Type'">Data Type *</span>
                                        <span *ngIf="cols=='Column Name'">Column Name *</span>
                                        <span *ngIf="cols=='Json Key'">Json Key</span>
                                        <span *ngIf="cols=='Ref. Table'">Ref. Table</span>
                                        <span *ngIf="cols=='Ref. Col. Name'">Ref. Col. Name</span>
                                        <span *ngIf="cols=='Ref. Condition Name'">Ref. Condition Name</span>
                                        <span *ngIf="cols=='Required'">Required</span>
                                        <span *ngIf="cols=='Action'">Action</span>
                                    </th>
                                    <ng-container *ngIf="selectedElement && selectedElement.column_name || addElements && addElements.column_name">
                                        <td mat-cell *matCellDef="let element;let i=index;let last=last">
                                            <div [formArrayName]="i">
                                                <ng-container *ngIf="cols=='Name'">
                                                    <div class="form-group mb-1">
                                                        <input id="name_{{i}}" value="{{element.name ?element.name:''}}" type="text" class="f-14 form-control input-background" formControlName="name" placeholder="Enter Name  ">
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="cols=='Data-Type'">
                                                    <div class="form-group mb-1 d-flex justify-content-center">
                                                        <mat-spinner *ngIf="dataTypeLoader" diameter="25" class="spin">
                                                        </mat-spinner>
                                                        <mat-select style="padding: 6%;
                                                        border: 1px solid #ced4da;
                                                        border-radius: 4px;" *ngIf="!dataTypeLoader" formControlName="data_type" placeholder="Select Data Type">
                                                            <mat-option *ngFor="let dataType of dataTypes" [value]="dataType.name">
                                                                {{dataType.label[api.language]}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="cols=='Column Name'">
                                                    <div class="form-group mb-1">
                                                        <input id="column-name_{{i}}" type="text" value="{{element.col_name ? element.col_name : ''}}" class="f-14 form-control input-background" formControlName="col_name" placeholder="Enter Column Name">
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="cols=='Json Key'">
                                                    <div class="form-group mb-1">
                                                        <input type="text" class="f-14 form-control input-background" formControlName="json_key" value="{{element.json_key ? element.json_key :''}}" placeholder="Enter Json Key">
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="cols=='Ref. Table'">
                                                    <div class="form-group mb-1">
                                                        <input id="ref_table_{{i}}" type="text" class="f-14 form-control input-background" formControlName="ref_table" [value]="element.refrence ? element.refrence.table :''" placeholder="Enter Ref. Table">
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="cols=='Ref. Col. Name'">
                                                    <div class="form-group mb-1">
                                                        <input type="text" class="f-14 form-control input-background" [value]="element.refrence ? element.refrence.col_name :''" formControlName="ref_col_name" placeholder="Enter Ref. Column">
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="cols=='Ref. Condition Name'">
                                                    <div class="form-group mb-1">
                                                        <input type="text" class="f-14 form-control input-background" [value]="element.refrence ? element.refrence.condition_name:''" formControlName="ref_condition" placeholder="Enter Ref. Condition Name">
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="cols=='Required'">
                                                    <div class="form-group mb-1 d-flex flex-column">
                                                        <mat-slide-toggle id="required_{{i}}" [checked]="element.required=='true'?true:false" formControlName="required">
                                                        </mat-slide-toggle>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="cols=='Action'">
                                                    <button type="button" mat-icon-button class="csv-icon-highlight-fix" (click)="removeTableRow(element,i)">
                                                        <img src="../../../../assets/images/svgicons/remove.svg" alt="">
                                                    </button>
                                                    <button *ngIf="last" type="button" class="csv-icon-highlight-fix" mat-icon-button (click)="addTableRow(i)">
                                                        <img class="add" src="../../../../assets/images/svgicons/add.svg"
                                                            alt="">
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </td>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
                        </table>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </form>
</div>