<div *ngIf="!toggle">
    <div fxHide="true" fxShow.gt-sm="true" fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">

        <div fxLayout="row" class="w-100" fxLayoutAlign="start center">
            <label class="main-heading mt-2">Statistics Information</label>
            <!-- <div class="d-flex justify-content-between align-center">
                <mat-select class="btn-upload" placeholder="UPLOAD TYPE">
                    <mat-option>Parcel Shape</mat-option>
                    <mat-option>Others</mat-option>
                </mat-select>
            </div> -->
        </div>

        <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="button-b mr-4">
                <button mat-button class="blue-button" (click)='action()'>
                    ADD STATISTICS</button>
            </div>
            
        </div>
    </div>
    <div fxLayout="row" class="w-100 mt-3" fxLayoutAlign="start center">
        <label class="main-heading">Total Count 100</label>
    </div>
    <div class="table-responsive mt-4">
        <table class="table table-bordered">
            <thead class="heading">
                <tr>
                    <th>  S. No.</th>
                    <th>Title</th>
                    <th>Type of Information</th>
                    <th>Data</th>
                    <th>Entity</th>
                    <th>Status</th>
                    <th>ACTION</th>
                </tr>
            </thead>
            <tbody class="table-data">
                <tr>
                    <td><span class="text-right"><img src="/assets/icon/move.svg">  <span class="ml-4">1.</span></span></td>
                    <td>Area overview</td>
                    <td>Area overview</td>
                    <td class="data-svg"><img src="/assets/icon/Group 2164.svg"></td>
                    <td>Abu Fatira, Block 4</td>
                    <td> <img src="/assets/icon/Group 1171.svg"></td>
                    <td>
                        <span class="mr-3"><img src="/assets/icon/Edit.svg"></span>
                        <img src="/assets/icon/Group 1034.svg">
                              
                         
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <mat-paginator [length]="100" class="d-flex justify-content-start"
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator>
<!-- <div class="has-text-centered">
    <pagination-controls (pageChange)="page = $event"></pagination-controls>
</div> -->
</div>


<div *ngIf="toggle">
<app-city-wise-info (back)="action()"></app-city-wise-info>
</div>