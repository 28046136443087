<div class="view-data">
    <div class="mtc-header">
        <span>{{heading?.title}}
            <span *ngIf="heading.entity == 'neighbourhood' || heading.entity == 'block'"> | {{heading.neigh_name}}</span>
            <span *ngIf="heading.entity == 'block'"> | Block {{heading.block_name}}</span> 
        </span>
        <div class="d-flex">
            <button class="save" (click)="savedata()" [disabled]="!isDraged">Save</button>
            <button class="cancel" (click)="canceled()">Cancel</button>
        </div>
    </div>
    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
    <div class="row m-0" style="max-height: 80vh; overflow: auto;">
        <div class="col-12">
            <mat-select class="mt-2" placeholder="Select Language" (selectionChange)="selectedLang($event.value)" [(ngModel)]="lang">
                <mat-option *ngFor="let language of language_list" [value]="language.language_id">
                    {{ language.name }}
                </mat-option>
            </mat-select>
        </div>
        <div class="col-6">
            <div class="card-view">
                <div class="title" *ngIf="selectedType=='details'">Data</div>
                <div class="title" *ngIf="selectedType=='trends'">Analytics</div>
                <div  class="hover-data">
                    <div class="d-flex align-items-center justify-content-between font-weight-bold">
                        <span class="ml-2">Title</span>
                        <span class="mr-2">Value</span>
                    </div>
                    <div cdkDropList  (cdkDropListDropped)="dropData($event)"  [cdkDropListDisabled]="dragDisabled">                        
                        <div class="d-flex justify-content-between align-items-center bb" *ngFor="let item of datalist" cdkDrag>
                            <div class="data-placeholder" *cdkDragPlaceholder></div>
                            <span class="ml-2 mt-2">
                                <img width="18" matTooltip="Drag me" (mousedown)="dragDisabled = false;" style="cursor: move; margin: 0 10px 0 0;"
                                                            src="assets/icon/drag.svg" alt="">
                                {{item.title}}
                            </span>
                            <span class="mr-2 mt-2">{{item.value}} </span>
                        </div>
                    </div>               
                </div>
            </div>
        </div>
        <div class="col-6" *ngIf="selectedType=='trends'">
            <div class="card-view">
                <div class="title">Key Attraction</div>
                <div class="hover-data">
                    <div class="d-flex align-items-center justify-content-between font-weight-bold">
                        <span class="ml-2">Title</span>
                        <span class="mr-2">Value</span>
                    </div>
                    <div cdkDropList  (cdkDropListDropped)="dropKey($event)"  [cdkDropListDisabled]="dragDisabled">                        
                        <div class="d-flex justify-content-between align-items-center bb" *ngFor="let item of keyAttrctionList" cdkDrag>
                            <div class="data-placeholder" *cdkDragPlaceholder></div>
                            <span class="ml-2 mt-2">
                                <img width="18" matTooltip="Drag me" (mousedown)="dragDisabled = false;" style="cursor: move; margin: 0 10px 0 0;"
                                                            src="assets/icon/drag.svg" alt="">{{item.title}}</span>
                            <span class="mr-2 mt-2">{{item.value}} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="selectedType=='trends'">
            <div class="card-view">
                <div class="title">Images</div>
                <div cdkDropList  (cdkDropListDropped)="dropImages($event)"  [cdkDropListDisabled]="dragDisabled">
                    <div class="card-view" *ngFor="let obj of imagesList" cdkDrag>
                        <div class="custom-placeholder" *cdkDragPlaceholder></div>
                        <div class="font-weight-bold mb-2">
                            <img width="18" matTooltip="Drag me" (mousedown)="dragDisabled = false;" style="cursor: move; margin: 0 10px 0 0;"
                                                            src="assets/icon/drag.svg" alt="">
                                                            {{obj.category}}</div>
                        <div class="img-view">
                            <ng-container *ngFor="let img of obj?.images">
                                <img src="{{img}}" alt="img">
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>