<div class="row w-100">
  <div class="col-md-3 pr-0  ">
    <mat-card appearance="outlined" class="m-2 w-100">
      <mat-card-header>
        <mat-card-title>Block valuation data</mat-card-title>
        <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
      </mat-card-header>
      <mat-card-content class="d-flex flex-column justify-content-center align-items-center">
        <mat-form-field  class="w-90">
          <mat-label>Select a type of data</mat-label>
          <mat-select (selectionChange)="selectValuationType($event)" matNativeControl required>
            <mat-option *ngFor="let type of valuationTypes" [value]="type.value">{{type.label}}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <button *ngIf="selected" mat-raised-button color="primary" (click)="download(selected)">Download All</button> -->
        <!-- <angular2csv *ngIf="selected" [data]="completeData" filename="Valuation_data" [options]="options"></angular2csv> -->
        <button (click)="download()" *ngIf="selected" style="bottom: 10px;position: relative;">Download</button>
      </mat-card-content>
      <!-- <mat-card-actions>
       
      </mat-card-actions> -->
    </mat-card>
  </div>
  <div class="col-md-9 pr-0">
    <mat-card appearance="outlined" class="m-2 w-100">
      <mat-card-header>
        <mat-card-title>
          Result <span class="mr-2" *ngIf="selected">for {{selected}}</span>
          <!-- <small *ngIf="count">Showing {{(count < limit)? count: limit}} of {{count}}</small> -->
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="overFlow">
        <div class="loader_view" *ngIf="loader">
          <div class="load-cont">
            <h3 class="mat-h3 text-center">Loading...</h3>
            <mat-progress-bar [color]="'primary'" mode="indeterminate">
            </mat-progress-bar>
          </div>
        </div>
        <div class="w-100 text-center" *ngIf="dataList.length == 0 && !loader">
          <h1 class="mat-h2 text-center">Select valuation type to view data</h1>
        </div>
        <mat-list cols="3" class="m-1" role="list" *ngIf="dataList.length > 0 && !loader">
          <mat-list-item *ngFor="let data of dataList" role="listitem">{{data.block_uid}}</mat-list-item>
        </mat-list>
      </mat-card-content>
      <mat-paginator #paginator [length]="count" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
      </mat-paginator>
    </mat-card>
  </div>
</div>