import { Injectable } from '@angular/core';
import { ApiserviceService } from '../apiservice.service';

@Injectable()
export class CommonfunctionService {
  setUserLocationData;

  constructor(private api: ApiserviceService) {
    //this.checkResourceAccess('attributegroups','GET',false);

  }

  // return index of oblect from object array, return number
  getArrayindex(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach((obj, k) => {
        if (obj[key] == val && flag) {
          res = k;
          flag = false;
        }
      });
    }
    return res;
  }

  // extract value by key from object array, return object
  getArrayValue(key: any, val: any, arrayObj: any): any {
    let res = {};
    let flag = true;
    arrayObj.forEach(obj => {
      if (obj[key] == val && flag) {
        res = obj;
        flag = false;
      }
    });
    return res;
  }

  // get array of child element from parent
  getChildArray(key: any, val: any, arrayObj: any): any {
    let res: any = [];
    let i = 0;
    arrayObj.forEach(obj => {
      if (obj[key] == val) {
        res[i] = obj;
        i++;
      }
    });
    return res;
  }

  // remove particular value from array of object
  getDeletedArray(key: any, val: any, arrayObj: any): any {
    if (Array.isArray(val)) { // accepting both array and single value
      val = val;
    } else {
      val = [val];
    }
    let res: any = [];
    let i = 0;
    if (arrayObj) {
      arrayObj.forEach(obj => {
        if (val.indexOf(obj[key]) == -1) {
          res[i] = obj;
          i++;
        }
      });
    }
    return res;
  }

  jsonLength(obj) {
    return Object.keys(obj).length;
  }
  //accepting only date Object
  formatDate(value) {
    value = new Date(value);
    return value.getFullYear() + "-" + (value.getMonth() + 1).toString().padStart(2, 0) + "-" + value.getDate().toString().padStart(2, 0);
  }
 //accepting only date Object
 formatViewDate(value) {
  value = new Date(value);
  return  value.getDate().toString().padStart(2, 0) + "-" + (value.getMonth() + 1).toString().padStart(2, 0) + "-" + value.getFullYear();
}
  // copy json object without reference
  deepCopyJson(obj) {
    let copy = obj, k;

    if (obj && typeof obj === 'object') {
      copy = Object.prototype.toString.call(obj) === '[object Array]' ? [] : {};
      for (k in obj) {
        //copy[k] = this.deepCopyJson(obj[k]);
        copy[k] = obj[k];
      }
    }

    return copy;
  }

  getErrorMessage(err) {
    let msg = err.error;
    //console.log(msg);
    return msg.message;
  }

  /**
   * checkResourceAccess function checking resources Access
   * @param name
   * @returns resource
   */
  checkResourceAccess(name, flag = true) {
    let resource = {
      GET: false,
      PATCH: false,
      POST: false,
      DELETE: false
    }
    if (flag) {
      //this.api.getAllowResources(); //call the api again
    }
    let resources = this.api.allowResources;
    if (resources && resources.length) {
      resources.forEach(element => {
        if (element.resource_name == name) {
          if (element.methods.indexOf('GET') != -1) {
            resource.GET = true;
          }
          if (element.methods.indexOf('POST') != -1) {
            resource.POST = true;
          }
          if (element.methods.indexOf('PATCH') != -1) {
            resource.PATCH = true;
          }
          if (element.methods.indexOf('DELETE') != -1) {
            resource.DELETE = true;
          }
        }
      });
    }
    return resource;
  }

  assignmentSortOrder(data) {
    var newdata = [];
    data.forEach(element => {
      element.name = element.label[this.api.language];
      newdata.push(element);
    });
    newdata.sort(this.GetSortOrder("name"));
    return newdata;
  }
  neighborhoodSortOrder(data) {
    var newdata = [];
    let neigh_keys;
    data.forEach(element => {
      // neigh_keys = Object.keys(element.neigh_name)[0];
      element.name = (element.neigh_name[this.api.language] == undefined || element.neigh_name[this.api.language] == '') ? element.neigh_name[1] : element.neigh_name[this.api.language];
      if (element.name) newdata.push(element);
    });
    newdata.sort(this.GetSortOrder("name"));
    return newdata;
  }

    /**
   * GetSortOrder Function for shorting orders
   * @param prop
   * @returns uint
   */
  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
        return 1;
      } else if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
        return -1;
      }
      return 0;
    }
  }
  formatDateUTC(dt: any, getTime: boolean = false) {
    let value;
    dt = new Date(dt);
    value = new Date(dt + ' UTC');
    if (getTime) {
      return value.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) + " " + value.getHours().toString().padStart(2, 0) + ":"
        + value.getMinutes().toString().padStart(2, 0) + ":" + value.getSeconds().toString().padStart(2, 0);
    } else {
      return (
        value.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
      );
    }
  }

  // duplicate value from array
  removeDuplicateKey(arr: Array<any>) { // not in use
    let uniqueArray = [];
    arr.forEach(function (element) {
      if (uniqueArray.indexOf(element) === -1) {
        uniqueArray.push(element);
      }
    });
    return uniqueArray;
  }



  downloadFile(filename, csvData) {

    let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8,%EF%BB%BF' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + "_" + (+new Date) + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);

  }

  setCookie(cname: any, cvalue: any, exdays: any) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  getCookie(cname: any) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  camelizeAll(str) {
    const arr = str.split('_');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(' ');
    return str2;
  }
  capitalize_first_char(str) {
    const str2 = str
      ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
      : str;
    return str2;
  }

  // check JSON object empty
  isJsonEmpty(element){
    if(element){
      return Object.keys(element).length > 0 ? false: true;
    }
    return true;
  }

  ConvertToCSV(headerList) {
    let str = '';
    let row = '';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    return str;
  }

  removeUnderscores(value) {
    if (value) {
      value = value.toString().trim();
      value = value.toLowerCase();
      let name = value.replace(/_/g, ' ');
      return this.camelizeAll(name);
    } else {
      return '';
    }
  }
}
