<table class="table">
    <tr>
        <td><span>Corner</span> <span>1</span>    </td>
        <td><span>No of Streets Access</span><span>2</span> </td>
    </tr>
    <tr>
        <td><span>Setback</span><span>Yes</span></td>
        <td><span>South Frontage</span><span>10M</span></td>
    </tr>
    <tr>
        <td><span>East Frontage</span><span>20M</span></td>
        <td></td>
    </tr>
</table>