import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ImageCroppedEvent , base64ToFile } from 'ngx-image-cropper';
import { ApiserviceService } from '../../../../app/apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { ImageEditorComponent } from '../../image-editor/image-editor.component';
import { NotificationService } from '../../services/notification.service';
import { UserAction } from "../../user-action/user-activity";
@Component({
  selector: "app-add-city-wise-details",
  templateUrl: "./add-city-wise-details.component.html",
  styleUrls: ["./add-city-wise-details.component.scss"],
})
export class AddCityWiseDetailsComponent implements OnInit {
  @Output() back = new EventEmitter();
  @Input() action_type: string;
  @Input() update_date: any;
  @Input() selectedType: string;
  public loader = false;
  public showRemove = false;
  public blocksList: any = [];
  public selectedBlock;
  public searchText: string = "";
  public statsForm: any = [];
  public neighborhoodsList: any = [];
  public entity = [
    {
      name: "City",
      value: "city",
    },
    {
      name: "Neighbourhood",
      value: "neighbourhood",
    },
    {
      name: "Block",
      value: "block",
    },
  ];
  // public type_info = [
  //   {
  //     name: 'City Information',
  //     value: 'city_info'
  //   },
  //   {
  //     name: 'Block Area Overview',
  //     value: 'block_area_overview'
  //   }]
  // public selectedEntity: any = 'neighbourhood';
  public keysSet: Array<{}> = [];
  public valuesSet: Array<{}> = [];

  public categoryTitleSet: Array<{}> = [];
  // public categoryFileSet: Array<{}> = [];

  public images: Array<{}> = [];
  public imageUrls = {};
  public filenNme: any;
  public formData: any;
  public dialogReff:any;
  public dialogReference:any;
  public imgChangeEvt: any = "";
  public cropImgPreview: any = "";
  public rowIndex:any;

  public attrkeysSet: Array<{}> = [];
  public attrvaluesSet: Array<{}> = [];

  public language_list: any = [];
  public selected_formlng: any;
  dragDisabled: boolean = true;

  get neighborhood(): AbstractControl {
    return this.statsForm.get("neighborhood");
  }
  get blockData(): AbstractControl {
    return this.statsForm.get("block");
  }
  get title(): AbstractControl {
    return this.statsForm.get("title");
  }
  get metaTitle(): AbstractControl {
    return this.statsForm.get("meta_title");
  }
  get metaDescription(): AbstractControl {
    return this.statsForm.get("meta_description");
  }
  get Slug(): AbstractControl{
    return this.statsForm.get("slug");
  }

  constructor(
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private notify: NotificationService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
  ) {}
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '3rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    enableToolbar: false,
    showToolbar: true,
    defaultParagraphSeparator: 'p',
    toolbarHiddenButtons: [
      ['undo', 'redo', 'strikeThrough', 'subscrit', 'fontName'],
      [
        'fontSize',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],
  }
  ngOnInit(): void {
    if (this.action_type == "add") {
      this.getLanguages();
      this.initForm();
    }
    if (this.action_type == "edit") {
      this.initForm();
      this.getLanguages();
      this.patchFormData(this.update_date);
    }
  }

  getLanguages() {
    this.api.getUmsData("language").subscribe((res: any) => {
      if (res && res.data) {
        res.data.forEach((element) => {
          if (element.status == 1) {
            this.language_list.push(element);
          }
        });
      }
    });
  }

  selectedLang(lng_id) {
    if (lng_id) {
      this.selected_formlng = lng_id;
      if (this.action_type == "edit") {
        this.patchFormLangDep(this.update_date);
      }
    }
  }

  get statsFormControl() {
    return this.statsForm.controls;
  }

  initForm() {
    this.statsForm = this.fb.group({
      keys: this.fb.array([]),
      value: this.fb.array([]),
      formLanguage: ["", Validators.required],
      infoType: [""],
      title: [""],
      slug: [""],
      meta_title: ["", Validators.required],
      description: [""],
      meta_description: ["", Validators.required],
      entity: ["", Validators.required],
      neighborhood: [""],
      block: [""],
      attrKeys: this.fb.array([]),
      attrValues: this.fb.array([]),

      categorys: this.fb.array([]),
    });
    if (this.action_type == "add") {
      this.addRow();
      if (this.selectedType == "trends") {
        this.addAttrRow();
        this.addCategorysRow();
      }
    }
    if (this.selectedType == "trends") {
      this.statsForm.get('meta_title').clearValidators();
      this.statsForm.get('meta_description').clearValidators();
      this.statsForm.get('meta_title').updateValueAndValidity();
      this.statsForm.get('meta_description').updateValueAndValidity();
    } 
  }

  patchFormData(data) {
    let keys: any = [],
      value = [],
      keyValuePair: any = [],
      index: number = 0,
      categorys = [];
    let attrkeys: any = [],
      attrvalue = [],
      attrkeyValuePair: any = [],
      atrrindex: number = 0;
    if (data.data) {
      keyValuePair =
        data.data && data.data[this.api.language]
          ? data.data[this.api.language]
          : data.data[1];
      for (let i = 0; i<keyValuePair.length; i++) {
        keys.push({ keys: keyValuePair[i].title });
        value.push({ value: keyValuePair[i].value });
        this.addRow();
      }
      if (this.selectedType == "trends") {
        attrkeyValuePair =
          data.key_attraction && data.key_attraction[this.api.language]
            ? data.key_attraction[this.api.language]
            : data.key_attraction[1];
        if (attrkeyValuePair && attrkeyValuePair.length > 0) {
          for (let x = 0; x<attrkeyValuePair.length; x++) {
            attrkeys[x] = { attrKeys: attrkeyValuePair[x].title };
            attrvalue[x] = { attrValues: attrkeyValuePair[x].value };
            this.addAttrRow();
          }
        } else {
          this.addAttrRow();
        }

        if (data.images && data.images.length > 0) {
          this.images = [];
          this.images = data.images;
          this.imageUrls = {};
          let imageUrls;
          for (let rowIndex = 0; rowIndex < this.images.length; rowIndex++) {
            if (!this.imageUrls.hasOwnProperty(rowIndex)) {
              imageUrls = this.imageUrls[rowIndex] = [];
            } else {
              imageUrls = this.imageUrls[rowIndex];
            }
            categorys[rowIndex] = {
              categorys: this.images[rowIndex]["category"],
            };
            for (
              let jj = 0;
              jj < this.images[rowIndex]["images"].length;
              jj++
            ) {
              imageUrls.push(this.images[rowIndex]["images"][jj]);
            }

            this.addCategorysRow();
          }
        } else {
          this.addCategorysRow();
        }
      }
    }

    setTimeout(() => {
      this.statsForm.patchValue({
        formLanguage: 1,
        infoType: data.info_type,
        slug: data.slug,
        title:
          data.title && data.title[this.api.language]
            ? data.title[this.api.language]
            : data.title && data.title[1]
            ? data.title[1]
            : data.title && data.title[2]
            ? data.title[2]
            : "",
          description:
            data.description && data.description[this.api.language]
            ? data.description[this.api.language]
            : data.description && data.description[1]
            ? data.description[1]
            : data.description && data.description[2]
            ? data.description[2]
            : "",
            meta_title:
            data.meta_title && data.meta_title[this.api.language]
              ? data.meta_title[this.api.language]
              : data.meta_title && data.meta_title[1]
              ? data.meta_title[1]
              : data.meta_title && data.meta_title[2]
              ? data.meta_title[2]
              : "",
          meta_description:
            data.meta_description && data.meta_description[this.api.language]
              ? data.meta_description[this.api.language]
              : data.meta_description && data.meta_description[1]
              ? data.meta_description[1]
              : data.meta_description && data.meta_description[2]
              ? data.meta_description[2]
              : "",
        entity: data.entity,
        neighborhood: data.neigh_uid,
        block: data.block_uid,
        keys: keys,
        value: value,
        attrKeys: attrkeys,
        attrValues: attrvalue,
        categorys: categorys,
      });
      this.getSelectedEntityValue(data.entity, data.neigh_uid);
    }, 100);
  }

  patchFormLangDep(data) {
    let statsform_keys = this.statsForm.controls.keys.value,
      statsform_value = this.statsForm.controls.value.value;
    if (
      statsform_keys.length &&
      statsform_value.length &&
      statsform_value.length == statsform_keys.length
    ) {
      for (var i = 0; i < statsform_keys.length + 1; i++) {
        this.removeAll(i);
      }
    }
    let attract_keys = this.statsForm.controls.attrKeys.value,
    attract_value = this.statsForm.controls.attrValues.value;
    if (
      attract_keys.length &&
      attract_value.length &&
      attract_value.length == attract_keys.length
    ) {
      for (var i = 0; i < attract_keys.length + 1; i++) {
        this.removeAttrAll(i);
      }
    }
    let keys: any = [],
      value = [],
      keyValuePair: any = [],
      index: number = 0,
      categorys = [];
    let attrkeys: any = [],
      attrvalue = [],
      attrkeyValuePair: any = [],
      atrrindex: number = 0;
    if (data.data) {
      keyValuePair =
        data.data && data.data[this.selected_formlng]
          ? data.data[this.selected_formlng]
          : data.data[1]
          ? data.data[1]
          : data.data[2];
        for (let i = 0; i<keyValuePair.length; i++) {
          keys.push({ keys: keyValuePair[i].title });
          value.push({ value: keyValuePair[i].value });
          this.addRow();
        }
    }
    if (this.selectedType == "trends") {
      attrkeyValuePair =
        data.key_attraction && data.key_attraction[this.selected_formlng]
          ? data.key_attraction[this.selected_formlng]
          : data.key_attraction[1]
          ? data.key_attraction[1]
          : data.key_attraction[2];
      if (attrkeyValuePair && attrkeyValuePair.length > 0) {
        for (let x = 0; x<attrkeyValuePair.length; x++) {
          attrkeys[x] = { attrKeys: attrkeyValuePair[x].title };
          attrvalue[x] = { attrValues: attrkeyValuePair[x].value };
          this.addAttrRow();
        }
      }
      if (data.images && data.images.length > 0) {
        this.images = [];
        this.images = data.images;
        this.imageUrls = {};
        let imageUrls;
        for (let rowIndex = 0; rowIndex < this.images.length; rowIndex++) {
          if (!this.imageUrls.hasOwnProperty(rowIndex)) {
            imageUrls = this.imageUrls[rowIndex] = [];
          } else {
            imageUrls = this.imageUrls[rowIndex];
          }
          categorys[rowIndex] = {
            categorys: this.images[rowIndex]["category"],
          };
          for (let jj = 0; jj < this.images[rowIndex]["images"].length; jj++) {
            imageUrls.push(this.images[rowIndex]["images"][jj]);
          }
          this.addCategorysRow();
        }
      } else {
        this.addCategorysRow();
      }
    }

    setTimeout(() => {
      
      this.statsForm.patchValue({
        // formLanguage:
        infoType: data.info_type,
        slug: data.slug,
        title:
        data.title && data.title[this.selected_formlng]
        ? data.title[this.selected_formlng]
        : data.title && data.title[1]
        ? data.title[1]
        : data.title && data.title[2]
        ? data.title[2]
        : "",
        description:
          data.description && data.description[this.selected_formlng]
          ? data.description[this.selected_formlng]
          : data.description && data.description[1]
          ? data.description[1]
          : data.description && data.description[2]
          ? data.description[2]
          : "",
        meta_title:
          data.meta_title && data.meta_title[this.selected_formlng]
            ? data.meta_title[this.selected_formlng]
            : data.meta_title && data.meta_title[1]
            ? data.meta_title[1]
            : data.meta_title && data.meta_title[2]
            ? data.meta_title[2]
            : "",
        meta_description:
          data.meta_description && data.meta_description[this.selected_formlng]
            ? data.meta_description[this.selected_formlng]
            : data.meta_description && data.meta_description[1]
            ? data.meta_description[1]
            : data.meta_description && data.meta_description[2]
            ? data.meta_description[2]
            : "",
        entity: data.entity,
        neighborhood: data.neigh_uid,
        block: data.block_uid,
        keys: keys,
        value: value,
        attrKeys: attrkeys,
        attrValues: attrvalue,
        categorys: categorys,
      });
      if(this.selected_formlng != '1'){
        this.statsForm.controls['slug'].disable();
      }else{
        this.statsForm.controls['slug'].enable();
      }
      this.getSelectedEntityValue(data.entity, data.neigh_uid);
    });
  }

  getSelectedEntityValue(entity, neigh_uid?, flag = false) {
    let entity_name = entity.value ? entity.value : entity;

    // this.selectedEntity = entity_name;
    if (entity_name == "city") {
      this.neighborhood.disable();
      this.blockData.disable();
      this.statsForm.patchValue({
        slug:''
      })
      this.statsForm.controls['slug'].disable();
    }

    if (entity_name == "neighbourhood") {
      this.neighborhood.enable();
      this.getNeighborhoods();
      this.statsForm.controls['slug'].enable();
      this.neighborhood.setValidators([Validators.required]);
      this.Slug.setValidators([Validators.required]);
      this.blockData.disable();
    } else {
      this.neighborhood.setValidators(null);
    }
    if (entity_name == "block") {
      this.neighborhood.enable();
      this.blockData.enable();
      this.getNeighborhoods();
      this.getBlocks(neigh_uid);
      this.statsForm.patchValue({
        slug:''
      })
      this.statsForm.controls['slug'].disable();
      this.neighborhood.setValidators([Validators.required]);
      this.blockData.setValidators([Validators.required]);
    } else {
      this.blockData.setValidators(null);
    }
    this.neighborhood.updateValueAndValidity();
    this.blockData.updateValueAndValidity();

    if (flag) {
      this.statsForm.patchValue({
        neighborhood: "",
        block: "",
      });
    }
  }

  getNeighborhoods() {
    this.neighborhoodsList = [];
    this.loader = true;
    this.api.getData(`neighbourhoods/details?limit=1000&offset=0`).subscribe(
      (res: any) => {
        this.loader = false;
        if (!res.data?.length) return;
        let sortedList = this.fnc.neighborhoodSortOrder(res.data);
        sortedList.map((item: any) => {
          this.neighborhoodsList.push({
            neigh_id: item.neigh_id,
            neigh_uid: item.neigh_uid,
            neigh_name: item.neigh_name[this.api.language]
              ? item.neigh_name[this.api.language]
              : item.neigh_uid,
            no_of_blocks: item.no_of_blocks,
          });
        });
      },
      (err) => {
        this.loader = false;
      }
    );
  }

  getBlocks(neigh_uid) {
    if (!neigh_uid) return;
    let neighborhood_uid = neigh_uid.value ? neigh_uid.value : neigh_uid;
    this.blocksList = [];
    this.selectedBlock = null;
    this.loader = true;
    this.api.getData(`blocks/details?neigh_uid=${neighborhood_uid}`).subscribe(
      (res: any) => {
        this.loader = false;
        if (!res.data) return;
        res.data.map((item: any) => {
          this.blocksList.push({
            block_uid: item.block_uid,
            block_name: item.block_name[this.api.language]
              ? item.block_name[this.api.language]
              : item.block_uid,
          });
        });
      },
      (err) => {
        this.loader = false;
      }
    );
  }

  initkeysFields() {
    return this.fb.group({
      keys: [""],
    });
  }

  initValueFields() {
    return this.fb.group({
      value: [""],
    });
  }

  initCategoryTitleFields() {
    return this.fb.group({
      categorys: [""],
      files: [""],
    });
  }

  addStatistics(val) {
    if (this.statsForm.valid) {
      if (this.selected_formlng == undefined) {
        this.selected_formlng = this.api.language;
      }
      let data = val.value;

      let stat_data = [],
        attr_stat_data = [],
        title = {},
        desc = {},
        langdep_data = {},
        meta_description={},
        meta_title={},
        langAttractionData = null;
      title[this.selected_formlng] = data.title;
      desc[this.selected_formlng] = data.description;
      meta_title[this.selected_formlng] = data.meta_title;
      meta_description[this.selected_formlng] = data.meta_description;

      data.keys.forEach((key, k) => {
        stat_data.push({'title':key.keys, 'value':data.value[k].value, 'order': k});
      });

      langdep_data[this.selected_formlng] = stat_data;
      if (this.selectedType == "trends") {
        langAttractionData = {};
        data.attrKeys.forEach((key, k) => {
          attr_stat_data.push({'title':key.attrKeys, 'value':data.attrValues[k].attrValues, 'order': k});
        });
        langAttractionData[this.selected_formlng] = attr_stat_data;
      }
      let slug = data.slug ? data.slug.trim().replace(/\ /g, "-").toLowerCase():'';
      let url = `city-details`;
      let body = {
        title: title,
        slug: slug,
        meta_title: meta_title,
        meta_description: meta_description,
        data: langdep_data,
        key_attraction: langAttractionData,
        images: this.images,
        info_type: this.selectedType,
        description: desc,
        neigh_uid: data.neighborhood,
        block_uid: data.block,
        entity: data.entity,
        status: 1,
        added_by: this.api.user_id,
      };
      // const activity = {
      //   neigh: data?.neighborhood,
      //   block: data?.block,
      //   actionId: data?.neighborhood,
      //   data: body,
      //   actionType: UserAction.add_statistics.Action_ID,
      // };
      this.loader = true;
      this.api.postData(url, body).subscribe({
        next :(res: any) => {
          this.loader = false;
          if (res && res.status == 201) {
            // this.api.logUserActivity(activity);
            this.notify.notify(res.message, "success", 3000);
            this.back.emit("update-list");
          } else {
            this.notify.notify(res.message, "warn", 3000);
          }
        },
        error :(err) => {
          this.loader = false;
          this.notify.notify("City Wise Detail Addition Failure", "error");
        }
      });
    } else {
      this.notify.notify("Fill All Required Fields", "warn", 3000);
      return;
    }
  }

  updateValuation(val) {
    if (this.statsForm.valid) {
      let updateData = val.value;
      if (this.selected_formlng == undefined) {
        this.selected_formlng = updateData.formLanguage;
      }
      let langdep_data = {},
        stat_data = [],
        title = {},
        desc = {},
        meta_description={},
        meta_title={},
        attr_stat_data = [],
        langAttractionData = null;
      title[this.selected_formlng] = updateData.title;
      desc[this.selected_formlng] = updateData.description;
      meta_title[this.selected_formlng] = updateData.meta_title;
      meta_description[this.selected_formlng] = updateData.meta_description;

      updateData.keys.forEach((key, k) => {
        if (key.keys) {
          stat_data.push({'title':key.keys, 'value':updateData.value[k].value, 'order': k});
        }
      });
      langdep_data[this.selected_formlng] = stat_data;
      if (this.selectedType == "trends") {
        langAttractionData = {};
        updateData.attrKeys.forEach((key, k) => {
          if (key.attrKeys) {
            attr_stat_data.push({'title':key.attrKeys, 'value':updateData.attrValues[k].attrValues, 'order': k});
          }
        });
        langAttractionData[this.selected_formlng] = attr_stat_data;
      }
      let slug = updateData.slug ? updateData.slug.trim().replace(/\ /g, "-").toLowerCase():'';
      let url = `city-details`;
      let body = {
        id: this.update_date.id,
        title: title,
        slug: slug,
        meta_title: meta_title,
        meta_description: meta_description,
        data: langdep_data,
        key_attraction: langAttractionData,
        info_type: this.selectedType,
        description: desc,
        images: this.images,
        // "govern_uid": "string",
        neigh_uid: updateData.neighborhood,
        // "property_uid": "string",
        block_uid: updateData.block,
        entity: updateData.entity,
        status: 1,
        updated_by: this.api.user_id,
      };

      // const activity = {
      //   neigh: updateData?.neighborhood,
      //   block: updateData?.block,
      //   actionId: updateData?.neighborhood,
      //   data: body,
      //   actionType: UserAction.add_statistics.Action_ID,
      // };
      this.loader = true;
      this.api.patchData(url, body).subscribe({
       next: (res: any) => {
          this.loader = false;
          if (res && res.status == 201) {
            // this.api.logUserActivity(activity);
            this.notify.notify(res.message, "success", 3000);
            this.back.emit("update-list");
          } else {
            this.notify.notify(res.message, "warn", 3000);
          }
        },
        error :(err) => {
          this.loader = false;
          this.notify.notify("City Detail Updation Error", "error");
        }
      });
    } else {
      this.notify.notify("Fill All Required Fields", "warn", 3000);
      return;
    }
  }
  // upload image and return url
  uploadImg(rowIndex) {
    // preapre data to show uplodaded Image
    let imageUrls;
    if (!this.imageUrls.hasOwnProperty(rowIndex)) {
      imageUrls = this.imageUrls[rowIndex] = [];
    } else {
      imageUrls = this.imageUrls[rowIndex];
    }
    this.imageUrls[rowIndex]["loader"] = true;
    // prepare data to save in DB
    if (this.images.length === rowIndex) {
      this.images[rowIndex] = {};
    }
    let images;
    if (!this.images[rowIndex].hasOwnProperty("images")) {
      images = this.images[rowIndex]["images"] = [];
    } else {
      images = this.images[rowIndex]["images"];
    }
    if (images.length > 9) {
      this.notify.notify(
        "You can only upload a maximum of 10 Images",
        "warn",
        1000
      );
      return;
    }

    return new Promise((resolve, reject) => {
      this.loader = true;
      try {
        this.api
          .postImageFile(
            `uploads/images?folder_name=trendsImages`,
            this.formData
          )
          .subscribe(
            (res: any) => {
              this.loader = false;
              this.imageUrls[rowIndex]["loader"] = false;
              if (res.url) {
                resolve(res.url);
                imageUrls.unshift(res.url);
                images.unshift(res.url);
              }
            },
            (err) => {
              this.filenNme = "";
              this.loader = false;
              this.notify.notify("An error occurred", "warn", 1000);
              reject(null);
            }
          );
      } catch (error) {
        this.loader = false;
        reject(null);
      }
    });
  }
  /**
   *uploadImage function to upload image
   *@param event
   *@param rowindex
   */
  uploadImage(event, rowIndex, tempref) {
    this.imgChangeEvt = event;
    this.rowIndex = rowIndex;
    // Open image cropper dialog
    this.dialogReference = this.dialog.open(ImageEditorComponent, {
      width: '840px',
      panelClass: 'create-master-panel',
      maxHeight: 'calc(100vh - 50px)',
      height : 'auto',
      data: {
          info: event,
          type: event.type,
          size: 720,
          ratio: 6 / 4
        }
    });
    this.dialogReference.afterClosed().subscribe(result => {
      if(result){
      this.formData = result;
      this.uploadImg(this.rowIndex);
      }
    });    
  }
  
  /**
   *updateTitle function to add/Update Image Title
   *@param event
   *@param index
   */
  updateTitle(event, index) {
    if (event.target.value) {
      if (this.images.length === index) {
        this.images[index] = {};
      }
      this.images[index]["category"] = event.target.value;
    }
  }
  /**
   *defaultImage function to set d Imagefaulte image
   *@param event
   *@param index
   */
  defaultImage(event, index) {
    if (event.target.value) {
      if (this.images.length === index) {
        this.images[index] = {};
      }
      this.images[index]["default"] = event.target.value;
    }
  }
  /**
   *addCategorysRow function to add  new image row
   *@param event
   *@param index
   */
  addCategorysRow() {
    this.categoryTitleSet.push({ categorys: "", files: "" });
    let categorys = <UntypedFormArray>this.statsForm.controls.categorys;
    categorys.push(this.initCategoryTitleFields());
    this.hideRemove(categorys.length);
  }
  /**
   *removeCategorysRow function to remove  image row
   *@param key
   */
  removeCategorysRow(key) {
    if (this.categoryTitleSet.length > 1) {
      this.categoryTitleSet.splice(key, 1);
      delete this.imageUrls[key];
      let keys = Object.keys(this.imageUrls);
      if (keys && keys.length != key) {
        for (let i = 0; i < keys.length; i++) {
          this.imageUrls[i] = this.imageUrls[keys[i]];
          delete this.imageUrls[keys[i]];
        }
      }
      this.images.splice(key, 1);
      let categorys = <UntypedFormArray>this.statsForm.controls.categorys;
      categorys.removeAt(key);
      this.hideRemove(categorys.length);
    } else {
      this.notify.notify("Cannot Remove Last Row", "warn", 1000);
    }
  }
  /**
   *removeCategorysRow function to remove  image row
   *@param rowIndex
   *@param index image index
   */
  removeCategorysImage(rowIndex, index) {
    this.imageUrls[rowIndex].splice(index, 1);
    this.images[rowIndex]["images"].splice(index, 1);
  }

  /**
   *hideRemove function to show/hide remove  button
   *@param item rowIndex
   */
  hideRemove(item: any) {
    if (item > 1) this.showRemove = true;
    else this.showRemove = false;
  }

  addRow() {
    this.keysSet.push({ keys: "" });
    this.valuesSet.push({ value: "" });
    /* #Form */
    let ks = <UntypedFormArray>this.statsForm.controls.keys;
    let vs = <UntypedFormArray>this.statsForm.controls.value;
    ks.push(this.initkeysFields());
    vs.push(this.initValueFields());
    /* End - #Form */
  }
  removeAll(key) {
    this.keysSet = [];
    this.valuesSet = [];
    let ks = <UntypedFormArray>this.statsForm.controls.keys;
    let vs = <UntypedFormArray>this.statsForm.controls.value;
    ks.removeAt(key);
    vs.removeAt(key);
  }

  removeRow(key) {
    if (this.keysSet.length > 1 && this.valuesSet.length > 1) {
      this.keysSet.splice(key, 1);
      this.valuesSet.splice(key, 1);
      let ks = <UntypedFormArray>this.statsForm.controls.keys;
      let vs = <UntypedFormArray>this.statsForm.controls.value;
      ks.removeAt(key);
      vs.removeAt(key);
    } else {
      this.notify.notify("Cannot Remove Last Row", "warn", 1000);
    }
  }

  addAttrRow() {
    this.attrkeysSet.push({ keys: "" });
    this.attrvaluesSet.push({ value: "" });
    /* #Form */
    let ks = <UntypedFormArray>this.statsForm.controls.attrKeys;
    let vs = <UntypedFormArray>this.statsForm.controls.attrValues;
    ks.push(this.initkeysAttrFields());
    vs.push(this.initValueAttrFields());
    /* End - #Form */
  }
  removeAttrAll(key) {
    this.attrkeysSet = [];
    this.attrvaluesSet = [];
    let ks = <UntypedFormArray>this.statsForm.controls.attrKeys;
    let vs = <UntypedFormArray>this.statsForm.controls.attrValues;
    ks.removeAt(key);
    vs.removeAt(key);
  }

  removeAttrRow(key) {
    if (this.attrkeysSet.length > 1 && this.attrvaluesSet.length > 1) {
      this.attrkeysSet.splice(key, 1);
      this.attrvaluesSet.splice(key, 1);
      let ks = <UntypedFormArray>this.statsForm.controls.attrKeys;
      let vs = <UntypedFormArray>this.statsForm.controls.attrValues;
      ks.removeAt(key);
      vs.removeAt(key);
    } else {
      this.notify.notify("Cannot Remove Last Row", "warn", 1000);
    }
  }
  initkeysAttrFields() {
    return this.fb.group({
      attrKeys: [""],
    });
  }

  initValueAttrFields() {
    return this.fb.group({
      attrValues: [""],
    });
  }
  goBack() {
    this.back.emit();
  }
}
