<div class="h-100 bg-white">
    <ng-container *ngIf="addTemplate">
        <app-create-template [parent]='this' [mode]='formMode' [selectedElement]="selectedElement">
        </app-create-template>
    </ng-container>
    <ng-container *ngIf="editQuery">
        <app-query-index [mode]=" (selectedElement && selectedElement.query_index) ? 'edit' :'add'" [parent]='this' [selectedElement]="selectedElement">
        </app-query-index>
    </ng-container>
    <ng-container *ngIf="!addTemplate && !editQuery">
        <div fxLayout="row" class="w-100 px-2 crumb-c" [class.over_auto]="mobile" fxLayoutAlign="space-between center">
            <h4 class="open-sans mb-0 text-nowrap" style="color: #616474;">CSV Template</h4>
            <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
                <div class="position-relative mx-1">
                    <mat-icon class="search-icon">search
                    </mat-icon>
                    <input [formControl]="searchTemplate" name="search" class="form-input-l search-inputbox" placeholder="Search File Type" type="text">
                </div>

                <button mat-button class="orange lean-radius mx-1" (click)="addTemplateDialog('add')" style="background: #f69230;">
                    <svg id="Group_1347" data-name="Group 1347" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
                        <g id="Group_1336" data-name="Group 1336" transform="translate(5.333 5)">
                          <path id="plus" d="M6.371,4.31a.187.187,0,0,1-.187-.187V0H4.31V4.123a.187.187,0,0,1-.187.187H0V6.184H4.123a.187.187,0,0,1,.187.187v4.123H6.184V6.371a.187.187,0,0,1,.187-.187h4.123V4.31Zm0,0" fill="#fff"/>
                        </g>
                      </svg>
                    <span style="color: white;top: 2px;position: relative;">ADD TEMPLATE</span>
                </button>
            </div>
        </div>


        <app-loader *ngIf="loader"></app-loader>
        <div class="d-flex  align-items-center justify-content-between over_auto p-2">
            <div class="px-3 py-2 w-100 total-count">
                <span> Total Count :{{this.total}} </span>
            </div>
            
            <mat-select class="sort-inputbox" placeholder="Sort By" [(ngModel)]="orderBy" (selectionChange)="sortBy()">
                <mat-option *ngFor="let type of getStatus()" [value]="type.value">
                    {{ type.label }}
                </mat-option>
            </mat-select>
            
            <mat-select class="sort-inputbox mr-0" [(ngModel)]="sortAsc" placeholder="Sort Order" (selectionChange)="sortOrder()">
                <mat-option value="true"><span>ASC</span></mat-option>
                <mat-option value="false"><span>DES</span></mat-option>
                <!-- <mat-option *ngFor="let value of sort_order" [value]="value.value">
                    {{ value.label }}
                </mat-option> -->
            </mat-select>
            <mat-select class="sort-inputbox" [(ngModel)]="status" placeholder="Sort Order" (selectionChange)="filterStatus()">
                <mat-option *ngFor="let type of filter_status" [value]="type.value">
                    {{ type.label[api.language] }}
                </mat-option>
                <!-- <mat-option *ngFor="let value of sort_order" [value]="value.value">
                    {{ value.label }}
                </mat-option> -->
            </mat-select>
        </div>
        <div class="table-div px-3 pt-1">
            <table mat-table #templateTable [dataSource]="dataSource" matSort class="template-table w-100">
                <ng-container *ngFor=" let cols of displayedColumns">
                    <ng-container matColumnDef="{{ cols }}">
                        <ng-container *ngIf="cols=='file_type'">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                <span class="open-sans font-weight-bold" *ngIf="cols == 'file_type'">File Type</span>
                            </th>
                        </ng-container>
                        <ng-container *ngIf="cols!='file_type'">
                            <th mat-header-cell *matHeaderCellDef>
                                <span class="open-sans font-weight-bold" *ngIf="cols == 'Action'">Action</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols == 'sno'">S.NO</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols == 'Status'">Status</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols == 'table_name'">Table Name</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols == 'column_name'">Column Name</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols == 'added_date'">Added Date</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols == 'Query'">Query  </span>
                            </th>
                        </ng-container>
                        <ng-container *ngIf="dataSource">
                            <td mat-cell *matCellDef="let element; let i=index;" class="position-relative">
                                <span class="f-14" *ngIf="cols == 'sno'">
                                {{offset+i+1}}.
                            </span>
                                <div *ngIf="cols == 'Action'">
                                    <button mat-icon-button class="csv-icon-highlight-fix action-icon" matTooltip="Edit" (click)="addTemplateDialog('edit',element)">
                                    <mat-icon class="edit-action-icon" svgIcon="edit-icon"></mat-icon>
                                </button>
                                </div>
                                <div *ngIf="cols == 'Query'">
                                    <button mat-icon-button class="csv-icon-highlight-fix action-icon" matTooltip="Edit Query" (click)="addQueryIndex(element)">
                                    <mat-icon class="edit-action-icon" svgIcon="edit-icon"></mat-icon>
                                </button>
                                </div>
                                <ng-container *ngIf="cols=='column_name'">
                                    <div class="position-relative">
                                        <span style="cursor: pointer;">
                                        <img (click)="showDataPopup(element.id)"  src="../../../../assets/images/svgicons/Group 2164.svg"></span>
                                        <div *ngIf="select_row_id == element.id" id="data_{{element.id}}" class="hover-data">
                                            <div class="head text-center">
                                                <span>Column Name</span>
                                                <img class="close" (click)="showDataPopup(element.id)" src="assets/images/svgicons/cross.svg" alt="">
                                            </div>
                                            <div class="code-view">
                                                <code>
                                                    {{ returnVal(element,cols)}}
                                                </code>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <mat-slide-toggle *ngIf="cols=='Status'" [checked]="element.status==1?true:false" (change)="updateStatus(element.id,$event)">
                                </mat-slide-toggle>
                                <p matTooltip={{returnVal(element,cols)}} *ngIf="cols != 'S.No' && cols != 'column_name'" class="f-14 open-sans">
                                    {{returnVal(element, cols)}}</p>
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
            </table>
           
        </div>

        <ng-container *ngIf="dataSource">
            <div class="d-flex mt-2 justify-content-end">
                <mat-paginator [length]="total" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = $event; handlePagination($event)">
                </mat-paginator>
            </div>
        </ng-container>

    </ng-container>
</div>