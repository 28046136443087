import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
// import { Http, RequestOptions, URLSearchParams, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
// import { AutoLogoutService } from './services/auto-logout.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment'
import { NotificationService } from './admin/services/notification.service';
import { UserAction } from "./admin/user-action/user-activity";

@Injectable({providedIn:'root'})
export class ApiserviceService {
  public httpOptions;
  public status;
  public device: any;
  public ip: any='';
  public user_id: any;
  public broker_id:Number;
  // public user_name: string;
  public user_token: string = '';
  public city_id: any = '';
  public city_code = '';
  public session_id: string = '';
  public language: number = 1;
  public language_list = [];
  public property_type_list = [];
  public entity_type_list = [];
  public allowResources = [];
  public umsUrl = null;
  public estUrl = null
  public gisUrl = null;
  public wms_url = null;
  public valUrl = null;
  public setUserLocationData;
  isMobile;
  // set url of api
  setApiUrl() {
    this.estUrl = environment.estUrl ;
    this.umsUrl = environment.umsUrl;
    this.gisUrl = environment.gisUrl;
    this.valUrl = environment.valUrl;
    this.wms_url = environment.wms_url;
    this.user_token = (this.user_token == '') ? environment.user_token: this.user_token
    
    // let host = window.location.hostname;

  }
  public user_name =  new BehaviorSubject<any>(localStorage.getItem('user_name')); 
  profile_image = new BehaviorSubject<any>(localStorage.getItem('profile_image'));;
 
  constructor(private http: HttpClient, 
  //  httpBackend: HttpBackend,
    private route: Router, 
    private deviceInfo: DeviceDetectorService,
    private notify:NotificationService) {
      this.isMobile = this.deviceInfo.isMobile();
      // this.http = new HttpClient(httpBackend);
    if (localStorage.getItem('user_token')) this.user_token = localStorage.getItem('user_token');
   
   
    this.setApiUrl();

    if (localStorage.getItem('language_id')) {
      this.language = +localStorage.getItem('language_id');
    }
    this.getIpAddress();
    if (localStorage.getItem('device')) {
      this.device = JSON.parse(localStorage.getItem('device'));
    } else {
      this.device = this.getDevice();
      localStorage.setItem('device', JSON.stringify(this.device));
    }
    if (localStorage.getItem("ip")) {
      this.ip = localStorage.getItem("ip");
    } else {
      this.getIpAddress();
    }
    this.user_id = localStorage.getItem('user_id');
    if(this.user_id && this.city_id){
      this.getUserDetail(this.user_id)
      this.getUserRole();
    }
    // if (localStorage.getItem('user_token')) this.user_token = localStorage.getItem('user_token');
    this.user_name.next(localStorage.getItem('user_name'));
    if (localStorage.getItem('city_id')) {
      this.city_id = localStorage.getItem('city_id');
      this.city_code = localStorage.getItem('city_code');
    }

    if (localStorage.getItem('session_id')) {
      this.session_id = localStorage.getItem('session_id');
    }

    if (this.user_id && this.city_id) {
      //this.getAllowResources();

    }
    this.getLanguageList();
  }

  
  /**
   * external ip
   * @returns 
   */
  getIpAddress() {
    this.http.get('https://api.ipify.org/?format=json')
      .subscribe((data: any) => {
        let ip_details: any = data;
        this.ip = ip_details.ip;
        localStorage.setItem('ip', this.ip);
      });
  }
  
  /**
   * getDevice function to get get device info
   */
  getDevice() {
    let device = this.deviceInfo.getDeviceInfo();
    return device;
  }

  /**
   * setting header for UMS
   * @returns 
   */
  setUmsHeader(app_id = '10') {
   
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('app-id', app_id);
    headers = headers.append('user-lang', 'en');
    headers = headers.append('city-id', String(this.city_id))
    headers = headers.append('city-code', String(this.city_code))
    if (localStorage.getItem('ip')) {
      headers = headers.append('user-ip', this.ip);
    } else {
      headers = headers.append('user-Ip', '::');
    }
    headers = headers.append('user-token', this.user_token);
    this.httpOptions = { headers: headers };
  }

  /**
   * setting header for gis
   * @returns 
   */
  setGisHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('API-KEY', '7kjghhjj');
    headers = headers.append('USER-LANGUAGE', '1');
    headers = headers.append('USER-TOKEN', String(this.user_token));
    headers = headers.append('USER-CITY', String(this.city_code));
    this.httpOptions = { headers: headers };
  }
  
  /**
   * setting header for file upload
   * @returns 
   */
  setUploadHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('API-KEY', '7kjghhjj');
    headers = headers.append('USER-LANGUAGE', '1');
    headers = headers.append('USER-CITY', String(this.city_code));
    this.httpOptions = { headers: headers };
  }
  setUploadHeaderEstater(content_type = 'multipart/form-data', response_type) {
    let headers = new HttpHeaders();
    headers = headers.append('USER-CITY', String(this.city_id));
    headers = headers.append('USER-TOKEN', String(this.user_token));
    headers = headers.append('USER-LANGUAGE', '' + this.language + '');
    headers = headers.append('USER-LANGUAGE-CODE', 'en');
    headers = headers.append('api-key', environment.api_key);
    this.httpOptions = { headers: headers };
    if (response_type) {
      this.httpOptions['responseType'] = response_type;
    }

  }

  setUploadHeaderUms(content_type = 'multipart/form-data', response_type) {
    let headers = new HttpHeaders();
    // headers.append('Content-Type', content_type);
    // let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
    headers = headers.append('city-id', String(this.city_id));
    headers = headers.append('city-code', String(this.city_code));
    headers = headers.append('user-token', String(this.user_token));
    headers = headers.append('app-id', '10');
    headers = headers.append('user-lang', 'en');
    if (localStorage.getItem('ip')) {
      headers = headers.append('user-ip', this.ip);
    } else {
      headers = headers.append('user-ip', '::');
    }
    this.httpOptions = { headers: headers };
    if (response_type) {
      this.httpOptions['responseType'] = response_type;
      //headers = headers.append('responseType', response_type);
    }
  }

  /**
   * setting header for estater
   * @returns 
   */
  setHeader(content_type = 'application/json') {

    let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', content_type);
    //headers = headers.append('USER-DEVICE', dInfo);
    //headers = headers.append('session-id', this.session_id);
    if (localStorage.getItem('ip')) {
      headers = headers.append('USER-IP', this.ip);
    } else {
      headers = headers.append('USER-IP', '::');
    }
    headers = headers.append('USER-CITY', String(this.city_id));
    headers = headers.append('USER-TOKEN', String(this.user_token));
    headers = headers.append('USER-LANGUAGE', '' + this.language + '');
    headers = headers.append('USER-LANGUAGE-CODE', 'en');
    headers = headers.append('api-key', environment.api_key);
    this.httpOptions = { headers: headers };
  }

  /**
   * setting header for Uploade media
   * @returns 
   */
   setUploadImageHeader(content_type = 'application/json') {
    let headers = new HttpHeaders();
    headers = headers.append('USER-CITY', String(this.city_id));
    headers = headers.append('USER-TOKEN', String(this.user_token));
    headers = headers.append('api-key', environment.api_key);
    this.httpOptions = { headers: headers };
  }
  
  /**
   * getUmsData function to get records
   * @param url 
   */
  getUmsData(url) {
    this.setUmsHeader();
    return this.http.get(this.umsUrl + url, this.httpOptions)
    // .map(data => data.json());
  }
  /**
   * postFile function to Uploaded File action
   * @param url 
   * @param body 
   */
   postImageFile(url, body) {
    this.setUploadImageHeader();
    return this.http.post(this.estUrl + url, body, this.httpOptions);
  }

  /**
   * getData function to get records
   * @param url 
   */
  getData(url) {
    this.setHeader();
    return this.http.get(this.estUrl + url, this.httpOptions)
    // .map(data => data.json());
  }
  
  /**
   * getValData function to get records
   * @param url 
   */
  getValData(url) {
    this.setHeader();
    return this.http.get(this.valUrl + url, this.httpOptions)
    // .map(data => data.json());
  }

  
  /**
   * getGisData function to get record of gis
   * @param url 
   */
  getGisData(url) {
    this.setGisHeader();
    return this.http.get(this.gisUrl + url, this.httpOptions)
    // .map(data => data.json());
  }

  /**
   * postGisData function to post record of gis
   * @param url 
   * @param body 
   */
  postGisData(url, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setGisHeader();
    return this.http.post(this.gisUrl + url, body, this.httpOptions);
  }

  /**
   * postUserAction function to Uploaded File action
   * @param url 
   * @param body 
   */
  postUserAction(url, body) {
    this.setGisHeader();
    return this.http.post(this.gisUrl + url, body, this.httpOptions);
  }

  postFile(url, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUploadHeader();
    return this.http.post(this.gisUrl + url, body, this.httpOptions);
  }

  postFileUms(url, body, response_type = null) {
    // if (body === null || body === undefined) {
    //   throw new Error('Required parameter body was null or undefined when calling Api.');
    // }
    // this.setUploadHeaderUms('application/json', response_type);
    // return this.http.post(this.estUrl + url, body, this.httpOptions);
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUploadHeaderUms('application/json', response_type);
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }


  postFileEst(url, body, response_type = null) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUploadHeaderEstater('application/json', response_type);
    return this.http.post(this.estUrl + url, body, this.httpOptions);
  }
  
  /**
   * postUmsData function to send a POST request to the API to create a data object
   * @param url 
   * @param body 
   */
  postUmsData(url, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUmsHeader();
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }
  postData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.post(this.estUrl + url, body, this.httpOptions);
  }
  postValData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.post(this.valUrl + url, body, this.httpOptions);
  }
  
  /**
   * patchUmsData function to send a PATCH request to the API to update a data object
   * @param urlWithId 
   * @param body 
   */
  patchUmsData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUmsHeader();
    return this.http.patch(this.umsUrl + urlWithId, body, this.httpOptions);
  }

  patchData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.patch(this.estUrl + urlWithId, body, this.httpOptions);
  }

  patchValData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.patch(this.valUrl + urlWithId, body, this.httpOptions);
  }
  
  /**
   * deleteUmsData function to send a DELETE request to the API to delete a data object
   * @param urlWithId 
   */
  deleteUmsData(urlWithId) {
    this.setUmsHeader();
    return this.http.delete(this.umsUrl + urlWithId, this.httpOptions);
  }

  /**
   * deleteData function to send a DELETE request to the API to delete a data object
   * @param urlWithId 
   */
  deleteData(urlWithId) {
    this.setHeader();
    return this.http.delete(this.estUrl + urlWithId, this.httpOptions);
  }
  
  /**
   * getLanguageList function to getting list of language
   */
  getLanguageList() {
    this.language_list = [];
    this.getUmsData('language')
      .subscribe(
        (res: any) => {
          if (res && res.data) {
            res.data.forEach(element => {
              if (element.status == 1) {
                this.language_list.push(element);
              }
            });
            localStorage.setItem('languageList', JSON.stringify(this.language_list));
          }
        
        }
      );
    let langList = localStorage.getItem('langugeList')
    if (langList) {
      this.language_list = JSON.parse(langList);
    }
  }

  getAddressByLatlon(lat, lon) {
    return this.http.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${environment.google_key}`
    );
  }
  

  /**
   * getAllowResources function to getting allow resources
   */
  getAllowResources() {
    let body = `user/resource?place_id=${this.city_id}&user_id=${this.user_id}`
    this.getUmsData(body).subscribe({
      next :(res: any) => {
 
     }
    })

  }

  logout(val) {
    const data={
      user_id:this.user_id,
      place_id:this.city_id,
    }
    const activity={
      actionId: this.user_id,
      data: data,
      actionType:UserAction.logout.Action_ID
    }
    this.logUserActivity(activity);
    if(environment.isLocalServe ||  this.isMobile){
      this.getUmsData('logout?all_device='+val).subscribe((data) => {});
      localStorage.clear();
      this.user_id = null;
      this.user_token= null;
      this.city_id = 'null';
      this.route.navigate(['/login'])
    }else{
      localStorage.clear();
      this.user_id = null;
      this.user_token= null;
      this.city_id = 'null';
      let newwindow = window.open(`${environment.adminHost}/logout?continue=${environment.baseURL}&all_device=${val}`, '_self');
      if (window.focus) {newwindow.focus()}
    }
      
  }


  checkRole(role) {
    let status = false;
    let roles = localStorage.getItem('roles');
    if (roles) {
      let roles_arr = [];
      roles_arr = roles.split(',');
      if (roles_arr.includes(role)) status = true;
    }
    return status;
  }


  getUserRole() {
    let url = `users?user_ids=${this.user_id}`;
    this.getUmsData(url)
      .subscribe((data: any) => {
        if (data) {
          let val = data.data;
          let roles = val[0].role_names;
          localStorage.setItem('roles', roles);
        }
      })
  }

  getUserDetail(id) {
    this.getUmsData(`user?user_id=${id}`).subscribe({
      next:(res: any) => {
      if (res.data) {
        this.setUser_info(res.data);
      }
    },error:(err) => {
      }
      })
  }

  setUser_info(userdata) {
    let image = 'assets/images/user.png';
      if (userdata.profile_image) {
        image = userdata.profile_image;
      }
      localStorage.setItem('profile_image', image);
      this.profile_image.next(image);
      let name='';
      if (userdata.user_details['1'].first_name) {
          name = userdata.user_details['1'].first_name;
      }
      if (userdata.user_details['1'].last_name) {
          name += " " +  userdata.user_details['1'].last_name;
      }
      localStorage.setItem('user_name', name);
      let user_info = {
          "fname": userdata.user_details['1'].first_name,
          "lname": userdata.user_details['1'].last_name,
          "contact": userdata.phone_number,
          "country": userdata.nationality,
          "email": userdata.primary_email_id,
          "language": 'en',
          "imageURL": userdata.profile_image
      }
      localStorage.setItem('user_info', JSON.stringify(user_info));
      this.user_name.next(name);
  }


  setUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let latlon = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            user_country: '',
            city: '',
          };
          if (
            this.getCookie('location') &&
            typeof this.getCookie('location') == 'string'
          ) {
            this.setUserLocationData = JSON.parse(
              this.getCookie('location')
            );
          }
          if (!this.setUserLocationData) {
            this.getAddressByLatlon(
              latlon.latitude,
              latlon.longitude
            ).subscribe({
              next:(res: any) => {
                if (res.status) {
                  let results = res.results;
                  if (results[0]) {
                    var arrAddress = results;
                    let fullAddr: any = arrAddress[0].address_components;
                    fullAddr.map((element) => {
                      if (element.types.indexOf('neighborhood') > -1) {
                        latlon['neighborhood'] = element.long_name;
                      } else if (element.types.indexOf('locality') > -1) {
                        latlon['city'] = element.long_name;
                      } else if (element.types.indexOf('country') > -1) {
                        latlon['user_country'] = element.long_name;
                      } else if (element.types.indexOf('postal_code') > -1) {
                        latlon['postal_code'] = element.long_name;
                      } else if (
                        element.types.indexOf('administrative_area_level_1') >
                        -1
                      ) {
                        latlon['state'] = element.long_name;
                      } else if (
                        element.types.indexOf('administrative_area_level_2') >
                        -1
                      ) {
                        latlon['area'] = element.long_name;
                      }
                    });
                    this.setUserLocationData = latlon;
                    this.setCookie(
                      'location',
                      JSON.stringify(this.setUserLocationData),
                      1
                    );
                  } else {
                    this.setUserLocationData = latlon;
                    //console.log("google response data not found");
                  }
                } else {
                  this.setUserLocationData = latlon;
                  //console.log("Geocoder failed due to: " + res.status);
                }
              },
              error:(err) => {
                //console.log(err);
                this.setUserLocationData = latlon;
              }
          });
          }
        },
        function (error) {
          //this.setUserLocationData = null;
        },
        { enableHighAccuracy: true, timeout: 30000 }
      );
    }
  }

  getUserLocationData() {
    return this.setUserLocationData;
  }

  logUserActivity(log) {
    
    
    const { data,actionId,geom,neigh,block,parcel,actionType} = log;
    if (!actionType) {
      this.notify.notify('Action ID is required for activity logging!', 'error', 2000);
      return;
    }
    let url = `userActivity/add`;
    let body = {
      user_id: this.user_id ? this.user_id : null,
      broker_id: this.broker_id ? this.broker_id : null,
      session_id: this.session_id ? this.session_id : null,
      action_type: actionType,
      geom: geom,
      neighbourhood:neigh,
      block: block,
      parcel: parcel,
      data: data,
      action_id: actionId,
      detail: "",
      user_country: this.getUserLocationData() ? this.getUserLocationData().user_country : null,
      lat: this.getUserLocationData() ? this.getUserLocationData().latitude : null,
      lon: this.getUserLocationData() ? this.getUserLocationData().longitude : null,
      city: this.getUserLocationData() ? this.getUserLocationData().city : null,
      // action_id: actionId ? actionId : '',
      // user_id: this.api.user_id ? this.api.user_id : '',
      // duration: data?.duration ? data?.duration : 0,
      // data: userdata ? userdata : {},
      // location: this.getUserLocationData(),
      // ip: this.ip ? this.ip : null,
      // added_by: this.api.user_id,
    };
    // this.postData(url, body).subscribe((res: any) => {
    //   if (res && res.status == 201) {
    //   } else {
    //   }
    // });
  }
  setCookie(cname: any, cvalue: any, exdays: any) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  getCookie(cname: any) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
