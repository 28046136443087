import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements OnInit {
  @Input () editable: boolean = false;
  @Input() data:any;
  @Input () editExisting: boolean = false;
  @Input() preview:boolean = false;
  @Output() templateUpdate = new EventEmitter();

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.templateUpdate.emit({
      content: '',
      type: 'gallery'
    })
  }
}
