<div class="row w-100">
  <!-- table -->
  <div class="col-md-12 pr-0">
    <mat-card appearance="outlined" class="m-2 w-100">
      <mat-card-header class="mtc-header pt-2 pb-2 pr-2 position-relative d-flex justify-content-between">
        <mat-card-title>
            Comparison Parameter List
        </mat-card-title>
        <div class="d-flex justify-content-between">
            <div class="search">
                <input class="search-input" [formControl]="searchField" type="text" name="search" placeholder="Search Neighbourhood UID">
            </div>
            <div class="show-deleted-container">
                <div class="show-deleted_dd">
                    <mat-select placeholder="Choose status" [(ngModel)]="statusCode" (selectionChange)="selectionChange($event.value)">
                        <mat-option value="1">Active</mat-option>
                        <mat-option value="0">Inactive</mat-option>
                        <mat-option value="-1">Deleted</mat-option>
                    </mat-select>
                </div>
                <button mat-raised-button (click)="openDialog('add')">
                    Add Parameter
                </button>
            </div>
        </div>
    </mat-card-header>
    
      <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
      <mat-card-content class="list pr-2 pb-2 pl-2 pt-2">
        <div class="w-100 calc-h">
          <table mat-table matSort [dataSource]="dataSource" class="w-100">
            <ng-container *ngFor='let cols of displayedColumns'>
              <!-- for action -->
              <ng-container *ngIf="cols == 'actions'">
                <ng-container matColumnDef="{{cols}}">
                  <th mat-header-cell *matHeaderCellDef>
                    <span class="header action cp">{{getLabel(cols)}}</span>
                  </th>
                  <td mat-cell *matCellDef="let element" class="pl-2 pr-2">
                    <div class="d-flex justify-content-center align-items-center">
                      <button color='white' mat-mini-fab (click)="openDialog('edit',element)">
                        <mat-icon class="editIcon blue">edit</mat-icon>
                      </button>
                      <button mat-mini-fab color="warn" (click)="openConfirmation(element)" *ngIf="element.status != -1">
                        <mat-icon class="deleteIcon">delete</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>
              </ng-container>

              <!-- for status -->
              <ng-container *ngIf="cols == 'status'">
                <ng-container matColumnDef="{{cols}}">
                  <th mat-header-cell *matHeaderCellDef>
                    <span class="header cp">{{getLabel(cols)}}</span>
                  </th>
                  <td mat-cell *matCellDef="let element" class="pl-2 pr-2">
                    <div class="d-flex justify-content-start align-items-center">
                      <mat-slide-toggle [checked]="element.status == 1 ? true: false"
                        (change)="updateStatus(element.id,$event)">
                      </mat-slide-toggle>
                    </div>
                  </td>
                </ng-container>
              </ng-container>
              <!--  -->
              <!-- for every other column -->
              <ng-container *ngIf="cols !='actions' && cols!='status'">
                <ng-container matColumnDef="{{cols}}">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    <span *ngIf="cols=='sn'">S.NO</span>
                    <span *ngIf="cols!='sn'" class="header cp">
                      {{getLabel(cols)}}
                    </span>
                  </th>
                  <td mat-cell *matCellDef="let element; let i=index;">
                    <span *ngIf="cols == 'sn'"> {{offset+i+1}} </span>
                    <p matTooltip={{returnVal(element,cols)}} class="more"> {{returnVal(element,cols)}} </p>
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

        </div>

      </mat-card-content>

    </mat-card>
    <mat-paginator [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent = $event; handlePagination($event)">
    </mat-paginator>
  </div>

</div>