import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ApiserviceService } from "../../../../apiservice.service";
import { ImageEditorComponent } from "../../../../admin/image-editor/image-editor.component";
import { NotificationService } from "../../../../admin/services/notification.service";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-2';

import { MomentDateAdapter } from "@angular/material-moment-adapter";
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// import { defaultFormat as _rollupMoment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CommonfunctionService } from "../../../../services/commonfunction.service";



// const moment = _rollupMoment || _moment;

export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};



@Component({
  selector: "app-add-company",
  templateUrl: "./add-company.component.html",
  styleUrls: ["./add-company.component.css"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
  ],
})
export class AddCompanyComponent implements OnInit {

  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  cuntry:string = '';
	preferredCountries: CountryISO[] = [
    CountryISO.Bahrain,    
    CountryISO.India,
    CountryISO.Kuwait,
    CountryISO.SaudiArabia,
    CountryISO.Switzerland,
    CountryISO.UnitedKingdom,
    CountryISO.UnitedStates,
  ];

  public today = new Date();
  companyForm: UntypedFormGroup;
  action_type: string;
  loader: boolean;
  parent_data:any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "10rem",
    minHeight: "3rem",
    placeholder: "Enter text here...",
    translate: "no",
    enableToolbar: false,
    showToolbar: true,
    defaultParagraphSeparator: "p",
    toolbarHiddenButtons: [
      ["undo", "redo", "strikeThrough", "subscrit", "fontName"],
      [
        "fontSize",
        "customClasses",
        "insertImage",
        "insertVideo",
        "insertHorizontalRule",
        "removeFormat",
        "toggleEditorMode",
      ],
    ],
  };
  comp_type: any = [
    { label: "Broker", value: "1" },
    { label: "Bank", value: "2" },
  ];
  

  formData: any;
  phoneNumber;
  whatsAppNumber;

  @ViewChild("myFileInput") myFileInput;
  img_name: any;
  logo_url: any;

  isSubmited:boolean=false;

  constructor(private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private api:ApiserviceService,
    private notify: NotificationService,
    public dialogRef: MatDialogRef<AddCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fnc:CommonfunctionService
    ) {
    
    this.action_type = data.type;
    this.parent_data = data.data;
    
    this.companyForm = this.fb.group({
      company_type: ["", Validators.required],
      company_name: ["", [Validators.required,  Validators.minLength(2), Validators.maxLength(100)]],
      register_name: [""],
      registration_number: [""],
      registration_authority: [""],
      contact_person: [""],
      email: [{value:"", disabled: this.action_type == 'edit'?true:false}, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      contact_number: ["", Validators.required],
      fb_page: ["",[Validators.pattern('(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})')]],
      twitter_handler: ["", [Validators.pattern('(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})')]],
      whatsapp_number: [""],
      email_template: [""],
      description: [""],
      nationality: [""],
      working_since: [""],
      languages:[""],
      address:[""]
    });

    if(this.action_type == 'edit'){

      this.whatsAppNumber = this.parent_data?.whatsapp_number
      this.phoneNumber = this.parent_data?.contact_number

      this.companyForm.patchValue({
        company_name: this.parent_data?.company_name,
        register_name: this.parent_data?.register_name,
        registration_number: this.parent_data?.registration_number,
        registration_authority: this.parent_data?.registration_authority,
        contact_person: this.parent_data?.contact_person,
        contact_number: this.parent_data?.contact_number,
        email: this.parent_data?.email,
        fb_page: this.parent_data?.fb_page,
        twitter_handler: this.parent_data?.twitter_handler,
        whatsapp_number: this.parent_data?.whatsapp_number,
        email_template: this.parent_data?.email_template,
        company_type: (this.parent_data?.company_type_id)?this.parent_data?.company_type_id.toString():'',
        description: this.parent_data?.description,
        nationality: this.parent_data?.nationality,
        working_since: this.parent_data?.working_since,
        languages: this.parent_data.languages? this.parent_data.languages.split(','):[],
        address:  this.parent_data.address? this.parent_data.address:''
      })
    }
  }


  /**
   * contact number change event
   */
  checkNumber(){    
    this.companyForm.patchValue({
      contact_number: this.phoneNumber //(this.phoneNumber && this.phoneNumber?.e164Number) ? this.phoneNumber.e164Number.replace(this.phoneNumber.dialCode,''): ''
    })
  }
  /**
   * whatsapp number change event
   */
  checkWhatsNumber(){
    this.companyForm.patchValue({
      whatsapp_number: this.whatsAppNumber //(this.whatsAppNumber && this.whatsAppNumber?.e164Number) ? this.whatsAppNumber.e164Number.replace(this.whatsAppNumber.dialCode,''): ''
    })
  }

  close(){
    this.dialogRef.close();
  }
  /**
   *uploadImage function to upload image
   *@param event
   *@param rowindex
   */
   uploadImage(event) {
    // Open image cropper dialog;
    this.img_name = event.target.files[0].name;
    const dialogReference = this.dialog.open(ImageEditorComponent, {
      width: '840px',
      panelClass: 'create-master-panel',
      maxHeight: 'calc(100vh - 50px)',
      height : 'auto',
      data: {
          info: event,
          type: event.type,
          size: 720,
          ratio: 2 / 2
        }
    });
    dialogReference.afterClosed().subscribe((result:FormData) => {
      if(result){
        this.formData = result;
        let file = result.getAll('file_url');
        this.logo_url = file[0];
       
      }else{
        this.logo_url=null;
        this.formData=null;
        this.img_name='';
        this.myFileInput.nativeElement.value = "";
      }
    });    
  }


  // upload image and return url
  uploadImg() {
    return new Promise((resolve, reject) => {
      try {
        if(!this.formData) {
          reject(null);
        }else{
        
        this.api
          .postImageFile(
            `uploads/images?folder_name=em_broker`,
            this.formData
          )
          .subscribe(
            (res: any) => {
              if (res.url) {
                resolve(res.url);
              }else{
                reject(null);
              }
            },
            (err) => {
              this.notify.notify("An error occurred", "warn", 1000);
              reject(null);
            }
          );
        }
      } catch (error) {
        reject(null);
      }
    });
  }

  ngOnInit(): void {}

  async addCompany(form_data) {
    this.isSubmited=true;
    if(this.companyForm.invalid){
      const controls = this.companyForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.notify.notify('Invalid '+ this.fnc.camelizeAll(name), 'warn');
          return;
        }
      }
      return;
    }
    
    this.loader=true;
    let img;
    try {
      img = await this.uploadImg();
    } catch (error) {
      img=null;
    }
    
  let body = {
    "city_id": this.api.city_id,
    "company_name": {[this.api.language]: form_data.company_name },
    "register_name": {[this.api.language]: form_data.register_name },
    "registration_number": {[this.api.language]: form_data.registration_number },
    "registration_authority": {[this.api.language]: form_data.registration_authority },    
    "contact_person": {[this.api.language]: form_data.contact_person },
    "contact_number": form_data.contact_number,
    "email": form_data.email,
    "fb_page": form_data.fb_page,
    "twitter_handler": form_data.twitter_handler,
    "whatsapp_number": form_data.whatsapp_number,
    "email_template": {[this.api.language]: form_data.email_template },
    "user_id": this.api.user_id,
    "company_type": form_data.company_type,
    "description": {[this.api.language]: form_data.description },
    "nationality": form_data.nationality,
    "working_since": form_data.working_since ? this.fnc.formatDate(new Date(form_data.working_since)) :'',
    "languages": form_data.languages ? form_data.languages.toString():'',
    "address": {[this.api.language]: form_data.address },
  }
  
  this.isSubmited =false;
    if(this.action_type == 'add'){
      body["logo_url"] =  img ? img :"";
      this.api.postData('company', body).subscribe({
        next :(res:any)=>{
        this.myFileInput.nativeElement.value = "";
        this.formData = null;
        this.img_name='';
        this.loader=false;
        if(res && res.status == 201){
          this.notify.notify(res.message, 'success');
          this.dialogRef.close(true);
        }else{
          this.notify.notify(res.message, 'warn');
        }
      }, error: (err)=>{
        this.loader=false;
      }})
    }else if(this.action_type =='edit'){
      body["logo_url"] = img ? img: (this.parent_data && this.parent_data.logo_url?this.parent_data.logo_url:'');
      body['company_id'] = this.parent_data.company_id;
      this.api.patchData('company', body).subscribe({
        next :(res:any)=>{
        this.myFileInput.nativeElement.value = "";
        this.formData = null;
        this.loader=false;
        if(res && res.status == 201){
          this.notify.notify(res.message, 'success');
          this.dialogRef.close(true);
        }else{
          this.notify.notify(res.message, 'warn');
        }
      },error: (err)=>{
        this.loader=false;
      }})
    }
  }

  imgOverlay(){
    let zoomimg = document.getElementById('zoomID');
    if(zoomimg.classList.contains('d-none')){
      zoomimg.classList.remove('d-none');
    }
  }
  imgOverlayOut(){
    let zoomimg = document.getElementById('zoomID');
    if(zoomimg){
      zoomimg.classList.add('d-none');
    }
  }
  zoomImage(){

    let fullImg = document.getElementById('fullImg');
    if(fullImg.classList.contains('d-none')){
      fullImg.classList.remove('d-none')
    }
  }
  deZoomImage(){
    let fullImg = document.getElementById('fullImg');
    if(fullImg){
      fullImg.classList.add('d-none')
    }
  }
}
