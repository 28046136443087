
import { Component, OnInit, Inject } from '@angular/core';
import { ApiserviceService } from '../../../apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { AdminMenusService } from '../../admin-sidebar/admin-menus.service';
import { NotificationService } from '../../services/notification.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, Validators, FormGroup, FormControl, UntypedFormBuilder, FormArray, NgModel } from '@angular/forms';
import { UserAction } from "../../user-action/user-activity";
@Component({
    selector: 'app-add-edit',
    templateUrl: './add-edit.component.html',
    styleUrls: ['../settings.component.css']
})
export class AddEditComponent implements OnInit {

    public parent_data;
    public mode;
    public SettingForm: any;
    public _loader: boolean;
    public setting_type = [{ name: 'DB', value: 'db' }, { name: 'General', value: 'general' }];
    constructor(
        public dialogRef: MatDialogRef<AddEditComponent>,
        @Inject(MAT_DIALOG_DATA) public info: any,
        private notify: NotificationService,
        private apiService: ApiserviceService,
        private _fb: UntypedFormBuilder
    ) {
        this.parent_data = this.info;
        this.mode = this.parent_data.type;
        if (this.mode == 'add') {
            this.SettingForm = this._fb.group({
                key: ['', Validators.required],
                type: ['', Validators.required],
                // value: ['', Validators.required],
                value: ['',  [Validators.required, Validators.minLength(2), Validators.maxLength(60)]]
            });
        }
    }

    ngOnInit() {
    }
    cancel() {
        this.dialogRef.close();
    }
    add_setting(data) {
        let val = data.value;
        let regExp = /[a-z]/i;
        if (!regExp.test(val.key) || !regExp.test(val.type) || !regExp.test(val.value)) {
            this.notify.notify("No Alphabetic Characters found in Input Box", "warn");
            return;
        }
        let body = {
            "key": val.key,
            "type": val.type,
            "value": val.value,
            "user_id": this.apiService.user_id
        }
        this._loader = true;
        this.apiService.postData('settings', body).subscribe({
            next: (res: any) => {
                if (res && res.status == 201) {
                    // const activity={
                    //     actionId: res.data,
                    //     data: body,
                    //     actionType:UserAction.add_setting.Action_ID
                    //   }
                    // this.apiService.logUserActivity(activity);
                    this.notify.notify(res.message, "success");
                    this.parent_data.pdata.getSettings();
                    this.dialogRef.close();
                }
                else {
                    if (res && res.message) {
                        if (res.message.includes('duplicate'))
                            this.notify.notify("Duplicate Entry cannot be added", "warn");
                        else
                            this.notify.notify(res.message, "warn");
                    }
                }
                this._loader = false;
            },
            error: (err) => {
                this.notify.notify(err.error.message, "error");
                this._loader = false;
            }
        })
    }

    blockSpecialChar(event) {
        if (event.keyCode == 95 || event.keyCode == 45) {
            event.returnValue = true;
        } else if (!((event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode >= 48) && (event.keyCode <= 57))) {
            event.returnValue = false;
            return;
        }
        event.returnValue = true;
    }
}