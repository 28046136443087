<div class="add_blog">
    <div fxHide="true" fxShow.gt-sm="true" fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
        <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="start center">
            <label *ngIf="action_type=='add'" class="main-heading d-flex align-items-center">
                <a matTooltip="Go Back" class="back-btn d-flex align-items-center pointer" (click)="goBack()">
                    <mat-icon class="mat-icons-sm mr-2">arrow_backward</mat-icon>
                </a>
                <span>Add {{ selectedType | titlecase }}</span>
            </label>

            <label *ngIf="action_type=='edit'" class="main-heading d-flex align-items-center">
                <a matTooltip="Go Back" class="back-btn d-flex align-items-center pointer" (click)="goBack()">
                    <mat-icon class="mat-icons-sm mr-2">arrow_backward</mat-icon>
                </a>
                <span>Update {{ selectedType | titlecase }} Post</span>
            </label>
        </div>
    </div>
    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
    <form [formGroup]="blogForm" autocomplete="off" novalidate (ngSubmit)="addBlog(blogForm)">
        <div class="container-fluid left-50">
            <p class="pt-3 mb-0 pl-2">Fill information below</p>
            <div class="content">
                <div class="user-details">
                    <div class="grid px-2">
                        <div>

                            <div class="input-box card-section">
                                <span class="detail type">Language <span class="text-danger">*</span> </span>
                                <mat-select class="mt-2" placeholder="Select Language"
                                    (selectionChange)="selectedLang($event.value)" formControlName="formLanguage"
                                    tabindex="1">
                                    <mat-option *ngFor="let language of language_list" [value]="language.language_id">
                                        {{ language.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    <span class="text-danger"
                                        *ngIf="(statsFormControl.formLanguage.touched || blogForm.valid) && statsFormControl.formLanguage.errors?.required">
                                        Language is required
                                    </span>
                                </mat-error>
                            </div>

                            <div class="input-box card-section">
                                <span class="detail mb-3">Category <span class="text-danger">*</span></span>
                                <input *ngIf="selectedType !== 'news'" type="text" placeholder="Enter category here"
                                    formControlName="post_category" tabindex="6">
                                <mat-select class="mt-2" placeholder="Select Category" *ngIf="selectedType === 'news'"
                                    formControlName="post_category" tabindex="1">
                                    <mat-option value="general">General</mat-option>
                                    <mat-option value="heading">Heading</mat-option>
                                </mat-select>
                                <mat-error *ngIf="postCategory.invalid && postCategory.touched">
                                    <span class="text-danger" *ngIf="postCategory.errors?.required">
                                        Category is required
                                    </span>
                                </mat-error>
                            </div>

                            <div class="input-box card-section">
                                <span class="detail mb-3">Title <span class="text-danger">*</span></span>
                                <input type="text" placeholder="Enter Title Here" formControlName="post_title"
                                    tabindex="2">
                                <mat-error *ngIf="postTitle.invalid && postTitle.touched">
                                    <span class="text-danger" *ngIf="postTitle.errors?.required">
                                        Title is required
                                    </span>
                                </mat-error>
                            </div>
                            <div class="input-box card-section">
                                <div class="detail mb-2">Short Description <span class="text-danger"
                                        *ngIf="!(blogForm.get('post_category')?.value === 'heading')">*</span></div>
                                <div class="position-relative">
                                    <!-- <textarea [formControl]="postContentControl" placeholder="Enter description here" tabindex="3"></textarea> -->
                                    <textarea formControlName="post_content" placeholder="Enter description here"
                                        tabindex="3" (keyup)="updateCounter($event.target.value)"></textarea>
                                    <!-- <mat-error *ngIf="postContent.invalid && postContent.touched">
                                        <span class="text-danger" *ngIf="postContent.errors?.required">
                                            Short Description is required
                                        </span>
                                    </mat-error> -->
                                    <mat-error
                                        *ngIf="blogForm.get('post_content')?.invalid && blogForm.get('post_content')?.touched">
                                        <span class="text-danger"
                                            *ngIf="blogForm.get('post_content')?.errors?.required">
                                            Post content is required
                                        </span>
                                    </mat-error>
                                    <!-- <div class="d-flex justify-content-between">
                                        <div class="warning text-nowrap" *ngIf="length_error_message">Maximum character
                                            limit reached!</div>
                                        <div class="counter text-right w-100" [class.exceeded]="length_error_message">
                                            {{charector}}/{{ maxLength }}</div>
                                    </div> -->
                                </div>
                                <!-- <textarea formControlName="post_content"  placeholder="Enter description here" tabindex="3"></textarea> -->
                                <!-- <angular-editor formControlName="post_content" [config]="editorConfig" placeholder="Enter description here" tabindex="3"></angular-editor> -->
                            </div>




                        </div>

                        <div>
                            <div class="input-box card-section">
                                <span class="detail mb-3">Author</span>
                                <input type="text" placeholder="" formControlName="post_author" tabindex="4">
                            </div>

                            <div class="input-box card-section">
                                <div class=" ">
                                    <span class=" d-flex w-100">Date <span class="text-danger">*</span></span>
                                    <mat-form-field>
                                        <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker1"
                                            placeholder="DD/MM/YYYY" formControlName="post_date" class="custom_input">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <mat-error *ngIf="postDate.invalid && postDate.touched">
                                    <span class="text-danger"
                                        *ngIf="postDate.errors?.required && !postDate.errors?.matDatepickerParse">
                                        Date is required
                                    </span>
                                    <span *ngIf="postDate.errors?.required && postDate.errors?.matDatepickerParse"
                                        class="text-danger">Invalid date</span>
                                    <span *ngIf="postDate.errors?.matDatepickerMax" class="text-danger">Please choose a
                                        date that is on or before today's date</span>
                                    <span *ngIf="postDate.errors?.matDatepickerMin" class="text-danger">Dates before
                                        2010 are not valid!</span>
                                </mat-error>
                            </div>



                            <div class="input-box card-section">
                                <span class="detail mb-3">URL <span class="text-danger"
                                        *ngIf="!(blogForm.get('post_category')?.value === 'heading')">*</span></span>
                                <input type="text" placeholder="Enter url here" formControlName="post_url" tabindex="7">
                                <mat-error
                                    *ngIf="blogForm.get('post_url')?.invalid && blogForm.get('post_url')?.touched">
                                    <span class="text-danger" *ngIf="blogForm.get('post_url')?.errors?.required">
                                        URL is required
                                    </span>
                                </mat-error>
                            </div>
                            <!-- Image Upload Section  -->
                            <div class="d-flex align-content-end image_container">
                                <div class="card-section image_card_section">
                                    <div class="detail">Images<span class="text-danger"
                                            *ngIf="!(blogForm.get('post_category')?.value === 'heading')">*</span></div>
                                    <!--  -->
                                    <div class="data-div">

                                        <div class="">
                                            <!-- formarray -->
                                            <div class="mt-2">
                                                <div>
                                                    <div class="groups">
                                                        <div class="image_thumbnail">
                                                            <ul>
                                                                <li>
                                                                    <div class="file_button">
                                                                        <span class="uploadOutlined">
                                                                            <span *ngIf="imageloader"
                                                                                class="loader"></span>
                                                                            <span *ngIf="!imageloader"
                                                                                class="plus">+</span>
                                                                            <span>Drop file <br> OR <br> Upload</span>
                                                                        </span>
                                                                        <input [tabindex]="8" [disabled]="imageloader"
                                                                            type="file" class="pointer" title=" "
                                                                            accept="image/*"
                                                                            (change)="uploadImage($event)">
                                                                    </div>

                                                                </li>
                                                                <li *ngIf="imageUrls">
                                                                    <img [src]="imageUrls" alt="" class="thumb">
                                                                    <span class="pointer remove_image"
                                                                        matTooltip="Remove Image"
                                                                        (click)="removeBlogImage()">
                                                                        <img src="../../../../assets/images/svgicons/delete_image.png"
                                                                            alt="">
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--  -->
                                        </div>
                                    </div>
                                    <mat-progress-bar mode="indeterminate" color="primary"
                                        *ngIf="imageloader"></mat-progress-bar>
                                </div>

                                <div class="button d-flex justify-content-end">
                                    <button *ngIf="action_type=='add'" type="submit" tabindex="50"
                                        [disabled]="imageloader"> Submit</button>
                                    <button *ngIf="action_type=='edit'" type="button"
                                        (click)="updateValuation(blogForm)" tabindex="50" [disabled]="imageloader">
                                        Update</button>
                                    <button class="cancel-btn" (click)="goBack()" tabindex="61">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

</div>