import { Injectable } from '@angular/core';

@Injectable()
export class ShareService {
  tableObj:any
  notify_obj: any;
  adminComp: any;
  appComp:any;
  constructor() { }

}
