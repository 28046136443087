import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-templates-factory',
  templateUrl: './templates-factory.component.html',
  styleUrls: ['./templates-factory.component.css']
})
export class TemplatesFactoryComponent implements OnInit {

  @Input() templates:any = [];
  @Input() editing:boolean = false;
  @Input() showAddButton:boolean = false;
  @Output() valueChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  // functions========================================================================================================
  addThisTemplate(type){
    this.valueChange.emit(type);
  }
  // functions========================================================================================================
}
