<mat-toolbar class="creategroup">
    <div>Confirmation</div>
</mat-toolbar>
<mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <span>Are you sure you wish to delete Polygon - {{selectedPoly.label[currentLanguage]}} ?</span>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
    <button mat-button class="blue-button" (click)="delete()">Yes</button>
    <button mat-raised-button (click)="close();">No</button>
</mat-dialog-actions>