<div class="w-100 p-2 d-flex justify-content-center align-items-center">
  <mat-card appearance="outlined" class="m-2 mat-elevation-z6">
    <mat-card-header class="position-relative">
      <mat-card-title class="mx-3">
        Download Price Data
      </mat-card-title>
      <mat-progress-bar class="mtc-progress" *ngIf="loader" [color]="'primary'" mode="indeterminate">
      </mat-progress-bar>
    </mat-card-header>

    <mat-card-content class="overFlow">
      <section class="w-100">
        <h4 class="m-2 mat-h2 text-center">Select a price type to download</h4>
        <div class="mx-3 price-form">
          <div class="center-div">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Select Neighborhood</mat-label>
                <mat-select [disabled]="loader" (selectionChange)="selectDataType({neighborhood: $event.source.value})">
                  <input class="searchIn" mat-input [(ngModel)]="searchText" placeholder="Type here to search..." />
                  <mat-option *ngFor="let neigh of neighborhoodsList | filter : 'neigh_name': searchText" [value]="neigh.neigh_uid">
                    {{neigh.neigh_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="w-100">
                <mat-label>Select Block</mat-label>
                <mat-select multiple [disabled]="!selectedNeighborhood || loader"
                  (selectionChange)="selectDataType({block: $event.source.value})">
                  <mat-option *ngFor="let block of blocksList" [value]="block.block_uid">
                    {{block.block_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="w-100">
                <mat-label>Price types</mat-label>
                <mat-select [disabled]="!selectedNeighborhood || loader"
                  (selectionChange)="selectDataType({type: $event.source.value})">
                  <mat-option *ngFor="let type of dataTypes" [value]="type.value">
                    {{type.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="pb-4">
              <button class="w-100" mat-raised-button color="primary"
              [disabled]="!selectedNeighborhood || !selectedType || loader" (click)="download()">Download</button>
            </div>
          </div>
        </div>
      </section>
      <section class="w-100">
        <!-- <mat-form-field>
          <input matInput [matDatepicker]="picker" placeholder="From date">
          <mat-datepicker-toggle matSuffix [for]="pickerFrom" [disabled]="false"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
        <span class="mr-2 ml-2">-</span>
        <mat-form-field>
          <input matInput [matDatepicker]="picker" placeholder="To date">
          <mat-datepicker-toggle matSuffix [for]="pickerTo" [disabled]="false"></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field> -->
      </section>
    </mat-card-content>
  </mat-card>


</div>
