import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiserviceService } from '../../../apiservice.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: "app-copy-paste",
  templateUrl: "./copy-paste.component.html",
  styleUrls: ["./copy-paste.component.scss"],
})
export class CopyPasteComponent implements OnInit {
  neighList:any= [];
  searchText: string = "";
  block_search: string = "";
  copyData;

  blockList: any=[];
  loader: boolean;

  selected_neighbourhood=[];
  selected_block=[];
  dataSource = [];

  constructor(
    public dialogRef: MatDialogRef<CopyPasteComponent>,
    @Inject(MAT_DIALOG_DATA) public parentData: any,
    private fb: UntypedFormBuilder,
    public api: ApiserviceService,
    private notify:NotificationService,
  ) {
    this.neighList = [];
    if(this.parentData.neigh_list?.length){
      this.neighList = this.parentData.neigh_list;
    }
    this.dataSource = this.parentData.dataSource;
    this.copyData = this.parentData.data;
   
  }

  getValue(event){
      this.selected_neighbourhood=event
  }
  getValueBlock(event){
    this.selected_block=event
  }

  ngOnInit(): void {}

  getBlocks(value) {
    this.selected_neighbourhood=value
    this.loader=true;
    this.blockList=[];
    let selected_block_list=[];
    let neigh_list = this.dataSource.filter(e => e.neigh_uid == value);
    neigh_list.map(e=>{
      selected_block_list.push(e.block_uid);
    })
    this.api.getData( `blocks/details?neigh_uid=${value}&get_geom=false&get_media=false`)
      .subscribe((res: any) => {
        this.loader=false;
        if(res.data){
          let block_List=[];          
          res.data.map(e=>{
            e['block_name']= (e.block_name && e.block_name[this.api.language]) ? e.block_name[this.api.language] : '';
            if(!selected_block_list.includes(e.block_uid) && e.block_uid){
              block_List.push(e);
            }
          })
          
          block_List.sort(function(a, b) {
            return a.block_name - b.block_name;
          });  
          this.blockList = block_List;
        }        
      }, err=>{
        this.loader=false;
      });
  }


  submit(Value=null) {
   let body = {
      "copy_id": this.copyData.id,
      "entity":this.copyData.entity,
      "info_type": this.copyData.info_type,
      "added_by": this.api.user_id,
      "neigh_uid": this.selected_neighbourhood.toString(),
      "block_uid": this.selected_block ? this.selected_block.toString(): null
    }
    this.loader=true;
    this.api.postData(`copy-city-details`, body).subscribe({
      next: (res:any)=>{
      this.loader=false;
      if(res && res.status==201){
        this.notify.notify(res.message, "success");
        this.dialogRef.close(true);
      }else{
        this.notify.notify(res.message, "warn");
      }
    },error : err=>{
      this.loader=false;
      }
    })
  }
  close() {
    this.dialogRef.close(false);
  }
}
