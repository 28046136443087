import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { NotificationService } from '../../../services/notification.service';
import { ApiserviceService } from '../../../../apiservice.service';

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.css']
})
export class CreateTemplateComponent implements OnInit, AfterViewInit {
  public loader: boolean = false;
  public addElements: any = {}
  public dataTypes: any=[];
  public dataTypeLoader: boolean = false;
  public datatypevalidatore: boolean = true;
  get fileType() {
    return this.templateForm.get('fileType');
  };
  get tableName() {
    return this.templateForm.get('tableName');
  };
  get templateColumnName() {
    const colname = this.templateForm.get('columnName') as FormArray;
    return colname.at(0).get('name');
  };
  get templateColumnDataType() {
    const colname = this.templateForm.get('columnName') as FormArray;
    return colname.at(0).get('data_type');
  };
  get templateColumnsColName() {
    const colname = this.templateForm.get('columnName') as FormArray;
    return colname.at(0).get('col_name');
  };
  public displayedColumns = ['Name',
    'Column Name',
    'Data-Type',
    'Json Key',
    'Ref. Table',
    'Ref. Col. Name',
    'Ref. Condition Name',
    'Required',
    'Action'];
  @ViewChild('templateTable') table: MatTable<any>;
  @Input() public parent;
  @Input() public mode;
  @Input() public selectedElement;
  public col_name_valueset: Array<{}> = [];
  public templateForm: any = [];
  public req_conrtrol_count: number = 0;
  constructor(private api: ApiserviceService,
    private notify: NotificationService,
    private fb: FormBuilder) {
  this.getDataTypes();
  }

  /**  
 * This is a functionto add form field to the form group

 * @returns {FormGroup} Returns Form group to add to the Form Array.
 */
  initFields() {
    return this.fb.group({
      name: ['',Validators.required],
      data_type: [{value:'',disabled:this.datatypevalidatore},Validators.required],
      col_name: ['',Validators.required],
      json_key: [''],
      required: [''],
      ref_table: [''],
      ref_col_name: [''],
      ref_condition: ['']
    })
  }

  /**  
 * This is a functionto add form field to the form array
 * @check if total language available is not equal to added language to the form

 * @returns {} Returns void.
 */
  addRow() {
    this.col_name_valueset.push({ name: '', data_type: '', col_name: '', json_key: '', required: '', ref_table: '', ref_col_name: '', ref_condition: '' });
    /* #Form */
    let dd = <FormArray>this.templateForm.controls.columnName;  
    dd.push(this.initFields());
    /* End - #Form */
  }

    /**  
 * This is a functionto remove a language selection dropdown and its input field 
 * from the form in the view

 * @param key @type {Number} - index value of the language selection dropdown to be removed
 * @check if total language added to the form is greater than 1
 * @returns {} Returns void.
 */
  removeRow(key) {
    if (this.col_name_valueset.length > 1) {
      this.col_name_valueset.splice(key, 1);
      let dd = <FormArray>this.templateForm.controls.columnName;
      dd.removeAt(key);
    }
  }

  addTableRow(index?) { 
    this.datatypevalidatore = false;
    this.addRow(); 
    let templateForm = this.fb.group({
      name: [''],
      data_type: [''],
      col_name: [''],
      json_key: [''],
      required: ['false'],
      ref_table: [''],
      ref_col_name: [''],
      ref_condition: ['']
    })
    if (this.mode == 'edit') {
      if(index || index == 0){
        this.selectedElement.column_name.splice(index+1, 0, templateForm.value);
      }else{
        this.selectedElement.column_name.push(templateForm.value);
      }
    }
    if (this.mode == 'add') {
      if(index || index == 0){
        this.addElements.column_name.splice(index+1, 0,templateForm.value);
      }else{
        this.addElements.column_name.push(templateForm.value);
      }

    }
    this.datatypevalidatore = true;
    this.table.renderRows();
    // this.templateForm.reset();
  }

  removeTableRow(element?,index?) {
    if (this.mode=='edit') {
      if (this.selectedElement.column_name.length == 1) {
        return;
      }
      this.selectedElement.column_name.forEach((value, index) => {
        if (value == element) {
          this.selectedElement.column_name.splice(index, 1)
          this.table.renderRows();
        }
      });
      this.removeRow(index)
    }
    if (this.mode=='add') {
      if (this.addElements.column_name.length == 1) {
        return;
      }
      this.addElements.column_name.forEach((value, index) => {
        if (value == element) {
          this.addElements.column_name.splice(index, 1)
          this.table.renderRows();
        }
      });
      this.removeRow(index)
    }
  }

    /**  
  * This is a functionto add CSV Master with data from FORM in the HTML
  * and add it to the system.
  * Logs User actitivy to the system.
 
  * @param data @type {FormGroup} - Form Vales to be added to the system as a new CSV Master
  * @api POST /csv-master
  * @returns {} Returns void.
  */
  saveTemplate(data:FormGroup) {
    this.loader = true;
    let value = data.value; 
    for (let index = 0; index < value.columnName.length; index++) {
      const element = value.columnName[index];
      if(!element.name || !element.data_type || !element.col_name){
        this.loader = false;
        this.notify.notify("Please fill all required fields!",'error');
        return;
      }
      else if(element.ref_table && !element.ref_col_name ){
        this.loader = false;

        this.notify.notify("Enter Reference Column Name",'warn');
        return;
      }
      else if(!element.ref_table && element.ref_col_name ){
        this.loader = false;

        this.notify.notify("Enter Reference Table Name",'warn');
        return;  
      }
      if (element.ref_table && element.ref_col_name) {
        element['refrence'] = {
          table: element.ref_table,
          col_name: element.ref_col_name,
          condition_name: element.ref_condition ? element.ref_condition : ''
        }
        delete element['ref_table'];
        delete element['ref_col_name'];
        delete element['ref_condition'];
      }
      else {
        delete element['ref_table'];
        delete element['ref_col_name'];
        delete element['ref_condition'];
      }}
    let templateBody = {
      file_type: value.fileType,
      column_name: value.columnName,
      table_name: value.tableName,
      status: 1,
      added_by: this.api.user_id,
      // sort_order: 0
    };
    if (value.ref_col_name && value.ref_table) {
      templateBody['refrence'] = {
        table: value.ref_table,
        col_name: value.ref_col_name,
        condition_name: value.ref_condition ? value.ref_condition : ''
      }
    }
    this.api.postData(`csv-master`, templateBody).subscribe((res: any) => {
      this.loader = false;;
      if (res && res.data && res.status == 201) {
        this.notify.notify(res.message,'success');
        // const activity = {
        //   userdata: templateBody,
        //   actionId: 14,
        //   entityId: res.data,
        //   entity:'csv_template'
        // };
        // this.api.logUserActivity(activity);
        this.parent.addTemplate = false;
        this.parent.getTemplates();
      } else {
        this.notify.notify(res.message,'error');
      }
    }, err => {
    });
  }

  /**  
  * This is a functionto update CSV Master with data from FORM in the HTML
  * and update it to the system.
  * Logs User actitivy to the system.
 
  * @param data @type {FormGroup} - Form Vales to be added to the system as a new CSV Master
  * @api PATCH /csv-master
  * @returns {} Returns void.
  */
  updateTemplate(data) { 
    this.loader=true; 
    let value = data.getRawValue(); 
    for (let index = 0; index < value.columnName.length; index++) {
      const element = value.columnName[index]; 
      if(!element.name || !element.data_type || !element.col_name){
        this.loader = false;
        this.notify.notify("Please fill all required fields!",'error');
        return;
      }
      else if(element.ref_table && !element.ref_col_name ){
        this.loader = false;

        this.notify.notify("Enter Reference Column Name",'warn');
        return;
      }
      else if(!element.ref_table && element.ref_col_name ){
        this.loader = false;

        this.notify.notify("Enter Reference Table Name",'warn');
        return;  
      }
      if (element.ref_table && element.ref_col_name) {
        element['refrence'] = {
          table: element.ref_table,
          col_name: element.ref_col_name,
          condition_name: element.ref_condition ? element.ref_condition : ''
        }
        delete element['ref_table'];
        delete element['ref_col_name'];
        delete element['ref_condition'];
      }
      else {
        delete element['ref_table'];
        delete element['ref_col_name'];
        delete element['ref_condition'];
      }
    }
    let templateBody = {
      id: this.selectedElement.id,
      column_name: value.columnName,
      table_name: value.tableName,
      updated_by: this.api.user_id,
    };
    // const activity = {
    //   userdata: templateBody,
    //   actionId: 3,
    //   entityId:this.selectedElement.id,
    //   entity:'csv_template'
    // };
    this.api.patchData(`csv-master`, templateBody).subscribe((res: any) => {
      this.loader = false;
      if (res && res.data && res.status == 201) {
        this.notify.notify(res.message,'success');
        // this.api.logUserActivity(activity);
        this.parent.addTemplate = false;
        this.parent.getTemplates();
      } else {
        this.notify.notify(res.message,'error');
      }
    }, err => {
    });
  }

  ngOnInit(): void {
    // this.addTableRow();
    if (this.mode == "add") {
      this.templateForm = this.fb.group({
        fileType: ['', Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9_ ]*$')])],
        columnName: this.fb.array([]),
        tableName: ['', Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9._ ]*$')])],
        required: ['']
      });
      // this.addRow();
      this.addElements['column_name'] = [];
      this.addTableRow();
    }
    if (this.mode == "edit") {
      this.templateForm = this.fb.group({
        fileType: [{ value: '', disabled: true }, Validators.required],
        columnName: this.fb.array([]),
        tableName: ['',Validators.required],
        status: [''],
        required: ['']
      });
      // this.templateForm.reset();
      let editData = this.selectedElement, columns: any = [], index: number = 0;
      if (editData) {
        for (let prop of editData.column_name) {
          let is_req: boolean = false;
          if (prop.required == 'true' || prop.required==true) {
            is_req = true;
          }
          columns[index] = {
            name: prop.name ? prop.name : '',
            data_type: prop.data_type ? prop.data_type : '',
            col_name: prop.col_name ? prop.col_name : '',
            json_key: prop.json_key ? prop.json_key : '',
            ref_table: prop.refrence ? prop.refrence.table : '',
            ref_col_name: prop.refrence ? prop.refrence.col_name : '',
            ref_condition: prop.refrence ? prop.refrence.condition_name : '',
            required: is_req
          };  // +prop to convert into number
          this.addRow();
          index++
        }
      }
      this.templateForm.patchValue({
        fileType: this.selectedElement ? this.selectedElement.file_type : '',
        columnName: columns ? columns : '',
        tableName: this.selectedElement ? this.selectedElement.table_name : '',
        status: this.selectedElement ? this.selectedElement.status : ''
      })
    }
  }

  /**  
 * This is a function to GET list of Data types from API.
 * List of data types are stored in {@link dataType} variable.

 * @api GET /data-types
 * @returns {} Returns void.
 */

  getDataTypes(){
    this.dataTypeLoader=true;
    this.api.getData('data-types').subscribe((res:any)=>{
      this.dataTypeLoader=false;
      if(res.data && res.status==200){
        this.dataTypes = res.data;
      }else{
        this.notify.notify(res.message,'error');
      }
    },err=>{
      this.notify.notify(err,'error');
    })
  }

  ngAfterViewInit() {

  }

}
