import { Component, OnInit } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';;
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { MatDialog} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-broker-master',
  templateUrl: './broker-master.component.html',
  styleUrls: ['./broker-master.component.css']
})

export class BrokerMasterComponent implements OnInit {
  master_types: any = MASTERS;
  
  selectedFormName: any;

  constructor(private api: ApiserviceService, private menu: AdminMenusService,
    public dialog: MatDialog,
    private router: ActivatedRoute) {
    this.menu.setActive('broker_master');
   
  }

  isSelected(name) {
    return (this.selectedFormName === name) ? 'selected' : '';
  }
  ngOnInit() {
    this.router.params.subscribe(params => {
      const name = params['page'];
      this.selectedFormName = name;
    });
   }

  getIndex(index) {
    return ((index % 10) == 0) ? (index % 10) + 1 : index % 10;
  }
}


//toggle new add form on button click

/**
 * API Master, API Configuration, Company, Brokers, Agents, Broker Area
 */
const MASTERS = [
  {
    name: "api-master",
    label: 'API Master',
    id: 1
  },
  {
    name: "company",
    label: 'Company',
    id: 2
  },
  {
    name: "company-area",
    label: 'Company Area',
    id: 3,
  },
  {
    name: "package",
    label: 'Package',
    id: 4,
  },
  {
    name: "broker-package-subscription",
    label: 'Broker Package Subscription',
    id: 6
  }
]