<div class="">
    <div class="header-bar">
        <span *ngIf="action_type=='add'">Add API Master</span>
        <span *ngIf="action_type=='edit'">Update API Master</span>
    </div>
    <mat-progress-bar *ngIf="loader" class="progress" [color]="'primary'" mode="indeterminate">
    </mat-progress-bar>
    <form [formGroup]="APiMasterForm" autocomplete="off" novalidate (ngSubmit)="addAPiMaster(APiMasterForm.value)" class="package-form">
        <mat-dialog-content style="margin: 0; padding: 0;">
            <div class="grid">                 
                                       
                <div class="card-section">
                    <span class="detail mb-3">Short Name <span class="text-danger">*</span></span>
                    <input type="text" placeholder="Enter short name" formControlName="short_name">
                    <mat-error *ngIf="APiMasterForm.controls['short_name'].hasError('required') && APiMasterForm.controls['short_name'].touched">Short name is required</mat-error>
                </div>
            
                <div class="card-section">
                    <span class="detail mb-3">Url <span class="text-danger">*</span></span>
                    <input type="text" placeholder="Enter url" formControlName="url">
                    <mat-error *ngIf="APiMasterForm.controls['url'].hasError('required') && APiMasterForm.controls['url'].touched">Url is required</mat-error>
                    <mat-error *ngIf="APiMasterForm.controls['url'].hasError('pattern') && APiMasterForm.controls['url'].touched">Please input valid URL with https://</mat-error>
                </div>
                <div class="card-section">
                    <span class="detail mb-3">Description</span>
                    <textarea  type="text" placeholder="Enter description" formControlName="description"></textarea>
                </div>

            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <div class="d-flex justify-content-end px-4">
                <button type="button" (click)="close()" class="cancel-btn mx-1">Cancel</button>
                <button *ngIf="action_type=='add'" type="submit" class="save-button mx-1" [disabled]="loader" tabindex="60"> Submit</button>
                <button *ngIf="action_type=='edit'" type="button" class="save-button mx-1" (click)="addAPiMaster(APiMasterForm.value)" [disabled]="loader"> Update</button>
            </div>
        </mat-dialog-actions>
    </form>
    
</div>