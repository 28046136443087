<div class="h-100 bg-white">
    <div fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
        <div fxLayout="row" class="bleft" fxLayoutAlign="space-between center">
            <h4 class="open-sans mb-0 text-nowrap" style="color: #616474;">CSV Upload</h4>
        </div>
        <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="button-b">
                <mat-chip-listbox class="mr-2 ml-2">
                    <mat-chip-option class="vspace" *ngIf="fileName" [selectable]="selectable" [removable]="removable" (removed)="reset()">
                        {{fileName}}
                        <mat-icon matChipRemove *ngIf="removable" class="lnk Rejected">cancel</mat-icon>
                    </mat-chip-option>
                </mat-chip-listbox>
                <span class="upload-btn-wrapper">
                    <button class="btn-browse mr-2"> BROWSE FILE </button>
                    <input type="file" name="myfile" #myFileInput (change)="handleFileInput($event)" accept=".zip" />
                </span>
                <button (click)="uploadFile()" mat-button class="btn-upload" [disabled]="uploadLoading">
                    <span class="material-icons">file_upload</span> UPLOAD</button>
            </div>
        </div>
    </div>
    <div>
        <div class="mat-loader">
            <mat-progress-bar *ngIf="uploadLoading || acceptLoading" mode="indeterminate">
            </mat-progress-bar>
        </div>
        <app-loader *ngIf="csvListLoader"></app-loader>
        <div class="d-flex justify-content-between align-items-center px-3 py-2 over_auto">
            <div class="text-nowrap">
                <span> Total Count: {{this.total}} </span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <a class="btn-download"  routerLink="/admin/delete-csv-upload">Manage upload CSV   </a>
                <button class="btn-download d-flex justify-content-center align-items-center" [matMenuTriggerFor]="menu">
                    <span class="d-flex align-items-center justify-content-between">
                        Download template
                    </span>
                    <mat-icon class="down-icon" svgIcon="down_arrow_fill">
                    </mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="template-down">
                    <button (click)="downloadCSV('All')" mat-menu-item>
                        <span>All</span>
                    </button>
                    <button (click)="downloadCSV(json)" mat-menu-item *ngFor="let json of jsonData">
                        <span>{{json.file_type}}</span>
                    </button>
                </mat-menu>
                <mat-icon svgIcon="refresh" style="width: 45px; height: 33px; cursor: pointer;" (click)="refresh()"></mat-icon>
            </div>
        </div>

        <!-- <mat-spinner [diameter]="80" class="csvSpinner" mode="indeterminate" *ngIf="csvListLoader">
        </mat-spinner> -->


    </div>
    <div class="table-div w-100 px-3">

        <table mat-table [dataSource]="dataSource" class="csv-table w-100">
            <ng-container *ngFor=" let cols of displayedColumns">
                <ng-container matColumnDef="{{ cols }}">
                    <th mat-header-cell *matHeaderCellDef>
                        <span class="open-sans font-weight-bold" *ngIf="cols == 'Action'">Action</span>
                        <span class="open-sans font-weight-bold" *ngIf="cols == 'sno'">S.NO</span>
                        <span class="open-sans font-weight-bold" *ngIf="cols == 'Status'">Status</span>
                        <span class="open-sans font-weight-bold" *ngIf="cols == 'Added_Date'">Updated Date</span>
                        <span class="open-sans font-weight-bold" *ngIf="cols == 'File_Name'">File Name</span>
                        <span class="open-sans font-weight-bold" *ngIf="cols == 'Upload_ID'">Upload ID</span>
                        <span class="open-sans font-weight-bold" *ngIf="cols == 'Error'">Error Count</span>
                        <span class="open-sans font-weight-bold" *ngIf="cols == 'Total'">Total Count</span>
                        <span class="open-sans font-weight-bold" *ngIf="cols == 'Show_Log'">Show Log</span>
                        <!-- <span class="open-sans font-weight-bold" *ngIf="cols!='sno' && cols!='objective_label'">{{ cols }}</span> -->
                    </th>
                    <ng-container *ngIf="dataSource">
                        <td mat-cell *matCellDef="let element; let i=index;">
                            <span class="f-14" *ngIf="cols == 'sno'">
                                        {{offset+i+1}}.
                                    </span>
                            <div *ngIf="cols == 'Action'">
                                <span class="d-flex">
                                            <button [disabled]="hideAccept(element.status)" (click)="accept(element)"
                                                class="accept-button icon-btn mr-3">Accept</button> 
                                            <button [disabled]="hidestop(element.status)" (click)="stopProcess(element)"
                                                class="reject-button icon-btn mr-3">Stop process</button>
                                        </span>
                            </div>
                            <div *ngIf="cols=='Status'">
                                <span [class.red]="red(element.status)" [class.green]="getStatus(element.status)=='Accepted'">{{getStatus(element.status)}}</span>
                            </div>
                            <ng-container *ngIf="cols=='Total'">
                                <div *ngIf="(element.total_count != null || element.total_count != undefined)">
                                    <div class="val-acc-button"> {{element.total_count}} </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="cols=='Error'">
                                <div *ngIf="(element.error_count != null || element.error_count != undefined)">
                                    <div class="val-acc-button"> {{element.error_count}} </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="cols=='Show_Log'">
                                <span class="d-flex">
                                            <button class="val-acc-button mr-2"
                                                (click)="download_log(element,'validate')" [disabled]="element.status == 3">
                                                Validate Log </button>
                                                <button class="val-acc-button mr-2"
                                                (click)="download_log(element,'accept')" [disabled]="element.status == 3">
                                                Accept Log </button>
                                        </span>
                            </ng-container>
                            <p matTooltip={{returnVal(element,cols)}} *ngIf="cols != 'sno' && cols !='Action' && cols!='Status' && cols !='Total' && cols!='Error' && cols != 'Show_Log'" class="f-14 open-sans">
                                {{returnVal(element, cols)}}</p>
                        </td>
                    </ng-container>

                </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
        </table>
        <ng-container *ngIf="csvResponseStatus==404 && !csvListLoader">
            <div class="mt-5" fxLayout="row" fxLayoutAlign="center center">
                <span class="material-icons mr-2 availability">
                            error_outline
                        </span>
                <span class="availability">Not Available</span>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="dataSource">
        <div class="d-flex mt-2 justify-content-end">
            <mat-paginator [length]="total" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = $event; handlePagination($event)">
            </mat-paginator>
        </div>
    </ng-container>
</div>