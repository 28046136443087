import { Component, OnInit, Inject } from '@angular/core';
import { Validators, UntypedFormBuilder, FormArray } from "@angular/forms";
import { ApiserviceService } from "../../../apiservice.service";
import { NotificationService } from "../../services/notification.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommonfunctionService } from "../../../services/commonfunction.service";
import { UserAction } from "../../user-action/user-activity";
@Component({
  selector: 'app-add-transaction-attribute',
  templateUrl: './add-transaction-attribute.component.html',
  styleUrls: ['./add-transaction-attribute.component.css']
})
export class AddTransactionAttributeComponent implements OnInit {
  languages: Array<any> = [];
  languageSet: Array<{}> = [];
  transactForm: any;
  parent_data;
  mode;
  loader: boolean;
  lang_dependent: boolean = false;
  is_detail: boolean = false;
  is_required: boolean = false;
  dataTypes: any = [{
    label: "Text",
    value: "text"
  }, {
    label: "Decimal",
    value: "decimal"
  }];
  selectedListType: string;
  selectType: any = [{
    label: "Attribute Name",
    value: "attribute_name"
  }, {
    label: "Column Name",
    value: "column_name"
  }];

  transaction_name: any;
  listData: any = [];


  constructor(public dialogRef: MatDialogRef<AddTransactionAttributeComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any,
    private fnc: CommonfunctionService,
    private notify: NotificationService,
    private api: ApiserviceService,
    private fb: UntypedFormBuilder
  ) {
    this.parent_data = this.info;
    this.mode = this.parent_data.type;
    this.getLanguages();
    if (this.mode == 'add') {
      this.transactForm = this.fb.group({
        type: ['', Validators.required],
        name: ['', Validators.required],
        description: ['', Validators.required],
      });
    }
    if (this.mode == 'edit') {

      this.transactForm = this.fb.group({
        name: [{ value: '', disabled: true }],
        description: [{ value: '', disabled: false }],
      });
      this.listData.push({
        "name": this.parent_data.edit.attribute_name,
        "label": this.parent_data.edit.attribute_label,
      })
      setTimeout(() => {
        this.transactForm.patchValue({
          'name': this.parent_data.edit.attribute_name,
          'description': this.parent_data.edit.description,
        });
      });
    }
  }


  ngOnInit() {
  }

  //function to call attribute name or column name on the basis of selection
  selectTypeList(event) {
    this.selectedListType = event.value;
    if (this.selectedListType == "column_name") {
      this.getColumn();
    }
    if (this.selectedListType == "attribute_name") {
      this.getAttributName();
    }
  }

  /**
   * This function use to getting attribute
   * @returns
   */
  getAttributName() {
    this.listData = [];
    this.loader = true;
    let url = `em-attributes?module_type=deadmin&entity=property`;
    this.api.getData(url).subscribe({
      next :(res: any) => {
      if (res && res.data) {
        if (res.data.length) {
          res.data.forEach(element => {
            this.listData.push({
              "id": element.attribute_id,
              "name": element.attribute_name,
              "label": element.attribute_label,
            })

          });
        }
      }
      this.loader = false;
    }, error: err => {
      this.listData = [];
      this.loader = false;
    }})
  }

  /**
   * This function use for getting listable-column from api
   * @returns
   */
  getColumn() {
    this.listData = [];
    this.loader = true;
    let url = `listable-column?type=properties&action=get`;
    this.api.getData(url).subscribe({
      next:(res: any) => {
      if (res && res.data) {
        if (res.data.length) {
          res.data.forEach(element => {
            this.listData.push({
              "id": 1,
              "name": element.column_name,
            })

          });
        }
      }
      this.loader = false;
    }, error: err => {
      this.listData = [];
      this.loader = false;
    }})
  }



/**
 * This is a function that use to save transaction-attribute given payload
 * @param val val is payload of transaction-attribute
 * @returns
 */
  addTransaction(val) {
    let data = val.value;
    let url = `transaction-attribute`;
    let body = {
      "attribute_id": null,
      "attribute_name": null,
      "attribute_label": null,
      "parameter": null,
      "column_name": null,
      "description": data.description,
      "data_type": null,
      "field_length": null,
      "is_lang_dependent": this.lang_dependent,
      "is_required": this.is_required,
      "is_show_in_details": this.is_detail,
      "status": 1,
      "added_by": this.api.user_id,
      "sort_order": null,
      "user_id": this.api.user_id
    }
    if (this.selectedListType == "attribute_name") {
      let att_details = this.fnc.getArrayValue('name', data.name, this.listData);
      body.attribute_name = data.name;
      if (att_details) {
        body.attribute_id = att_details.attribute_id;
        body.attribute_label = att_details.label;

      }
    }
    if (this.selectedListType == "column_name") {
      let column_details = this.fnc.getArrayValue('name', data.name, this.listData);
      body.column_name = data.name;
      body.attribute_name = data.name;
      if (column_details) {
        body.column_name = column_details.column_name;
        body.data_type = column_details.data_type;
      }
    }
    
    this.loader = true;
    this.api.postData(url, body).subscribe((res: any) => {
      this.loader = false;
      if (res && res.status == 201) {
        // const activity={
        //   actionId: res.data,
        //   data: body,
        //   actionType:UserAction.add_transaction_attribute.Action_ID
        // }
        // this.api.logUserActivity(activity);
        this.notify.notify(res.message, 'success');
        this.parent_data.pdata.getTransactionAttributes();
        this.parent_data.pdata.getTransactCount();
        this.dialogRef.close();

      }
      else {
        this.loader = false;
        this.notify.notify(res.message, 'error')
      }

    }, err => {
      this.loader = false;
      this.notify.notify('valuation factor Add Failure', 'error');
    })
  }

  /**
 * This is a function that use to update transaction-attribute given payload
 * @param val val is payload param
 * @returns
 */
  updateValuation(val) {
    let updateData = val.value;
    let url = `transaction-attribute`
    let body = {
      "transaction_attribute_id": this.parent_data.edit.transaction_attribute_id,
      "description": updateData.description,
      "added_by": this.api.user_id,
      "user_id": this.api.user_id,
    }
    this.loader = true;
    this.api.patchData(url, body).subscribe((res: any) => {
      this.loader = false;
      if (res && res.status == 201) {
        // const activity={
        //   actionId: res.data,
        //   data: body,
        //   actionType:UserAction.update_transaction_attribute.Action_ID
        // }
        // this.api.logUserActivity(activity);
        // this.getCategory();
        this.dialogRef.close();
        this.notify.notify(res.message, 'success');
        this.parent_data.pdata.getTransactionAttributes();
        this.parent_data.pdata.getTransactCount();
      }else{
        this.loader = false;
        this.notify.notify(res.message, 'error')
      }
    }, err => {
      this.loader = false;
      this.notify.notify('Trnsaction Attribute Updation Error', 'error');
    })
  }



  getLanguages() {
    this.api.getUmsData('language')
      .subscribe(
        (res: any) => {
          if (res && res.data) {
            res.data.forEach(element => {
              if (element.status == 1) {
                this.languageSet.push(element);
              }
            });
          }
         
        }
      );
  }

  initLanguageFields() {
    return this.fb.group({
      language: [''],
      value: ['']
    })
  }

  cancel() {
    this.dialogRef.close();
  }
}
