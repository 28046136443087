import { Injectable } from '@angular/core';
import { createEffect, Actions } from '@ngrx/effects'; //npm i @ngrx/effects
import { Action, Store } from '@ngrx/store';

// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/switchMap';
// import 'rxjs/add/operator/switch';
// import 'rxjs/add/observable/timer';
import { map,switchMap, timer } from 'rxjs';

import { Observable } from 'rxjs';

import * as ApplicationActions from './actions';

@Injectable()
export class ApplicationEffects {

  //APPLICATION_TIMEOUT_TIME = 1000 * 60 * 5;  // 5 min auto logout
    // APPLICATION_TIMEOUT_TIME = 1000 * 60 * 30;

    
    // extendApplicationTimeout$ = createEffect(() => this.actions$.pipe(switchMap( ( action: Action ) => timer(this.APPLICATION_TIMEOUT_TIME) 
    // )).pipe(map(() => new ApplicationActions.LogOut()))
    //     );

    constructor( private actions$: Actions ) {}
}
