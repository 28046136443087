import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ConfirmDialogBox } from '../../../confirm-dialog/confirm-dialogbox';
import { NotificationService } from '../../../services/notification.service';
import { ApiserviceService } from '../../../../apiservice.service';

@Component({
  selector: 'app-query-index',
  templateUrl: './query-index.component.html',
  styleUrls: ['./query-index.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QueryIndexComponent implements OnInit {
  public templateQueryForm: any = [];
  public col_name_valueset: Array<{}> = [];
  public addElements: any = {};
  public loader: boolean = false;
  public listLoader:boolean=false;
  public selectedTemplate: any;
  public tableIndexList: any = [];
  get index_name() {
    const colname = this.templateQueryForm.get('queryIndex') as FormArray;
    return colname.at(0).get('index_name');
  };
  get index() {
    const colname = this.templateQueryForm.get('queryIndex') as FormArray;
    return colname.at(0).get('index');
  };
  get column() {
    const colname = this.templateQueryForm.get('queryIndex') as FormArray;
    return colname.at(0).get('column');
  };
  get condition() {
    const colname = this.templateQueryForm.get('queryIndex') as FormArray;
    return colname.at(0).get('condition');
  };

  @ViewChild('templateQueryTable') table: MatTable<any>;
  @Input() public parent;
  @Input() public mode;
  @Input() public selectedElement;
  public displayedColumns = [
    "index_name",
    "Index",
    "Column",
    "Condition",
    "Action"];
  public indexListCols=[
    "index_name",
    "index_def",
  ]
  remove_check: boolean = false;
  constructor(private api: ApiserviceService,
    private notify: NotificationService,
    private fb: FormBuilder,
    private dialog:MatDialog) {

  }

   /**  
 * This is a functionto add form field to the form group and if the form is 
 * in edit mode then it will be pre-populated

 * @param data @type {Object} - Object elmeent data to be populated in the form
 * @returns {FormGroup} Returns Form group to add to the Form Array.
 */
  initFields(data?) {
    if (data) {
      return this.fb.group({
        index_name: [data.index_name ? data.index_name : '',Validators.required],
        index: [data.index ? data.index.replace(/\(|\)/g, "") : '', Validators.required],
        column: [data.column ? data.column : ''],
        condition: [data.condition ? data.condition : ''],
      })
    }
    else {
      return this.fb.group({
        index_name: ['',Validators.required],
        index: ['', Validators.required],
        column: [''],
        condition: [''],
      })
    }
  }

/**  
 * This is a functionto add form field to the form array
 * @check if total language available is not equal to added language to the form

 * @param data @type {Object} - Object elmeent data to be populated in the form
 * @returns {} Returns void.
 */
  addRow(data?) {
    this.col_name_valueset.push({index_name:'', index: '', column: '', condition: '' });
    /* #Form */
    let dd = <FormArray>this.templateQueryForm.controls.queryIndex;
    dd.push(this.initFields(data));
    /* End - #Form */
  }

  /**  
 * This is a functionto remove a language selection dropdown and its input field 
 * from the form in the view

 * @param key @type {Number} - index value of the language selection dropdown to be removed
 * @check if total language added to the form is greater than 1
 * @returns {} Returns void.
 */
  removeRow(key) {
      this.col_name_valueset.splice(key, 1);
      let dd = <FormArray>this.templateQueryForm.controls.queryIndex;
      dd.removeAt(key);
  }


  addTableRow(data?) {
    this.addRow(data);
    let templateForm = this.fb.group({
      index_name:[''],
      index: [''],
      column: [''],
      condition: [''],
    })
    if (this.mode == 'edit') {
      this.selectedElement.query_index.push(templateForm.value);
    }
    if (this.mode == 'add') {
      this.addElements.query_index.push(templateForm.value);

    }
    this.remove_check = true;
    this.table.renderRows();
    let dataSource:any = this.table.dataSource
    if(dataSource.length <= 1 ){
    this.remove_check = false;
    }
    // this.templateForm.reset();
  }

  removeTableRow(element?, index?) {
    if (this.mode == 'edit') {
      this.selectedElement.query_index.forEach((value, index) => {
        if (value == element) {
          this.selectedElement.query_index.splice(index, 1)
          this.table.renderRows();
        }
      });
      this.removeRow(index)
    }
    if (this.mode == 'add') {
      this.addElements.query_index.forEach((value, index) => {
        if (value == element) {
          this.addElements.query_index.splice(index, 1)
          this.table.renderRows();
        }
      });
      this.removeRow(index)
    }
    let dataSource:any = this.table.dataSource
    if(dataSource.length <= 1 ){
    this.remove_check = false;
    }
  }
  
   /**  
  * This is a functionto update CSV Master with data from FORM in the HTML
  * and update it to the system.
 
  * @param data @type {FormGroup} - Form Vales to be added to the system as a new Objective
  * @api PATCH /csv-master
  * @returns {} Returns void.
  */
  updateQuery(data:FormGroup) {
    this.loader = true;
    let value = data.value;
    let template_indexes = [];
    value.queryIndex.forEach((data, index) => {
      Object.keys(data).forEach((obj) => {
        if (obj == 'index') {
          if(data[obj].indexOf("(") == 0){
            data[obj] = data[obj].substr(1,data[obj].length - 2);
          } 
          data[obj] = `(${data[obj]})`
        }
      })
      template_indexes[index] = data;
    });
    let body = {
      id: this.selectedTemplate.id,
      query_index: template_indexes,
      // status: this.selectedTemplate.status,
      updated_by: this.api.user_id
    }
    // const activity={
    //   userdata:body,
    //   actionId:4,
    //   entityId:this.selectedTemplate.id,
    // };
    this.api.patchData(`csv-master`, body).subscribe((res: any) => {
      this.loader = false;
      if (res.data && res.status == 201) {
        this.notify.notify(res.message, 'success')
        this.parent.closeDialog();
        // this.api.logUserActivity(activity);
      } else {
        this.notify.notify(res.message,'error');
      }

    }, err => {
    });
  }

  //add table row after successfull api response
  getTableIndexList(tableName) {
    this.listLoader=true;
    let url=`table-index-list?table_name=${tableName}`,temp=[],columns: any = [], index: number = 0;
    this.api.getData(url).subscribe((res: any) => {
      this.listLoader=false;
      if (res.data && res.status == 200) {
        res.data.map(element=>{
          const data={
            index_name:element.index_name ? element.index_name : '',
            index_def:element.index_def ? element.index_def :'',
          }
          temp.push(data);
        })
        this.tableIndexList = temp;
      }
    }, err => {
    })
  }

  ngOnInit(): void {
    if (this.mode == "add") {
      this.templateQueryForm = this.fb.group({
        queryIndex: this.fb.array([]),
      });
      this.selectedTemplate = this.selectedElement;
      this.selectedElement = null;
      this.addElements['query_index'] = [];
      this.addTableRow();
    }
    if (this.mode == "edit") {
      this.selectedTemplate = this.selectedElement;
      this.templateQueryForm = this.fb.group({
        queryIndex: this.fb.array([]),
      });
      let editData = this.selectedElement, columns: any = [], index: number = 0;
      if (editData) {
        if (!Array.isArray(editData.query_index)) {
          editData.query_index = [editData.query_index];
        }
        for (let prop of editData.query_index) {
          let indexString: string;
          if (prop.index) {
            indexString = prop.index.substr(1,prop.index.length - 2);
          }
          columns[index] = {
            index: indexString,
            condition: prop.condition ? prop.condition : '',
            column: prop.column ? prop.column : '',
          };  // +prop to convert into number
          this.addRow(prop);
          index++;
        }
      }
      this.templateQueryForm.patchValue({
        queryIndex: columns ? columns : '',
      })
      this.getTableIndexList(this.selectedElement.table_name)
    }
  }

  
  /**  
   * This is a functionto open confirmation dialog box while deleting a query index
  
   * @requires MatDialog
   * @requires ConfirmDialogBox
   * @returns {} Returns void.
   */
  confirmation() {
    if (!this.parent.template_access.DELETE) {
      this.notify.notify("You are not authorized to DELETE Indexes",'warn');
      return;
    }
    let _data = {
      type: 'delete',
      parent_data: null,
      message:"Looks like some indexe(s) were marked deleted. Do you want to delete them?",
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      panelClass:'show-dialog',
      disableClose: true,
      data: _data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'YES') {
        this.deleteAllIndex();
      } else if (result == 'NO') {
        dialogRef.close();
      }
    });
  }

    /**  
   * This is a functionto delete query index
  
   * @api PATCH /create-index
   * @returns {} Returns void.
   */
  deleteAllIndex(){
    let url='create-index';
    const body={
      index_data: null,
      table_name:this.selectedElement.table_name,
      updated_by:Number(this.api.user_id),
    }
    this.api.patchData(url,body).subscribe((res:any)=>{
      if(res.data && res.status==201){
        this.notify.notify(res.message,'success')
        this.parent.closeDialog();
      }else{
        this.notify.notify(res.message,'error');
      }
    },err=>{

    })
  }

}
