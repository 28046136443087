import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from '@angular/material/paginator';
import { NgForm, Validators, FormGroup, FormControl, UntypedFormBuilder, UntypedFormArray, NgModel } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ApiserviceService } from '../../apiservice.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { NotificationService } from '../services/notification.service';
import { Options } from 'sortablejs';
import { UserAction } from "../user-action/user-activity";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>umsapi_user_resource_post</li>
 * <li>emapi_category_get</li>
 * <li>emapi_category_post</li>
 * <li>emapi_category_patch</li>
 * <li>emapi_master_get</li>
 * <li>emapi_mastervalue_patch</li>
 * </ol>
 *
 */

@Component({
    selector: 'app-categories',
    templateUrl: 'categories.component.html',
    styleUrls: ['categories.component.css']
})
export class CategoriesComponent {

    displayedColumns = ['drag', 'sn', 'name', 'title', 'categories', 'status', 'action'];
    categoryItems = [];

    entities: any = [{ name: "property", label: "Property" },
        // { name: "property_floor", label: "Property floor" },
        // { name: "property_unit", label: "Property unit" }
    ]

    property_types = [];
    mastersList = [];
    sub_masters = [];
    masters_values = [];
    tempMasterValue: any = [];
    proprty_type: any;
    master: any;
    category_name: any;
    category: any;
    entity: any;
    sub_master: any;

    nearby = ['religious', 'schools', 'healthcare', 'parking', 'shopping', 'restaurants', 'park', 'social'];
    selected_entity_name: any;

    add_category: string = 'CATEGORYLIST';
    isFormDiv: boolean;
    updateDataId: number;
    dataSource: any = [];

    category_access = {
        'GET': true,
        'POST': true,
        'PATCH': true,
        'DELETE': true
    };
    loader: boolean = false;
    //Form
    languages: Array<any> = [];
    language_valueset: Array<{}> = [];
    categoryForm: any;
    editCategoryForm: any;
    status: any = true;
    statusCode: boolean=false;
    dialogReff: any;
    language_list: any = [];

    constructor(
        private menus: AdminMenusService,
        private notify: NotificationService,
        private apiService: ApiserviceService,
        private fnc: CommonfunctionService,
        private _fb: UntypedFormBuilder,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private dialog: MatDialog,
    ) {
        this.menus.setActive('categories');
        this.getAllowResources();
        this.getLanguages();
        iconRegistry.addSvgIcon('religious', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/svgicons/nearby/_religious.svg'))
        iconRegistry.addSvgIcon('schools', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/svgicons/nearby/_schools.svg'))
        iconRegistry.addSvgIcon('healthcare', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/svgicons/nearby/_healthcare.svg'))
        iconRegistry.addSvgIcon('parking', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/svgicons/nearby/_parking.svg'))
        iconRegistry.addSvgIcon('shopping', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/svgicons/nearby/_shopping.svg'))
        iconRegistry.addSvgIcon('restaurants', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/svgicons/nearby/_restaurants.svg'))
        iconRegistry.addSvgIcon('park', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/svgicons/nearby/_park.svg'))
        iconRegistry.addSvgIcon('social', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/svgicons/nearby/_social.svg'))
    }

    /**
   * API call to get Nearby with status
   */
  getDeleted(event) {
    this.statusCode = event.checked;
    this.getCategory();
  }
  
    /**
     * getAllowResources function getting allow resources
     * @returns Void
     */
    getAllowResources() {
        this.apiService.getUserRole();
        let body = `user/resource?place_id=${this.apiService.city_id}&user_id=${this.apiService.user_id}`
        this.apiService.getUmsData(body).subscribe({
            next : (res: any) => {
                this.apiService.allowResources = res.data;
                this.getAllowResource();
                // this.getAllowResource();
                // this.getCategory();
            }
            
        })

    }
    getAllowResource() {
        this.category_access = this.fnc.checkResourceAccess('nearby_categories', false);
    }

    getLanguages() {
        this.apiService.getUmsData('language')
            .subscribe(
                (res: any) => {
                    if (res && res.data) {
                        res.data.forEach(element => {
                            if (element.status == 1) {
                                this.language_list.push(element);
                            }
                        });
                    }
                }
            );
    }

    initLanguageFields() {
        return this._fb.group({
            language: [this.language_list[0].language_id, Validators.required],
            value: ['', Validators.required]
        })
    }

    addRow() {
        if (this.language_valueset.length != this.language_list.length) {
            this.language_valueset.push({ language: '', value: '' });
            /* #Form */
            let dd = <UntypedFormArray>this.categoryForm.controls.language;
            dd.push(this.initLanguageFields());
            /* End - #Form */
        }
        else {
            this.notify.notify('No more languages to add', 'error', 5000);

        }
    }

    removeRow(key) {
        if (this.language_valueset.length > 1) {
            this.language_valueset.splice(key, 1);
            let dd = <UntypedFormArray>this.categoryForm.controls.language;
            dd.removeAt(key);
        }
    }

    initForm(disabled = false) {
        this.language_valueset = [];
        this.categoryForm = this._fb.group({
            category_name: [{ value: '', disabled: disabled }, Validators.required],
            entity: ['', Validators.required],
            proprty_type: [],
            master: [],
            sub_master: [''],
            category: ['', Validators.required],
            language: this._fb.array([])
        });
        if (!disabled) {
            this.addRow();
            setTimeout(() => {
                this.categoryForm.patchValue({ 'language': [{ language: this.languages[0].language_id, value: '' }] });
            }, 2000);
        }
    }
    /**
     * addCategory function to add Category
     * @param val 
     * @param e 
     */
    addCategory(val, e) {
        if (!this.category_access.POST) {
            this.notify.notify("You are not authorized to Add Category", "warn");
            return;
        }
        let data = val.value;
        let re = /\ /gi;
        this.category_name = data.category_name.trim().replace(re, '_');
        let category_label = {};
        data.language.forEach((v, k) => {
            category_label[v.language] = v.value;
        });

        let url = `category`;
        let body = {
            "id": 0,
            "name": this.category_name,
            "icon": null,
            "label": category_label,
            "entity": data.entity,
            "categories": data.category,
            "user_id": this.apiService.user_id,
        }
        if (this.status) {
            body["status"] = 1;
        } else {
            body["status"] = 0;
        }
        // const activity={
        //     // actionId: UserAction.add_categories.Action_ID,
        //     data: body,
        //     actionType:UserAction.add_categories.Action_ID
        //   }
        this.loader = true;
        this.apiService.postData(url, body).subscribe((data: any) => {
            this.loader = false;
            if (data) {
                // data?.forEach(element => {
                        // if (element.code == 1) {
                        //   const activity={
                        //     actionId: element.id,
                        //     data: body,
                        //     actionType:UserAction.add_categories.Action_ID
                        //   }
                        //   this.apiService.logUserActivity(activity);
                        // }
                // })
                if (data.name && data.name == "error") {
                    this.notify.notify(data.detail, 'warn', 2500);
                    this.isFormDiv = true;
                }
                else {
                    this.notify.notify('Category Successfuly Added', 'success', 2500);
                    this.getCategory();
                    this.isFormDiv = false;
                    this.dialogReff.close();
                    this.add_category = 'CATEGORYLIST';
                    this.categoryForm.patchValue({ 'category_name': null, 'language': [{ language: null, value: '' }] })
                }
            }
        }, err => {
            this.loader = false;
            this.notify.notify('category add fail', 'error');
        })
    }

    changeEntity(e, mode) {
        if (this.add_category == 'ADD') {
            this.mastersList = [];
            this.sub_masters = [];
            this.masters_values = [];
        }
        this.selected_entity_name = e.value;
        if (mode == 'edit') {
            this.selected_entity_name = e;
        }
        this.getMasterApi();
        this.proprty_type = null;
        if (this.master) {
            this.getMastersValues(this.master);
        }
    }
    changeMaster(event) {
        if (event.value) {
            this.master = event.value;
            let master_id = event.value;
            this.changeEntity(this.selected_entity_name, 'edit');
            this.getSubMasters(master_id);
            this.getMastersValues(master_id);
        }
    }
    changeSubMaster(event) {
        this.getMastersValues(event.value);
    }
    changePropertyType(event) {
        if (this.master) { this.getMastersValues(this.master); }
    }
    
    /**
     * getCategory function to get category data
     * @param 
     */
    getCategory() {
        if (!this.category_access.GET) {
            this.notify.notify("You are not authorized to Access Nearby Category", "warn");
            return;
        }
        this.loader = true;
        this.dataSource = [];
        this.categoryItems = [];
        this.apiService.getData(`get-nearby-category?order_by=sort_order&status=${this.statusCode?0:1}`).subscribe((data: any) => {
            this.loader = false;
            if (data) {
                //data = this.fnc.assignmentSortOrder(data);
                data.map((element, index) => {
                    let res = {
                        id: element.id,
                        name: element.name,
                        entity: element.entity,
                        label: element.label[this.apiService.language],
                        lang_value: element.label,
                        categories: (element.categories)?.toString(),
                        status: (element.status == 1) ? true : false
                    }
                    this.categoryItems.push(res);
                });
                this.dataSource = this.categoryItems;
            }
        }, err => {
            this.loader = false;
        })
    }
    sortCategory() {
        this.sortCategoryItems();
    }
    //sorting menu items
    sortCategoryItems() {
        if (!this.category_access.PATCH) {
            this.notify.notify('You are not authorized to update category', 'warn');
            return;
        }
        let body: any = [];
        this.categoryItems.forEach((v, k) => {
            body.push({
                "user_id": this.apiService.user_id,
                "sort_order": k + 1,
                "id": v.id
            });
        });
        this.apiService.patchData('category', body).subscribe((res: any) => {
            // if (res.code == 1) {
            //     const activity={
            //       actionId: res.id,
            //       data: body,
            //       actionType:UserAction.update_categories.Action_ID
            //     }
            //     this.apiService.logUserActivity(activity);
            //   }
            // this.notify.notify('Menu order updated', 'DISMISS');
        }, err => {
            // this.notify.notify('Order not updated, please try again', 'DISMISS');
        })
    }
    //get property type
    getPropertyType() {
        this.apiService.getData('propertytypes?status=1').subscribe({
            next :(res: any) => {
                if(res.status == 200){
                    this.property_types = res.data;
                }
        }, error : (err) => {

        }})
    }
    //update status
    updateStatus(row_id, event) {
        if (!this.category_access.PATCH) {
            this.notify.notify("You are not authorized to Update Category", "warn");
            return;
        }
        let body: any = [{
            "id": row_id,
            "status": (event.checked == true) ? 1 : 0,
            "user_id": this.apiService.user_id
        }];
        this.loader = true;
        this.apiService.patchData('category', body).subscribe((res: any) => {
            this.loader = false;
            if (res) {
                // if(res.code == 1){
                //     const activity={
                //         actionId: res.id,
                //         data: body,
                //         actionType:UserAction.update_categories.Action_ID
                //       }
                //     this.apiService.logUserActivity(activity)
                // }
                this.getCategory();
                this.notify.notify(res.message, "success");
            }
        }, err => {
            this.loader = false;
        })
    }
    
    /**
     * Get Master function
     */
    getMasterApi() {
        this.mastersList = [];
        let url = `masters?sort_by=master_name&status=1`
        this.apiService.getData(url).subscribe({
            next : (res: any) => {
            if(res.status == 200){
                res.data.forEach(element => {
                    if (element && element.parent_master_id == null) {
                        this.mastersList.push(element);
                    }
                });
            }
        }, error: (err) => {

        }})
    }
    
    /**
     * Get sub master by master id
     * @param parent_master_id 
     */
    getSubMasters(parent_master_id) {
        let url = `masters?parent_master_id=${parent_master_id}&sort_by=master_name&status=1`;
        this.apiService.getData(url).subscribe({
            next : (res: any) => {
            if (res.status == 200) {
                this.sub_masters = res.data;
            }
        },
        error: (err) => {

        }})
    }

    /**
     * Get master value by master id
     * @param master_id 
     */
    getMastersValues(master_id: any) {
        let url = `${this.selected_entity_name}/masters/${master_id}/values?sort_asc=true&sort_by=master_value_name&status=1`;
        if (this.proprty_type) url += `?property_type_ids=${this.proprty_type}`;
        this.apiService.getData(url).subscribe({
            next: (res: any) => {
            if (res) {
                this.masters_values = res.data;
                if (this.tempMasterValue.length > 0) {
                    this.tempMasterValue.forEach(element => {
                        if (this.fnc.jsonLength(this.fnc.getArrayValue('master_value_name', element, this.masters_values)) == 0) {
                            this.masters_values.push({
                                master_value_name: element,
                                master_value_label: { [this.apiService.language]: element }
                            });
                        }

                    });
                }
            }
        },
        error: (err) => {

        }})
    }

    reportoptions: Options = {
        draggable: '.dragx1',
        handle: '.draghandle1',
        scroll: true,
        onUpdate: (event: any) => this.sortCategory()
    };
    ngOnInit() {
        this.getCategory();
        this.getPropertyType();
    }
    open_category(templateRef) {
        this.tempMasterValue = [];
        this.initForm(false);
        this.isFormDiv = true;
        this.openDialog(templateRef, this.isFormDiv)
        this.add_category = 'ADD';
    }

    openDialog(templateRef, status) {
        this.dialogReff = this.dialog.open(templateRef, {
            width: '360px',
            panelClass: 'customPadding',
        });
        this.dialogReff.afterClosed().subscribe(result => {
            this.cancel()
        });
    }

    cancel() {
        this.isFormDiv = false;
        this.masters_values = [];
        this.updateDataId = null;
        this.categoryForm.patchValue({
            'category_name': null,
            'language': [{ language: null, value: '' }],
            'entity': null,
            'proprty_type': null,
            'master': null,
            'sub_master': null,
            'category': null
        })
    }

    //
    //public tempCategory:any;
    savetempCat(el) {
        this.tempMasterValue = el.value;
    }
    
    /**
     * This is a function to update category
     * @param editData 
     * @param event 
     * @returns 
     */
    edit(editData, event, template) {
        if (!this.category_access.PATCH) {
            this.notify.notify("You are not authorized to update Category", "warn");
            return;
        }
        this.tempMasterValue = [];
        let labels = [], index = 0;
        this.language_valueset = [];
        if (!this.languages) return;
        this.initForm(true);
        this.updateDataId = editData.id
        this.isFormDiv = true;
        this.openDialog(template, this.isFormDiv)
        this.add_category = 'UPDATE';
        this.masters_values = [];
        let nameArr = editData.categories.split(',');
        this.tempMasterValue = editData.categories.split(',');
        nameArr.map(el => {
            let data = {
                master_value_name: el,
                master_value_label: { [this.apiService.language]: el }
            }
            this.masters_values.push(data)
        })
        for (let prop in editData.lang_value) {
            labels[index] = { language: +prop, value: editData.lang_value[prop] };  // +prop to convert into number
            this.addRow();
            index++
        }
        setTimeout(() => {
            this.categoryForm.patchValue({
                'proprty_type': '',
                'entity': editData.entity,
                'master': '',
                'sub_master': '',
                'category_name': editData.name,
                'category': editData.categories.split(','),
                'language': labels,
            });
        });
        this.changeEntity(editData.entity, 'edit');
        this.getMasterApi();
    }
    
    /**
     * update Category Near By
     * @param val val is a payload param
     */
    updateCategoryNearBy(val) {
        let updateData = val.value;
        let category_label = {};
        updateData.language.forEach((v, k) => {
            category_label[v.language] = v.value;
        });

        let body = [{
            "id": this.updateDataId,
            "label": category_label,
            "categories": updateData.category,
            "user_id": this.apiService.user_id,
        }]
        // const activity={
        //     // actionId: UserAction.update_categories.Action_ID,
        //     data: body,
        //     actionType:UserAction.update_categories.Action_ID
        //   }
        this.loader = true;
        this.apiService.patchData('category', body).subscribe({
            next:(res: any) => {
            this.loader = false;
            if (res && res.code == 1) {
                // if (res.code == 1) {
                //     const activity={
                //       actionId: res.id,
                //       data: body,
                //       actionType:UserAction.update_categories.Action_ID
                //     }
                //     this.apiService.logUserActivity(activity);
                //   }
                this.getCategory();
                this.isFormDiv = false;
                this.dialogReff.close();
                this.add_category = 'CATEGORYLIST';
                this.notify.notify(res.message, 'success', 11000);
            }
        }, error:err => {
            this.loader = false;
            this.notify.notify(err.error.message, 'error', 11000);
        }});
    }
}