<ng-container *ngIf="!toggle">
    <div class="row w-100 blog_list pl-3">
        <div class="col-md-12 pr-0" >

            <div class="mtc-header pt-2 pb-2">
                <h3> {{ selectedType | titlecase }} Post </h3>
                <button class="btn-add-statis" (click)="action('add')">
                    <span>ADD {{ selectedType | uppercase }} POST</span>
                </button>
            </div>
            <div class="d-flex justify-content-between"
                style="align-items: center; height: 60px; border-bottom: 1.5px solid #c5c5c5;">
                <div class="d-flex justify-content-start align-items-center">

                    <div class="drpdown">
                        <mat-select [(value)]="selectedType" (selectionChange)="typeBlogsNews($event.value)" id="type"
                            class="form-control search-inputbox" placeholder="Select Type">
                            <mat-option value="blog">Blog</mat-option>
                            <mat-option value="news">News</mat-option>
                        </mat-select>
                    </div>

                </div>
                <div class="d-flex justify-content-between">
                    <div class="search">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="position-relative">
                                <mat-icon class="search-icon">search
                                </mat-icon>
                                <input name="filter" [(ngModel)]="filterTerm" class="form-control search-input"
                                    placeholder="Search..." type="text">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
            <mat-card-content class="list p-2">
                <div class="w-100 calc-h">
                    <table mat-table matSort [dataSource]="dataSource | filter:'filterkeys':filterTerm: data"
                        class="w-100">

                        <ng-container *ngFor='let disCol of displayedColumns'>
                            <!-- for action column -->
                            <ng-container *ngIf="disCol == 'action'">
                                <ng-container matColumnDef="{{disCol}}">
                                    <th mat-header-cell *matHeaderCellDef class="text-center">
                                        <span class="header cp">{{getLabel(disCol)}}</span>
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="pl-2 pr-2">
                                        <div class="action">
                                            <button matTooltip="Edit"
                                                [disabled]="element.status == -1 || element.status == 0"
                                                (click)="action('edit',element)">
                                                <img src="../../../assets/icon/Edit.svg" alt="">
                                            </button>
                                            <button matTooltip="Delete"
                                                [disabled]="element.status == -1 || element.status == 0"
                                                (click)="openConfirmation(element)">
                                                <img src="../../../assets/icon/delete.svg" alt="">
                                            </button>
                                        </div>
                                    </td>
                                </ng-container>
                            </ng-container>
                            <!--  -->
                            <ng-container *ngIf="disCol !='action'">
                                <ng-container matColumnDef="{{disCol}}">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                        <span class="header cp" [ngClass]="{'text-center d-block': disCol == 'SN'}">
                                            {{getLabel(disCol)}} </span>
                                    </th>
                                    <td mat-cell *matCellDef="let element; let i=index;">
                                        <span *ngIf="disCol != 'description'"> {{returnVal(element, disCol)}}</span>
                                        <span *ngIf="disCol == 'SN'" class="text-center d-block"> {{offset+i+1}}</span>
                                        <div class="d-flex justify-content-start align-items-center"
                                            *ngIf="disCol == 'description'">
                                            <p matTooltip={{returnVal(element,disCol)}} class="more">
                                                {{returnVal(element,disCol)}}
                                            </p>
                                        </div>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>
                </div>
            </mat-card-content>
            <mat-paginator [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
                (page)="pageEvent = $event; handlePagination($event)">
            </mat-paginator>
        </div>
    </div>
</ng-container>


<ng-container *ngIf="toggle">
    <app-add-blog [action_type]="form_action" [update_data]="update_data" [selectedType]="selectedType"
        (back)="action($event)"></app-add-blog>
</ng-container>