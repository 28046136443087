import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-type1',
  templateUrl: './type1.component.html',
  styleUrls: ['./type1.component.css']
})
export class Type1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
