import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../apiservice.service";
import { NotificationService } from "../../../../admin/services/notification.service";


@Component({
  selector: 'app-add-package',
  templateUrl: './add-package.component.html',
  styleUrls: ['./add-package.component.css']
})
export class AddPackageComponent implements OnInit {
  PackageForm: UntypedFormGroup;
  action_type: string;
  loader: boolean;
  parent_data:any;
  

  
  constructor(private fb: UntypedFormBuilder,
    private api:ApiserviceService,
    private notify: NotificationService,
    public dialogRef: MatDialogRef<AddPackageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    
    this.action_type = data.type;
    this.parent_data = data.data;
    this.PackageForm = this.fb.group({
      package_name: ['', Validators.required],
      validity_days:  ['',[ Validators.required, Validators.minLength(1), Validators.maxLength(5)]],
      price: ['', Validators.required],
      template:  [''],
      api_count: ['', Validators.required],
    });

    if(this.action_type == 'edit'){
      this.PackageForm.patchValue({
        package_name:this.parent_data.package_name, 
        validity_days: this.parent_data.validity_days, 
        price:this.parent_data.price, 
        template: this.parent_data.template,
        api_count:this.parent_data.api_count, 
      })
    }
  }

  close(){
    this.dialogRef.close();
  }
  
  ngOnInit(): void {}

  addPackage(form_data) {
    if(this.PackageForm.invalid){
      this.notify.notify('Fill the mandatory fields', 'warn')
      return;
    }
  
  let body = {
    package_name: { [this.api.language]: form_data.package_name },
    validity_days: form_data.validity_days,
    price: form_data.price,
    template: form_data.template,
    api_count: form_data.api_count,
    user_id: this.api.user_id
  };

  this.loader=true;
    
    if(this.action_type == 'add'){
     
      this.api.postData('package', body).subscribe((res:any)=>{
        this.loader=false;
        if(res && res.status == 201){
          this.notify.notify(res.message, 'success');
          this.dialogRef.close(true);
        }else{
          this.notify.notify(res.message, 'warn');
        }
      }, err=>{
        this.loader=false;
      })
    }else if(this.action_type =='edit'){
      body['package_id'] = this.parent_data.package_id;
      this.api.patchData('package', body).subscribe((res:any)=>{
        this.loader=false;
        if(res && res.status == 201){
          this.notify.notify(res.message, 'success');
          this.dialogRef.close(true);
        }else{
          this.notify.notify(res.message, 'warn');
        }
      }, err=>{
        this.loader=false;
      })
    }
  }
}
