<div class="form">
    <div class="mtc-header pt-2 pb-2 pr-2 pl-2 position-relative" fxLayout="row" fxLayoutAlign="center center">
        <div class="pt-1">
            <h5 *ngIf="mode =='add'">Add Valuation Factor</h5>
            <h5 *ngIf="mode =='edit'">Update valuation Factor</h5>
        </div>
    </div>
    <div>
        <form [formGroup]="factorForm" autocomplete="off" novalidate (ngSubmit)="addFactor(factorForm)">
            <div class="calc-f">
                <div class="pt-2 pl-4 pr-4">
                    <mat-form-field class="w-100">
                        <input matInput placeholder="Name" formControlName="factorName" type="text">
                    </mat-form-field>
                    <div formArrayName="language">
                        <div *ngFor="let languagevalues of languageSet;let kk = index">
                            <div [formGroupName]="kk" fxLayout="row" fxLayoutAlign="space-between start">
                                <mat-form-field floatLabel="never" fxFlex="30%">
                                    <mat-select placeholder="Language" formControlName="language">
                                        <mat-option *ngFor="let language of language_list"
                                            [value]="language.language_id">
                                            {{ language.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field floatLabel="never" fxFlex="40%">
                                    <input matInput type="text" placeholder="Label" formControlName="value">
                                    <!--<mat-hint>Hint message goes here</mat-hint>-->
                                    <mat-error>Label is required</mat-error>
                                </mat-form-field>

                                <div fxFlex="10%">
                                    <button type="button" mat-icon-button (click)="removeRow(kk)">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                                <div>
                                    <button type="button" mat-icon-button (click)="addRow()">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Dropdown -->
                    <mat-form-field class="w-100">
                        <mat-select placeholder="Calculation Type" formControlName="calcType">
                            <mat-option *ngFor="let types of calculationType" [value]="types.value">
                                {{types.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <mat-select placeholder="First Value" formControlName="firstvalue">
                            <mat-option *ngFor="let values of firstValue" [value]="values.value">
                                {{values.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- description -->
                    <div formArrayName="descLanguage">
                        <div *ngFor="let languageValues of langDescSet;let i=index">
                            <div [formGroupName]="i" fxLayouot="row" fxLayoutAlign="space-between start">
                                <mat-form-field floatLabel="never" fxFlex=30%>
                                    <mat-select placeholder="Language" formControlName="language">
                                        <mat-option *ngFor="let language of language_list"
                                            [value]="language.language_id">
                                            {{ language.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field floatLabel="never" fxFlex="40%">
                                    <mat-label> Description</mat-label>
                                    <textarea matInput type="text" formControlName="value"></textarea>
                                    <mat-error>Description is Required</mat-error>
                                </mat-form-field>
                                <div fxFlex="10%">
                                    <button type="button" mat-icon-button (click)="removeDescRow(i)">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                                <div>
                                    <button type="button" mat-icon-button (click)="addDescRow()">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                <div class="pr-2">
                    <button *ngIf="mode =='add'" mat-raised-button color="primary" [disabled]="factorForm.invalid">
                        Submit
                    </button>
                    <button type="button" *ngIf="mode == 'edit'" mat-raised-button color="primary"
                        [disabled]="factorForm.invalid" (click)="updateValuation(factorForm)">
                        Update
                    </button>
                </div>
                <div class="pl-2">
                    <button type="button" mat-raised-button (click)="cancel()">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>