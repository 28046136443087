import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-type3',
  templateUrl: './type3.component.html',
  styleUrls: ['./type3.component.css']
})
export class Type3Component implements OnInit {

  public accessibility_items = [
    {
      name: 'business district',
      icon: 'settings',
      percentage: '98.3%',
      id: 1
    },
    {
      name: 'government places',
      icon: 'settings',
      percentage: '57%',
      id: 2
    },
    {
      name: 'industries',
      icon: 'settings',
      percentage: '8%',
      id: 3
    },
    // {
    //   name: 'retail malls',
    //   icon: 'settings',
    //   percentage: '30%',
    //   id: 4
    // },
    // {
    //   name: 'eductions',
    //   icon: 'settings',
    //   percentage: '80%',
    //   id: 5
    // },
    // {
    //   name: 'entertainment places',
    //   icon: 'settings',
    //   percentage: '62%',
    //   id: 6
    // }
  ];

  constructor() {
  
  }


  ngOnInit() {
  }
}
