import { Component,HostBinding } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromRoot from './state/reducers';
import * as ApplicationActions from './state/application/actions';
import { ApiserviceService } from './apiservice.service';
import { ShareService } from './admin/services/share.service';
import { CommonfunctionService } from './services/commonfunction.service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  $isLoggedIn: Observable<boolean>;
  @HostBinding('class.application') class = 'application';
  constructor(private store: Store<fromRoot.State>, private api: ApiserviceService, private share: ShareService,
    private router: Router) {
    this.share.appComp = this;

    if(environment.isLocalServe || this.api.isMobile){
        // if (localStorage.getItem('user_id') && !localStorage.getItem('city_id')) {
        //     this.router.navigateByUrl('/country');
        // }
        // if (localStorage.getItem('user_id') && localStorage.getItem('city_id')) {
        //     this.router.navigateByUrl('/admin');
        // }
    }else{
        if (!localStorage.getItem('user_id') && !localStorage.getItem('user_token')) {
            let newwindow = window.open(`${environment.adminHost}/login?continue=${(environment.baseURL)}`, '_self');
            if (window.focus) {newwindow.focus()}
        }
        // else if (localStorage.getItem('user_id') && !localStorage.getItem('city_id')) {
        //     this.router.navigateByUrl('/country');
        // }else if (localStorage.getItem('user_id') && localStorage.getItem('city_id')) {
        //     this.router.navigateByUrl('/admin');
        // }
    }
  }

  ngOnInit(){
    this.api.setUserLocation();
    this.$isLoggedIn = this.store.select(fromRoot.selectIsLoggedIn);
    this.$isLoggedIn.subscribe((state:any)=>{
      if(!state){
        this.api.logout(false);
      }
    })
    document.body.addEventListener('click', () => this.extendLogout());
  }
  logIn(){
    this.store.dispatch(new ApplicationActions.LogIn());
  }
  extendLogout(){
    this.store.dispatch(new ApplicationActions.ExtendLogoutTimer());
  }
}
