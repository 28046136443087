<div class="login-wrapper">
    <div class="login-middle" [ngClass]="{'forgotpassword': showForgot}">
      <div class="logo-contain">
        <img src="/assets/images/logovert2.svg" class="center" width="250" />
      </div>
      <div class="login-container" *ngIf="!showForgot">
        <mat-card appearance="outlined" class="cen">
          <mat-card-content>
            <mat-tab-group #tabGroup [@.disabled]="true" dynamicHeight="true" disableRipple class="demo-tab-group">
              <mat-tab label="Login">
                <form [formGroup]="loginForm" (ngSubmit)="doLogin(loginForm.value)">
                  <div class="inside-content">
                    <mat-form-field floatLabel="never" class="full-width">
                      <mat-icon matPrefix class="mat-icons">email</mat-icon>
                      <input matInput type="email" placeholder="Enter your email address" formControlName="email">
                      <mat-error>Email is required</mat-error>
                    </mat-form-field>
                    <mat-form-field floatLabel="never" class="full-width">
                      <mat-icon matPrefix class="mat-icons">lock_outline</mat-icon>
                      <input type="password" matInput placeholder="Enter your password" formControlName="password" [type]="hide ? 'password' : 'text'">
                      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </mat-form-field>

                    <!-- <div class="catp-d">
                      <div class="cap-img">
                        <re-captcha required (resolved)="resolved($event)" siteKey="6LfKCVIUAAAAADyREfHHktMNkvpOCv-E7JD9ZhFF"></re-captcha>
                      </div>
                    </div> -->
                    <div *ngIf="error" class="login-err">{{error}}</div>
                    <div class="button-row">
                      <button [disabled]="loginForm.invalid" mat-button color="primary">
                        <span *ngIf="!login_action">Login</span>
                        <span *ngIf="login_action">Logging in...</span>
                        <mat-icon *ngIf="!login_action" class="mat-icons-sm">arrow_forward</mat-icon>
                      </button>
                    </div>
                  </div>
                </form>
                <a class="bottom_admin" (click)="jumpForgot()">
                  <div class="c-v">
                    <div class="tx">
                      Forgot Password?
                    </div>
                  </div>
                </a>
              </mat-tab>
            </mat-tab-group>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="forgot-container" *ngIf="showForgot">
        <div class="inside-content">
          <div class="tab_header">Forgot Password</div>
          <p class="text">Enter your email. We'll email instructions on how to reset your password</p>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-icon matPrefix class="mat-icons">email</mat-icon>
            <input [(ngModel)]="forget_email" matInput placeholder="Enter your email address" value="">
          </mat-form-field>
          <span *ngIf="f_error" class="forget-err pb-2">{{forget_message}}</span>
          <span *ngIf="!f_error" class="forget-success pb-2">{{forget_message}}</span>
          <div class="button-row">
            <button mat-button color="primary" (click)="jumpForgot()">
              <mat-icon class="mat-icons-sm">arrow_backward</mat-icon> Go back
            </button>
            <button [disabled]="!forget_email ||!enable_btn" (click)="forgetPassword()" mat-button color="primary">Submit
              <mat-icon class="mat-icons-sm">arrow_forward</mat-icon>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
