<div class="w-100 p-2 d-flex justify-content-center align-items-center">
  <mat-card appearance="outlined" class="m-2 mat-elevation-z6">
    <mat-card-header class="position-relative">
      <mat-card-title>
        Send App Notification
      </mat-card-title>
      <mat-progress-bar class="mtc-progress" *ngIf="loader" [color]="'primary'" mode="indeterminate">
      </mat-progress-bar>
    </mat-card-header>
    <mat-card-content class="overFlow">
      <section class="w-100" style="padding: 10px 0px 50px 0px;">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <mat-form-field class="mr-3" style="width: 40%!important;">
            <mat-label>Enter Title</mat-label>
            <input type="text" matInput placeholder="Enter Notification Title" [(ngModel)]="title">
          </mat-form-field>
          <div>
            <button class="mr-3" mat-raised-button color="primary" [disabled]="loader" (click)="sendNotification()">Send
              Notification</button>
          </div>
        </div>
        <div class="w-100">
          <mat-form-field class="mr-3" style="width: 40%!important;">
            <mat-label>Enter Message</mat-label>
            <textarea matInput placeholder="Enter Notification Message" [(ngModel)]="message"></textarea>
          </mat-form-field>
        </div>
      </section>
    </mat-card-content>
  </mat-card>
</div>