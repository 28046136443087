<div class="align-grid" *ngIf="cardsList.length>0">
  <ng-container *ngFor="let card of cardsList">
    <!-- mat card start -->
    <mat-card appearance="outlined" class="card-{{getIndex(card.index)}} card-shadow">
      <mat-card-header>
        <mat-card-title class="cp">{{card.title}}</mat-card-title>
        <!-- <mat-card-subtitle>Last Sync: {{card.last_sync | date}}</mat-card-subtitle> -->
        <mat-progress-bar *ngIf="load && selectedCardId == card.index" class="progress" [color]="'primary'"
          mode="indeterminate">
        </mat-progress-bar>
      </mat-card-header>

      <mat-card-content class="card_content">
        <p title=" {{card.description}}">
          {{card.description}}
        </p>
      </mat-card-content>
      <mat-card-actions>
        <!-- <button mat-button>HIDE CARD</button> -->
        <!-- <button mat-button >REFRESH</button> -->
        <div class="flex-apart">
          <button mat-mini-fab [disabled]="load && selectedCardId == card.index" (click)="refreshData(card)"
            mat-tooltip="Refresh snapshot">
            <mat-icon [ngClass]="{'rotation': load && (selectedCardId == card.index)}" class="rotation">sync</mat-icon>
            <!-- <i class="fa fa-spinner fa-spin"></i> -->
          </button>
          <span *ngIf="card.last_sync" class="sync-time"><b>Last Sync:</b> {{ fnc.formatDateUTC(card.last_sync,true) }}</span>
        </div>

      </mat-card-actions>
    </mat-card>
    <!-- mat card end -->
  </ng-container>
</div>

<div class="loader_view" *ngIf="cardsList.length == 0">
  <div class="load-cont">
    <h3>Loading...</h3>
    <mat-progress-bar [color]="'primary'" mode="indeterminate">
    </mat-progress-bar>
  </div>
</div>