import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from '../admin/services/notification.service';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private route: Router, private notify: NotificationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if ([403].includes(event?.body?.status)) {
            localStorage.clear();
            this.route.navigate(['/login']);
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if ([403].includes(error.status) || (error.error.message.includes('Invalid user token'))) {
          localStorage.clear();
          this.route.navigate(['/login']);
        } else {
          this.notify.notify(error.error.message, 'error');
        }
        return throwError(() => error)
      })
    );
  }
}
