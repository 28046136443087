import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ApiserviceService } from '../../apiservice.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { AdminBreadcrumbService } from '../admin-breadcrumb/admin-breadcrumb.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../services/notification.service';
import { PropertyType } from '../../interfaces';
import { ShareService } from '../services/share.service';
import { Router } from '@angular/router';
// import { UserAction } from "../user-action/user-activity";


/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>umsapi_user_resource_post</li>
 * <li>estapi_propertytypes_get</li>
 * <li>emapi_menu_post</li>
 * <li>emapi_menu_patch</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-admin-valuator',
  templateUrl: './admin-valuator.component.html',
  styleUrls: ['./admin-valuator.component.css']
})
export class AdminValuatorComponent implements OnInit {
  selected_menu_type: any;
  current_menu: any;

  selected_property_type: any;
  current_property: any;
  public menu_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  menu_types: any = [
    { name: 'Test', id: 1 },
    { name: 'menu2', id: 2 }
  ];
  attributes = ['is_demolished', 'is_deleted', 'quality_roads', 'property_number', 'property_name', 'property_category', 'is_demolished', 'is_deleted', 'quality_roads', 'property_number', 'property_name', 'property_category'];
  property_types: Array<PropertyType> = [];
  menuConfig: any;
  menuForm: UntypedFormGroup;
  label: any;
  createMenu: boolean = false;
  updateMenu: boolean = false;
  //template related--------------
  editTemplate: boolean = false;
  editExisting: boolean = false;
  templateType: string;
  templateData: any;
  confirmation: boolean = false;
  menuData: any;
  existing_template_data: any = null;
  existing_title: any = null;
  //template related--------------
  generatedCode: any;
  constructor(private api: ApiserviceService,
    private menus: AdminMenusService,
    private _crumbs: AdminBreadcrumbService,
    private _fb: UntypedFormBuilder,
    private fnc: CommonfunctionService,
    public dialog: MatDialog,
    private notify: NotificationService,
    private share: ShareService,
    private route: Router) {
    this.initForm();
    //setting active menus----------------------------
    this.menus.setActive('menu_configuration');
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: 'Menu Configuration' };
    this._crumbs.mobiletitle = 'Menu Configuration';
    this._crumbs.count = 1;
    //setting active menus----------------------------
  }

  ngOnInit() {
    // calling initial apis---------------------------------------------------------------------------------
    this.getAllowResources();
    this.menuForm.valueChanges.subscribe((data) => {
      this.menuConfig = data;
    })
    // calling initial apis---------------------------------------------------------------------------------
  }
  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next : (res: any) => {
        this.api.allowResources = res.data;
        this.menu_access = this.fnc.checkResourceAccess('menu', false);
        // this.getMenuType();
        this.getPropertyType();
      }
      
    }
    )

  }
  // getAllowResourcesApi() {
  //   this.api.getUserRole(); // getting role assign to this user
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     .subscribe(
  //       (data: any) => {
  //         this.api.allowResources = data;
  //         this.getMenuType();
  //         this.getPropertyType();
  //         this.menu_access = this.fnc.checkResourceAccess('menu', false);
  //       },
  //       err => {
  //         if (err['status'] == 403) { // log out when unauthorised
  //           localStorage.clear();
  //           this.api.user_id = null;
  //           this.route.navigateByUrl('admin/login');
  //         }
  //       }
  //     )
  // }
  ngAfterViewInit() {

  }

  //functions---------------------------------------------------------------------------------------------
  changeMenuType(e) {

  }
  changePropertyType(e) {

  }
  initForm() {
    this.menuForm = this._fb.group({
      menu_name: [null, [Validators.required]],
      menu_title: [null, [Validators.required]],
      menu_require_login: [false],
      menu_status: [true],
      menu_icon_name: [null, [Validators.required]]
    });
  }

  /**
   * add, edit, update, publish actions
   * @param type 
   * @param data 
   * @param existing 
   */
  action(type, data: any = null, existing: any = null) {
    if (type == 'add') {
      this.createMenu = true;
      this.updateMenu = false;
      this.menuForm.reset();
      this.menuForm.patchValue({menu_status:true})
      this.menuForm.get('menu_name').enable();
      this.menuForm.get('menu_icon_name').enable();
      this.menuForm.get('menu_title').enable();
    }
    if (type == 'publish') {
      this.publish();
    }
    if (type == 'update') {
      this.update();
    }
    if (type == 'edit') {
      console.log(data);
      
      let template = data.template;
      if (template) {
        this.existing_template_data = template;
      }else{
        this.existing_template_data = null;
      }
      if (data.title)  {
        this.existing_title = data.title;
      } else{
        this.existing_title = null;
      } 
      this.createMenu = true;
      this.editTemplate = true;
      this.editExisting = true;
      this.templateData = template ? template[this.api.language] :'';
      this.generatedCode = this.templateData;
      this.updateMenu = true;
      this.menuData = data;
      if (data) {
        this.menuForm.patchValue({
          menu_name: data.name,
          menu_title: data.title ? data.title[this.api.language]:'',
          menu_require_login: data.login_require,
          menu_status: data.status,
          menu_icon_name: data.icon
        })
        this.menuForm.get('menu_name').disable();
      }
    }
    if (type == 'back') {
      this.createMenu = false;
      this.updateMenu = false;
      this.editTemplate = false;
      this.editExisting = false;
      this.templateData = null;
      this.generatedCode = null;
      this.updateMenu = false;
      this.menuData = null;
      this.menuForm.reset();
    }
  }

  selectTemplateType(type) {
    this.updateMenu = false;
    this.menuData = null;
    this.templateData = null;
    this.editTemplate = true;
    this.editExisting = false;
    // this.menuForm.reset();
  }

  removeTemplate() {
    this.editTemplate = false;
  }

  //functions---------------------------------------------------------------------------------------------

  //API calls---------------------------------------------------------------------------------------------
  // getMenuType() {
  //   this.selected_menu_type = 1; //setting default value for menu type
  //   this.api.getData('menutypes').subscribe((res: any) => {
  //     if (res) {

  //     }
  //   }, err => {

  //   })
  // }

  getTemplate(template) {
    this.generatedCode = template;
  }
  
  getPropertyType() {
    this.api.getData('propertytypes').subscribe({
      next :(res: any) => {
      if (res && res.status == 200) {
        let { data } = res;
        data.forEach(element => {
          let data: PropertyType = {
            added_date: element.added_date,
            property_type_id: element.property_type_id,
            property_type_label: (element.property_type_label) ? element.property_type_label[this.api.language] : element.property_type_name,
            property_type_name: element.property_type_name,
            status: element.status,
            updated_by: element.updated_by,
            updated_date: element.updated_date
          }
          this.property_types.push(data);
        });
      }
    }, error: (err) => {

    }})
  }

  publish() {

    if (!this.menu_access.POST) {
      this.notify.notify('You are not authorized to add menu', 'warn');
      return;
    }
    // if (!this.selected_menu_type) {
    //   this.notify.notify('Please select a menu type', 'warn');
    //   return;
    // }
    if (!this.menuConfig.menu_name) {
      this.notify.notify('Menu name cannot be blank', 'warn');
      return;
    }

    let body = {
      "icon": this.menuConfig.menu_icon_name,
      "login_require": (this.menuConfig.menu_require_login) ? true : false,
      "name": this.menuConfig.menu_name,
      "status": (this.menuConfig.menu_status) ? 1 : 0,
      "template": { [this.api.language]: this.generatedCode },
      "title": {
        [this.api.language]: this.menuConfig.menu_title
      },
      "type_id": this.selected_menu_type,
      "user_id": +this.api.user_id
    }

    if (this.selected_property_type) body['property_Type'] = this.selected_property_type;
    let url = 'menu';
    this.api.postData(url, body).subscribe((res: any) => {
      res.forEach(element => {
        if (element.code == 1) {
          // const activity={
          //   actionId: element.id,
          //   data: body,
          //   actionType:UserAction.detail_menu.Action_ID
          // }
          // this.api.logUserActivity(activity);
        }
        this.notify.notify(element.message, 'success', 2500);
        if (element.id != null) {
          this.action('back');
          this.share.tableObj.getMenuList();
        }
      });

    }, err => {
      this.notify.notify('No menu was added, please try again ', 'error');
    })
  }

  update() {
    let temp = {}, title = {};
    if (!this.menu_access.PATCH) {
      this.notify.notify('You are not authorized to update menu', 'warn');
      return;
    }
    // if (!this.selected_menu_type) {
    //   this.notify.notify('Please select a menu type', 'warn');
    //   return;
    // }
    if (this.existing_template_data) {
      temp = this.existing_template_data;
    }
    if (this.existing_title) {
      title = this.existing_title;
    }
    temp[this.api.language] = this.generatedCode;
    title[this.api.language] = this.menuConfig.menu_title;
    let body = [{
      "icon": this.menuConfig.menu_icon_name,
      "id": this.menuData.id,
      "login_require": (this.menuConfig.menu_require_login) ? true : false,
      "property_Type": 0,
      "status": (this.menuConfig.menu_status) ? 1 : 0,
      "template": temp,
      "title": title,
      "type_id": this.selected_menu_type,
      "user_id": +this.api.user_id
    }]
    if (this.selected_property_type) body['property_Type'] = this.selected_property_type;
    // const activity={
    //   // actionId: UserAction.detail_menu.Action_ID,
    //   data: body,
    //   actionType:UserAction.detail_menu.Action_ID
    // }
    let url = 'menu';
    this.api.patchData(url, body).subscribe((res: any) => {
      // if(res[0]?.code == 1){
      //   const activity={
      //     actionId: res[0]?.id,
      //     data: body,
      //     actionType:UserAction.menu_update.Action_ID
      //   }
      //   this.api.logUserActivity(activity);
      // }
      this.action('back');
      this.notify.notify('Menu updated successfully', 'success');
      this.share.tableObj.getMenuList();
    }, err => {
      this.notify.notify('No menu was added, please try again ', 'error');
    })

  }

  //API calls---------------------------------------------------------------------------------------------
}
