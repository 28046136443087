<mat-toolbar>
    Preview
</mat-toolbar>
<mat-dialog-content>
    <ng-container *ngIf="selectedDataType.length>0">
        <ng-container *ngFor="let data of selectedDataType">
            <div class="rel" *ngIf="data.render_type == 'paragraph'">
                <app-paragraph [preview]="true" [data]="data"></app-paragraph>
            </div>
            <div class="rel" *ngIf="data.render_type == 'list'">
                <app-list [preview]="true" [data]="data">
                </app-list>
            </div>
            <div class="rel" *ngIf="data.render_type == 'image'">
                <app-image [preview]="true" [data]="data">
                </app-image>
            </div>
            <div class="rel" *ngIf="data.render_type == 'chart'">
                <app-chart [preview]="true" [data]="data">
                </app-chart>
            </div>
        </ng-container>
    </ng-container>
</mat-dialog-content>
