import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AdminBreadcrumbService } from '../admin-breadcrumb/admin-breadcrumb.service';

@Component({
  selector: 'admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {
  public title : string = '';
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
      private _crumbs : AdminBreadcrumbService) { 
      //this.title = this.api.user_name;
  }

  ngOnInit() {
    this.title = this._crumbs.mobiletitle;
  }

}
