<div fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
    <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="space-between center">
        <h4 class="open-sans mb-0 text-uppercase" style="color: #616474;">CSV Template Query
        </h4>
        <div fxLayout="row" fxLayoutAlign="end center">
        </div>
    </div>
    <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="button-b">
            <button *ngIf="mode=='add'" mat-button class="orange lean-radius" [disabled]="templateQueryForm.invalid" (click)="updateQuery(templateQueryForm)">
                SUBMIT</button>
            <button *ngIf="mode=='edit'" mat-button class="orange lean-radius" [disabled]="templateQueryForm.invalid" (click)="updateQuery(templateQueryForm)">
                UPDATE</button>
            <button mat-button class="btn lean-radius" style="border: 1px solid #9c9c9c !important;" (click)="parent.closeDialog()">
                CANCEL</button>
        </div>
    </div>
</div>
<div class="mat-loader">
    <mat-progress-bar *ngIf="loader" mode="indeterminate">
    </mat-progress-bar>
</div>
<ng-container *ngIf="listLoader">
    <app-loader></app-loader>
</ng-container>
<div class="d-flex mx-3 my-2 position-relative">
    <label class="font-weight-bold p-0 open-sans" style="font-size: 18px!important;padding-top: 2%;" fxFlex="15%">{{selectedTemplate ?
        selectedTemplate.file_type :selectedElement.file_type}}</label>
    <mat-icon *ngIf="tableIndexList.length > 0" class="position-absolute" [matMenuTriggerFor]="indexList" matTooltip="Show Available Index List">
        info_outline</mat-icon>
    <mat-menu #indexList="matMenu">
        <table mat-table [dataSource]="tableIndexList">
            <ng-container matColumnDef="index_name">
                <th mat-header-cell *matHeaderCellDef>Index Name</th>
                <td mat-cell class="p-3" *matCellDef="let element"> {{element.index_name}} </td>
            </ng-container>
            <ng-container matColumnDef="index_def">
                <th mat-header-cell *matHeaderCellDef> Index Definition </th>
                <td class="p-3" mat-cell *matCellDef="let element"> {{element.index_def}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="indexListCols"></tr>
            <tr mat-row *matRowDef="let row; columns: indexListCols;"></tr>
        </table>
    </mat-menu>
</div>
<div class="d-flex flex-column mx-3 mt-0">
    <form [formGroup]="templateQueryForm">
        <mat-dialog-content>
            <div class="form-container">
                <!-- column input -->
                <div class="form-input-container align-items-start d-flex flex-column">
                    <div class="w-100" fxLayout="column" formArrayName="queryIndex">
                        <table #templateQueryTable mat-table [dataSource]="selectedElement ? selectedElement.query_index:addElements.query_index" class="column-table w-100">
                            <ng-container *ngFor="let cols of displayedColumns">
                                <ng-container matColumnDef="{{cols}}">
                                    <th mat-header-cel *matHeaderCellDef>
                                        <span *ngIf="cols=='index_name'">Index Name *</span>
                                        <span *ngIf="cols=='Index'">Index *</span>
                                        <span *ngIf="cols=='Column'">Column *</span>
                                        <span *ngIf="cols=='Condition'">Condition</span>
                                        <span *ngIf="cols=='Action'">Action</span>

                                    </th>
                                    <ng-container *ngIf="selectedElement && selectedElement.query_index || addElements && addElements.query_index">
                                        <td mat-cell *matCellDef="let element;let i=index">
                                            <div [formArrayName]="i">
                                                <ng-container *ngIf="cols=='index_name'">
                                                    <div class="form-group mb-1" >
                                                        <input id="index_name_{{i}}" type="text" class="f-14 form-control input-background" formControlName="index_name" placeholder="Enter Index Name *">
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="cols=='Index'">
                                                    <div class="form-group mb-1">
                                                        <input id="name_{{i}}" type="text" class="f-14 form-control input-background" formControlName="index" placeholder="eg:- neigh_uid,segment_id, year *">
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="cols=='Column'">
                                                    <div class="form-group mb-1">
                                                        <input id="data-type_{{i}}" type="text" class="f-14 form-control input-background" formControlName="column" placeholder="eg:- sub_parameter_id">
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="cols=='Condition'">
                                                    <div class="form-group mb-1">
                                                        <input id="column-name_{{i}}" type="text" class="f-14 form-control input-background" formControlName="condition" placeholder="eg:- IS NOT NULL">
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="cols=='Action'">
                                                    <button *ngIf="tableIndexList.length > 1 || remove_check" type="button" mat-icon-button class="csv-icon-highlight-fix" (click)="removeTableRow(element,i)">
                                                        <img src="../../../../assets/images/svgicons/remove.svg" alt="">
                                                    </button>
                                                    <button type="button" mat-icon-button class="csv-icon-highlight-fix" (click)="addTableRow()">
                                                        <img class="add" src="../../../../assets/images/svgicons/add.svg"
                                                            alt="">
                                                    </button>
                                                </ng-container>
                                            </div>

                                        </td>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
                        </table>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </form>
</div>