import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AddValuationFactorMasterComponent } from './add-valuation-factor-master/add-valuation-factor-master.component';
import { ConfirmDialogBox } from "../confirm-dialog/confirm-dialogbox";
import { UntypedFormControl } from '@angular/forms';
import { UserAction } from "../user-action/user-activity";
import { debounceTime } from 'rxjs';
@Component({
  selector: 'app-valuation-factor-master',
  templateUrl: './valuation-factor-master.component.html',
  styleUrls: ['./valuation-factor-master.component.css']
})
export class ValuationFactorMasterComponent implements OnInit {
  displayedColumns = ['SN',
    'name',
    'label',
    'calculation_type',
    'first_value',
    'description',
    'added_by',
    'added_date',
    "status",
    'action']
  searchField: UntypedFormControl;
  returnVal(element, dispCol) {
    switch (dispCol) {
      case 'name': return element.name;
      case 'label': return this.showlabel(element, 'label');
      case 'calculation_type': return element.calculation_type;
      case 'first_value': return element.first_value;
      case 'description': return this.showlabel(element, 'details');
      case 'added_by': return element.added_by;
      case 'added_date': return this.functionService.formatDateUTC(element.added_date);
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  limit: number = 10;
  offset: number = 0;
  totalCount: number;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;
  dataSource: any = [];
  loader: boolean;
  status: string = "0,1";
  factor_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };

  constructor(private menu: AdminMenusService,
    private functionService: CommonfunctionService,
    private notify: NotificationService,
    private apiService: ApiserviceService,
    public dialog: MatDialog,
    public route: Router) {
    this.menu.setActive('valuation_factor_master');
    this.menu.setActive('valuation');
    this.getAllowResources();
  }
  
  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    let body = `user/resource?place_id=${this.apiService.city_id}&user_id=${this.apiService.user_id}`
    this.apiService.getUmsData(body).subscribe({
      next: (res: any) => {
        this.apiService.allowResources = res.data;
        this.getAllowResource();
        this.getValuationMasters();
        this.getFactorCount();
        // this.getApplicationCount();
      }
      
    })
  }

  getAllowResource() {
    this.factor_access = this.functionService.checkResourceAccess('valuation_factor', false);
  }

  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.functionService.formatDate(date);
    }
    else {
      return '';
    }
  }

  /**
   * API to show Language dependent lables
   * @param item 
   * @param key 
   * @returns 
   */
  showlabel(item: any, key) {
    if (item && item[key] && item[key][this.apiService.language]) {
      return item[key][this.apiService.language];
    } else {
      return null;
    }
  }
  getLabel(key) {
    return key ? key.replace(/_/g, " ") : "";
  }

  /**
   * API[/valuation-factor-master] call to GET Saved Masters
   * @param searchterm 
   * @returns 
   */
  getValuationMasters(searchterm = null) {
    if (!this.factor_access.GET) {
      this.notify.notify("You are not authorized to VIEW Valuation factor", "warn");
      return;
    }
    this.loader = true;
    let url = `valuation-factor-master?action=get&status=${this.status}&limit=${this.limit}&offset=${this.offset}&sort_by=name&sort_asc=true`;
    if (searchterm != null) {
      url = `valuation-factor-master?name=${searchterm}&status=${this.status}&action=get`
    }
    this.apiService.getValData(url).subscribe((res: any) => {
      this.loader = false;
      if (res && res.data) {
        this.dataSource = res.data;
      }
    }, err => {
      this.dataSource = [];
      this.loader = false;
    })
  }
  /**
   * patch API for /valuation-factor-master to update status
   * @param id 
   * @param event 
   * @returns 
   */
  updateStatus(id, event) {
    if (!this.factor_access.PATCH) {
      this.notify.notify("You are not authorized to Update Valuation factor", "warn");
      return;
    }
    let body: any = {
      "id": id,
      "status": (event.checked == true) ? 1 : 0,
      "added_by": this.apiService.user_id,
    };
    this.loader = true;
    this.apiService.patchValData('valuation-factor-master', body)
  .subscribe({
    next: (res: any) => {
      if (res && res.status === 201) {
        // const activity = {
        //   actionId: res.data,
        //   data: body,
        //   actionType: UserAction.update_valuation_factor.Action_ID
        // };

        // this.apiService.logUserActivity(activity);
        this.loader = false;
        this.notify.notify(`${res.message}`, "success");
        this.getFactorCount();
      } else {
        this.loader = false;
        this.notify.notify(`${res.message}`, "error");
      }
      this.getValuationMasters();
    },
    error: (err) => {
      this.loader = false;
      // Handle error notification or logging here if needed
    }
  });

  }
  /**
   * API call to get Factors with status=-1 and Data length for pagination
   * @param event 
   */
  getDeleted(event) {
    if (event.checked == false) {
      this.status = "0,1";
    }
    else {
      this.status = "-1";
    }
    this.getFactorCount();
    this.getValuationMasters();
  }

  
  /**
   * for Add Fator Dialog Box
   * @returns
   */
  openDialog(type: any, data: any = null) {
    if (type == 'add' && !this.factor_access.POST) {
      this.notify.notify("You are not authorized to Add Valuation Factor", "warn");
      return;
    } if (type == 'edit' && !this.factor_access.PATCH) {
      this.notify.notify("You are not authorized to EDIT Valuation Factor", "warn");
      return;
    }
    if (type) {
      var _data = {
        type: type,
        edit: data,
        pdata: this
      }
      const dialogRef = this.dialog.open(AddValuationFactorMasterComponent, {
        width: '450px',
        panelClass:'customPadding',
        data: _data
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  public selected_factor;
  openConfirmation(value) {
    if (!this.factor_access.DELETE) {
      this.notify.notify("You are not authorized to delete Valuation Factor", "warn");
      return;
    }
    this.selected_factor = value;
    let _data = {
      parent_data: this,
      message: 'Do you want to delete Valuation Factor?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  /**
   * patch[Delete] API for /valuation-factor-master
   * @param confirm 
   */
  confirmDialogYes(confirm) {
    if (confirm == 'YES') {
      let body = {
        "id": this.selected_factor.id,
        "status": -1,
        "added_by": this.apiService.user_id
      }
      this.apiService.patchValData('valuation-factor-master', body).subscribe((res: any) => {
        if(res && res.status == 201){
        //   const activity={
        //     actionId: res.data,
        //     data: body,
        //     actionType:UserAction.update_valuation_factor.Action_ID
        //   }
        // this.apiService.logUserActivity(activity);
        this.notify.notify("Valuation Factor Deleted Successfully", "success");
        this.getValuationMasters();
        this.getFactorCount();
        }
        else{
          this.loader=false;
          this.notify.notify(`${res.message}`, "error");
        }
      }, err => {
        this.notify.notify("Valuation Factor deletion error!", "error")
      })
    }
  }

  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getValuationMasters();
  }


  ngOnInit() {
    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(term => {
        term = term.trim().toLowerCase();
        if (term) {
          this.getValuationMasters(term)
        } else {
          this.getValuationMasters(null)
        }
      })
  }
  /**
   * GET /valuation-factor-master API for data length for pagination 
   * @returns
   */
  getFactorCount() {
    let url = `valuation-factor-master?action=get&status=${this.status}`;
    this.apiService.getValData(url).subscribe((res: any) => {
      if (res && res.data) {
        this.totalCount = res.data.length;
      }
    }, err => {
    })
  }


}
