<div class="p-3">
    <div class="title-bar">
        <div class="d-flex align-items-center">
            <h4>Broker package subscription</h4>
            <input class="form-control search-bar" [formControl]="searchField" type="text" name="search" placeholder="Search by company name">
        </div>
        <div class="d-flex align-items-center">
           <mat-select [(ngModel)]="status" (selectionChange)="changeStatus($event.value)">
                <mat-option [value]="'1'">Enabled</mat-option>
                <mat-option [value]="'0'">Disabled</mat-option>
                <mat-option [value]="'-1'">Deleted</mat-option>
            </mat-select>
            <button (click)="addPackageSub('add')">Add Broker Subscription</button>
        </div>
    </div>
    <mat-progress-bar *ngIf="loader" class="progress" [color]="'primary'" mode="indeterminate">
    </mat-progress-bar>
    <div>
        <div class="tableDiv border">
            <table class="table">
                <thead>
                    <tr> 
                        <th>Package name</th>
                        <th>Company name</th>
                        <th>Start date</th>
                        <th>End date</th>
                        <th>Api count</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let obj of package_subs_list">
                        <td>{{obj.package_name}}</td>
                        <td>{{obj.company_name}}</td>
                        <td>{{obj.start_date}}</td>
                        <td>{{obj.end_date}}</td>
                        <td>{{obj.api_count}}</td>
                        <td class="d-flex justify-content-around align-items-center">
                                <mat-slide-toggle  matTooltip="status"
                                    class="example-margin mx-2" [checked]="obj.status == 1 ? true: false"
                                    (change)="updateStatus(obj.subscription_id,$event, obj.status)">
                                </mat-slide-toggle>
                              <mat-icon *ngIf="status == 1" matTooltip="Edit" (click)="addPackageSub('edit', obj)" class="icon edit">edit</mat-icon>
                              <mat-icon *ngIf="status == 1 || status == 0" matTooltip="Delete" (click)="deleteMaster(obj)" class="icon delete">delete</mat-icon>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <mat-paginator [length]="total" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
                (page)="pageEvent = $event; handlePagination($event)">
            </mat-paginator>
        </div>
    </div>
</div>