import { Component, OnInit } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { NotificationService } from '../services/notification.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, Validators, FormGroup, UntypedFormControl, FormBuilder, FormArray, NgModel } from '@angular/forms';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { AddEditComponent } from './add-edit/add-edit.component';
import { CategoryDialogComponent } from './category-dialog/category-dialog.component';
import { UserAction } from "../user-action/user-activity";
import { debounceTime } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  displayedColumns = ['sn', 'key', 'value', 'type', 'status', 'added_by', 'added_date', 'action'];
  dataSource: any = [];
  loader: boolean;
  selectedCellId: any;
  changed_value: string;
  status: string = "1";
  searchField: UntypedFormControl;
  search: any = '';
  statusCode: string= '1';
  limit: number =10;
  totalCount: number;;
  offset:number =0;
  pageEvent: PageEvent;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  returnVal(element, cols) {
    switch (cols) {
      case 'neigh_name': return element.neigh_name;
      case 'neigh_uid': return element.neigh_uid;
       case 'parameter_name': return element.parameter_name;
      case 'key_name': return element.key_name;
      case 'value_type': return element.value_type;
      case 'value': return element.value;

    }
  }
  setting_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  total: any;
  public indexListCols=[
    "name",
    "options",
  ];
  indexList: any = [];
 

  constructor(
    private menus: AdminMenusService,
    private notify: NotificationService,
    private apiService: ApiserviceService,
    private fnc: CommonfunctionService,
    public dialog: MatDialog,
    
  ) {
    // this.menus.setActive('valuation');
    this.getParameters();
    this.getParameterCount();
    this.menus.setActive('settings');
    this.getAllowResources();
  }

  getParameters(searchTerm = null) {
    // if (!this.parameter_access.GET) {
    //   this.notify.notify("You are not authorized to Add Comparison Parameter", "Dismiss");
    //   return;
    // }
    this.loader = true;
    let url = `settings?status=${this.statusCode}&limit=${this.limit}&offset=${this.offset}`;
    if (searchTerm != null) {
      url = `settings?neigh_uid=${searchTerm}&status=${this.statusCode}`;
     
    }
    this.apiService.getData(url).subscribe({
     next: (res: any) => {
      this.loader = false;
      if (res && res.data) {
        this.dataSource = res.data;
      } else {
        this.notify.notify(res.message, "error", 5000);
        this.dataSource = [];
      }
    },error: err => {
      this.notify.notify(err.error.message, "error", 5000)
      this.dataSource = [];
      this.loader = false;
    }});
  }

  getParameterCount() {
    let url = `settings?status=${this.statusCode}`;
    this.apiService.getData(url).subscribe({
      next:(res: any) => {
      if (res && res.data) {
        this.totalCount = res.data.length;
      } else {
        this.totalCount = 0;
      }
     }, error: err => {
        this.totalCount = 0;
      }
    });
  }


  
  /**
   * getAllowResources function getting allow resources 
   * @returns Void
   */
  getAllowResources() {
    this.apiService.getUserRole();
    let body = `user/resource?place_id=${this.apiService.city_id}&user_id=${this.apiService.user_id}`
    this.apiService.getUmsData(body).subscribe({
      next :(res: any) => {
        this.apiService.allowResources = res.data;
        this.setting_access = this.fnc.checkResourceAccess('settings', false);
        this.getAllowResource();
        this.getSettings();
      }
     
    })

  }


  getAllowResource() {
    this.setting_access = this.fnc.checkResourceAccess('setting', false);
  }

  editValue(ele, type) {
    if (ele.key == 'property_categories') {
      this.selectedCellId = '';
      let _data = { ele: ele, info: this }
      const dialogRef = this.dialog.open(CategoryDialogComponent, {
        width: '600px',
        data: _data
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    } else {
      this.selectedCellId = ele.setting_id + '_' + type;
      this.changed_value = ele.value;
    }

  }

  cancelEdit(uid) {
    this.selectedCellId = '';
  }
  edit_setting(data) {
    if (!this.setting_access.PATCH) {
      this.notify.notify("You are not authorized to EDIT setting", "warn");
      return;
    }
    var body = {
      "setting_id": data.setting_id,
      "value": this.changed_value,
      "status": data.status,
      "user_id": this.apiService.user_id
    }
    this.loader = true;
   
    this.apiService.patchData('settings', body).subscribe((res: any) => {
      if (res && res.status == 201) {
        // const activity={
        //   actionId: res.data,
        //   data: body,
        //   actionType:UserAction.update_setting.Action_ID
        // }
        this.selectedCellId = '';
        this.notify.notify(res.message, "warn")
        this.getSettings();
        // this.apiService.logUserActivity(activity)
      }
      this.loader = false;
    }, err => {
      this.notify.notify("settings not update", "error")
      this.loader = false;
    })
  }

  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.fnc.formatDate(date);
    } else {
      return '';
    }
  }

  openDailog(type: any, data: any = null) {
    if (!this.setting_access.POST) {
      this.notify.notify("You are not authorized to Add setting", "warn");
      return;
    }
    if (type == 'add') {
      var _data = {
        type: type,
        edit: data,
        pdata: this
      }
      const dialogRef = this.dialog.open(AddEditComponent, {
        width: '350px',
        panelClass:'customPadding',
        data: _data
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  public selected_setting;
  openConfirmation(value) {
    if (!this.setting_access.DELETE) {
      this.notify.notify("You are not authorized to Delete setting", "warn");
      return;
    }
    this.selected_setting = value;
    let _data = {
      parent_data: this,
      message: 'Do you want to delete setting?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  confirmDialogYes(confirm) {
    if (confirm == 'YES') {
      let body = {
        "setting_id": this.selected_setting.setting_id,
        "status": -1,
        "user_id": this.apiService.user_id
      }
      this.apiService.patchData('settings', body).subscribe((res: any) => {
        if(res && res.status == 201)
        {
          // const activity={
          //   actionId: res.data,
          //   data: body,
          //   actionType:UserAction.update_setting.Action_ID
          // }
          // this.apiService.logUserActivity(activity)
          this.notify.notify("Setting has been deleted successfully", "success");
          this.getSettings();
        }
      }, err => {
        this.notify.notify("Setting has been deleted failed", "error")
      })
    }
  }

  ngOnInit() {
    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(term => {
        term = term.trim().toLowerCase();
        if (term) {
          this.getSettings(term)
        } else {
          this.getSettings(null)
        }
      })
  }

  /**
   * This is a function that used to getting setting from api
   * @param searchterm searchterm is a param 
   * @returns 
   */
  getSettings(searchterm = null) {
    if (!this.setting_access.GET) {
      this.notify.notify("You are not authorized to Access setting", "warn");
      return;
    }
    this.loader = true;
    //this.dataSource = [];
    let url = `settings?status=${this.statusCode}`;
    if (searchterm != null) {
      url = `settings?search_text=${searchterm}&status=${this.statusCode}`;
    }
    this.apiService.getData(url).subscribe({
      next: (res: any) => {
        this.indexList = [];
        this.loader = false;
        if (res && res.data) {
          this.dataSource = res.data;
          this.total = res.datalength;
          if (res.server) {
            this.indexList = res.server;
          };
        } else {
          this.dataSource = [];
          this.indexList = [];
        }
      }, 
      error: () => {
        this.dataSource = [];
        this.indexList = [];
        this.loader = false;
      }
    })
  }

  /**
   * This is a function to use update status
   * @param row_id 
   * @param event 
   * @returns 
   */
  updateStatus(row_id, event) {
    if (!this.setting_access.PATCH) {
      this.notify.notify("You are not authorized to update setting", "warn");
      // Reverting the status back to its initial position
      event.source.checked = !event.checked;
      return;
    }
    let body: any = {
      "setting_id": row_id,
      "status": (event.checked == true) ? 1 : 0,
      "user_id": this.apiService.user_id
    };
    this.loader = true;
    this.apiService.patchData('settings', body).subscribe({
      next:(res: any) => {
      if (res && res.status == 201) {
        //   const activity={
        //     actionId: res.data,
        //     data: body,
        //     actionType:UserAction.update_setting.Action_ID
        //   }
        // this.apiService.logUserActivity(activity)
        this.loader = false;
        this.notify.notify(`Setting has been updated successfully`, "success");
        this.getSettings();
        this.getParameterCount();
      }
    },error: err => {
      this.loader = false;
    }});
  }
  
  // updateStatus(row_id, event) {
  //   if (!this.setting_access.PATCH) {
  //     this.notify.notify("You are not authorized to update setting", "warn");
  //     return;
  //   }
  //   let body: any = {
  //     "setting_id": row_id,
  //     "status": (event.checked == true) ? 1 : 0,
  //     "user_id": this.apiService.user_id
  //   };
  //   this.loader = true;
  //   this.apiService.patchData('settings', body).subscribe((res: any) => {
  //     if(res && res.status == 201)
  //     {
  //     //   const activity={
  //     //     actionId: res.data,
  //     //     data: body,
  //     //     actionType:UserAction.update_setting.Action_ID
  //     //   }
  //     // this.apiService.logUserActivity(activity)
  //     this.loader = false;
  //     this.notify.notify(`Setting has been updated successfully`, "success");
  //     this.getSettings();
  //     }
  //   }, err => {
  //     this.loader = false;
  //   })
  // }

  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getParameters();
  }
  
  
  /**
   * API call to get Attributes with status=-1 and Data length for pagination
   */

  
  getDeleted(event) {
    if (!this.setting_access.GET) {
      this.notify.notify("You are not authorized to Access Settings", "warn");
      return;
    }
    if (event.checked == false) {
      this.status = "0,1";
    }
    else {
      this.status = "-1";
    }
    // this.getSettings();
    this.getParameterCount();
    this.getParameters();
  }
  selectionChange(val){
    this.statusCode = val;
    this.getParameterCount();
    this.getParameters();
  }
  

}
