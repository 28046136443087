import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiserviceService } from "../../apiservice.service";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { NotificationService } from "../services/notification.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogBox } from "../confirm-dialog/confirm-dialogbox";
import { Router } from "@angular/router";
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AddAttributeComponent } from './add-attribute/add-attribute.component';
import { UntypedFormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { UserAction } from "../user-action/user-activity";
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-listable-attribute',
  templateUrl: './listable-attribute.component.html',
  styleUrls: ['./listable-attribute.component.scss']
})

export class ListableAttributeComponent implements OnInit {
  displayedColumns = ['sn', 'attribute_name', 'column_name', 'status', 'added_date', 'action'];
  dataSource: any = [];
  loader: boolean;
  status: string = "0,1";
  searchField: UntypedFormControl;
  seach: any = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  limit: number = 10;
  offset: number = 0;
  totalCount: number;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;
  listable_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  constructor(private api: ApiserviceService, private menu: AdminMenusService,
    private fncSrv: CommonfunctionService,
    private notify: NotificationService,
    private apiServ: ApiserviceService,
    public dialog: MatDialog,
    public route: Router) {
    this.menu.setActive('listable_attribute');
    this.getAllowResources();

  }


  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next:(res: any) => {
       
        this.api.allowResources = res.data;
        this.getAllowResource();
        this.getAttribute();
        this.getAttibuteCount();
      }
      
    })

  }


  getAllowResource() {
    this.listable_access = this.fncSrv.checkResourceAccess('listable_attribute', false);

  }

  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.fncSrv.formatDate(date);
    }
    else {
      return '';
    }
  }

  openDialog(type: any, data: any = null) {
    if (!this.listable_access.POST) {
      this.notify.notify("You are not authorized to Add Listable Attribute", "warn");
      return;
    }
    if (type == 'add') {
      var _data = {
        type: type,
        edit: data,
        pdata: this
      }
      const dialogRef = this.dialog.open(AddAttributeComponent, {
        width: '350px',
        panelClass: 'customPadding',
        data: _data
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  public selected_attribute;
  openConfirmation(value) {
    if (!this.listable_access.DELETE) {
      this.notify.notify("You are not authorized to delete Listable Attribute", "warn");
      return;
    }
    this.selected_attribute = value;
    let _data = {
      parent_data: this,
      message: 'Do you want to delete this setting?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  
  /**
   * patch[Delete] API for /listable-attribute
   * @param confirm 
   */
  confirmDialogYes(confirm) {
    if (confirm == 'YES') {
      let body = {
        "id": this.selected_attribute.id,
        "status": -1,
        "user_id": this.apiServ.user_id
      }
      this.apiServ.patchData('listable-attribute', body).subscribe({
        next: (res: any) => {
          this.loader = false;
          if (res && res.status == 201) {
            //   const activity={
            //     actionId: res.data,
            //     data: body,
            //     actionType:UserAction.update_attribute.Action_ID
            //   }
            // this.api.logUserActivity(activity);
            this.notify.notify("Attribute Delete Successful", "success");
            this.getAttribute();
            this.getAttibuteCount();
          }
          else {
            this.notify.notify(res.message, "error");
          }
        }, error: () => {
          this.notify.notify("Unsuccessful Attribute Deletion", "error")
        }
      })
    }
  }

  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getAttribute();
  }

  ngOnInit() {
    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(term => {
        term = term.trim().toLowerCase();
        if (term) {
          this.getAttribute(term)
        } else {
          this.getAttribute(null)
        }
      })
  }

/**
 *  GET /listable-attribute API for data length for pagination 
 */
  getAttibuteCount() {
    let url = `listable-attribute?entity_id=1&status=${this.status}`;
    this.apiServ.getData(url).subscribe({
      next: (res: any) => {
        if (res && res.data?.length) {
          this.totalCount = res.data.length;
        }
      }, error: () => { }
    })
  }

  /** 
   * GET /listable-attribute API to show on frontend
  */
  getAttribute(searchterm = null) {
    this.loader = true;
    //this.dataSource = [];
    let url = `listable-attribute?entity_id=1&status=${this.status}&limit=${this.limit}&offset=${this.offset}&sort_by=attribute_name&sort_asc=true`;
    if (searchterm != null) {
      url = `listable-attribute?attribute_name=${searchterm}`;
    }
    this.apiServ.getData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        if (res && res.data?.length) {
          this.dataSource = res.data;
        } else {
          this.dataSource = [];
        }
      }, error: () => {
        this.dataSource = [];
        this.loader = false;
      }
    })
  }

  /**
   *   patch API for /listable-attribute to update status
   */  
  updateStatus(id, event) {
    if (!this.listable_access.PATCH) {
      this.notify.notify("You are not authorized to update Listable Attribute", "warn");
      return;
    }
    let body: any = {
      "id": id,
      "status": (event.checked == true) ? 1 : 0,
      "user_id": this.apiServ.user_id
    };
    this.loader = true;
    this.apiServ.patchData('listable-attribute', body).subscribe({
      next: (res: any) => {
        this.loader = false;
        if (res && res.status == 201) {
          //   const activity={
          //     actionId: res.data,
          //     data: body,
          //     actionType:UserAction.update_attribute.Action_ID
          //   }
          // this.api.logUserActivity(activity);
          this.notify.notify(`${res.message}`, "success")
        } else {
          this.loader = false;
          this.notify.notify(`${res.message}`, "error")
        }
      }, error: () => {
        this.loader = false;
      }
    })
  }
  /**
   * API call to get Attributes with status=-1 and Data length for pagination
   * @param event 
   * @returns 
   */
  getDeleted(event) {
    if (!this.listable_access.GET) {
      this.notify.notify("You are not authorized to Access Listable Attibute", "warn");
      return;
    }
    if (event.checked == false) {
      this.status = "0,1";
    }
    else {
      this.status = "-1";
    }
    this.getAttibuteCount();
    this.getAttribute();
  }

}
