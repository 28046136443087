import { Component, OnInit, Inject } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../apiservice.service";
import { NotificationService } from "../../../../admin/services/notification.service";



@Component({
  selector: 'app-add-broker-subscription',
  templateUrl: './add-broker-subscription.component.html',
  styleUrls: ['./add-broker-subscription.component.css']
})
export class AddBrokerSubscriptionComponent implements OnInit {

  PackageSubsForm: UntypedFormGroup;
  action_type: string;
  loader: boolean;
  parent_data:any;

  comp_list:any=[];
  package_list:any=[];

  constructor(
    private fb: UntypedFormBuilder,
    private api:ApiserviceService,
    private notify: NotificationService,
    public dialogRef: MatDialogRef<AddBrokerSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.action_type = data.type;
      this.parent_data = data.data;

      this.getCompanies();
      this.getPackages();

      this.PackageSubsForm = this.fb.group({
        package_name: ['', Validators.required],
        company_name:  ['', Validators.required],
      });

      if(this.action_type == 'edit'){
        this.PackageSubsForm.patchValue({
          package_name:this.parent_data.package_id, 
          company_name: this.parent_data.company_id,
        })
      }

    }

   close(){
    this.dialogRef.close();
  }
 
   /**
   * Get company list
   */
  getCompanies(){
    let url = `company?status=1`;
    this.loader = true;
    this.api.getData(url).subscribe((res:any)=>{
      this.loader = false;
      if(res && res.data && res.data.length){
        res.data.map(e=>{
          e['name'] = e.company_name[this.api.language]
        })
          this.comp_list =  res.data;
      }
    },err=>{
      this.loader = false;
    })
  }

  /**
   * Get package list
   */
  getPackages(){
    let url = `package?status=1`;
    this.loader = true;
    this.api.getData(url).subscribe((res:any)=>{
      this.loader = false;
      if(res && res.data && res.data.length){
        res.data.map(e=>{
          e['name'] = e.package_name[this.api.language]
        })
          this.package_list = res.data;
      }
    },err=>{
      this.loader = false;
    })
  }


  ngOnInit(): void {}

   /**
   * Add / Update form package subscription
   */
  addPackage(form_data) {
    if(this.PackageSubsForm.invalid){
      this.notify.notify('Fill the mandatory fields', 'warn')
      return;
    }
  
  let body = {
    package_id: form_data.package_name,
    company_id: form_data.company_name,
    user_id: this.api.user_id   
  };

  this.loader=true;
    
    if(this.action_type == 'add'){
     
      this.api.postData('package-subscription', body).subscribe({
        next : (res:any)=>{
        this.loader=false;
        if(res && res.status == 201){
          this.notify.notify(res.message, 'success');
          this.dialogRef.close(true);
        }else{
          this.notify.notify(res.message, 'warn');
        }
      },error: err=>{
        this.loader=false;
      }})
    }else if(this.action_type =='edit'){
      body['subscription_id'] = this.parent_data.subscription_id,
      this.api.patchData('package-subscription', body).subscribe({
        next : (res:any)=>{
        this.loader=false;
        if(res && res.status == 201){
          this.notify.notify(res.message, 'success');
          this.dialogRef.close(true);
        }else{
          this.notify.notify(res.message, 'warn');
        }
      },error: err=>{
        this.loader=false;
      }})
    }
  }
}
