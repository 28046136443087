import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { MatPaginator } from '@angular/material/paginator';
import { merge, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-block-valuation',
  templateUrl: './block-valuation.component.html',
  styleUrls: ['./block-valuation.component.css']
})
export class BlockValuationComponent implements OnInit {
  block_access = {
    'GET': false,
    'POST': true,
    'PATCH': false,
    'DELETE': false
  };
  valuationTypes: any = [
    {
      label: "Property Price",
      value: "property_price"
    },
    {
      label: "Land Price",
      value: "land_price"
    },
    {
      label: "Built Up Area",
      value: "bua_data"
    },
    // {
    //   label: "Accessibility Index",
    //   value: "accessibility"
    // },
  ];
  dataList: any = [];
  count: number = 0;
  limit: number = 30;
  offset: number = 0;
  selected: any = null;
  loader: boolean = false;
  completeData: any = [];
  options: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    headers: ['Block UID'],
    showTitle: true,
    useBom: false,
    removeNewLines: true,
    keys: ['block_uid']
  };
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private api: ApiserviceService, private menu: AdminMenusService,
    private fnc: CommonfunctionService,
    private notify: NotificationService) {
    this.menu.setActive('block-valuation');
    this.menu.setActive('valuation');
    this.getAllowResources();
  }

  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next: (res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
      }
     
    })
  }
  getAllowResource() {
    this.block_access = this.fnc.checkResourceAccess('block_valuation', false);
  }
  // getAllowResourceApi() {
  //   this.api.getUserRole();
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body).subscribe((res: any) => {
  //     this.api.allowResources = res;
  //     this.block_access = this.fnc.checkResourceAccess('block_valuation', false);
  //   }, err => {
  //     if (err.status = 403 || err.status == 0) {
  //       this.api.logout();
  //     }
  //   })
  // }
  selectValuationType(e) {
    if (!this.block_access.GET) {
      this.notify.notify("You are not Authorized to access Block Valuation Data", "Dismiss");
      return;
    }
    let type = e.source.value;
    this.completeData = [];
    let obj = this.fnc.getArrayValue('value', type, this.valuationTypes);
    this.selected = obj.label;
    this.loader = true;
    this.api.getData(`get-valuation-block-detail?type=${type}&limit=${this.limit}&offset=${this.offset}`).subscribe({
      next: (res: any) => {
        this.loader = false;
        if(res.data.length){
          this.dataList = [];
          this.count = res.data.length;
          this.completeData = res.data;
          merge(this.paginator.page).pipe(
            startWith({}),
            switchMap(() => {
              return of(res.data);
            }))  
            .subscribe(data => {
              const from = this.paginator.pageIndex * 10;
              const to = from + this.paginator.pageSize;
              this.limit = this.paginator.pageSize;
              this.dataList = data.slice(from, to);
            });
         }
        
      }, error: () => {
        this.completeData = [];
        this.loader = false;
      }
    })
  }

  download() {

  let items = [];
  this.completeData.map(e=>{
    items.push([e?.block_uid])
  })
  let csvJson = [];

  csvJson.push(['Block UID']);
  csvJson = [...csvJson, ...items];
  let csvContent = '';
  csvJson.forEach(function (rowArray) {
    let row = rowArray.join(',');
    csvContent += row + '\r\n';
  });

    this.fnc.downloadFile('Valuation_data', csvContent);
  }
  ngOnInit() {
    // $('button').addClass('mat-raised-button');
  }

}
