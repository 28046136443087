import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import MediumEditor from 'medium-editor';

@Component({
  selector: 'app-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.css']
})
export class ParagraphComponent implements OnInit {
  @Input () editable: boolean = false;
  @Input () editExisting: boolean = false;
  @ViewChild('container') container: ElementRef;
  @Output() templateUpdate = new EventEmitter();
  @Input() data:any;
  @Input() preview:boolean = false;
  constructor() { }

  ngOnInit() {
    
  }

  ngAfterViewInit(){
    if(!this.preview){
      if(this.data && this.data.data && this.editable){
        this.editExisting = true;
        const element = this.container.nativeElement;
        element.innerHTML = this.data.data.content;
        const editor = new MediumEditor(element);
        this.update();
      }else if (this.editable){
        const element = this.container.nativeElement;
        const editor = new MediumEditor(element);
      }
    }else{
      if(this.data && this.editable){
        this.editExisting = true;
        const element = this.container.nativeElement;
        element.innerHTML = this.data.data.content;
        this.update();
      }else {
        const element = this.container.nativeElement;
        element.innerHTML = this.data.data.content;
      }
    }  
  }

  update() {       
    this.templateUpdate.emit({
      content: this.container.nativeElement.innerHTML,
      type: 'paragraph'
    });
  }
}