<div>
  <div fxHide="true" fxShow.gt-sm="true" fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
    <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="space-between center">
      <admin-breadcrumb></admin-breadcrumb>
      <!-- <div fxLayout="row" fxLayoutAlign="end center">
        <mat-select placeholder="Menu type" [(ngModel)]="selected_menu_type" (selectionChange)="changeMenuType($event)"
          class="form-select-l">
          <mat-option class="cp" *ngFor="let data of menu_types" [value]="data.id">{{data.name}}</mat-option>
        </mat-select>
        <mat-select placeholder="Property type" [(ngModel)]="selected_property_type"
          (selectionChange)="changePropertyType($event)" class="form-select-l ml-2">
          <mat-option class="cp" *ngFor="let data of property_types" [value]="data.property_type_id">
            {{data.property_type_label}}</mat-option>
        </mat-select>
      </div> -->
    </div>
    <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="button-b">
        <button *ngIf="!createMenu && !updateMenu" (click)="action('add')" mat-button class="blue-button">Add
          menu</button>
        <button *ngIf="createMenu && !updateMenu" mat-button class="blue-button"
          (click)="action('publish')">Publish</button>
        <button *ngIf="createMenu && updateMenu" mat-button class="blue-button"
          (click)="action('update')">Update</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="createMenu" fxLayout="column">
  <mat-toolbar class="w-100 br-b">
    <button mat-button (click)="action('back')">
      <mat-icon class="est-gr">arrow_backward</mat-icon>
      Back
    </button>
    <!-- <div fxLayout="row" class="w-100">
      <mat-horizontal-stepper class="w-100 stepper" [selectedIndex]="selectedIndex" [linear]="true" #stepper (selectionChange)="stepchanged($event)">
        <mat-step [completed]="steps.step1Completed">
            <ng-template matStepLabel>Configure menu item</ng-template>
        </mat-step>
        <mat-step [completed]="steps.step2Completed">
            <ng-template matStepLabel>Generate template</ng-template>
        </mat-step>
        <mat-step [completed]="steps.step3Completed">
            <ng-template matStepLabel>Publish</ng-template>
        </mat-step>
      </mat-horizontal-stepper>
    </div> -->
  </mat-toolbar>
  <div fxLayout="row">
    <div class="l-cont">
      <mat-toolbar class="header">
        <h3 class="mat-h3">Menu configuration</h3>
      </mat-toolbar>
      <div class="newscroll">
        <div class="list-container">
          <div class="cst rowse" fxLayout="column" fxLayoutAlign="start left">
            <form [formGroup]="menuForm">
              <mat-form-field class="w-100">
                <input matInput placeholder="Enter menu name" formControlName="menu_name">
              </mat-form-field>
              <mat-form-field class="w-100">
                <input matInput placeholder="Enter title" formControlName="menu_title">
              </mat-form-field>
              <mat-form-field class="w-100">
                <input matInput placeholder="Enter icon name" formControlName="menu_icon_name">
              </mat-form-field>
              <div fxLayoutAlign="row" class="w-100">
                <mat-checkbox formControlName="menu_require_login">Require login</mat-checkbox>
                <mat-checkbox formControlName="menu_status" class="ml-2">Status</mat-checkbox>
              </div>
              <!-- <div fxLayoutAlign="row" class="w-100" fxLayoutAlign="center center" class="mt-2">
                <button mat-button class="blue-button" type="submit" [disabled]="!menuForm.valid">Save</button>
              </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="calc3cols" class="col3">
      <div fxLayout="column" fxLayoutAlign="start center" class="w-100 h-100">
        <div class="bottom-pane w-100 grid1-3">
          <div fxLayout="column" class="w-100 p-1 scrollY">
            <mat-card appearance="outlined" class="mb-2" *ngIf="editTemplate">
              <mat-card-title>
                <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
                  <div>
                    <button mat-button (click)="removeTemplate()">
                      <mat-icon class="est-gr">close</mat-icon>Remove
                    </button>
                  </div>
                  <div></div>
                </div>
              </mat-card-title>
              <mat-divider class="w-100"></mat-divider>
              <mat-card-content class="p-1">
                <app-combo-toolbar *ngIf="editTemplate" [editable]="editTemplate" [data]="templateData"
                  [editExisting]="editExisting" (templateModified)='getTemplate($event)'></app-combo-toolbar>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="w-100 br-l" fxLayout="column">
            <!-- <popover-content #myPopover placement="bottom" [animation]="true" [closeOnClickOutside]="true">
              <div class="info_">
                <div class="info_div_child">Atrribute List</div>
                <div class="attr_list">
                  <div class="info_div" *ngFor="let attr of attributes">{{attr}}</div>
                </div>
              </div>
            </popover-content> -->
            <!-- <button mat-button [matMenuTriggerFor]="menu">Menu</button> -->
            <mat-menu #menu="matMenu">
              <div class="info_">
                <div class="info_div_child">Atrribute List</div>
                <div class="attr_list">
                  <div class="info_div" *ngFor="let attr of attributes">{{attr}}</div>
                </div>
              </div>
            </mat-menu>

            <mat-toolbar class="header" fxLayout="row" fxLayoutAlign="space-between center">
              <h3 class="mat-h3">Preview</h3>
              <span class="info" [matMenuTriggerFor]="menu">
                <mat-icon class="est-gr">info</mat-icon>
              </span>
            </mat-toolbar>
            <div fxLayout="column" class="w-100">
              <div class="yscroll-1">
                <app-templates-factory (valueChange)='selectTemplateType($event)' [showAddButton]="!updateMenu">
                </app-templates-factory>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!createMenu" fxLayout="column" class="p-1">
  <app-menu-table (valueChange)='action("edit", $event)'></app-menu-table>
</div>