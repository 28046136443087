import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';
import { ApiserviceService } from '../../../apiservice.service';
import { AdminMenusService } from '../../admin-sidebar/admin-menus.service';
import { PageEvent } from '@angular/material/paginator';
import { CommonfunctionService } from '../../../services/commonfunction.service';

@Component({
  selector: 'app-csv-template',
  templateUrl: './csv-template.component.html',
  styleUrls: ['./csv-template.component.css']
})
export class CsvTemplateComponent implements OnInit {
  public sort_by: any[] = [
    { label: 'File Type', value: 'file_type' },
    { label: 'Added Date', value: 'added_date' },
  ];

  public sort_by_arb: any[] = [
    { label: 'نوع الملف', value: 'pm_key' },
    { label: 'تاريخ الإضافة', value: 'added_date' },
  ];
  public sort_order: any[] = [
    { label: 'Asc', value: 'true' },
    { label: 'Desc', value: 'false' },
  ];
  filter_status: any[] = [
    { label: { 1: 'Active', 2: 'نشيط' }, value: 1 },
    { label: { 1: 'Inactive', 2: 'غير نشط' }, value: 0 }
  ];
  public orderBy: any = 'added_date';
  public sortAsc: any = 'false';
  public limit: number = 10;
  public offset: number = 0;
  public pageSizeOptions: number[] = [5, 10, 25, 50, 100, 200];
  public status = 1;
  public addTemplate: boolean = false;
  public editQuery: boolean = false;
  public responseStatus: string;
  public template_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  public loader: boolean = false;
  public dataSource: any = [];
  public searchTemplate: FormControl;
  public displayedColumns = ['sno',
    // 'objective_name',
    'file_type',
    'column_name',
    'table_name',
    'added_date',
    'Status',
    'Query',
    'Action']
  public formMode: any;
  public selectedElement: any;
  public total: any;
  public select_row_id: any;
  public mobile: boolean = false;

  constructor(
    private api: ApiserviceService,
    private menus: AdminMenusService,
    private notify: NotificationService,
    private sanitizer: DomSanitizer,
    private fncSrv: CommonfunctionService,
    private iconRegistry: MatIconRegistry) {
    this.menus.setActive('manage_csv');
    // this.menus.setActive('csv_template');
    iconRegistry.addSvgIcon('edit-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/edit.svg'))
    iconRegistry.addSvgIcon('delete-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/delete.svg'))
    this.searchTemplate = new FormControl();
    this.getAllowResources();
  }

  /**  
   * This is a function that uses switch case and returns value from provided 
   * array element on the basis of column provided as second parameter.  
  
   * @param element @type {object} - A single array element from array of csv master API response
   * @param cols @type {string} - A single array element from {@link displayedColumns} array
   * @returns @type {String} Return a value based on the condition.  
   */
  returnVal(element, cols) {
    switch (cols) {
      case 'file_type': return element.file_type ? element.file_type : '';
      case 'column_name': return element.column_name ? JSON.stringify(element.column_name, null, 4) : '';
      case 'table_name': return element.table_name ? element.table_name : '';
      case 'added_date': return element.added_date ? this.fncSrv.formatDateUTC(element.added_date, true) : '';
    }
  }

  /**  
 * This is a function responds to Sort By Selection change from HTML file
 * to get API response based on the selection. Uses ngModel to get value 

 * @returns {} Returns void.  
 */
  sortBy() {
    this.getTemplates();
  }

  /**  
 * This is a function responds to Sort Order Selection change from HTML file
 * to get API response based on the selection. Uses ngModel to get value 

 * @returns {} Returns void.  
 */
  sortOrder() {
    this.getTemplates();
  }

  /**  
   * This is a function responds to Filter Selection change from HTML file
   * to get API response based on the selection. Uses ngModel to get value
  
   * @returns {} Returns void.  
   */
  filterStatus() {
    this.getTemplates();
  }

  /**  
   * This is a function that is used to get list of all resources allocated 
   * to the logged in user and checks resource access for this component &
   * access APIs and to perform actions.  
  
   * @api GET user/resource
   * @returns {} Returns void.  
   */
  getAllowResources() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next: (res: any) => {
      
        this.api.allowResources = res.data;
        this.template_access = this.fncSrv.checkResourceAccess('csv_template', false);
        this.getTemplates();
    
      }
    
    })
  }



  showDataPopup(div_id) {
    if (this.select_row_id == div_id) {
      this.select_row_id = null;
    } else {
      this.select_row_id = div_id;
    }
  }


  /**  
   * This is a function to GET list of csv-master from API. 
   * Resources are checked.
   * List of CSV Mater are stored in {@link dataSource} variable.
   * Limit and offset used for pagination.
   * sort order and sort by used for sorting conditionally. 
  
   * @api GET /csv-master
   * @param searchTerm @type {string} - A string value to search csv-master
   * @returns {} Returns void.
   */
  getTemplates(searchTerm?) {
    if (!this.template_access.GET) {
      this.notify.notify("You are not Authorized to GET Csv Templates", 'warn');
      return;
    }
    this.loader = true;
    let url = `csv-master?sort-asc=true&limit=${this.limit}&offset=${this.offset}&status=${this.status}`;
    if (this.orderBy != '' || this.orderBy != "" || this.orderBy == undefined || this.orderBy == null) {
      url += `&sort_by=${this.orderBy}`;
    }
    if (this.sortAsc != '' || this.sortAsc != "" || this.sortAsc != undefined || this.sortAsc != null) {
      url += `&sort_asc=${this.sortAsc}`;
    }
    if (searchTerm) {
      url += `&file_type=${searchTerm}`
    }
    if (this.offset == 0) {
      url += `&is_count=true`
    }

    this.api.getData(url).subscribe((res: any) => {
      this.loader = false;
      if (res && res.status == 200) {
        if (this.offset == 0) {
          this.total = res.totalRecord;
        }
        this.responseStatus = res.status;
        this.dataSource = res.data
      }
      else {
        this.responseStatus = res.status;
        this.dataSource = []
      }
    },
      err => {
        this.dataSource = []
      })
  }

  /**  
 * This is a function to change pagination page and get the list of CSV Master.

 * @param e @type {PageEvent} - A Page Event to get page details
 * @returns {} Returns void.
 */
  handlePagination(e: PageEvent) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getTemplates();
  }
  /**
    * This is a functionto update Status of the selected CSV Master.
    * Resources are checked for PATCH.
    * Logs User actitivy to the system.
   
    * @api PATCH /objective/
    * @param id @type {Number} - A number value of CSV Master ID that needs to be updated
    * @param  data @type {MatSlideToggleChange} - toggle change event values
    * @returns {} Returns void.
    */
  updateStatus(id, event) {
    if (!this.template_access.PATCH) {
      this.notify.notify("You are not authorized to UPDATE Csv Template", 'warn');
      return;
    }
    let url = `csv-master`;
    let body: any = {
      id: id,
      status: (event.checked == true) ? 1 : 0,
      updated_by: this.api.user_id
    };
    const activity = {
      userdata: body,
      entityId: id,
      actionId: 14,
      entity: 'csv_template'
    };
    this.api.patchData(url, body).subscribe({
      next: (res: any) => {
        if (res && res.data) {
          this.getTemplates();
          this.notify.notify(res.message, 'success');
          // this.api.logUserActivity(activity)
        }
        else {
          this.notify.notify(res.message, 'error');
        }
      }, error: () => { }
    })
  }

  /**  
   * This is a functionto open Add/Edit CSV Master Dialog to ADD or EDIT CSV Master. 
   * Resources are checked for POST and PATCH separately.
  
   * @param {String} mode @type {string} - A string value to open Add/Edit CSV Master Dialog
   * @param {Object} element @type {any} - Master value to be edited in case of Edit CSV Master 
   * @returns {} Returns void.
   */
  addTemplateDialog(mode?, element?) {
    if (mode == 'add') {
      if (!this.template_access.POST) {
        this.notify.notify("You are not authorized to ADD CSV Template", 'warn');
        return;
      }
      if (this.selectedElement) {
        this.selectedElement = null;
      }
      this.formMode = mode;
    }
    else if (mode == 'edit') {
      if (!this.template_access.PATCH) {
        this.notify.notify("You are not authorized to UPDATE CSV Template", 'warn');
        return;
      }
      this.formMode = mode;
      this.selectedElement = element;

    }
    this.addTemplate = true;
  }

  addQueryIndex(element) {
    if (!this.template_access.PATCH) {
      this.notify.notify("You are not authorized to UPDATE CSV Template", 'warn');
      return;
    }
    this.selectedElement = element;
    this.editQuery = true;
  }

  /**  
   * This is a functionto close Template Dialog.
  
   * @returns {} Returns void.
   */
  closeDialog() {
    this.addTemplate = false;
    this.editQuery = false;
    this.selectedElement = null;
    this.getTemplates();
  }

  ngOnInit(): void {
    this.searchTemplate.valueChanges.pipe(debounceTime(500)).subscribe(term => {
      term = term.toLowerCase();
      this.offset = 0;
      if (term && term.length) {
        this.getTemplates(term);
      }
      else if (term.length == 0) {
        this.getTemplates();
      }
    })
  }

  /**  
 * This is a function to return array of elements on the basis of application
 * language selection.

 * @returns @type {Array} Returns an array.
 */
  getStatus() {
    return this.sort_by;
  }
}
