<div class="form">
  <div class="mtc-header pt-2 pb-2 pr-2 pl-2 position-relative" fxLayout="row" fxLayputAlign="center center">
    <div class="p-1">
      <h5 *ngIf="mode=='add'">Add Transaction Attribute</h5>
      <h5 *ngIf="mode=='edit'">Edit Transaction Attribute</h5>
    </div>
    <mat-progress-bar class="mtc-progress" mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
  </div>
  <div>
    <form [formGroup]="transactForm" autocomplete="off" (ngSubmit)="addTransaction(transactForm)">
      <div class="calc-f">
        <div class="pt-2 pl-4 pr-4">
          <mat-form-field *ngIf="mode!='edit'" class="w-100">
            <mat-select placeholder="Select Type" (selectionChange)="selectTypeList($event)" formControlName="type">
              <mat-option *ngFor="let type of selectType" [value]="type.value">
                {{ type.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-select placeholder="Field Name" formControlName="name">
              <mat-option *ngFor="let data of listData" [value]="data.name">
                {{ data.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field class="w-100">
            <input type="text" matInput placeholder="Name" formControlName="transactName">
          </mat-form-field>
          <div formArrayName="transactLanguage">
            <div *ngFor="let values of languageSet;let transact=index">
              <div [formGroupName]="transact" fxLayout="row" fxLayoutAlign="space-between start">
                <mat-form-field floatLabel="never" fxFlex=30%>
                  <mat-select placeholder="Language" formControlName="language">
                    <mat-option *ngFor="let language of languageSet" [value]="language.language_id">
                      {{ language.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field floatLabel="never" fxFlex=40%>
                  <input matInput type="text" placeholder="Label" formControlName="value">
                </mat-form-field>
                <div class="addremove" fxFlex=10%>
                  <button type="button" mat-icon-button (click)="removeRow(transact)">
                    <mat-icon>remove</mat-icon>
                  </button>
                </div>
                <div>
                  <button type="button" mat-icon-button (click)="addRow()">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <mat-form-field class="w-100">
            <input matInput placeholder="Column Name" formControlName="CName" type="text">
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-select placeholder="Data Type" formControlName="dataType">
              <mat-option *ngFor="let type of dataTypes" [value]="type.value">
                {{ type.label }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
          <mat-form-field floatLabel="never" class="w-100">
            <mat-label> Description</mat-label>
            <textarea matInput type="text" formControlName="description"></textarea>
            <mat-error>Description is Required</mat-error>
          </mat-form-field>

          <!-- <input type="checkbox" formControlName="langDepedent">
          Is Language Dependent?
          <input type="checkbox" formControlName="isRequired">
          Is Required?
          <input type="checkbox" formControlName="inDetails">
          Is Show in Detail?
          <input type="checkbox" [checked]="element.status == 1? 1: 0" formControlName="status">
          Status -->
          <div class="togglegroup">
            <mat-slide-toggle class="example-margin" [checked]="lang_dependent" [(ngModel)]="lang_dependent"
              [ngModelOptions]="{standalone: true}" [disabled]="mode=='edit'">
              Language Dependent
            </mat-slide-toggle>
            <mat-slide-toggle class="example-margin" [checked]="is_detail" [(ngModel)]="is_detail"
              [ngModelOptions]="{standalone: true}" [disabled]="mode=='edit'">
              Show in Details
            </mat-slide-toggle>
            <mat-slide-toggle class="example-margin" [checked]="is_required" [(ngModel)]="is_required"
              [ngModelOptions]="{standalone: true}" [disabled]="mode=='edit'">
              Required
            </mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="footer" fxLayout="row" fxLayoutAlign="center center">
        <div class="pr-2">
          <button *ngIf="mode=='add'" mat-raised-button color="primary" [disabled]="transactForm.invalid">
            Submit
          </button>
          <button *ngIf="mode=='edit'" type="button" mat-raised-button color="primary" [disabled]="transactForm.invalid"
            (click)="updateValuation(transactForm)">
            Update
          </button>
        </div>
        <div class="pl-2">
          <button type="button" mat-raised-button (click)="cancel()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</div>