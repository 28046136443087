import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { AdminBreadcrumbService } from '../admin-breadcrumb/admin-breadcrumb.service';
import { NotificationService } from '../services/notification.service';
import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { UserAction } from "../user-action/user-activity"; 

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
export interface UploadHistory {
  sn: number
  upload_Id: any
  dscription: string
  file_name: string
  status: number
  added_by: any
  updated_by: any
  updated_date: string
}
@Component({
  selector: 'app-csv-upload',
  templateUrl: './csv-upload.component.html',
  styleUrls: ['./csv-upload.component.css']
})
export class CsvUploadComponent implements OnInit {
  displayedColumns: string[] = ['sn', 'upload_Id', 'description', 'file_name', 'status', 'updated_date'];
  dataSource: any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tableData: Array<UploadHistory> = [];
  fileName: string = '';
  fileUploadForm: UntypedFormGroup;
  fileToUpload: File = null;
  download = "Download Template"
  upload_access = {
    'GET': false,
    'POST': true,
    'PATCH': false,
    'DELETE': false
  };
  loader: boolean = false;
  //loader conf
  color: string = 'primary';
  mode: string = 'indeterminate';
  value: number = 40;
  //loader conf

  limit: number = 10;
  offset: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  pageEvent: PageEvent;
  history_loader: boolean = false;
  total_count: number = 0;
  currentPage: number;
  @ViewChild('myFileInput') myFileInput;

  constructor(private menus: AdminMenusService,
    private _crumbs: AdminBreadcrumbService,
    private notify: NotificationService,
    private api: ApiserviceService,
    private fnc: CommonfunctionService) {
    this.menus.setActive('csv_upload');
    this.menus.setActive('valuation');
    this.getAllowResources();
    // this.getAllowResource();
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: 'CSV Upload' };
    this._crumbs.mobiletitle = 'CSV Upload';
    this.fileUploadForm = new UntypedFormGroup({
      file: new UntypedFormControl(null, [Validators.required])
    })
  }

  handleFileInput(event) {
    let fileList: FileList = event.target.files;
    let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /zip-*/;
      if (!file.type.match(pattern)) {
        this.notify.notify('Unsupported file type', 'warn');
        this.fileUploadForm = new UntypedFormGroup({
          file: new UntypedFormControl(null, [Validators.required])
        })
        return;
      }
      this.fileToUpload = file;
      this.fileName = this.fileToUpload.name;
    } else {
      this.myFileInput.nativeElement.value = '';
    }
  }

  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next : (res: any) => {
        this.api.allowResources = res.data;
        this.upload_access = this.fnc.checkResourceAccess('csv_upload', false);
        setTimeout(() => {
          this.getUploadHistory();
        }, 600);
      }
      
    })

  }

  getColumnName(column) {
    return column.replace(/_/g, " ")
  }

  uploadFile(val) {
    if (!this.upload_access.POST) {
      this.notify.notify("You are not authorized to upload CSV data", "warn");
      return;
    }
    this.loader = true;
    let url = `uploads/priceData?added_by=${this.api.user_id}`;
    let ln = this.fileToUpload.size;
    let formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
    console.log(url)
    console.log(formData)
    this.api.postFileEst(url, formData).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.myFileInput.nativeElement.value = '';
        console.log(res)
        if (res.status == 201) {
          this.notify.notify(res.message, 'success');
          this.getUploadHistory();
          this.fileName = '';
        } else {
          this.notify.notify(res.message, 'error');
        }

      }, error: (err) => {
        this.myFileInput.nativeElement.value = '';
        this.fileName = '';
        this.loader = false;
        this.notify.notify(this.fnc.getErrorMessage(err.error), 'error');
      }
    })
  }
  /**
   * This is a funtion that use to get uploaded data
   * @returns 
   */
  getUploadHistory() {
    if (!this.upload_access.GET) {
      this.notify.notify("You are not authorized to Access CSV upload", "warn");
      return;
    }

    let url = `get-valuation-master?offset=${this.offset}&limit=${this.limit}`;

    if (this.offset == 0) {
      url += `&is_count=true`
    }
    this.history_loader = true;
    this.api.getData(url).subscribe((res: any) => {

      this.dataSource = new MatTableDataSource<UploadHistory>([])
      let result = res.data;
      this.tableData = [];
      if (this.offset == 0) {
        this.total_count = res.totalRecord;
      }
      if (result && result.length) {

        result.map((item, index) => {
          let data: UploadHistory = {
            sn: ((index + 1) + this.offset),
            upload_Id: item.id,
            dscription: item.dscription,
            file_name: item.file_name,
            status: item.status,
            added_by: item.added_by,
            updated_by: item.updated_by,
            updated_date: this.fnc.formatDateUTC(new Date(item.updated_date), true)
          }
          this.tableData.push(data);
        })

        this.dataSource = new MatTableDataSource<UploadHistory>(this.tableData)
      }

      this.history_loader = false;
    }, (err) => {
      this.tableData = [];
      this.history_loader = false;
    })
  }
  downloadtemplate() {
    if (this.download == "Downloading...") return;
    this.download = "Downloading...";
    let url = `uploads/download-template`;
    this.api.getData(url).subscribe((res: any) => {
      if (res.data) {
        window.open("https://" + res.data, "_blank");
        this.download = "Download Template";
      }
    },
      err => {
        this.download = "Download Template";
        this.notify.notify(this.fnc.getErrorMessage(err), 'error')
      })
  }

  getColumnWidth(column) {
    let final = '';
    let values: any[] = [
      { name: 'sl_No', value: '10%' },
      { name: 'upload_Id', value: '15%' },
      { name: 'description', value: '25%' },
      { name: 'file_name', value: '30%' },
      { name: 'status', value: '10%' },
      { name: 'updated_date', value: '20%' }
    ];
    if (column) {
      values.forEach((item) => {
        if (item.name === column) {
          final = item.value;
        };
      })
      return final;
    }
  }

  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getUploadHistory();
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.getAllowResources();
  }

}
