<div>
    <div fxHide="true" fxShow.gt-sm="true" fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
        <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="space-between center">
            <admin-breadcrumb></admin-breadcrumb>
            <div fxLayout="row" fxLayoutAlign="end center">
                <mat-select placeholder="select type" [(ngModel)]="selected_type" (selectionChange)="changeType($event)"
                    class="form-select-l">
                    <mat-option class="cp" value="">select type</mat-option>
                    <mat-option class="cp" *ngFor="let data of type" [value]="data">{{data}}</mat-option>
                </mat-select>
                <mat-select placeholder="select grade" [(ngModel)]="selected_grade"
                    (selectionChange)="changeGrade($event)" class="form-select-l ml-2">
                    <mat-option class="cp" value="">select grade</mat-option>
                    <mat-option class="cp" *ngFor="let data of grade" [value]="data">
                        {{data}}</mat-option>
                </mat-select>
               <input class="search-input" [formControl]="searchField" type="text" placeholder="Neigh search...">
            </div>
        </div>
    </div>
</div>
<div class="parent-container" [ngStyle]="{'height':height+'px'}">
    <mat-card appearance="outlined" class="example-card">
        <mat-card-content class="tabs">
            <div class="table-container">
                <mat-table [dataSource]="dataSource" class="w-100">
                    <mat-progress-bar class="w-100 abs" mode="indeterminate"></mat-progress-bar>

                    <ng-container matColumnDef="neigh_uid">
                        <mat-header-cell *matHeaderCellDef>Neigh Uid </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <span>{{element.neigh_uid}}</span>
                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="neigh_Name">
                        <mat-header-cell *matHeaderCellDef> Neigh Name </mat-header-cell>
                        <mat-cell *matCellDef="let element"><span>{{element.neigh_Name}}</span></mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                        <mat-cell *matCellDef="let element"><span>{{element.type}}</span></mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="grade">
                        <mat-header-cell *matHeaderCellDef> Grade </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
                                <span (click)="edit(element,'grade')" *ngIf="selectedCellId != element.neigh_uid+'_grade'"
                                    class="descr inline-fix-desc grade_">{{element.grade}}</span>
                                <mat-select placeholder="select grade" [(ngModel)]="changed_grade" *ngIf="selectedCellId == element.neigh_uid+'_grade'"
                                    class="grade_">
                                    <mat-option class="cp" *ngFor="let data of grade" [value]="data">
                                        {{data}}</mat-option>
                                </mat-select>
                                <div class="mlr-2" fxLayout="row" fxLayoutAlign="center center" *ngIf="selectedCellId == element.neigh_uid+'_grade'">
                                    <mat-button-toggle class="marg-2" value="left"
                                        (click)="updatePropertLandData(element)" title="Save">
                                        <mat-icon *ngIf="!updateLoader" class="size-sm accent">save</mat-icon>
                                        <mat-spinner *ngIf="updateLoader"></mat-spinner>
                                    </mat-button-toggle>
                                    <mat-button-toggle class="marg-2" value="center"
                                        (click)="cancelEdit(element.neigh_uid)" title="Cancel">
                                        <mat-icon class="size-sm"
                                        [ngClass]="{'default': selectedCellId == element.neigh_uid, 'inactive': selectedCellId != element.neigh_uid}">
                                        close</mat-icon>
                                    </mat-button-toggle>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="price_min">
                        <mat-header-cell *matHeaderCellDef> Price Min </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
                                <span (click)="edit(element,'price_min')" *ngIf="selectedCellId != element.neigh_uid+'_price_min'"
                                    class="grade_">{{element.price_min}}</span>
                                <input class="grade_" type="text" [(ngModel)]="changed_price_min" *ngIf="selectedCellId == element.neigh_uid+'_price_min'">
                                <div class="mlr-2" fxLayout="row" fxLayoutAlign="center center" *ngIf="selectedCellId == element.neigh_uid+'_price_min'">
                                    <mat-button-toggle class="marg-2" value="left"
                                        (click)="updatePropertLandData(element)" title="Save">
                                        <mat-icon *ngIf="!updateLoader" class="size-sm accent">save</mat-icon>
                                        <mat-spinner *ngIf="updateLoader"></mat-spinner>
                                    </mat-button-toggle>
                                    <mat-button-toggle class="marg-2" value="center"
                                        (click)="cancelEdit(element.neigh_uid)" title="Cancel">
                                        <mat-icon class="size-sm"
                                        [ngClass]="{'default': selectedCellId == element.neigh_uid, 'inactive': selectedCellId != element.neigh_uid}">
                                        close</mat-icon>
                                    </mat-button-toggle>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="price_max">
                        <mat-header-cell *matHeaderCellDef> Price Max </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
                                <span (click)="edit(element, 'price_max')" *ngIf="selectedCellId != element.neigh_uid+'_price_max'"
                                    class="grade_">{{element.price_max}}</span>
                                <input class="grade_" type="text" [(ngModel)]="changed_price_max" *ngIf="selectedCellId == element.neigh_uid+'_price_max'">
                                <div class="mlr-2" fxLayout="row" fxLayoutAlign="center center" *ngIf="selectedCellId == element.neigh_uid+'_price_max'">
                                    <mat-button-toggle class="marg-2" value="left"
                                        (click)="updatePropertLandData(element)" title="Save">
                                        <mat-icon *ngIf="!updateLoader" class="size-sm accent">save</mat-icon>
                                        <mat-spinner *ngIf="updateLoader"></mat-spinner>
                                    </mat-button-toggle>
                                    <mat-button-toggle class="marg-2" value="center"
                                        (click)="cancelEdit(element.neigh_uid)" title="Cancel">
                                        <mat-icon class="size-sm"
                                        [ngClass]="{'default': selectedCellId == element.neigh_uid, 'inactive': selectedCellId != element.neigh_uid}">
                                        close</mat-icon>
                                    </mat-button-toggle>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="price_avg">
                        <mat-header-cell *matHeaderCellDef> Price Avg </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
                                <span (click)="edit(element, 'price_avg')" *ngIf="selectedCellId != element.neigh_uid+'_price_avg'"
                                    class="grade_">{{element.price_avg}}</span>
                                <input class="grade_" type="text" [(ngModel)]="changed_price_avg" *ngIf="selectedCellId == element.neigh_uid+'_price_avg'">
                                <div class="mlr-2" fxLayout="row" fxLayoutAlign="center center" *ngIf="selectedCellId == element.neigh_uid+'_price_avg'">
                                    <mat-button-toggle class="marg-2" value="left"
                                        (click)="updatePropertLandData(element)" title="Save">
                                        <mat-icon *ngIf="!updateLoader" class="size-sm accent">save</mat-icon>
                                        <mat-spinner *ngIf="updateLoader"></mat-spinner>
                                    </mat-button-toggle>
                                    <mat-button-toggle class="marg-2" value="center"
                                        (click)="cancelEdit(element.neigh_uid)" title="Cancel">
                                        <mat-icon class="size-sm"
                                        [ngClass]="{'default': selectedCellId == element.neigh_uid, 'inactive': selectedCellId != element.neigh_uid}">
                                        close</mat-icon>
                                    </mat-button-toggle>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                        <mat-cell *matCellDef="let element"><span>#</span></mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" class="dragx1"></mat-row>
                </mat-table>
            </div>
            <div class="w-100" fxLayout="row">
                <mat-paginator class="w-100" [length]="total" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
                </mat-paginator>
              </div>
            <div *ngIf="loader1" class='loader-div'>
                <ngx-loading [show]="loader1"></ngx-loading>
            </div>
        </mat-card-content>
    </mat-card>

</div>