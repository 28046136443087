import { NgModule } from '@angular/core';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { AdminRoutingModule } from './admin-routing/admin-routing.module';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { SelectCityComponent } from './city-select/city-select-component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationService } from './services/notification.service';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FlexLayoutModule } from "@angular/flex-layout";
import { AdminMenusService } from './admin-sidebar/admin-menus.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DndModule } from 'ngx-drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminSidebarComponent } from './admin-sidebar/admin-sidebar.component';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AdminBreadcrumbComponent, AdminSearchbarComponent, AdminActionbuttonsComponent } from './admin-breadcrumb/admin-breadcrumb.component';
import { SortablejsModule } from '@dustfoundation/ngx-sortablejs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AdminMasterComponent } from './admin-master/admin-master.component';
import { UsermenuComponent, ProfileDialog } from './admin-header/usermenu/usermenu.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AdminBreadcrumbService } from './admin-breadcrumb/admin-breadcrumb.service';
import { AppNotificationService } from './services/app-notification.service';
import { AuthGuard } from './services/auth.guard';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { ShareService } from './services/share.service';
import { NotificationComponent, NotifyDetailComponent } from './notification/notification.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// import { AngularCesiumModule } from 'angular-cesium';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { LanguageComponent } from './language/language.component';
import { SafeUrlPipe } from '../safe-url.pipe';
import { AdminValuatorComponent } from './admin-valuator/admin-valuator.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MenuTableComponent, HtmlDialog } from './admin-valuator/menu-table.component';
import { MessagingService } from '../services/messaging.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { TemplatesFactoryComponent } from './admin-valuator/templates-factory/templates-factory.component';
import { ListComponent } from './admin-valuator/templates-factory/list/list.component';
import { ParagraphComponent } from './admin-valuator/templates-factory/paragraph/paragraph.component';
import { ImageComponent } from './admin-valuator/templates-factory/image/image.component';
import { ChartComponent } from './admin-valuator/templates-factory/chart/chart.component';
import { ComboToolbarComponent } from './admin-valuator/templates-factory/combo-toolbar/combo-toolbar.component';
import { SyncPropertyComponent } from './sync-property/sync-property.component';
import { Type1Component } from './admin-valuator/templates-factory/list/type1/type1.component';
import { Type2Component } from './admin-valuator/templates-factory/list/type2/type2.component';
import { Type3Component } from './admin-valuator/templates-factory/list/type3/type3.component';
import { Type4Component } from './admin-valuator/templates-factory/list/type4/type4.component';
import { Type5Component } from './admin-valuator/templates-factory/list/type5/type5.component';
import { ConfirmDialogBox } from './confirm-dialog/confirm-dialogbox';
import { CategoriesComponent } from './categories/categories.component';
import { PropertyLandConfigComponent } from './propertyLandConfig/property_land_config'
// import { CKEditorModule } from 'ngx-ckeditor';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CsvUploadComponent } from './csv-upload/csv-upload.component';
import { BlockValuationComponent } from './block-valuation/block-valuation.component';

import { PricingDownloadComponent } from './pricing-download/pricing-download.component';
import { FilterPipe } from '../filters/filterPipe';
import { FilterPipeCat } from '../filters/filterPipeCat';
import { RemoveBlockComponent } from './remove-block/remove-block.component';
import { SettingsComponent } from './settings/settings.component';
import { AddEditComponent } from './settings/add-edit/add-edit.component';
import { CategoryDialogComponent } from './settings/category-dialog/category-dialog.component';
import { BrokerMasterComponent } from './broker-master/broker-master.component';
import { AreaComponent, ConfirmDialog, LabelDialog } from './broker-master/company-area/area.component';
import { ListableAttributeComponent } from './listable-attribute/listable-attribute.component';
import { AddAttributeComponent } from './listable-attribute/add-attribute/add-attribute.component';
import { ValuationFactorMasterComponent } from './valuation-factor-master/valuation-factor-master.component';
import { AddValuationFactorMasterComponent } from './valuation-factor-master/add-valuation-factor-master/add-valuation-factor-master.component';
import { TransactionAttributeComponent } from './transaction-attribute/transaction-attribute.component';
import { AddTransactionAttributeComponent } from './transaction-attribute/add-transaction-attribute/add-transaction-attribute.component';
import { ComparisonParameterComponent } from './comparison-parameter/comparison-parameter.component';
import { AddParameterComponent } from './comparison-parameter/add-parameter/add-parameter.component';
import { ApiserviceService } from '../apiservice.service';
import { NeighbourhoodMarkersComponent } from './neighbourhood-markers/neighbourhood-markers.component';
import { SendNotificationComponent } from './send-notification/send-notification.component';
import { CityWiseTableComponent } from './city-wise-detail/city-wise-table/city-wise-table.component';
import { CityWiseInfoComponent } from './city-wise-detail/city-wise-info/city-wise-info.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { CityWiseDetailsComponent } from './city-wise-details/city-wise-details.component';
import { AddCityWiseDetailsComponent } from './city-wise-details/add-city-wise-details/add-city-wise-details.component';
import { CopyPasteComponent } from './city-wise-details/copy-paste/copy-paste.component';
import { ViewDataComponent } from './city-wise-details/view-data/view-data.component';
import { SelectCheckAllComponent } from './city-wise-details/select-check-all/select-check-all.component';
import { MultiSelectComponent } from './core/multi-select/multi-select.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageEditorComponent } from './image-editor/image-editor.component'
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SingleSelectComponent } from './core/single-select/single-select.component';
import { RedisComponent } from './redis/redis.component';
import { RedisViewComponent } from './redis-view/redis-view.component';
import { CompanyComponent } from './broker-master/company/company.component';
import { PackageComponent } from './broker-master/package/package.component';
import { PackageSubscriptionComponent } from './broker-master/package-subscription/package-subscription.component';
import { AddCompanyComponent } from './broker-master/company/add-company/add-company.component';
import { AddPackageComponent } from './broker-master/package/add-package/add-package.component';
import { AddBrokerSubscriptionComponent } from './broker-master/package-subscription/add-broker-subscription/add-broker-subscription.component';
import { ApiMasterComponent } from './broker-master/api-master/api-master.component';
import { AddApiMasterComponent } from './broker-master/api-master/add-api-master/add-api-master.component';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-2';
import { MasterUiListboxComponent } from './master-ui-listbox/master-ui-listbox.component';
import { AddMastersComponent } from './master-ui-listbox/add-masters/add-masters.component';

import { ManageCsvUploadComponent } from './manage-csv/csv-upload/manage-csv-upload.component';
import { CreateTemplateComponent } from './manage-csv/csv-template/create-template/create-template.component';
import { QueryIndexComponent } from './manage-csv/csv-template/query-index/query-index.component';
import { CsvTemplateComponent } from './manage-csv/csv-template/csv-template.component';
import { DeleteUploadCsvComponent } from './manage-csv/delete-upload-csv/delete-upload-csv.component';
import { SyncTableComponent } from './sync-table/sync-table.component';
import { BlogListComponent } from './blog/blog-list.component';
import { AddBlogComponent } from './blog/add-blog/add-blog.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  swipeEasing: false
};

@NgModule({
    imports: [
        // CKEditorModule,
        DragDropModule,
        CdkTableModule,
        CommonModule,
        // HttpModule,
        HttpClientModule,
        NgxPaginationModule,
        ImageCropperModule,
        AngularEditorModule,
        FormsModule,
        ReactiveFormsModule,
        SortablejsModule.forRoot({ animation: 150 }),
        RecaptchaModule,
        RecaptchaV3Module,
        // AngularCesiumModule.forRoot(),
        MatProgressSpinnerModule,
        RecaptchaFormsModule,
        AdminRoutingModule,
        MatInputModule,
        MatTableModule,
        PopoverModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        PerfectScrollbarModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        OverlayModule,
        MatIconModule, MatCheckboxModule, MatButtonModule, MatButtonToggleModule,
        MatCardModule, MatGridListModule, MatSidenavModule, MatToolbarModule,
        MatTabsModule, MatListModule, MatMenuModule, MatTooltipModule, MatDialogModule,
        FlexLayoutModule, MatSelectModule, MatRadioModule, MatSnackBarModule,
        BrowserAnimationsModule, MatTreeModule, MatExpansionModule,
        DndModule, MatPaginatorModule, MatChipsModule, MatStepperModule,
        NgxIntlTelInputModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.wanderingCubes,
            backdropBackgroundColour: 'rgba(0,0,0,0.3)',
            backdropBorderRadius: '4px',
            primaryColour: '#f69230',
            secondaryColour: '#16ceed',
            tertiaryColour: '#23cb6b',
            fullScreenBackdrop: false
        }),
    ],
    declarations: [
        SafeUrlPipe,
        AdminComponent,
        ConfirmDialog,
        LabelDialog,
        AdminLoginComponent,
        AdminSidebarComponent,
        AdminHeaderComponent,
        AdminBreadcrumbComponent,
        AdminMasterComponent,
        AdminSearchbarComponent,
        AdminActionbuttonsComponent,
        UsermenuComponent,
        SyncPropertyComponent,
        ProfileDialog,
        SelectCityComponent,
        NotificationComponent,
        NotifyDetailComponent,
        LanguageComponent,
        AdminProfileComponent,
        AdminValuatorComponent,
        MenuTableComponent,
        HtmlDialog,
        TemplatesFactoryComponent,
        ListComponent,
        ParagraphComponent,
        ImageComponent,
        ChartComponent,
        ComboToolbarComponent,
        Type1Component,
        Type2Component,
        Type3Component,
        Type4Component,
        Type5Component,
        ConfirmDialogBox,
        PropertyLandConfigComponent,
        CsvUploadComponent,
        CategoriesComponent,
        BlockValuationComponent,
        PricingDownloadComponent,
        FilterPipe,
        FilterPipeCat,
        RemoveBlockComponent,
        SettingsComponent,
        AddEditComponent,
        CategoryDialogComponent,
        BrokerMasterComponent,
        AreaComponent,
        ListableAttributeComponent,
        AddAttributeComponent,
        ValuationFactorMasterComponent,
        AddValuationFactorMasterComponent,
        TransactionAttributeComponent,
        AddTransactionAttributeComponent,
        ComparisonParameterComponent,
        AddParameterComponent,
        NeighbourhoodMarkersComponent,
        SendNotificationComponent,
        CityWiseTableComponent,
        CityWiseInfoComponent,
        CityWiseDetailsComponent,
        AddCityWiseDetailsComponent,
        CopyPasteComponent,
        ViewDataComponent,
        SelectCheckAllComponent,
        MultiSelectComponent,
        ImageEditorComponent,
        SingleSelectComponent,
        RedisComponent,
        RedisViewComponent,
        CompanyComponent,
        PackageComponent,
        PackageSubscriptionComponent,
        AddCompanyComponent,
        AddPackageComponent,
        AddBrokerSubscriptionComponent,
        ApiMasterComponent,
        AddApiMasterComponent,
        MasterUiListboxComponent,
        AddMastersComponent,
        ManageCsvUploadComponent,
        CreateTemplateComponent,
        QueryIndexComponent,
        CsvTemplateComponent,
        DeleteUploadCsvComponent,
        SyncTableComponent,
        BlogListComponent,
        AddBlogComponent
    ],
    providers: [
        ApiserviceService,
        MessagingService,
        NotificationService,
        AdminMenusService,
        AdminBreadcrumbService,
        AuthGuard,
        ShareService,
        AppNotificationService,
        { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcrU60UAAAAACWPSjhzScna9MxRym1WiSNzEFky' },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }
    ],
    exports: [AdminLoginComponent, SafeUrlPipe]
})
export class AdminModule { }
