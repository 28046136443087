<!-- <section class="location bg-gray" id="item5">
<ul class="list-group list-group-horizontal icon-group d-flex">
    <li class="list-group-item apartment"><span class="btn-circle-lg active"></span>Apartment</li>
    <li class="list-group-item retail"><span class="btn-circle-lg"></span> Retail Spaces</li>
    <li class="list-group-item offices"><span class="btn-circle-lg"></span> Offices</li>
    <li class="list-group-item industry"><span class="btn-circle-lg"></span>Industries</li>
</ul>
<ul class="list-group pl-5 pr-5 info-data">
    <li class="list-group-item d-flex justify-content-between align-items-center">
        Studio
        <span class="badge">300</span>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-center">
        2BHK
        <span class="badge">All type space - 1,500</span>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-center">
        3BHK
        <span class="badge">1,200</span>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-center">
        4BHK
        <span class="badge">700</span>
    </li>
</ul>
</section> -->
<section>
    <div class="row h-100">
        <div class="overall">
            <span>Overall</span>
            <span>78.5 %</span>
        </div>
        <div class="w-100">
            <div class="btn-group-wrapper">
                <span class="directions right">
                        <mat-icon class="est-gr">arrow_forward</mat-icon>
                </span>
                <span class="directions left">
                        <mat-icon class="est-gr">arrow_backward</mat-icon>
                </span>
                <div class="btn-group flex-wrap">
                    <button *ngFor="let val of accessibility_items" class="btn btn-default" type="button" data-toggle="collapse"
                        data-target="#bottom-section-business" aria-expanded="false" aria-controls="bottom-section">
                        <div class="w-100 d-flex flex-column justify-content-center">
                            <div class="img w-100 d-flex justify-content-center">
                                <mat-icon svgIcon="{{val.icon}}"></mat-icon>
                                <!-- <i class="fas {{val.icon}}"></i> -->
                            </div>
                            <div class="heading">
                                {{val.name}}
                            </div>
                            <div class="percentage text-center w-100">
                                {{val.percentage}}
                            </div>
                        </div>
                    </button>
                </div>
                <div class="bottom-section collapse" id="bottom-section-business">
                    <div class="row w-100 expand h-100 p-3">
                        <div class="col d-flex justify-content-around align-items-center" id="border">
                            <div class="d-flex justify-content-center align-items-center bottom-content">
                                Kuwait
                            </div>
                            <div class="d-flex justify-content-center align-items-center bottom-content">
                                72.3 %
                            </div>
                        </div>
                        <div class="col d-flex justify-content-around align-items-center" id="border">
                            <div class="d-flex justify-content-center align-items-center bottom-content">
                                Salmiya
                            </div>
                            <div class="d-flex justify-content-center align-items-center bottom-content">
                                68 %</div>
                        </div>
                        <div class="col d-flex justify-content-around align-items-center">
                            <div class="d-flex justify-content-center align-items-center bottom-content">
                                Fahafeel
                            </div>
                            <div class="d-flex justify-content-center align-items-center bottom-content">
                                61.1 %
                            </div>
                        </div>
                        <!-- <div class="col d-flex justify-content-around align-items-center">
                            <div class="d-flex justify-content-center align-items-center bottom-content">
                                Farwania
                            </div>
                            <div class="d-flex justify-content-center align-items-center bottom-content">
                                50 %</div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>