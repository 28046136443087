import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { ApiserviceService } from "../../apiservice.service";
import { CommonfunctionService } from '../../services/commonfunction.service';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { MatDialog } from '@angular/material/dialog';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-redis-view',
  templateUrl: './redis-view.component.html',
  styleUrls: ['./redis-view.component.css']
})
export class RedisViewComponent implements OnInit {
  id:string;
  pageSize: 25;
  length:number;
  pageSizeOptions: number[] = [25, 50, 100];
  displayedColumns = ['select', 'sno', 'key'];
  dataSource: any = [];
  redisData:any = [];
  filter;
  redisListLoader: boolean = false;
  selection = new SelectionModel(true, []);
  public redis_access = {
    'GET': true,
    'POST': false,
    'PATCH': false,
    'DELETE': true
  };
  // MatPaginator Output
  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }
  constructor(private notify: NotificationService, private apiService: ApiserviceService, private activatedRoute: ActivatedRoute,
    private fncSrv: CommonfunctionService, public dialog: MatDialog, private menu: AdminMenusService) {   
    this.getAllowResources();
    this.menu.setActive('manage_redis');
  }
  @ViewChild('paginator') paginator: MatPaginator;
  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');    
  }
  getAllowResources() {
    let body = `user/resource?place_id=${this.apiService.city_id}&user_id=${this.apiService.user_id}`
    this.apiService.getUmsData(body).subscribe({
      next: (res: any) => {
       
        this.apiService.allowResources = res.data;
        this.getAllowResource();``
        this.getRedisData();
      }
     
    })

  }
  getAllowResource() {
    this.redis_access = this.fncSrv.checkResourceAccess('redis', false);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) =>
          this.selection.select(row)
        );
  }
  /**  
   * This is a functionto to get redis data
   * Resources are checked for redis.
   * @returns {} Returns void.
   */
  getRedisData() {
    if (!this.redis_access.GET) {
      this.notify.notify("You are not Authorized to GET Redis Manage", "warn");
      return;
    }
    
    this.redisListLoader = true;
    let url = `/redis-keys?group_name=${this.id}`;
      this.apiService.getData(url).subscribe((res: any) => {     
        
      let data = res.data.map(
        (val, index) => {
          return {
            sno: index + 1,
            key: val,
          };
        }
      );
      this.length = data.length;
      this.dataSource = new MatTableDataSource(data);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
      },10);
      setTimeout(() => {
        this.redisListLoader = false;
      },20);
    },(err) => {
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.paginator = this.paginator;
      this.redisListLoader = false;
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
/**  
   * This is a functionto open confirmation dialog box while deleting a Parameter
   * Resources are checked for DELETE.
  
   * @requires MatDialog
   * @requires ConfirmDialogBox
   * @returns {} Returns void.
   */
 deleteKey() {
  if (!this.redis_access.DELETE) {
    this.notify.notify("You are not authorized to DELETE Redis", "warn");
    return;
  }
  let _data = {
    parent_data: this,
    message: `Do you want to delete?`
  }
  const dialogRef = this.dialog.open(ConfirmDialogBox, {
    width: '270px',
    panelClass: 'confirm_dialog',
    data: _data
  });
  dialogRef.afterClosed().subscribe(result => {
  });
}
public confirmDialogYes(check) {
  if (check == 'YES') {
      let keys = '';
      this.selection.selected.forEach((val, index) => {
        if (index === 0) {
          keys += val.key;
        } else {
          keys += `::${val.key}`;
        }
      });
      let body={
        "key":keys
      };
      let url = `/redis-delete-key`;
      this.apiService.postData(url,body).subscribe(
        (res: any) => {
          this.notify.notify('Deleted keys Successfully', 'success');
          this.selection.clear();
          this.dataSource.filter = '';
          this.filter = '';
          this.getRedisData();
        },
        (err) => {
          this.notify.notify('Something went wrong, Try again', 'warn');
          this.selection.clear();
        }
      );
    } else if (check == 'NO') {
      this.dialog.closeAll();
    }
}
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
