import { Component, OnInit } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { UserAction } from "../user-action/user-activity";
@Component({
  selector: 'app-pricing-download',
  templateUrl: './pricing-download.component.html',
  styleUrls: ['./pricing-download.component.css']
})
export class PricingDownloadComponent implements OnInit {
  public pricing_access = {
    'GET': false,
    'POST': true,
    'PATCH': false,
    'DELETE': false
  };
  loader: boolean = false;
  dataTypes: any = [
    {
      label: "Property Price",
      value: "property_price"
    },
    {
      label: "Land Price",
      value: "land_price"
    },
    {
      label: "Property Parameter",
      value: "property_parameter"
    },
    {
      label: "Property Weightage",
      value: "property_weightage"
    },
    {
      label: "Built-Up Data",
      value: "bua_data"
    },
    {
      label: "Built-Up Parameter",
      value: "bua_parameter"
    },
    {
      label: "Land Parameter",
      value: "land_parameter"
    },

  ];
  selectedType: any = null;
  selectedNeighborhood: any = null;
  selectedBlock: any = null;
  neighborhoodsList: any = [{
    neigh_id: 'default',
    neigh_uid: 'default',
    neigh_name: 'Default',
    no_of_blocks: null
  }];
  blocksList: any = [
    {block_uid: 'default',
    block_name: 'Default'}
  ];
  searchText: string = '';
  fromDate: any = null;
  toDate: any = null;
  constructor(private api: ApiserviceService, private menu: AdminMenusService,
    private fnc: CommonfunctionService,
    private notify: NotificationService) {
    this.menu.setActive('download-price-data');
    this.menu.setActive('valuation');
    this.getAllowResources()

  }

  ngOnInit() {


  }

  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next :(res: any) => {
        
        this.api.allowResources = res.data;
        this.pricing_access = this.fnc.checkResourceAccess('download_price', false);
        this.getNeighborhoods();
        // this.getApplicationCount();
      }
     
    })
  }


  getNeighborhoods() {
    if (!this.pricing_access.GET) {
      this.notify.notify("You are not Authorized to access Pricing Data Download", "warn");
      return;
    }
    this.loader = true;
    this.api.getData(`neighbourhoods/details?limit=1000&offset=0`).subscribe({next: (res: any) => {
      this.loader = false;
      if (!res.data?.length) return;
      let sortedList = this.fnc.neighborhoodSortOrder(res.data);
      sortedList.map((item: any) => {
        this.neighborhoodsList.push({
          neigh_id: item.neigh_id,
          neigh_uid: item.neigh_uid,
          neigh_name: (item.neigh_name[this.api.language]) ? item.neigh_name[this.api.language] : item.neigh_uid,
          no_of_blocks: item.no_of_blocks
        })
      });
    }, error:() => {
      this.loader = false;
    }})
  }

  /**
   * This is a function that use to get block
   * @param neigh_uid neigh_uid is param
   * @returns
   */
  getBlocks(neigh_uid) {
    if (!this.pricing_access.GET) {
      this.notify.notify("You are not Authorized to access Pricing Data Download", "warn");
      return;
    }
    if (!neigh_uid) return;
    this.blocksList = [];
    this.selectedBlock = null;
    if(neigh_uid == 'default'){
      this.blocksList =[{ block_uid: 'default', block_name: 'Default'}];
    }else{
      this.loader = true;
      this.api.getData(`blocks/details?neigh_uid=${neigh_uid}`).subscribe({next: (res: any) => {
        this.loader = false;
        if (!res.data) return;
        res.data.map((item: any) => {
          this.blocksList.push({
            block_uid: item.block_uid,
            block_name: (item.block_name[this.api.language]) ? item.block_name[this.api.language] : item.block_uid
          })
        });
      }, error:() => {
        this.loader = false;
      }})
    }

  }

  selectDataType(val) {
    if (val['block']) {
      let b = val['block'];

    }
    if (val && val['type']) this.selectedType = val['type'];
    if (val && val['neighborhood']) this.selectedNeighborhood = val['neighborhood'];
    if (val && val['block']) this.selectedBlock = val['block'];
    if (val['neighborhood']) {
      this.getBlocks(val['neighborhood']);
    }
  }

  getBlockValuation({ type, neighborhood, block }) {
    let from_date, to_date;
    this.loader = true;
    let url = `get-valuation-data?type=${type}&neigh_uid=${neighborhood}&block_uid=${(block && block.length > 0) ? block.toString() : []}`
    if ((from_date != null || from_date != undefined) && (to_date != null || to_date != undefined)) {
      url += `&from_date=${from_date}&to_date=${to_date}`;
    }

    this.api.getData(`${url}`).subscribe((res: any) => {
      if (res.data) {
        // const activity={
        //   neigh:neighborhood,
        //   block:block,
        //   // actionId: UserAction.add_attribute.Action_ID,
        //   data: res.data,
        //   actionType:UserAction.download_price_data.Action_ID
        // }
        // this.api.logUserActivity(activity)
        window.open(`https://${res.data}`, "_blank");
        this.notify.notify('Download success!', 'success');
      } this.loader = false;
    }, err => {
      this.notify.notify(this.fnc.getErrorMessage(err), 'error');
      this.loader = false;
    })
  }

  download() {
    if (!this.pricing_access.GET) {
      this.notify.notify("You are not Authorized to Download Pricing Data", "warn");
      return;
    }
    if (!this.selectedType) this.notify.notify('Please select a data type', 'warn');
    if (!this.selectedNeighborhood) this.notify.notify('Please select a Neighborhood', 'warn');
    if (!this.selectedType || !this.selectedNeighborhood) return;


    this.getBlockValuation({ type: this.selectedType, neighborhood: this.selectedNeighborhood, block: this.selectedBlock });
  }

}
