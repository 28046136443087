import { Component, OnInit, ViewChild, Inject, Input, AfterViewInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ApiserviceService } from '../../../apiservice.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { CommonfunctionService } from '../../../services/commonfunction.service'
import { UserAction } from "../../user-action/user-activity";
@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.css']
})
export class AreaComponent implements OnInit, AfterViewInit {
  //for mat chips
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  //for mat chips3`22

  showFiller: boolean = false;
  @ViewChild('gmap') gmapElement: any;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @Input() broker_access: any;
  map_overlay: any;
  map: any;
  polygons: any = [];
  selectedpolygon: any;
  drawingManager: any;
  circle: any;
  poly: any;
  polygon: any = [];
  mode: any;
  currentLanguage: any;
  selectedNeighborhood: any;
  neighbourhoods: any = [];
  neighbourhood_searchText: any;
  company_searchText: any;
  selectedForm: any = {
    "company_id": {
      type: "dropdown",
      isLanguageCoded: false,
      isRequired: true,
      dropDownListUrl: "company",
      dropdownLabel: { label: 'company_name', isLanguageCoded: true },
      dropdownValue: 'company_id'
    },
    "label": "string",
    "geom": "string",
    "status": "hidden",
    "user_id": 'hidden',
    "options": {
      type: 'area-component',
      label: 'Company Area',
      showLabel: false,
      id: 4,
      url: 'broker-user-area',
    }
  };
  dropDownList: any = [];
  dropDownValues: any = [];
  selectedBrokerId: any;
  selectedCompanyId: any;
  savedPolygons: any = [];
  load: boolean = false;
  constructor(private api: ApiserviceService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private notify: NotificationService,
    private fnc: CommonfunctionService) {

    this.getAllowResources();
    iconRegistry.addSvgIcon('circle', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/dots-circle.svg'))
      .addSvgIcon('polygon', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/line-path.svg'));
    this.currentLanguage = api.language;
  }

  /**
   * getAllowResources function to get record if valid user token
   * @returns Void
   */
  getAllowResources() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next:(res: any) => {
       
        this.api.allowResources = res.data;
        this.broker_access = this.fnc.checkResourceAccess('broker_master', false);
        this.getNeighborhoods();
      }
    
    })

  }

  ngAfterViewInit(): void {
    this.initMap();
  }
  ngOnInit() {
    this.initForm(this.selectedForm);
    // this.initMap();
  }

  saveData() {
    if (!this.broker_access.PATCH) {
      this.notify.notify("You Are Not Authorized to Save AREA", 'warn');
      return;
    }
    let body = {};
    let keys = Object.keys(this.selectedForm);
    if (!this.selectedCompanyId) this.notify.notify('Please select a Company', 'warn');
    // if (!this.selectedBrokerId) this.notify.notify('Please select a Broker', 'DISMISS');
    if (this.polygons.length == 0) this.notify.notify('No area defined, please define an area to save', 'warn');
    if (!this.selectedCompanyId || (this.polygons.length == 0)) return;
    keys.map((item) => {
      if (item !== 'options') {
        switch (item) {
          case 'broker_id':
            body['broker_id'] = +this.selectedBrokerId;
            break;
          case 'company_id':
            body['company_id'] = +this.selectedCompanyId;
            break;
          case 'label':
            body['label'] = {};
            break;
          case 'geom':
            body['geom'] = this.polygons;
            break;
          case 'status':
            body['status'] = 1;
            break;
          case 'user_id':
            body['user_id'] = +this.api.user_id;
            break;
          default:
            break;
        }
      }
    })
    body['action'] = 'company';
    body['broker_id'] = 1;
    this.load = true;

    this.api.postData(this.selectedForm.options.url, body).subscribe({
      next:(res: any) => {
      this.load = false;
      if (res && res.status == 201) {
        // const activity = {
        //   actionId: res.data,
        //   data: body,
        //   actionType: UserAction.company_area_assign.Action_ID
        // }
        // this.api.logUserActivity(activity);
        this.notify.notify(res.message, 'success');
      }
    }, error:err => {
      this.load = false;
      this.notify.notify(err.error.message, 'error');
    }});
  }

  getExistingAreas() {
    if (!this.broker_access.GET) {
      this.notify.notify("You are not Authorized to acces saved AREA", 'warn');
      return;
    }
    // if (!this.selectedBrokerId) return;
    if (!this.selectedCompanyId) return;
    this.load = true;
    let url = `${this.selectedForm.options.url}?broker_id=0&company_id=${this.selectedCompanyId}&status=1`;
    this.api.getData(url).subscribe((res: any) => {
      this.load = false;
      if (res.status == 200) {
        this.savedPolygons = res.data[0];
        if (this.savedPolygons)
          this.polygons = JSON.parse(this.savedPolygons?.geom);
        else
          this.polygons = [];
        this.initMap();
      } else {
        this.initMap();
      }
    }, err => {
      this.load = false;
      this.initMap();
    })
  }

  selectDropdownItem(e, name) { //getting selected broker and company
    // if (name == 'broker') {
    //   this.selectedBrokerId = e.value
    // }
    if (!this.broker_access.PATCH) {
      this.notify.notify("You Are Not Authorized to Access Companies", 'warn');
      return;
    }
    if (name == 'company') {
      this.selectedCompanyId = e.value
    }
    this.getExistingAreas();
  }

  //get label and value using the key sent from the JSON for selected form in broker component
  getLabel(val, item, getValueOnly = false) {
    let newLabel = '';
    let labelKey = item.label.label;
    let valueKey = item.valueKey;
    let isLanguageCoded = item.label.isLanguageCoded;
    if (getValueOnly) {
      newLabel = val[valueKey];
    } else {
      if (Array.isArray(labelKey)) {
        if (!isLanguageCoded) {
          labelKey.map((k) => {
            newLabel = `${newLabel} ${val[k]}`;
          })
        } else {
          labelKey.map((k) => {
            newLabel = newLabel = `${newLabel} ${val[k][this.api.language]}`;
          })
        }
      } else {
        if (!isLanguageCoded) {
          newLabel = val[labelKey];
        } else {
          newLabel = val[labelKey][this.api.language];
        }
      }
    }
    return newLabel;
  }

  //initialize the form using the JSON sent by broker component
  initForm(data) {
    let arr = Object.keys(data);
    arr.map((item: any) => {
      let datatype = data[item];
      if (typeof datatype === 'object' && datatype.type == 'dropdown') {
        this.dropDownList.push(datatype);
      }
    })
    this.getDropdownData(this.dropDownList);
  }

  getDropdownData(list) {
    list.map(async (obj: any) => {
      this.dropDownValues.push({
        name: obj.dropDownListUrl,
        value: await this.getList(obj),
        label: obj.dropdownLabel,
        valueKey: obj.dropdownValue,
        isLanguageCoded: obj.isLanguageCoded
      });
    })
  }

  getList(obj) {
    return new Promise((resolve) => {
      this.api.getData(`${obj.dropDownListUrl}?status=1`).subscribe((res: any) => {
        if (res.status == 200) {
          resolve(res.data);
        } else {
          resolve([]);
        }
      }, err => {
        resolve([]);
      })
    })
  }

  addLabel(neigh_obj = null) {
    let dialogRef = this.dialog.open(LabelDialog, {
      width: '350px',
      panelClass: 'create-master-panel',
      data: { parent: this, neigh_obj: neigh_obj }//optional
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  reset() {
    this.drawingManager.setDrawingMode(null);
    this.mode = '';
  }

  drawCircle() {
    if (!this.broker_access.PATCH) {
      this.notify.notify("You Are Not Authorized to Draw Circle", 'warn');
      return;
    }
    this.clearDrawing();
    this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
    this.mode = 'Circle';
  }

  drawPolygon() {
    if (!this.broker_access.PATCH) {
      this.notify.notify("You Are Not Authorized to Draw Polygon", 'warn');
      return;
    }
    this.clearDrawing();
    this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    this.mode = 'Polygon';
  }

  clearDrawing(clear_all = false) {
    if (!this.circle && !this.poly) {
      this.drawingManager.setMap(this.map);
    }
    if (clear_all) {
      if (this.poly) {
        this.poly.setMap(null);
      }
      if (this.circle) {
        this.circle.setMap(null);
      }
    }
  }

  saveDialog(): void {
    this.selectedNeighborhood = null;
    this.mode = '';
    this.reset();
    this.initMap();
    this.drawingManager.setDrawingMode(null);
    this.drawingManager.setMap(this.map);
  }

  remove(poly) {
    let index = this.polygons.indexOf(poly);
    if (index >= 0) {
      this.polygons.splice(index, 1);
    }
    this.sidenav.close();
    this.saveDialog();
  }


  initMap() {
    let lt = 29.353164821638565;
    let ln = 47.61034825389613;
    if (localStorage.getItem('city_detail')) {
      let city_detail = JSON.parse(localStorage.getItem('city_detail'));
      if (city_detail.lat) {
        lt = city_detail.lat;
        ln = city_detail.lon;
      }
    }
    let map = new google.maps.Map(this.gmapElement.nativeElement, {
      center: { lat: lt, lng: ln },
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.HYBRID
    });
    this.map_overlay = map.overlayMapTypes;
    this.map = map;

    if (this.polygons) {
      this.polygons.forEach(geomdata => {
        if (geomdata.geom) {
          let poly = new google.maps.Polygon({
            paths: this.getgeom(geomdata.geom),
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: '#FF0000',
            fillOpacity: 0.35
          });
          poly.setMap(map);
          this.getBound(poly);
          let info = new google.maps.InfoWindow();
          google.maps.event.addListener(poly, 'click', (e) => {
            this.selectedpolygon = geomdata;
            info.setContent(geomdata.label[this.api.language]);
            let latLng = e.latLng;
            info.setPosition(latLng);
            info.open(map);
          });
        }
      });
    }

    // let circle;
    this.drawingManager = new google.maps.drawing.DrawingManager({

      drawingMode: google.maps.drawing.OverlayType.MARKER,
      drawingControl: false,
      circleOptions: {
        strokeColor: '#F1AE65',
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: '#F1AE65',
        fillOpacity: 0.35,
        editable: false,
        draggable: false
      },
      polygonOptions: {
        editable: false,
        draggable: false
      }
    });

    //this.drawingManager.setMap(map);
    google.maps.event.addListener(this.drawingManager, 'circlecomplete', (circle) => {
      let radius = circle.getRadius();
      let clat = circle.getCenter().lat();
      let clng = circle.getCenter().lng();
      let position = new google.maps.LatLng(clat, clng);
      map.setCenter(position);
      map.fitBounds(circle.getBounds());
      this.circle = circle;
      const numberOfEdges = 32;

      let polygonc = this.generateGeoJSONCircle(position, radius, numberOfEdges);
      this.polygon = polygonc.coordinates;
      this.addLabel();
    });


    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon) => {
      let polygonBounds = polygon.getPath();
      let pBounds = polygon.getPaths();
      this.poly = polygon;
      let coordinates = [];
      for (let i = 0; i < polygonBounds.length; i++) {
        coordinates.push([polygonBounds.getAt(i).lng() + ' ' + polygonBounds.getAt(i).lat()]);
      }
      coordinates.push(coordinates[0]);
      this.polygon = [coordinates];
      this.addLabel();
    });

    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      this.drawingManager.setDrawingMode(null);
      if (event.type == 'circle') {
        let radius = event.overlay.radius;
        let center = { lat: event.overlay.center.lat() };
      }
    });

  }
  getNeighborhoods() {
    this.api.getData('neighbourhoods/details')
      .subscribe((res: any) => {
        if (res.status == 200) {
          const { data } = res;

          if (data.length) {
            let sortedList = this.fnc.neighborhoodSortOrder(data);
            this.neighbourhoods = sortedList;
          }
        }

      });
  }

  selectNeighbourhood() {
    let neighbourhood = this.fnc.getArrayValue('neigh_uid', this.selectedNeighborhood, this.neighbourhoods);
    let geomArr = [], newArr = [], geom = neighbourhood.geom, flag = false;
    geom = geom.replace('MULTIPOLYGON', '');
    geom = geom.replace('POLYGON', '');
    geom = geom.replace('(((', '');
    geom = geom.replace(')))', '');
    geom = geom.replace('(', '');
    geom = geom.replace(')', '');
    geom = geom.replace('(', '');
    geom = geom.replace(')', '');
    geomArr = geom.split(',');
    geomArr.forEach(latlng => {
      newArr.push([latlng]);
    });
    this.polygon = newArr;
    //checking if polygon exists
    if (this.polygons) {
      this.polygons.forEach(element => {
        if (element.geom == 'POLYGON((' + this.polygon.toString() + '))') {
          flag = true;
        }
      });
    }
    if (!flag) {
      this.drawingManager.setDrawingMode(null);
      this.addLabel({ poly: { label: neighbourhood.neigh_name, geom: 'POLYGON((' + this.polygon.toString() + '))' } });
    } else {
      this.notify.notify('Area already exist', 'warn');
    }
  }

  //convert circle to polygon function
  generateGeoJSONCircle(center: any, radius: any, numSides: any) {

    var points = [],
      degreeStep = 360 / numSides;

    for (var i = 0; i < numSides; i++) {
      var gpos = google.maps.geometry.spherical.computeOffset(center, radius, degreeStep * i);
      points.push([gpos.lng() + ' ' + gpos.lat()]);
    };

    // Duplicate the last point to close the geojson ring
    points.push(points[0]);
    return {
      type: 'Polygon',
      coordinates: [points]
    };
  }

  getgeom(data) {
    if (data != null) {
      let str = data;
      let result = str.match(/\(\((.*)\)\)/);
      let str2 = result[1];
      let latlon = str2.split(',');

      let temp = [];
      latlon.forEach(el => {
        let s = el.split(' ')
        temp.push({ "lat": +s[1], "lng": +s[0] });
      });
      return temp;
    }
  }

  addLayer(options) {
    if (!options.url) return;
    if (options.format == 'wms') {
      var layer =
        new google.maps.ImageMapType(
          {
            getTileUrl: (coord: google.maps.Point, zoom: number) => {
              // Compose URL for overlay tile
              var s = Math.pow(2, zoom);
              var twidth = 256;
              var theight = 256;

              //latlng bounds of the 4 corners of the google tile
              //Note the coord passed in represents the top left hand (NW) corner of the tile.
              var gBl = this.map.getProjection().fromPointToLatLng(new google.maps.Point(coord.x * twidth / s, (coord.y + 1) * theight / s)); // bottom left / SW
              var gTr = this.map.getProjection().fromPointToLatLng(new google.maps.Point((coord.x + 1) * twidth / s, coord.y * theight / s)); // top right / NE

              // Bounding box coords for tile in WMS pre-1.3 format (x,y)

              var bbox = parseFloat(gBl.lng()) + "," + parseFloat(gBl.lat()) + "," + parseFloat(gTr.lng()) + "," + parseFloat(gTr.lat());

              //base WMS URL
              var url = options.url;

              url += "&service=WMS";           //WMS service
              url += "&version=1.1.0";         //WMS version
              url += "&request=GetMap";        //WMS operation
              url += "&layers=gisdb:" + options.layerName; //WMS layers to draw
              url += "&styles=";               //use default style
              url += "&format=image/png";      //image format
              url += "&TRANSPARENT=TRUE";      //only draw areas where we have data
              url += "&srs=EPSG:4326";         //projection WGS84
              url += "&bbox=" + bbox;          //set bounding box for tile
              url += "&width=256";             //tile size used by google
              url += "&height=256";
              url += "&tiled=true";
              if (options.assignment) url += "&viewparams=assignment_no:" + options.assignment;
              //url = "http://51.15.221.10:8080/geoserver/gisdb/wms?service=WMS&version=1.1.0&request=GetMap&layers=gisdb:properties&styles=&
              //bbox=47.9728552938352,29.33170430618,48.0253671652929,29.379311241798&width=768&height=696&srs=EPSG:4326&format=image/png";
              return url;                 //return WMS URL for the tile
            },
            tileSize: new google.maps.Size(256, 256),
            opacity: 0.85,
            name: options.name
          });

      // add WMS layer to map
      this.map.overlayMapTypes.push(layer);

    }
  }

  getBound(polygon) {
    var bounds = new google.maps.LatLngBounds();
    var paths = polygon.getPaths();
    var path;
    for (var i = 0; i < paths.getLength(); i++) {
      path = paths.getAt(i);
      for (var ii = 0; ii < path.getLength(); ii++) {
        bounds.extend(path.getAt(ii));
      }
    }
    this.map.fitBounds(bounds);
  }

  confirm(poly) {
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '400px',
      panelClass: 'create-master-panel',
      data: {
        parent: this,
        selectedPoly: poly
      }//optional
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  currentArea(poly){
    
  }
}

@Component({
  selector: 'confirm',
  templateUrl: './confirm.html',
  styleUrls: ['./area.component.css']
})

export class ConfirmDialog {
  selectedPoly: any;
  currentLanguage: any;
  constructor(public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public api: ApiserviceService) {
    this.selectedPoly = this.data.selectedPoly;
    this.currentLanguage = api.language;
  }
  delete() {
    this.data.parent.remove(this.selectedPoly);
    this.dialogRef.close();
  }
  close() {
    // this.data.parent.sidenavClose();
    this.dialogRef.close();
  }
  ngOninit() { }
}

@Component({
  selector: 'label-dialog',
  templateUrl: './label-dialog.html',
  styleUrls: ['./area.component.css']
})

export class LabelDialog {
  language_valueset: Array<{}> = [];
  languages: Array<any>;
  labelValueForm: any;
  parent: any;
  constructor(public dialogRef: MatDialogRef<LabelDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, private api: ApiserviceService,
    private _fb: UntypedFormBuilder) {
    // this.api.getLanguageList();
    this.parent = this.data.parent;
    this.languages = this.api.language_list;

    this.labelValueForm = this._fb.group({
      language: this._fb.array([])
    })
    this.addRow();
    if (this.data.neigh_obj) {
      this.labelValueForm.patchValue({ 'language': [{ language: this.languages[0].language_id, value: this.data.neigh_obj.poly.label[this.api.language] }] });
    } else {
      this.labelValueForm.patchValue({ 'language': [{ language: this.languages[0].language_id, value: '' }] });
    }
  }

  addLabel(data, e) {
    let body = { label: {}, geom: null };
    let val = data.value
    val.language.forEach((v) => {
      body.label[v.language] = v.value;
    });
    body.geom = 'POLYGON((' + this.parent.polygon + '))';
    if (!this.data.neigh_obj) {
      this.parent.polygons.push(body);
    } else {
      this.parent.polygons.push(this.data.neigh_obj.poly);
    }
    this.parent.saveDialog();
    this.dialogRef.close();
  }

  initLanguageFields() {
    return this._fb.group({
      language: ['', Validators.required],
      value: ['', Validators.required]
    });
  }

  addRow() {
    this.language_valueset.push({ language: '', value: '' });
    /* #Form */
    let dd = <UntypedFormArray>this.labelValueForm.controls.language;
    dd.push(this.initLanguageFields());
    /* End - #Form */
  }

  removeRow(key) {
    if (this.language_valueset.length > 1) {
      this.language_valueset.splice(key, 1);
      let dd = <UntypedFormArray>this.labelValueForm.controls.language;
      dd.removeAt(key);
    }
  }

  close() {
    this.parent.reset();
    this.parent.initMap();
    this.parent.drawingManager.setDrawingMode(null);
    this.parent.drawingManager.setMap(this.parent.map);
    this.dialogRef.close();
  }

  ngOninit() { }
}