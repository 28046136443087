
    <ul class="list-group list-group-horizontal icon-group">
      <li class="list-group-item"><span class="btn btn-circle"><img
            src="./assets/images/template/icons/Single_Family.svg" alt=""></span>
        Single-Family<br> Home </li>
      <li class="list-group-item"><span class="btn btn-circle"><img src="./assets/images/template/icons/bed.svg"
            alt=""></span> 3 Beds</li>
      <li class="list-group-item"><span class="btn btn-circle"><img
            src="./assets/images/template/icons/bathtub-with-opened-shower.svg" alt=""></span> 3 Baths</li>
      <li class="list-group-item"><span class="btn btn-circle"><img src="./assets/images/template/icons/area.svg"
            alt=""></span> 3496 sqft</li>
      <li class="list-group-item"><span class="btn btn-circle"><img src="./assets/images/template/icons/Builtinl.svg"
            alt=""></span> Built in
        1993
      </li>
    </ul>